import * as React from 'react';

const IconEmailFilled = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M18 2a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h16Zm-1.232 4.427a1 1 0 0 0-1.393-.246l-5.371 3.76-5.37-3.76a1 1 0 1 0-1.147 1.638l5.938 4.159c.174.122.374.18.571.18h.017c.197 0 .397-.058.57-.18l5.94-4.159a1 1 0 0 0 .245-1.392Z" />
	</svg>
);

export default IconEmailFilled;

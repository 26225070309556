import * as React from 'react';

const IconEye = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M19.686 9.394c.269.431.29.912.03 1.372-2 3.441-4.44 6.031-7.67 6.923-3.591 1-6.69-.47-9.38-3.608-.86-1-1.56-2.256-2.32-3.403-.29-.431-.34-.922-.061-1.412 1.83-3.197 4.06-5.628 6.98-6.707.92-.333 1.53-.559 2.39-.559 3.933.041 6.43 2.06 8.66 5.227.49.686.92 1.431 1.37 2.167Zm-9.68 5.442c2.736-.012 5.006-2.208 4.994-4.832-.012-2.79-2.223-4.998-4.96-4.974C7.249 5.054 4.99 7.25 5 9.933c.013 2.778 2.177 4.903 5.007 4.903Zm-.103-2.222c-1.607.004-2.844-1.159-2.85-2.679-.005-1.472 1.28-2.676 2.87-2.687 1.563-.01 2.817 1.198 2.824 2.72.007 1.44-1.286 2.642-2.844 2.646Z" />
	</svg>
);

export default IconEye;

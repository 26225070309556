import * as React from 'react';

const IconCloseSmall = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M5.707 4.293 9.95 8.535l4.243-4.242a1 1 0 1 1 1.415 1.414L11.364 9.95l4.243 4.243a1 1 0 1 1-1.415 1.415L9.95 11.364l-4.242 4.243a1 1 0 0 1-1.414-1.415L8.535 9.95 4.293 5.707a1 1 0 0 1 1.414-1.414Z" />
	</svg>
);

export default IconCloseSmall;

import * as React from 'react';

const IconComment = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M18.325 3.333c0-.916-.742-1.666-1.658-1.666H3.333c-.916 0-1.666.75-1.666 1.666v10c0 .917.75 1.667 1.666 1.667H15l3.333 3.333-.008-15Zm-4.158 8.334H5.833A.836.836 0 0 1 5 10.833c0-.458.375-.833.833-.833h8.334c.458 0 .833.375.833.833a.836.836 0 0 1-.833.834Zm0-2.5H5.833A.836.836 0 0 1 5 8.333c0-.458.375-.833.833-.833h8.334c.458 0 .833.375.833.833a.836.836 0 0 1-.833.834Zm0-2.5H5.833A.836.836 0 0 1 5 5.833C5 5.375 5.375 5 5.833 5h8.334c.458 0 .833.375.833.833a.836.836 0 0 1-.833.834Z" />
	</svg>
);

export default IconComment;

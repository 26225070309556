import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { ChangeEvent, ForwardedRef, forwardRef, useRef, useState } from 'react';
import InputNumberControl from '~common/components/input-number/InputNumberControl';
import { mergeRefs } from '~common/helpers/mergeRefs';
import { baseInputStyles, baseInputWrapperStyles } from '~common/styles/shared';
import { BaseInputProps } from '~common/types/input';
import Sizes from '~tokens/sizes/Sizes';
import Spacings from '~tokens/spacings/Spacings';

// =================================================================================================
// INTERFACE
// =================================================================================================

export interface InputNumberProps extends BaseInputProps {
	/** Callback that will fire anytime the value of the input changes. */
	onChange?: (event: ChangeEvent<HTMLInputElement>, value: number) => void;
}

// =================================================================================================
// COMPONENT
// =================================================================================================

const InputNumber = forwardRef(
	(
		{
			className,
			disabled,
			feSeverity,
			feSize = 'md',
			max,
			min,
			onBlur,
			onChange,
			required,
			value,
			...rest
		}: InputNumberProps,
		ref: ForwardedRef<HTMLInputElement>
	) => {
		const inputRef = useRef<HTMLInputElement>(null);
		const mergedRefs = mergeRefs([ref, inputRef]);
		const [buttonDown, setButtonDown] = useState(false);

		const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
			event.target.value = Number(event.target.value).toString();
			onChange && onChange(event, Number(event.target.value));
		};

		return (
			<StyledInputNumber className={className} data-comp="input-number">
				<StyledInputNumberInput
					{...rest}
					disabled={disabled}
					feSize={feSize}
					feSeverity={feSeverity}
					max={max}
					min={min}
					onBlur={(event) => (!buttonDown ? onBlur && onBlur(event) : event.target.focus())}
					onChange={onChangeHandler}
					ref={mergedRefs}
					required={required}
					type="number"
					value={value}
				/>
				<InputNumberControl
					disabled={disabled}
					inputRef={inputRef}
					onBlur={onBlur}
					onClick={() => {
						setButtonDown(false);
					}}
					onMouseDown={() => setButtonDown(true)}
					severity={feSeverity}
					type="Increment"
				/>
				<InputNumberControl
					disabled={disabled}
					inputRef={inputRef}
					onBlur={onBlur}
					onClick={() => {
						setButtonDown(false);
					}}
					onMouseDown={() => setButtonDown(true)}
					severity={feSeverity}
					type="Decrement"
				/>
			</StyledInputNumber>
		);
	}
);

InputNumber.displayName = 'InputNumber';
export default InputNumber;

// =================================================================================================
// STYLE
// =================================================================================================

const StyledInputNumber = styled.div`
	${baseInputWrapperStyles};
`;

const StyledInputNumberInput = styled.input(
	({ feSeverity, feSize }: Pick<InputNumberProps, 'feSeverity' | 'feSize'>) => css`
		${baseInputStyles(feSize, feSeverity)};

		padding-right: calc(${Sizes.S48} + ${Spacings.Sm});
	`
);

import styled from '@emotion/styled';
import React, { useState } from 'react';
import ReactDatePicker, { CalendarContainer, ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import InputDate from '~common/components/input-date/InputDate';
import Layers from '~common/constants/layers';
import { BaseInputProps } from '~common/types/input';
import { datePickerCalendarStyles } from '~components/date-picker/datePickerCommonStyles';
import DatePickerFieldHeader from '~components/date-picker/DatePickerHeader';
import Portal from '~components/portal/Portal';

// =================================================================================================
// INTERFACE
// =================================================================================================

type RangeValue = [Date | null, Date | null];
type SingleValue = Date | null;
type DateValue<T extends boolean> = T extends true ? RangeValue : SingleValue;

export interface DatePickerFieldProps<T extends boolean>
	extends Omit<BaseInputProps, 'onChange' | 'value'> {
	feDateDisplay?: 'first' | 'last';
	feHideIcon?: boolean;
	feReactDatePickerProps?: Omit<ReactDatePickerProps<never, T>, 'onChange' | 'selectsRange'>;
	locale?: Locale;
	onChange: ReactDatePickerProps<never, T>['onChange'];
	value: DateValue<T>;
}

// =================================================================================================
// COMPONENT
// =================================================================================================

/**
 * @internal
 */
const DatePickerField = <T extends boolean = false>({
	'aria-errormessage': ariaErrorMessage,
	'aria-invalid': ariaInvalid,
	disabled = false,
	feDateDisplay,
	feHideIcon = false,
	feReactDatePickerProps,
	feSeverity,
	feSize = 'md',
	id,
	locale,
	onChange,
	value,
}: DatePickerFieldProps<T>) => {
	const [renderPortal, setRenderPortal] = useState(false);
	const range = Array.isArray(value);

	return (
		<ReactDatePicker
			{...feReactDatePickerProps}
			calendarContainer={StyledCalendarContainer}
			calendarStartDay={1}
			customInput={
				<InputDate
					aria-errormessage={ariaErrorMessage}
					aria-invalid={ariaInvalid}
					disabled={disabled}
					feDateDisplay={feDateDisplay}
					feHideIcon={feHideIcon}
					feSeverity={feSeverity}
					feSize={feSize}
					id={id} /* TODO: Add a ref as a prop */
				/>
			}
			dateFormat="yyyy-MM-dd"
			disabled={disabled}
			endDate={range ? value[1] : value}
			id={range ? undefined : id}
			locale={locale}
			monthsShown={range ? 2 : undefined}
			onCalendarClose={() => setRenderPortal(false)}
			onCalendarOpen={() => setRenderPortal(true)}
			onChange={onChange}
			popperContainer={({ children }) => (
				<StyledPortal feRender={renderPortal} data-portals="date-picker">
					{children}
				</StyledPortal>
			)}
			renderCustomHeader={(props) => <DatePickerFieldHeader {...props} locale={locale} />}
			renderDayContents={(day) => <StyledDatePickerFieldDay>{day}</StyledDatePickerFieldDay>}
			selected={range ? value[0] : value}
			selectsRange={range}
			showPopperArrow={false}
			startDate={range ? value[0] : undefined}
		/>
	);
};

DatePickerField.displayName = 'DatePickerField';
export default DatePickerField;

// =================================================================================================
// STYLE
// =================================================================================================

const StyledPortal = styled(Portal)`
	& .react-datepicker-popper {
		z-index: ${Layers.DatePicker};
	}
`;

const StyledDatePickerFieldDay = styled.div`
	align-items: center;
	display: flex;
	height: 100%;
	justify-content: center;
`;

const StyledCalendarContainer = styled(CalendarContainer)`
	${datePickerCalendarStyles}
`;

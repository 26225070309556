enum IconColors {
	Blue = 'rgb(79, 132, 224)',
	Brand = 'rgb(15, 88, 214)',
	Gray = 'rgb(103, 111, 124)',
	Green = 'rgb(4, 137, 66)',
	Orange = 'rgb(255, 128, 4)',
	Purple = 'rgb(120, 30, 147)',
	Red = 'rgb(204, 0, 0)',
	White = 'rgb(255, 255, 255)',
	Yellow = 'rgb(255, 202, 21)',
}

export default IconColors;

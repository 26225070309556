import { default as ArrowDown } from './assets/IconArrowDown';
import { default as ArrowDownUp } from './assets/IconArrowDownUp';
import { default as ArrowLeft } from './assets/IconArrowLeft';
import { default as ArrowRight } from './assets/IconArrowRight';
import { default as ArrowUp } from './assets/IconArrowUp';
import { default as Article } from './assets/IconArticle';
import { default as ArtificialIntelligence } from './assets/IconArtificialIntelligence';
import { default as Asset } from './assets/IconAsset';
import { default as Attachment } from './assets/IconAttachment';
import { default as BandCursor } from './assets/IconBandCursor';
import { default as Bands } from './assets/IconBands';
import { default as BatteryEmpty } from './assets/IconBatteryEmpty';
import { default as BatteryFull } from './assets/IconBatteryFull';
import { default as BatteryLow } from './assets/IconBatteryLow';
import { default as BearingFault } from './assets/IconBearingFault';
import { default as Book } from './assets/IconBook';
import { default as Bulb } from './assets/IconBulb';
import { default as Burger } from './assets/IconBurger';
import { default as CPM } from './assets/IconCPM';
import { default as Calendar } from './assets/IconCalendar';
import { default as CalendarBooked } from './assets/IconCalendarBooked';
import { default as CalendarEmpty } from './assets/IconCalendarEmpty';
import { default as CalendarNotBooked } from './assets/IconCalendarNotBooked';
import { default as CalendarRecurring } from './assets/IconCalendarRecurring';
import { default as CaretDown } from './assets/IconCaretDown';
import { default as CaretUp } from './assets/IconCaretUp';
import { default as CaretUpDown } from './assets/IconCaretUpDown';
import { default as Chat } from './assets/IconChat';
import { default as Check } from './assets/IconCheck';
import { default as CheckCircle } from './assets/IconCheckCircle';
import { default as CheckSmall } from './assets/IconCheckSmall';
import { default as ChevronDown } from './assets/IconChevronDown';
import { default as ChevronLeft } from './assets/IconChevronLeft';
import { default as ChevronRight } from './assets/IconChevronRight';
import { default as ChevronUp } from './assets/IconChevronUp';
import { default as ChevronUpDown } from './assets/IconChevronUpDown';
import { default as Close } from './assets/IconClose';
import { default as CloseAllFaults } from './assets/IconCloseAllFaults';
import { default as CloseFault } from './assets/IconCloseFault';
import { default as CloseSmall } from './assets/IconCloseSmall';
import { default as ColumnGraph } from './assets/IconColumnGraph';
import { default as Comment } from './assets/IconComment';
import { default as Connection1 } from './assets/IconConnection1';
import { default as Connection2 } from './assets/IconConnection2';
import { default as Connection3 } from './assets/IconConnection3';
import { default as Connection4 } from './assets/IconConnection4';
import { default as Danger } from './assets/IconDanger';
import { default as DefectFrequencies } from './assets/IconDefectFrequencies';
import { default as DefectFrequenciesAlternative } from './assets/IconDefectFrequenciesAlternative';
import { default as DoubleChevronLeft } from './assets/IconDoubleChevronLeft';
import { default as DoubleChevronRight } from './assets/IconDoubleChevronRight';
import { default as Download } from './assets/IconDownload';
import { default as Draft } from './assets/IconDraft';
import { default as DraftFilled } from './assets/IconDraftFilled';
import { default as DraftOutlined } from './assets/IconDraftOutlined';
import { default as DragNDrop } from './assets/IconDragNDrop';
import { default as Drop } from './assets/IconDrop';
import { default as Duplicate } from './assets/IconDuplicate';
import { default as Edit } from './assets/IconEdit';
import { default as EmailFilled } from './assets/IconEmailFilled';
import { default as EmailOutlined } from './assets/IconEmailOutlined';
import { default as Exclamation } from './assets/IconExclamation';
import { default as Eye } from './assets/IconEye';
import { default as EyeHidden } from './assets/IconEyeHidden';
import { default as EyeVisible } from './assets/IconEyeVisible';
import { default as Filter } from './assets/IconFilter';
import { default as Forbidden } from './assets/IconForbidden';
import { default as FullScreen } from './assets/IconFullScreen';
import { default as FullScreenExit } from './assets/IconFullScreenExit';
import { default as FunctionalLocation } from './assets/IconFunctionalLocation';
import { default as HarmonicCursor } from './assets/IconHarmonicCursor';
import { default as Heatmap } from './assets/IconHeatmap';
import { default as Hierarchy } from './assets/IconHierarchy';
import { default as History } from './assets/IconHistory';
import { default as HistoryAlt } from './assets/IconHistoryAlt';
import { default as HourglassFramedFilled } from './assets/IconHourglassFramedFilled';
import { default as HourglassFramedOutlined } from './assets/IconHourglassFramedOutlined';
import { default as HourglassOutlined } from './assets/IconHourglassOutlined';
import { default as Hz } from './assets/IconHz';
import { default as IMX } from './assets/IconIMX';
import { default as Image } from './assets/IconImage';
import { default as InfoCircleFilled } from './assets/IconInfoCircleFilled';
import { default as InfoCircleOutlined } from './assets/IconInfoCircleOutlined';
import { default as Integration } from './assets/IconIntegration';
import { default as Kebab } from './assets/IconKebab';
import { default as Link } from './assets/IconLink';
import { default as ListGroup } from './assets/IconListGroup';
import { default as ListItem } from './assets/IconListItem';
import { default as LocationPin } from './assets/IconLocationPin';
import { default as Lock } from './assets/IconLock';
import { default as LogOut } from './assets/IconLogOut';
import { default as Meatballs } from './assets/IconMeatballs';
import { default as Microphone } from './assets/IconMicrophone';
import { default as Minus } from './assets/IconMinus';
import { default as MinusSmall } from './assets/IconMinusSmall';
import { default as NoData } from './assets/IconNoData';
import { default as O } from './assets/IconO';
import { default as OpenInNew } from './assets/IconOpenInNew';
import { default as OverlayBaseline } from './assets/IconOverlayBaseline';
import { default as PDF } from './assets/IconPDF';
import { default as Paper } from './assets/IconPaper';
import { default as Pause } from './assets/IconPause';
import { default as PieChart } from './assets/IconPieChart';
import { default as Pin } from './assets/IconPin';
import { default as Play } from './assets/IconPlay';
import { default as Plus } from './assets/IconPlus';
import { default as PowerOff } from './assets/IconPowerOff';
import { default as Printer } from './assets/IconPrinter';
import { default as ProCollect } from './assets/IconProCollect';
import { default as RecAction } from './assets/IconRecAction';
import { default as Received } from './assets/IconReceived';
import { default as Refresh } from './assets/IconRefresh';
import { default as Reorder } from './assets/IconReorder';
import { default as Replace } from './assets/IconReplace';
import { default as Reply } from './assets/IconReply';
import { default as RewalkableRoute } from './assets/IconRewalkableRoute';
import { default as Routes } from './assets/IconRoutes';
import { default as Search } from './assets/IconSearch';
import { default as Send } from './assets/IconSend';
import { default as SensorA } from './assets/IconSensorA';
import { default as SensorB } from './assets/IconSensorB';
import { default as Settings } from './assets/IconSettings';
import { default as SidebandCursor } from './assets/IconSidebandCursor';
import { default as SingleCursor } from './assets/IconSingleCursor';
import { default as Spectrum } from './assets/IconSpectrum';
import { default as StarFilled } from './assets/IconStarFilled';
import { default as StarOutlined } from './assets/IconStarOutlined';
import { default as StatusCircle } from './assets/IconStatusCircle';
import { default as Stop } from './assets/IconStop';
import { default as StructuralVibration } from './assets/IconStructuralVibration';
import { default as Sync } from './assets/IconSync';
import { default as Timewave } from './assets/IconTimewave';
import { default as Trash } from './assets/IconTrash';
import { default as Trend } from './assets/IconTrend';
import { default as TrendingUp } from './assets/IconTrendingUp';
import { default as Undo } from './assets/IconUndo';
import { default as UnknownCircle } from './assets/IconUnknownCircle';
import { default as UnknownDiamond } from './assets/IconUnknownDiamond';
import { default as Unlink } from './assets/IconUnlink';
import { default as Unlock } from './assets/IconUnlock';
import { default as UnscheduledAction } from './assets/IconUnscheduledAction';
import { default as Upload } from './assets/IconUpload';
import { default as User } from './assets/IconUser';
import { default as ViewFull } from './assets/IconViewFull';
import { default as ViewHorizontal } from './assets/IconViewHorizontal';
import { default as ViewVertical } from './assets/IconViewVertical';
import { default as Warning } from './assets/IconWarning';
import { default as WarningCircle } from './assets/IconWarningCircle';
import { default as WarningDiamond } from './assets/IconWarningDiamond';
import { default as ZoomIn } from './assets/IconZoomIn';
import { default as ZoomOut } from './assets/IconZoomOut';

enum Icons {
	ArrowDown = 'ArrowDown',
	ArrowDownUp = 'ArrowDownUp',
	ArrowLeft = 'ArrowLeft',
	ArrowRight = 'ArrowRight',
	ArrowUp = 'ArrowUp',
	Article = 'Article',
	ArtificialIntelligence = 'ArtificialIntelligence',
	Asset = 'Asset',
	Attachment = 'Attachment',
	BandCursor = 'BandCursor',
	Bands = 'Bands',
	BatteryEmpty = 'BatteryEmpty',
	BatteryFull = 'BatteryFull',
	BatteryLow = 'BatteryLow',
	BearingFault = 'BearingFault',
	Book = 'Book',
	Bulb = 'Bulb',
	Burger = 'Burger',
	CPM = 'CPM',
	Calendar = 'Calendar',
	CalendarBooked = 'CalendarBooked',
	CalendarEmpty = 'CalendarEmpty',
	CalendarNotBooked = 'CalendarNotBooked',
	CalendarRecurring = 'CalendarRecurring',
	CaretDown = 'CaretDown',
	CaretUp = 'CaretUp',
	CaretUpDown = 'CaretUpDown',
	Chat = 'Chat',
	Check = 'Check',
	CheckCircle = 'CheckCircle',
	CheckSmall = 'CheckSmall',
	ChevronDown = 'ChevronDown',
	ChevronLeft = 'ChevronLeft',
	ChevronRight = 'ChevronRight',
	ChevronUp = 'ChevronUp',
	ChevronUpDown = 'ChevronUpDown',
	Close = 'Close',
	CloseAllFaults = 'CloseAllFaults',
	CloseFault = 'CloseFault',
	CloseSmall = 'CloseSmall',
	ColumnGraph = 'ColumnGraph',
	Comment = 'Comment',
	Connection1 = 'Connection1',
	Connection2 = 'Connection2',
	Connection3 = 'Connection3',
	Connection4 = 'Connection4',
	Danger = 'Danger',
	DefectFrequencies = 'DefectFrequencies',
	DefectFrequenciesAlternative = 'DefectFrequenciesAlternative',
	DoubleChevronLeft = 'DoubleChevronLeft',
	DoubleChevronRight = 'DoubleChevronRight',
	Download = 'Download',
	Draft = 'Draft',
	DraftFilled = 'DraftFilled',
	DraftOutlined = 'DraftOutlined',
	DragNDrop = 'DragNDrop',
	Drop = 'Drop',
	Duplicate = 'Duplicate',
	Edit = 'Edit',
	EmailFilled = 'EmailFilled',
	EmailOutlined = 'EmailOutlined',
	Exclamation = 'Exclamation',
	Eye = 'Eye',
	EyeHidden = 'EyeHidden',
	EyeVisible = 'EyeVisible',
	Filter = 'Filter',
	Forbidden = 'Forbidden',
	FullScreen = 'FullScreen',
	FullScreenExit = 'FullScreenExit',
	FunctionalLocation = 'FunctionalLocation',
	HarmonicCursor = 'HarmonicCursor',
	Heatmap = 'Heatmap',
	Hierarchy = 'Hierarchy',
	History = 'History',
	HistoryAlt = 'HistoryAlt',
	HourglassFramedFilled = 'HourglassFramedFilled',
	HourglassFramedOutlined = 'HourglassFramedOutlined',
	HourglassOutlined = 'HourglassOutlined',
	Hz = 'Hz',
	IMX = 'IMX',
	Image = 'Image',
	InfoCircleFilled = 'InfoCircleFilled',
	InfoCircleOutlined = 'InfoCircleOutlined',
	Integration = 'Integration',
	Kebab = 'Kebab',
	Link = 'Link',
	ListGroup = 'ListGroup',
	ListItem = 'ListItem',
	LocationPin = 'LocationPin',
	Lock = 'Lock',
	LogOut = 'LogOut',
	Meatballs = 'Meatballs',
	Microphone = 'Microphone',
	Minus = 'Minus',
	MinusSmall = 'MinusSmall',
	NoData = 'NoData',
	O = 'O',
	OpenInNew = 'OpenInNew',
	OverlayBaseline = 'OverlayBaseline',
	PDF = 'PDF',
	Paper = 'Paper',
	Pause = 'Pause',
	PieChart = 'PieChart',
	Pin = 'Pin',
	Play = 'Play',
	Plus = 'Plus',
	PowerOff = 'PowerOff',
	Printer = 'Printer',
	ProCollect = 'ProCollect',
	RecAction = 'RecAction',
	Received = 'Received',
	Refresh = 'Refresh',
	Reorder = 'Reorder',
	Replace = 'Replace',
	Reply = 'Reply',
	RewalkableRoute = 'RewalkableRoute',
	Routes = 'Routes',
	Search = 'Search',
	Send = 'Send',
	SensorA = 'SensorA',
	SensorB = 'SensorB',
	Settings = 'Settings',
	SidebandCursor = 'SidebandCursor',
	SingleCursor = 'SingleCursor',
	Spectrum = 'Spectrum',
	StarFilled = 'StarFilled',
	StarOutlined = 'StarOutlined',
	StatusCircle = 'StatusCircle',
	Stop = 'Stop',
	StructuralVibration = 'StructuralVibration',
	Sync = 'Sync',
	Timewave = 'Timewave',
	Trash = 'Trash',
	Trend = 'Trend',
	TrendingUp = 'TrendingUp',
	Undo = 'Undo',
	UnknownCircle = 'UnknownCircle',
	UnknownDiamond = 'UnknownDiamond',
	Unlink = 'Unlink',
	Unlock = 'Unlock',
	UnscheduledAction = 'UnscheduledAction',
	Upload = 'Upload',
	User = 'User',
	ViewFull = 'ViewFull',
	ViewHorizontal = 'ViewHorizontal',
	ViewVertical = 'ViewVertical',
	Warning = 'Warning',
	WarningCircle = 'WarningCircle',
	WarningDiamond = 'WarningDiamond',
	ZoomIn = 'ZoomIn',
	ZoomOut = 'ZoomOut',
}

export const IconData: Record<Icons, () => JSX.Element> = {
	[Icons.ArrowDown]: ArrowDown,
	[Icons.ArrowDownUp]: ArrowDownUp,
	[Icons.ArrowLeft]: ArrowLeft,
	[Icons.ArrowRight]: ArrowRight,
	[Icons.ArrowUp]: ArrowUp,
	[Icons.Article]: Article,
	[Icons.ArtificialIntelligence]: ArtificialIntelligence,
	[Icons.Asset]: Asset,
	[Icons.Attachment]: Attachment,
	[Icons.BandCursor]: BandCursor,
	[Icons.Bands]: Bands,
	[Icons.BatteryEmpty]: BatteryEmpty,
	[Icons.BatteryFull]: BatteryFull,
	[Icons.BatteryLow]: BatteryLow,
	[Icons.BearingFault]: BearingFault,
	[Icons.Book]: Book,
	[Icons.Bulb]: Bulb,
	[Icons.Burger]: Burger,
	[Icons.CPM]: CPM,
	[Icons.Calendar]: Calendar,
	[Icons.CalendarBooked]: CalendarBooked,
	[Icons.CalendarEmpty]: CalendarEmpty,
	[Icons.CalendarNotBooked]: CalendarNotBooked,
	[Icons.CalendarRecurring]: CalendarRecurring,
	[Icons.CaretDown]: CaretDown,
	[Icons.CaretUp]: CaretUp,
	[Icons.CaretUpDown]: CaretUpDown,
	[Icons.Chat]: Chat,
	[Icons.Check]: Check,
	[Icons.CheckCircle]: CheckCircle,
	[Icons.CheckSmall]: CheckSmall,
	[Icons.ChevronDown]: ChevronDown,
	[Icons.ChevronLeft]: ChevronLeft,
	[Icons.ChevronRight]: ChevronRight,
	[Icons.ChevronUp]: ChevronUp,
	[Icons.ChevronUpDown]: ChevronUpDown,
	[Icons.Close]: Close,
	[Icons.CloseAllFaults]: CloseAllFaults,
	[Icons.CloseFault]: CloseFault,
	[Icons.CloseSmall]: CloseSmall,
	[Icons.ColumnGraph]: ColumnGraph,
	[Icons.Comment]: Comment,
	[Icons.Connection1]: Connection1,
	[Icons.Connection2]: Connection2,
	[Icons.Connection3]: Connection3,
	[Icons.Connection4]: Connection4,
	[Icons.Danger]: Danger,
	[Icons.DefectFrequencies]: DefectFrequencies,
	[Icons.DefectFrequenciesAlternative]: DefectFrequenciesAlternative,
	[Icons.DoubleChevronLeft]: DoubleChevronLeft,
	[Icons.DoubleChevronRight]: DoubleChevronRight,
	[Icons.Download]: Download,
	[Icons.Draft]: Draft,
	[Icons.DraftFilled]: DraftFilled,
	[Icons.DraftOutlined]: DraftOutlined,
	[Icons.DragNDrop]: DragNDrop,
	[Icons.Drop]: Drop,
	[Icons.Duplicate]: Duplicate,
	[Icons.Edit]: Edit,
	[Icons.EmailFilled]: EmailFilled,
	[Icons.EmailOutlined]: EmailOutlined,
	[Icons.Exclamation]: Exclamation,
	[Icons.Eye]: Eye,
	[Icons.EyeHidden]: EyeHidden,
	[Icons.EyeVisible]: EyeVisible,
	[Icons.Filter]: Filter,
	[Icons.Forbidden]: Forbidden,
	[Icons.FullScreen]: FullScreen,
	[Icons.FullScreenExit]: FullScreenExit,
	[Icons.FunctionalLocation]: FunctionalLocation,
	[Icons.HarmonicCursor]: HarmonicCursor,
	[Icons.Heatmap]: Heatmap,
	[Icons.Hierarchy]: Hierarchy,
	[Icons.History]: History,
	[Icons.HistoryAlt]: HistoryAlt,
	[Icons.HourglassFramedFilled]: HourglassFramedFilled,
	[Icons.HourglassFramedOutlined]: HourglassFramedOutlined,
	[Icons.HourglassOutlined]: HourglassOutlined,
	[Icons.Hz]: Hz,
	[Icons.IMX]: IMX,
	[Icons.Image]: Image,
	[Icons.InfoCircleFilled]: InfoCircleFilled,
	[Icons.InfoCircleOutlined]: InfoCircleOutlined,
	[Icons.Integration]: Integration,
	[Icons.Kebab]: Kebab,
	[Icons.Link]: Link,
	[Icons.ListGroup]: ListGroup,
	[Icons.ListItem]: ListItem,
	[Icons.LocationPin]: LocationPin,
	[Icons.Lock]: Lock,
	[Icons.LogOut]: LogOut,
	[Icons.Meatballs]: Meatballs,
	[Icons.Microphone]: Microphone,
	[Icons.Minus]: Minus,
	[Icons.MinusSmall]: MinusSmall,
	[Icons.NoData]: NoData,
	[Icons.O]: O,
	[Icons.OpenInNew]: OpenInNew,
	[Icons.OverlayBaseline]: OverlayBaseline,
	[Icons.PDF]: PDF,
	[Icons.Paper]: Paper,
	[Icons.Pause]: Pause,
	[Icons.PieChart]: PieChart,
	[Icons.Pin]: Pin,
	[Icons.Play]: Play,
	[Icons.Plus]: Plus,
	[Icons.PowerOff]: PowerOff,
	[Icons.Printer]: Printer,
	[Icons.ProCollect]: ProCollect,
	[Icons.RecAction]: RecAction,
	[Icons.Received]: Received,
	[Icons.Refresh]: Refresh,
	[Icons.Reorder]: Reorder,
	[Icons.Replace]: Replace,
	[Icons.Reply]: Reply,
	[Icons.RewalkableRoute]: RewalkableRoute,
	[Icons.Routes]: Routes,
	[Icons.Search]: Search,
	[Icons.Send]: Send,
	[Icons.SensorA]: SensorA,
	[Icons.SensorB]: SensorB,
	[Icons.Settings]: Settings,
	[Icons.SidebandCursor]: SidebandCursor,
	[Icons.SingleCursor]: SingleCursor,
	[Icons.Spectrum]: Spectrum,
	[Icons.StarFilled]: StarFilled,
	[Icons.StarOutlined]: StarOutlined,
	[Icons.StatusCircle]: StatusCircle,
	[Icons.Stop]: Stop,
	[Icons.StructuralVibration]: StructuralVibration,
	[Icons.Sync]: Sync,
	[Icons.Timewave]: Timewave,
	[Icons.Trash]: Trash,
	[Icons.Trend]: Trend,
	[Icons.TrendingUp]: TrendingUp,
	[Icons.Undo]: Undo,
	[Icons.UnknownCircle]: UnknownCircle,
	[Icons.UnknownDiamond]: UnknownDiamond,
	[Icons.Unlink]: Unlink,
	[Icons.Unlock]: Unlock,
	[Icons.UnscheduledAction]: UnscheduledAction,
	[Icons.Upload]: Upload,
	[Icons.User]: User,
	[Icons.ViewFull]: ViewFull,
	[Icons.ViewHorizontal]: ViewHorizontal,
	[Icons.ViewVertical]: ViewVertical,
	[Icons.Warning]: Warning,
	[Icons.WarningCircle]: WarningCircle,
	[Icons.WarningDiamond]: WarningDiamond,
	[Icons.ZoomIn]: ZoomIn,
	[Icons.ZoomOut]: ZoomOut,
};

export default Icons;

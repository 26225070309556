import * as React from 'react';

const IconOverlayBaseline = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M9 11.148V0h2v11.138l2.294-2.41 1.412 1.49-4.716 4.955-4.698-4.956L6.708 8.73 9 11.148Zm-8 6.747h4V20H1v-2.105Zm7 0h4V20H8v-2.105Zm7 0h4V20h-4v-2.105Z" />
	</svg>
);

export default IconOverlayBaseline;

import * as React from 'react';

const IconReply = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M7.745 5.671V4.034c0-.917-1.105-1.38-1.75-.731L1.3 8.03a1.03 1.03 0 0 0 0 1.452l4.697 4.727c.644.649 1.75.196 1.75-.72v-1.741c5.115 0 8.696 1.647 11.254 5.252-1.023-5.15-4.093-10.299-11.255-11.329Z" />
	</svg>
);

export default IconReply;

import styled from '@emotion/styled';
import React, { FieldsetHTMLAttributes, ForwardedRef, forwardRef } from 'react';
import FontWeights from '~tokens/font-weights/FontWeights';
import Spacings from '~tokens/spacings/Spacings';

// =================================================================================================
// INTERFACEd
// =================================================================================================

export interface FieldsetProps extends FieldsetHTMLAttributes<HTMLFieldSetElement> {
	/** Legend for the Fieldset */
	feLegend: string;
}

// =================================================================================================
// COMPONENT
// =================================================================================================

/**
 * The `<Fieldset>` component is used to group several controls as well as labels (<label>) within a web form.
 * It extends the interface of native html `<fieldset>` element.
 *
 * See [MDN Web Docs](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/fieldset) for further information about the element and related attributes.
 */
const Fieldset = forwardRef(
	({ children, feLegend, ...rest }: FieldsetProps, ref: ForwardedRef<HTMLFieldSetElement>) => (
		<StyledFieldset {...rest} data-comp="fieldset" ref={ref}>
			<StyledFieldsetLegend>{feLegend}</StyledFieldsetLegend>
			<StyledFieldsetBody>{children}</StyledFieldsetBody>
		</StyledFieldset>
	)
);

Fieldset.displayName = 'Fieldset';
export default Fieldset;

// =================================================================================================
// STYLE
// =================================================================================================

const StyledFieldset = styled.fieldset`
	border: 0;
	margin: 0;
	padding: 0;
`;

const StyledFieldsetLegend = styled.legend`
	font-weight: ${FontWeights.Bold};
	padding: 0;
`;

const StyledFieldsetBody = styled.div`
	margin-top: ${Spacings.Md};
`;

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { ForwardedRef, forwardRef } from 'react';
import InputText from '~common/components/input-text/InputText';
import { baseInputWrapperStyles } from '~common/styles/shared';
import { BaseInputProps } from '~common/types/input';
import { DatePickerProps } from '~components/date-picker/DatePicker';
import Icon from '~components/icon/Icon';
import IconSizes from '~tokens/icon-sizes/IconSizes';
import Icons from '~tokens/icons/Icons';
import Spacings from '~tokens/spacings/Spacings';

// =================================================================================================
// INTERFACE
// =================================================================================================

interface InputDateProps extends BaseInputProps {
	feDateDisplay?: 'first' | 'last';
	feHideIcon?: boolean;
	onClick?: () => void;
}

// =================================================================================================
// COMPONENT
// =================================================================================================

const InputDate = forwardRef(
	(
		{ className, feDateDisplay, feHideIcon, feSize, onClick, value, ...rest }: InputDateProps,
		ref: ForwardedRef<HTMLInputElement>
	) => {
		if (value && feDateDisplay === 'first') {
			value = String(value).split(' - ')[0];
		} else if (value && feDateDisplay === 'last') {
			value = String(value).split(' - ')[1];
		}

		return (
			<StyledInputDate className={className} data-comp="input-date">
				<InputText
					{...rest}
					feSize={feSize}
					onClick={onClick}
					onFocus={onClick}
					placeholder="YYYY-MM-DD"
					ref={ref}
					type="text"
					value={value}
				/>
				{!feHideIcon && (
					<StyledIcon feIcon={Icons.Calendar} feSize={IconSizes.Lg} feInputSize={feSize} />
				)}
			</StyledInputDate>
		);
	}
);

InputDate.displayName = 'InputDate';
export default InputDate;

// =================================================================================================
// STYLE
// =================================================================================================

const StyledInputDate = styled.div`
	${baseInputWrapperStyles};
`;

const StyledIcon = styled(Icon)(
	({ feInputSize }: { feInputSize: DatePickerProps['feSize'] }) => css`
		position: absolute;
		right: ${feInputSize === 'md' ? Spacings.Sm : Spacings.Xs};
		top: 50%;
		transform: translateY(-50%);
	`
);

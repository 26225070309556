import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, {
	ForwardedRef,
	forwardRef,
	ForwardRefExoticComponent,
	TableHTMLAttributes,
} from 'react';
import Cell, { TableCellProps } from '~components/table/TableCell';
import Row, { TableRowProps } from '~components/table/TableRow';
import useTable from '~components/table/useTable';
import Colors from '~tokens/colors/Colors';
import Spacings from '~tokens/spacings/Spacings';

// =================================================================================================
// INTERFACE
// =================================================================================================

export type TableCell = Omit<TableCellProps, 'compact' | 'index' | 'sorting'>;

export type TableRow = Omit<TableRowProps, 'type' | 'variant'> & {
	cells: TableCell[];
};

export type TableRowInteractive = TableRow & {
	onClick: () => void;
};

interface Interactive {
	/** Renders a `<tbody>` inside the `<table>` represented by an array of row-objects */
	feBody: TableRowInteractive[];

	/** If true, table rows are interactive */
	feInteractive?: true;
}

interface Static {
	/** Renders a `<tbody>` inside the `<table>` represented by an array of row-objects */
	feBody: TableRow[];

	/** Controls wether table rows are interactive or static */
	feInteractive?: false;
}

interface BaseProps extends TableHTMLAttributes<HTMLTableElement> {
	/** If provided, will render a caption text at the top of the table */
	feCaption?: string;

	/** If true, will render a more compact table */
	feCompact?: boolean;

	/** If provided, renders a `<tfoot>` inside `<table>` represented by an array of row-objects */
	feFoot?: TableRow[];

	/** If provided, renders a `<thead>` inside the `<table>` represented by an array of row-objects  */
	feHead?: TableRow[];

	/** If true, makes the Table scrollable (occurs when content is taller than the parent's element height) */
	feScrollable?: boolean;
}

export type TableProps = BaseProps & (Interactive | Static);

// =================================================================================================
// COMPONENT
// =================================================================================================

/**
 * The `<Table>` component renders a table to organize and display information, with data arranged in columns and rows.<br>
 * It extends the interface of native html `<table>` element.<br><br>
 * To visually highlight dynamically added rows add the `feHighlight` prop on each row item.
 *
 * See [InVision DSM](https://skf.invisionapp.com/dsm/ab-skf/4-web-applications/nav/5fa7caf78c01200018354495/folder/62b406a40ab00ac7642ad89c) for design principles.<br>
 * See [MDN Web Docs](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/table) for further information about the element and related attributes.
 */
const Table: ForwardRefExoticComponent<TableProps> = forwardRef(
	(
		{
			feBody,
			feCaption,
			feCompact = false,
			feFoot,
			feHead,
			feInteractive = false,
			feScrollable,
			...rest
		}: TableProps,
		ref: ForwardedRef<HTMLTableElement>
	) => {
		const { sortedBody, sortedHead, sorting } = useTable(feBody, feHead);

		return (
			<StyledTable {...rest} data-comp="table" feScrollable={feScrollable} ref={ref}>
				<StyledTableInner>
					{feCaption && <StyledTableCaption>{feCaption}</StyledTableCaption>}
					{sortedHead && (
						<StyledTableHead feScrollable={feScrollable}>
							{sortedHead.map((row, index) => (
								<Row {...row} key={`row-${index}`} interactive={false} type="head">
									{row.cells.map((cell, index) => (
										<Cell
											{...cell}
											as={cell.as || 'th'}
											compact={true}
											index={index}
											key={index}
											sorting={sorting}
										>
											{cell.children}
										</Cell>
									))}
								</Row>
							))}
						</StyledTableHead>
					)}
					{feFoot && (
						<StyledTableFoot>
							{feFoot.map((row, index) => (
								<Row {...row} key={`row-${index}`} interactive={false} type="foot">
									{row.cells.map((cell, index) => (
										<Cell
											{...cell}
											as={cell.as || 'td'}
											compact={feCompact}
											index={index}
											key={index}
											sorting={sorting}
										>
											{cell.children}
										</Cell>
									))}
								</Row>
							))}
						</StyledTableFoot>
					)}
					<tbody>
						{sortedBody.map((row, index) => (
							<Row {...row} key={`row-${index}`} interactive={feInteractive} type="body">
								{row.cells.map((cell, index) => (
									<Cell
										{...cell}
										as={cell.as || 'td'}
										compact={feCompact}
										index={index}
										key={index}
										sorting={sorting}
									>
										{cell.children}
									</Cell>
								))}
							</Row>
						))}
					</tbody>
				</StyledTableInner>
			</StyledTable>
		);
	}
);

Table.displayName = 'Table';
export default Table;

// =================================================================================================
// STYLE
// =================================================================================================

const StyledTable = styled.div(
	({ feScrollable }: Pick<TableProps, 'feScrollable'>) => css`
		overflow-x: auto;

		${feScrollable &&
		css`
			height: 100%;
			overflow-y: auto;
		`}
	`
);

const StyledTableInner = styled.table`
	border-collapse: collapse;
	white-space: nowrap;
	width: 100%;
`;

const StyledTableCaption = styled.caption`
	margin: 0 0 ${Spacings.Md};
	text-align: left;
`;

const StyledTableHead = styled.thead(
	({ feScrollable }: Pick<TableProps, 'feScrollable'>) => css`
		background: ${Colors.Gray100};

		${feScrollable &&
		css`
			position: sticky;
			top: 0;
		`}
	`
);

const StyledTableFoot = styled.tfoot`
	background: ${Colors.Gray100};
`;

import { BaseSeverity } from '~common/types/severity';
import IconColors from '~tokens/icon-colors/IconColors';
import Icons from '~tokens/icons/Icons';

interface IconSeverityProps {
	color: IconColors;
	icon: Icons;
}

const IconSeverityMap: Map<BaseSeverity, IconSeverityProps> = new Map([
	['error', { color: IconColors.Red, icon: Icons.Danger }],
	['info', { color: IconColors.Brand, icon: Icons.InfoCircleFilled }],
	['success', { color: IconColors.Green, icon: Icons.CheckCircle }],
	['warning', { color: IconColors.Orange, icon: Icons.Warning }],
]);

const IconSeverity = Object.freeze(IconSeverityMap);

export default IconSeverity;

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Property } from 'csstype';
import {
	useMultipleSelection,
	UseMultipleSelectionProps,
	useSelect,
	UseSelectProps,
} from 'downshift';
import React, { SelectHTMLAttributes, useEffect, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import Layers from '~common/constants/layers';
import { isDefined } from '~common/helpers/isDefined';
import { baseInputStyles, baseInputWrapperStyles } from '~common/styles/shared';
import { BaseInputProps } from '~common/types/input';
import Icon from '~components/icon/Icon';
import Portal from '~components/portal/Portal';
import { SelectItem } from '~components/select/Select';
import { SelectValueTypeConstraint } from '~components/select/selectCommonTypes';
import SelectList from '~components/select/SelectList';
import Spacer from '~components/spacer/Spacer';
import Tag from '~components/tag/Tag';
import Text from '~components/text/Text';
import VisuallyHidden from '~components/visually-hidden/VisuallyHidden';
import Colors from '~tokens/colors/Colors';
import FontSizes from '~tokens/font-sizes/FontSizes';
import Icons from '~tokens/icons/Icons';
import Spacings from '~tokens/spacings/Spacings';

// =================================================================================================
// INTERFACE
// =================================================================================================

type BaseProps<T extends SelectValueTypeConstraint = string> = Omit<
	SelectHTMLAttributes<HTMLSelectElement>,
	'children' | 'defaultValue' | 'onChange' | 'value'
> &
	Pick<BaseInputProps, 'feSeverity'> & {
		/** The selectable items. Can be nested one level to create groups. */
		feItems: SelectItem<T>[];

		/** If provided, alters the z-index on the menu */
		feLayer?: Property.ZIndex;

		/** If provided, sets value of css `max-height` property */
		feListHeight?: number;

		/** If provided, sets the text of the input when nothing is selected */
		fePlaceholder?: string;

		/** If provided, displays an alternative size */
		feSize?: 'md' | 'sm';
	};

type SingleProps<T extends SelectValueTypeConstraint> = BaseProps<T> & {
	/** Sets an initial value for the input */
	defaultValue?: T;

	multiple: false;

	/** Callback that fires each time the selection changes */
	onChange?: (value: T) => void;

	/** Sets the value of the input. Makes the component controlled. */
	value?: T | null;
};

type MultiProps<T extends SelectValueTypeConstraint> = BaseProps<T> & {
	/** Sets an initial value for the input. */
	defaultValue?: T[];

	/** If true, hides the selection tags shown below select when `multiple` is true */
	feHideSelectionTags?: boolean;

	/** If provided, alters the text shown in select when selecting multiple items */
	feMultiSelectionText?: string;

	/** If provided, sets the text shown next to selection tags when `multiple` is true */
	feSelectionTagsText?: string;

	multiple: true;

	/** Callback that fires each time the selection changes. */
	onChange?: (value: T[]) => void;

	/** Sets the value of the input. Makes the component controlled. */
	value?: T[];
};

export type SelectFieldProps<T extends SelectValueTypeConstraint> = MultiProps<T> | SingleProps<T>;

// =================================================================================================
// COMPONENT
// =================================================================================================

/**
 * @internal
 */
const SelectField = <T extends SelectValueTypeConstraint = string>(props: SelectFieldProps<T>) => {
	const {
		className,
		defaultValue,
		disabled,
		feItems,
		feLayer,
		feListHeight,
		fePlaceholder,
		feSeverity,
		feSize,
		id,
		multiple,
		onChange,
		value,
	} = props;

	const feMultiSelectionText = multiple
		? props.feMultiSelectionText || '$1 selection/selections made'
		: undefined;

	const feHideSelectionTags = multiple ? props.feHideSelectionTags || false : undefined;
	const feSelectionTagsText = multiple ? props.feSelectionTagsText ?? 'Selections' : undefined;

	// Flatten the items, in case there are groups
	const allItems = feItems.flatMap((item) => item.items ?? item);

	const useMultipleSelectionProps: UseMultipleSelectionProps<SelectItem<T>> = {
		onSelectedItemsChange: ({ selectedItems }) => {
			multiple &&
				onChange &&
				selectedItems !== undefined &&
				onChange(selectedItems.map((item) => item.value).filter(isDefined));
		},
	};

	if (multiple) {
		if (defaultValue !== undefined) {
			useMultipleSelectionProps.initialSelectedItems = allItems.filter(
				(item) => item.value !== undefined && defaultValue.includes(item.value)
			);
		}

		if (value !== undefined) {
			useMultipleSelectionProps.selectedItems = allItems.filter(
				(item) => item.value !== undefined && value.includes(item.value)
			);
		}
	}

	const {
		addSelectedItem,
		getDropdownProps,
		removeSelectedItem,
		getSelectedItemProps,
		selectedItems,
		setSelectedItems,
	} = useMultipleSelection<SelectItem<T>>(useMultipleSelectionProps);
	const useSelectProps: UseSelectProps<SelectItem<T>> = {
		items: allItems,
		itemToString: (item) => String(item?.label),
	};

	if (!multiple) {
		useSelectProps.onSelectedItemChange = ({ selectedItem }) => {
			onChange && selectedItem?.value !== undefined && onChange(selectedItem.value);
		};

		useSelectProps.stateReducer = (state, actionAndChanges) => {
			const { changes, type } = actionAndChanges;
			const { selectedItem } = changes;

			switch (type) {
				case useSelect.stateChangeTypes.ToggleButtonKeyDownCharacter:
					return {
						...changes,
						selectedItem: selectedItem?.disabled ? undefined : selectedItem,
					};
				case useSelect.stateChangeTypes.ToggleButtonKeyDownArrowDown:
				case useSelect.stateChangeTypes.ToggleButtonKeyDownArrowUp:
					return {
						...changes,
						highlightedIndex: allItems.findIndex((item) => !item.disabled),
					};
			}
			return changes;
		};

		if (defaultValue !== undefined) {
			useSelectProps.initialSelectedItem = allItems.find((item) => item.value === defaultValue);
		}

		if (value !== undefined) {
			useSelectProps.selectedItem = allItems.find((item) => item.value === value) ?? null;
		}
	} else {
		useSelectProps.onSelectedItemChange = ({ selectedItem }) => {
			if (selectedItem) {
				if (selectedItems.find((item) => item.value === selectedItem.value)) {
					setSelectedItems(selectedItems.filter((item) => item.value !== selectedItem.value));
				} else {
					addSelectedItem(selectedItem);
				}
			}
		};

		useSelectProps.selectedItem = null;

		useSelectProps.stateReducer = (state, actionAndChanges) => {
			const { changes, type } = actionAndChanges;
			const { selectedItem } = changes;

			switch (type) {
				case useSelect.stateChangeTypes.ToggleButtonKeyDownCharacter:
					return {
						...changes,
						selectedItem: selectedItem?.disabled ? undefined : selectedItem,
					};
				case useSelect.stateChangeTypes.ToggleButtonKeyDownArrowDown:
				case useSelect.stateChangeTypes.ToggleButtonKeyDownArrowUp:
					return {
						...changes,
						highlightedIndex: allItems.findIndex((item) => !item.disabled),
					};
				case useSelect.stateChangeTypes.MenuKeyDownEnter:
				case useSelect.stateChangeTypes.MenuKeyDownSpaceButton:
				case useSelect.stateChangeTypes.ItemClick:
					return {
						...changes,
						isOpen: true, // Keep the menu open after clicking item.
						highlightedIndex: state.highlightedIndex, // Keep the recently clicked item highlighted.
					};
			}
			return changes;
		};
	}

	const {
		isOpen,
		selectedItem,
		getToggleButtonProps,
		getMenuProps,
		highlightedIndex,
		getItemProps,
	} = useSelect<SelectItem<T>>(useSelectProps);

	const [buttonContainerRef, setButtonContainerRef] = useState<HTMLDivElement | null>(null);
	const [listContainerRef, setListContainerRef] = useState<HTMLDivElement | null>(null);
	const selectionTagsRef = useRef<HTMLDivElement>(null);

	const [selectionTagsHeight, setSelectionTagsHeight] = useState(0);
	useEffect(() => {
		const resizeHandler = () => {
			if (selectionTagsRef.current) setSelectionTagsHeight(selectionTagsRef.current.offsetHeight);
		};
		window.addEventListener('resize', resizeHandler);
		return () => window.removeEventListener('resize', resizeHandler);
	}, []);

	const { styles, attributes } = usePopper(buttonContainerRef, listContainerRef, {
		strategy: 'fixed',
		placement: 'bottom-start',
		modifiers: [
			{
				name: 'offset',
				options: {
					offset: [0, -(multiple ? selectionTagsHeight : 0)],
				},
			},
		],
	});

	const buttonWidth = buttonContainerRef?.getBoundingClientRect().width;

	const SelectSelectionText =
		!feSelectionTagsText || feSelectionTagsText === ' ' ? VisuallyHidden : Text;

	return (
		<>
			<StyledSelectField className={className} ref={setButtonContainerRef}>
				<StyledSelectButton
					feSeverity={feSeverity}
					feSize={feSize}
					stIsOpen={isOpen}
					type="button"
					{...getDropdownProps(
						getToggleButtonProps({
							disabled,
							'aria-labelledby': id ? `${id}-label` : undefined,
							id,
							onClick: () => window.dispatchEvent(new Event('resize')),
						})
					)}
				>
					<StyledSelectButtonText>
						{multiple
							? selectedItems.length === 0 && fePlaceholder
								? fePlaceholder
								: feHideSelectionTags && selectedItems.length === 1
								? selectedItems[0].label
								: getMultiSelectionText(feMultiSelectionText, selectedItems.length)
							: selectedItem?.label || fePlaceholder || <Spacer />}
					</StyledSelectButtonText>
					<Icon feIcon={Icons.ChevronDown} />
				</StyledSelectButton>

				<div ref={selectionTagsRef}>
					{multiple && !feHideSelectionTags && selectedItems.length > 0 && (
						<>
							<Spacer />
							<SelectSelectionText feFontSize={FontSizes.Sm}>
								{feSelectionTagsText}:{' '}
							</SelectSelectionText>
							{selectedItems.map((item, index) => (
								<StyledTag
									key={index}
									feRemoveButton={{
										onClick: (e) => {
											e.stopPropagation();
											removeSelectedItem(item);
										},
									}}
									{...getSelectedItemProps({ selectedItem: item, index })}
								>
									{allItems.find((i) => i.value === item.value)?.label || ''}
								</StyledTag>
							))}
						</>
					)}
				</div>
			</StyledSelectField>

			<Portal data-portals="select">
				<StyledSelectPopper
					{...attributes.popper}
					feLayer={feLayer}
					stIsOpen={isOpen}
					ref={setListContainerRef}
					style={styles.popper}
				>
					<SelectList<T>
						allItems={allItems}
						getItemProps={getItemProps}
						getMenuProps={getMenuProps}
						highlightedIndex={highlightedIndex}
						isOpen={isOpen}
						items={feItems}
						listHeight={feListHeight}
						listWidth={buttonWidth}
						selectedItems={multiple ? selectedItems : selectedItem ? [selectedItem] : undefined}
						size={feSize}
					/>
				</StyledSelectPopper>
			</Portal>
		</>
	);
};

SelectField.displayName = 'SelectField';
export default SelectField;

// =================================================================================================
// HELPERS
// =================================================================================================

const getMultiSelectionText = (template?: string, selectedLength?: number) => {
	const temp = template?.split(' ') || [];
	temp.forEach((part, i) => {
		if (part.includes('/')) {
			const selectionWords = part.split('/');
			if (selectedLength === 1) temp[i] = selectionWords[0];
			else temp[i] = selectionWords[1];
		}
		if (part.includes('$1')) {
			temp[i] = part.replace('$1', `${selectedLength}`);
		}
	});
	return temp.join(' ');
};

// =================================================================================================
// COMPONENT
// =================================================================================================

const StyledSelectField = styled.div`
	${baseInputWrapperStyles};
`;

interface StyledSelectButtonProps extends Pick<SelectFieldProps<string>, 'feSeverity' | 'feSize'> {
	stIsOpen: boolean;
}

const StyledSelectButton = styled.button(
	({ feSeverity, feSize, stIsOpen }: StyledSelectButtonProps) => css`
		${baseInputStyles(feSize, feSeverity)};

		align-items: center;
		cursor: pointer;
		display: flex;
		justify-content: space-between;

		&:hover {
			background-color: ${!stIsOpen && Colors.Primary200};
		}
	`
);

const StyledSelectButtonText = styled.span`
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

const StyledTag = styled(Tag)`
	margin-right: ${Spacings.Xs};
	margin-top: ${Spacings.Xs};
`;

interface StyledSelectPopperProps extends Pick<SelectFieldProps<string>, 'feLayer'> {
	stIsOpen: boolean;
}

const StyledSelectPopper = styled.div(
	({ stIsOpen, feLayer }: StyledSelectPopperProps) => css`
		pointer-events: ${stIsOpen ? 'initial' : 'none'};
		z-index: ${feLayer || Layers.Select};
	`
);

import * as React from 'react';

const IconDraftFilled = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M17 1a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h14Zm-6.242 4.95-6.666 6.667a.3.3 0 0 0-.092.22v1.857c0 .172.134.306.306.306h1.857c.08 0 .159-.03.214-.092l6.672-6.666-2.291-2.291ZM12.5 13h-1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5Zm3 0h-1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5Zm-2.97-8.821-1.118 1.118 2.291 2.291 1.118-1.118a.608.608 0 0 0 0-.861l-1.43-1.43a.608.608 0 0 0-.861 0Z" />
	</svg>
);

export default IconDraftFilled;

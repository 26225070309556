import React, { ForwardedRef, forwardRef } from 'react';
import Hint from '~common/components/hint/Hint';
import InputPassword, { InputPasswordProps } from '~common/components/input-password/InputPassword';
import Label from '~common/components/label/Label';
import { BaseInputFieldProps } from '~common/types/input';
import Spacer from '~components/spacer/Spacer';
import generateId from '~helpers/generateId/generateId';
import Spacings from '~tokens/spacings/Spacings';

// =================================================================================================
// INTERFACE
// =================================================================================================

export interface PasswordFieldProps extends BaseInputFieldProps, InputPasswordProps {}

// =================================================================================================
// COMPONENT
// =================================================================================================

/**
 * <br>
 * <span class="sbdocs-tag sbdocs-blue">controlled</span>
 * <span class="sbdocs-tag sbdocs-blue">uncontrolled</span>
 * <br><br>
 *
 * The `<PasswordField>` component hides your input (for use with passwords).<br>
 * It extends the interface of native html `<input>` element.
 *
 * See [InVision DSM](https://skf.invisionapp.com/dsm/ab-skf/4-web-applications/nav/5fa7caf78c01200018354495/asset/6229e1679fe1609a48066a60) for design principles.<br>
 * See [MDN Web Docs](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/password) for further information about the element and related attributes.
 */
const PasswordField = forwardRef(
	(
		{
			'aria-errormessage': ariaErrorMessage,
			className,
			disabled,
			feHideLabel,
			feHint,
			feLabel,
			feRequiredText,
			feSeverity,
			feSize,
			id = generateId(),
			required,
			...rest
		}: PasswordFieldProps,
		ref: ForwardedRef<HTMLInputElement>
	) => {
		const hintId = feHint && feSeverity === 'error' ? id + '-hint' : undefined;

		return (
			<div className={className} data-comp="password-field">
				<Label
					feDisabled={disabled}
					feHideLabel={feHideLabel}
					feRequired={required}
					feRequiredText={feRequiredText}
					feSize={feSize}
					htmlFor={id}
				>
					{feLabel}
				</Label>
				{!feHideLabel && <Spacer feSpacing={Spacings.Xs} />}
				<InputPassword
					{...rest}
					aria-errormessage={
						feSeverity === 'error' ? (feHint ? hintId : ariaErrorMessage) : undefined
					}
					aria-invalid={feSeverity === 'error' ? true : undefined}
					disabled={disabled}
					feSeverity={feSeverity}
					feSize={feSize}
					id={id}
					ref={ref}
					required={required}
				/>
				{feHint && (
					<>
						<Spacer feSpacing={Spacings.Xxs} id={hintId} />
						<Hint feSeverity={feSeverity}>{feHint}</Hint>
					</>
				)}
			</div>
		);
	}
);

PasswordField.displayName = 'PasswordField';
export default PasswordField;

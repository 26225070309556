import * as React from 'react';

const IconSearch = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M12.926 12.926c2.234-2.233 2.215-5.874-.042-8.13-2.257-2.257-5.897-2.276-8.13-.042-2.234 2.233-2.215 5.873.042 8.13 2.256 2.257 5.897 2.276 8.13.042Zm.702 2.172c-3.062 2.335-7.477 2.082-10.303-.743C.248 11.277.222 6.314 3.268 3.268c3.046-3.046 8.01-3.02 11.087.057 2.825 2.826 3.078 7.241.743 10.303l3.612 3.611c.406.406.367 1.103 0 1.47-.368.368-1.065.407-1.471 0l-3.611-3.61Z" />
	</svg>
);

export default IconSearch;

import * as React from 'react';

const IconDanger = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="m9.994.4 8.294 4.789v9.577l-8.294 4.789L1.7 14.766V5.189L9.994.4Zm.02 12.606c-.284 0-.524.099-.72.297a.988.988 0 0 0-.294.721c0 .324.102.576.307.756.205.18.445.27.72.27.27 0 .506-.092.709-.274.203-.182.304-.433.304-.752 0-.282-.1-.523-.3-.721a.995.995 0 0 0-.727-.297ZM10.08 5c-.323 0-.584.106-.783.318C9.1 5.53 9 5.825 9 6.203c0 .209.012.518.035.93l.245 3.719c.041.423.11.739.205.946.096.208.267.311.513.311a.537.537 0 0 0 .52-.32c.104-.215.173-.523.205-.924l.32-3.678c.014-.205.021-.407.021-.608 0-.51-.066-.901-.198-1.172-.132-.271-.394-.407-.786-.407Z" />
	</svg>
);

export default IconDanger;

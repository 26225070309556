import * as React from 'react';

const IconStop = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M4.667 2h10.666C16.8 2 18 3.2 18 4.667v10.666C18 16.8 16.8 18 15.333 18H4.667A2.675 2.675 0 0 1 2 15.333V4.667C2 3.2 3.2 2 4.667 2Z" />
	</svg>
);

export default IconStop;

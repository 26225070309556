import * as React from 'react';

const IconSidebandCursor = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M10.846 8.53v9.599H9.154V8.53l.846.847.846-.847ZM6.41 4.59 10 1l3.59 3.59L10 8.18 6.41 4.59Zm-3.025 7.564 1.64-1.64 1.642 1.64-1.641 1.641-1.641-1.64ZM0 15.54l1.641-1.641 1.641 1.64-1.641 1.642L0 15.539Zm16.615-3.385-1.64 1.641-1.642-1.64 1.641-1.642 1.641 1.641ZM20 15.54l-1.641 1.64-1.641-1.64 1.641-1.641L20 15.538Z" />
	</svg>
);

export default IconSidebandCursor;

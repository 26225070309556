import * as React from 'react';

const IconFullScreen = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M4 12c-.55 0-1 .45-1 1v3c0 .55.45 1 1 1h3c.55 0 1-.45 1-1s-.45-1-1-1H5v-2c0-.55-.45-1-1-1Zm0-4c.55 0 1-.45 1-1V5h2c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1v3c0 .55.45 1 1 1Zm11 7h-2c-.55 0-1 .45-1 1s.45 1 1 1h3c.55 0 1-.45 1-1v-3c0-.55-.45-1-1-1s-1 .45-1 1v2ZM12 4c0 .55.45 1 1 1h2v2c0 .55.45 1 1 1s1-.45 1-1V4c0-.55-.45-1-1-1h-3c-.55 0-1 .45-1 1Z" />
	</svg>
);

export default IconFullScreen;

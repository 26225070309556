import * as React from 'react';

const IconTrash = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M4 17c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2H6c-1.1 0-2 .9-2 2v10ZM16 2h-2.5l-.71-.71c-.18-.18-.44-.29-.7-.29H7.91c-.26 0-.52.11-.7.29L6.5 2H4c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1Z" />
	</svg>
);

export default IconTrash;

import * as React from 'react';

const IconRecAction = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M4 12c.316 0 .571.256.571.571v2.858H7.43a.571.571 0 0 1 0 1.142H4.57v2.858a.571.571 0 1 1-1.142 0V16.57H.57a.571.571 0 0 1 0-1.142H3.43V12.57c0-.315.255-.571.571-.571ZM4.527.195a4.93 4.93 0 0 1 2.779.01c2.193.622 3.717 2.583 3.556 4.893-.06.805.171 1.528.87 2.03.03.032.05.062.08.082 1.041 1.015 2.081 2.04 3.132 3.044 1.213 1.166 2.436 2.323 3.658 3.489.252.24.536.45.779.713.687.724.808 1.729.333 2.593-.444.823-1.475 1.307-2.416 1.155a2.51 2.51 0 0 1-1.424-.733c-.646-.644-.968-.898-1.599-1.558-.455-.475-.455-.475-.886-.946 0 0-1.232-1.6-2.752-3.47-.742-.914-1.396-1.58-2.59-1.65-.989-.06-1.227-.024-2.216-.095-1.164-.08-2.183-.532-3.113-1.276C1.465 7.471.9 6.175.91 4.606c0-.36.091-.733.152-1.165.12.09.182.13.233.181.818.774 1.646 1.538 2.425 2.352.313.321.646.4 1.03.311 1.172-.271 2.404-1.276 2.697-2.573.082-.361.032-.664-.282-.954C6.366 2.034 5.588 1.26 4.81.506a4.84 4.84 0 0 1-.283-.311Zm13.115 15.004c-.434-.01-.839.353-.849.744-.009.403.375.763.82.774a.768.768 0 0 0 .778-.764.761.761 0 0 0-.75-.754Z" />
	</svg>
);

export default IconRecAction;

import * as React from 'react';

const IconHz = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M4.05 15v-4.372h4.015V15h2.05V5h-2.05v3.936H4.049V5H2v10h2.05ZM18 15v-1.685h-3.012c-.129 0-.464.016-1.004.048l.99-1.057 2.811-3.193V7.756h-6.078v1.59l2.596-.014c.37-.005.667-.014.893-.028-.217.214-.552.575-1.004 1.085l-2.755 3.117V15H18Z" />
	</svg>
);

export default IconHz;

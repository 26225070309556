import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { ForwardedRef, forwardRef, HTMLAttributes, ReactNode } from 'react';
import Colors from '~tokens/colors/Colors';
import Headings from '~tokens/headings/Headings';

// =================================================================================================
// INTERFACE
// =================================================================================================

export type HeadingLevel = 'h1' | 'h2' | 'h3' | 'h4';

export interface HeadingProps extends HTMLAttributes<HTMLHeadingElement> {
	/** Controls which heading element will be rendered. Should not be used to affect appearance. */
	as: HeadingLevel;

	/** The content of the heading */
	children: ReactNode;

	/** If provided, changes the appearance of the heading */
	feStyledAs?: HeadingLevel;
}

// =================================================================================================
// COMPONENT
// =================================================================================================

/**
 * The `<Heading>` component is to deliniate content on a page. When using, take note not to skip heading levels.<br>
 * It extends the interface of native html `<h1>` to `<h6>` elements.
 *
 * See [InVision DSM](https://skf.invisionapp.com/dsm/ab-skf/4-web-applications/nav/5fa7caf78c01200018354495/folder/619622199069cd7d11104ff9) for design principles.<br>
 * See [MDN Web Docs](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/Heading_Elements) for further information about the element and related attributes.
 */
const Heading = forwardRef(
	({ children, ...rest }: HeadingProps, ref: ForwardedRef<HTMLHeadingElement>) => (
		<StyledHeading {...rest} data-comp="heading" ref={ref}>
			{children}
		</StyledHeading>
	)
);

Heading.displayName = 'Heading';
export default Heading;

// =================================================================================================
// HELPER
// =================================================================================================

interface HeadingLevels {
	size: string;
	weight: string;
}

const levels: ReadonlyMap<HeadingLevel, HeadingLevels> = new Map([
	['h1', { size: Headings.H1Size, weight: Headings.H1Weight }],
	['h2', { size: Headings.H2Size, weight: Headings.H2Weight }],
	['h3', { size: Headings.H3Size, weight: Headings.H3Weight }],
	['h4', { size: Headings.H4Size, weight: Headings.H4Weight }],
]);

// =================================================================================================
// STYLE
// =================================================================================================

const StyledHeading = styled.h1(
	({ as, feStyledAs }: Pick<HeadingProps, 'as' | 'feStyledAs'>) => css`
		color: ${Colors.Black};
		font-size: ${levels.get(feStyledAs || as)?.size};
		font-weight: ${levels.get(feStyledAs || as)?.weight};
	`
);

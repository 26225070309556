import * as React from 'react';

const IconInfoCircleFilled = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M10 19a9 9 0 1 1 0-18 9 9 0 0 1 0 18ZM9 9v5a1 1 0 0 0 2 0V9a1 1 0 0 0-2 0Zm1-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z" />
	</svg>
);

export default IconInfoCircleFilled;

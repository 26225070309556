import styled from '@emotion/styled';
import React, { ForwardedRef, forwardRef } from 'react';
import InputRadio, { InputRadioProps } from '~common/components/input-radio/InputRadio';
import Label from '~common/components/label/Label';
import { BaseInputFieldProps } from '~common/types/input';
import generateId from '~helpers/generateId/generateId';
import Spacings from '~tokens/spacings/Spacings';

// =================================================================================================
// INTERFACE
// =================================================================================================

export interface RadioProps extends Omit<BaseInputFieldProps, 'feHint'>, InputRadioProps {}

// =================================================================================================
// COMPONENT
// =================================================================================================

/**
 * <br>
 * <span class="sbdocs-tag sbdocs-blue">uncontrolled</span>
 * <span class="sbdocs-tag sbdocs-blue">controlled</span>
 * <br><br>
 *
 * The `<Radio>` component is for "one-choice-only" input.<br>
 * It extends the interface of native html `<input>` element.
 *
 * See [InVision DSM](https://skf.invisionapp.com/dsm/ab-skf/4-web-applications/nav/5fa7caf78c01200018354495/asset/619e422b323f84082ff0b259) for design principles.<br>
 * See [MDN Web Docs](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/radio) for further information about the element and related attributes.
 */
const Radio = forwardRef(
	(
		{
			className,
			disabled,
			feHideLabel,
			feLabel,
			feRequiredText,
			feSeverity,
			feSize,
			id = generateId(),
			required,
			...rest
		}: RadioProps,
		ref: ForwardedRef<HTMLInputElement>
	) => (
		<StyledRadio className={className} data-comp="radio">
			<InputRadio
				{...rest}
				aria-invalid={feSeverity === 'error' ? true : undefined}
				disabled={disabled}
				feSeverity={feSeverity}
				feSize={feSize}
				id={id}
				ref={ref}
				required={required}
			/>
			<Label
				feDisabled={disabled}
				feHideLabel={feHideLabel}
				feRequired={required}
				feRequiredText={feRequiredText}
				feSize={feSize}
				htmlFor={id}
			>
				{feLabel}
			</Label>
		</StyledRadio>
	)
);

Radio.displayName = 'Radio';
export default Radio;

// =================================================================================================
// STYLE
// =================================================================================================

const StyledRadio = styled.div`
	align-items: center;
	display: flex;
	gap: ${Spacings.Xs};
`;

import * as React from 'react';

const IconSingleCursor = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M11 4.6a5.023 5.023 0 0 0-2 0V.5h2v4.1Zm0 9.8v5.1H9v-5.1a5.023 5.023 0 0 0 2 0Zm-1-8.9a4 4 0 1 1 0 8 4 4 0 0 1 0-8Zm0 2a2 2 0 1 0 0 4 2 2 0 0 0 0-4Z" />
	</svg>
);

export default IconSingleCursor;

import styled from '@emotion/styled';
import React, { ForwardedRef, forwardRef, HTMLAttributes, SourceHTMLAttributes } from 'react';
import Image, { ImageProps } from '~components/image/Image';
import MediaQueries from '~tokens/media-queries/MediaQueries';

// =================================================================================================
// INTERFACE
// =================================================================================================

export interface PictureSource extends SourceHTMLAttributes<HTMLSourceElement> {
	/** If provided, specifies the condition that the user agent will evaluate for each <source> element */
	media?: MediaQueries;
}

export interface PictureProps extends HTMLAttributes<HTMLPictureElement> {
	/** Fallback in case none of the offered <source> elements are able to provide a usable image */
	feImage: ImageProps;

	/** Array of multiple image resources. **Notice!** Make sure to specify them in order from larger to smaller */
	feSources: PictureSource[]; // TODO: See if we can automatically order the picture sizes from larger to smaller
}

// =================================================================================================
// COMPONENT
// =================================================================================================

/**
 * The native html `<picture>` element contains zero or more `<source>` elements and one `<img>` element to offer alternative versions of an image for different display/device scenarios.<br>
 * The `<Picture>` component makes this easy to set up without having to think about the elements.<br>
 * It extends the interface of native html `<picture>` element.
 *
 * See [MDN Web Docs](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/picture) for further information about the element and related attributes.
 */
const Picture = forwardRef(
	({ feImage, feSources, ...rest }: PictureProps, ref: ForwardedRef<HTMLPictureElement>) => (
		<StyledPicture {...rest} data-comp="picture" ref={ref}>
			{feSources?.map((source, index) => (
				<source {...source} key={index} />
			))}
			<Image {...feImage} />
		</StyledPicture>
	)
);

Picture.displayName = 'Picture';
export default Picture;

// =================================================================================================
// STYLE
// =================================================================================================

const StyledPicture = styled.picture`
	display: block;
`;

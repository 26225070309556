import { css } from '@emotion/react';
import Colors from '~tokens/colors/Colors';
import FontSizes from '~tokens/font-sizes/FontSizes';
import LineHeights from '~tokens/line-heights/LineHeights';

const globalStyles = css`
	/* Box sizing rules */
	*,
	*::before,
	*::after {
		box-sizing: border-box;
	}

	/* Remove default margin */
	body,
	h1,
	h2,
	h3,
	h4 {
		margin: 0;
	}

	/* Set core body defaults */
	body {
		color: ${Colors.Black};
		font: ${FontSizes.Md} / ${LineHeights.Md} Arial, sans-serif;
		text-rendering: optimizeSpeed;
	}

	/* Inherit fonts for inputs and buttons */
	input,
	button,
	textarea,
	select {
		font: inherit;
	}

	/* Set core button defaults */
	button {
		background: none;
		border: 0;
		cursor: pointer;
		margin: 0;
		padding: 0;

		&:disabled {
			cursor: not-allowed;
		}
	}

	img {
		display: block;
		max-width: 100%;
	}

	ol,
	ul {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
	@media (prefers-reduced-motion: reduce) {
		html:focus-within {
			scroll-behavior: auto;
		}

		*,
		*::before,
		*::after {
			animation-delay: 0.01ms !important;
			animation-duration: 0.01ms !important;
			animation-iteration-count: 1 !important;
			scroll-behavior: auto !important;
			transition-duration: 0.01ms !important;
		}
	}
`;

export default globalStyles;

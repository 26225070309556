import * as React from 'react';

const IconSensorB = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M11.5 0c.276 0 .5.254.5.567V2H7.5a.5.5 0 0 0-.492.41L7 2.5V16h8.625c.207 0 .375.224.375.5v2c0 .276-.168.5-.375.5H4.375C4.168 19 4 18.776 4 18.5v-2c0-.276.168-.5.375-.5h.624L5 .567C5 .254 5.224 0 5.5 0h6Zm3.071 4c.237 0 .429.15.429.333v9.334c0 .184-.192.333-.429.333H9.43C9.192 14 9 13.85 9 13.667V4.333C9 4.15 9.192 4 9.429 4h5.142Z" />
	</svg>
);

export default IconSensorB;

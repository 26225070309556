import * as React from 'react';

const IconClose = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M8.586 10 2 3.414A1 1 0 0 1 3.414 2L10 8.586 16.586 2A1 1 0 0 1 18 3.414L11.414 10 18 16.586A1 1 0 0 1 16.586 18L10 11.414 3.414 18A1 1 0 1 1 2 16.586L8.586 10Z" />
	</svg>
);

export default IconClose;

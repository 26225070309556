/* eslint-disable react/prop-types */
import { Placement } from '@popperjs/core';
import React, { createContext, ReactNode, useContext, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import Popover, { PopoverProps } from '~common/components/popover/Popover';
import { popperAlignment } from '~common/helpers/popperAlignment';
import { usePopperTransitions } from '~common/hooks/usePopperTransitions';
import Portal from '~components/portal/Portal';

// =================================================================================================
// INTERFACE
// =================================================================================================

export interface PopoverProviderProps {
	children: ReactNode;
}

type PopoverContextExtended = PopoverProps & {
	placement?: Placement;
};

export interface PopoverContextProps {
	addPopover: (props: PopoverProps, ref: HTMLElement | null) => void;
	removePopover?: () => void;
}

// =================================================================================================
// COMPONENT
// =================================================================================================

export const PopoverContext = createContext<PopoverContextProps>({
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	addPopover: (props: PopoverProps, ref: HTMLElement | null) =>
		console.log('Something went wrong.'),
});

let tool: 'dropdown' | 'popup' | 'tooltip';

export const useDropdown = () => {
	tool = 'dropdown';
	return useContext(PopoverContext);
};

export const usePopup = () => {
	tool = 'popup';
	return useContext(PopoverContext);
};

export const useTooltip = () => {
	tool = 'tooltip';
	return useContext(PopoverContext);
};

function _throwError(x: string): never {
	throw new Error('Unexpected variant: ' + x);
}

/**
 * The `<PopoverContext>` is used to enable the use of Dropdown, Popup and Tooltip.
 */
const PopoverProvider = ({ children }: PopoverProviderProps) => {
	const [popoverRef, setPopoverRef] = useState<HTMLDivElement | null>(null);
	const triggerRef = useRef<HTMLElement | null>(null);
	const [popoverData, setPopoverData] = useState<PopoverContextExtended | null>();
	const popoverActive = useRef(false);
	const portalRef = useRef<HTMLDivElement | null>(null);
	const removeListener = useRef<(() => void) | null>(null);
	usePopperTransitions(popoverRef, tool);

	// React Popper config
	const { styles, attributes } = usePopper(triggerRef.current, popoverRef, {
		strategy: 'fixed',
		placement: tool === 'dropdown' ? 'bottom-start' : 'right-start',
		modifiers: [
			{
				name: 'offset',
				options: {
					offset: tool === 'dropdown' ? [0, 4] : [0, 8],
				},
			},
		],
	});

	const _setCloseEventListener = () => {
		if (!triggerRef.current) return;
		_removePreviousListener();
		switch (tool) {
			case 'dropdown':
			case 'popup':
				document.addEventListener('click', _clickOutside);
				removeListener.current = () => document.removeEventListener('click', _clickOutside);
				break;
			case 'tooltip':
				triggerRef.current.addEventListener('mouseleave', _closePopover);
				removeListener.current = () =>
					triggerRef.current && triggerRef.current.removeEventListener('mouseleave', _closePopover);
				break;
			default:
				_throwError(`Missing ${tool} in switch case.`);
		}

		function _closePopover() {
			removePopover();
		}

		function _clickOutside(e: MouseEvent) {
			e.stopPropagation();
			e.preventDefault();
			const clikedEl = e.target as HTMLElement;
			if (!clikedEl) return;
			if (portalRef.current && !portalRef.current?.contains(clikedEl)) removePopover();
		}
	};

	const addPopover = (props: PopoverProps, ref: HTMLElement | null): void => {
		if (!ref || popoverActive.current || !props.content) return;
		const feType = props.feType;
		popoverActive.current = true;
		triggerRef.current = ref;
		setTimeout(() => _setCloseEventListener());
		popperAlignment({ popperName: feType, triggerRef });

		// const { feType } = props;

		switch (feType) {
			case 'dropdown':
				setPopoverData({ ...props, content: props.content, feType });
				break;
			case 'popup':
				setPopoverData({ ...props, content: props.content, feType });
				break;
			case 'tooltip':
				setPopoverData({ ...props, content: props.content, feType });
				break;
		}
	};

	const removePopover = () => {
		if (!triggerRef.current) return;
		setPopoverData(null);
		_removePreviousListener();
		popoverActive.current = false;
	};

	const _removePreviousListener = () => {
		if (removeListener.current) {
			removeListener.current();
			removeListener.current = null;
		}
	};

	return (
		<PopoverContext.Provider
			value={{
				addPopover,
				removePopover,
			}}
		>
			{children}
			<Portal feRender={!!popoverData} ref={portalRef}>
				{popoverData && (
					<Popover
						{...attributes.popper}
						{...popoverData}
						ref={setPopoverRef}
						style={styles.popper}
					/>
				)}
			</Portal>
		</PopoverContext.Provider>
	);
};

PopoverProvider.displayName = 'PopoverProvider';
export default PopoverProvider;

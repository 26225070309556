import * as React from 'react';

const IconUnlink = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M19.936 9.262c-.37-2.47-2.62-4.23-5.12-4.23h-2.87c-.52 0-.95.43-.95.95s.43.95.95.95h2.9c1.6 0 3.04 1.14 3.22 2.73.17 1.43-.64 2.69-1.85 3.22l1.4 1.4c1.63-1.02 2.64-2.91 2.32-5.02Zm-17.82-7.67a.996.996 0 1 0-1.41 1.41l2.4 2.4c-1.94.8-3.27 2.77-3.09 5.04.21 2.64 2.57 4.59 5.21 4.59h2.82c.52 0 .95-.43.95-.95s-.43-.95-.95-.95h-2.89c-1.63 0-3.1-1.19-3.25-2.82a3.095 3.095 0 0 1 2.75-3.35l2.1 2.1c-.43.09-.76.46-.76.92v.1c0 .52.43.95.95.95h1.78l2.27 2.27v.73a1 1 0 0 0 1 1h.73l3.3 3.3a.996.996 0 1 0 1.41-1.41L2.116 1.592Zm11.88 8.39c0-.52-.43-.95-.95-.95h-.66l1.49 1.49c.07-.13.12-.28.12-.44v-.1Z" />
	</svg>
);

export default IconUnlink;

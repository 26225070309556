import * as React from 'react';

const IconBook = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M15.007 2.5c1.319 0 2.72.198 3.883.712.664.297 1.11.937 1.11 1.667v10.165c0 1.18-1.11 2.046-2.256 1.749a11.331 11.331 0 0 0-2.746-.325c-1.42 0-2.929.225-4.148.83-.536.27-1.155.27-1.7 0-1.22-.596-2.729-.83-4.148-.83-.91 0-1.855.1-2.746.325A1.81 1.81 0 0 1 0 15.044V4.88c0-.73.446-1.37 1.11-1.667C2.283 2.698 3.683 2.5 5.002 2.5c1.774 0 3.684.36 5.003 1.352C11.323 2.86 13.233 2.5 15.007 2.5Zm0 1.802c-1.546 0-3.775.586-5.002 1.352v10.373c1.227-.766 3.456-1.352 5.002-1.352.7 0 1.41.054 2.092.18.564.1 1.091-.315 1.091-.883V5.438a.916.916 0 0 0-.728-.883 11.641 11.641 0 0 0-2.455-.253Zm1.603 7.169a.695.695 0 0 1 .615.767.706.706 0 0 1-.774.61c-1.51-.176-3.16-.038-4.41.36a.912.912 0 0 1-.214.027.687.687 0 0 1-.662-.48.686.686 0 0 1 .447-.868c1.427-.462 3.291-.61 4.998-.416Zm0-2.457a.695.695 0 0 1 .615.766.699.699 0 0 1-.774.61c-1.51-.175-3.16-.037-4.41.36a.912.912 0 0 1-.214.028.687.687 0 0 1-.662-.48.686.686 0 0 1 .447-.869c1.427-.462 3.291-.61 4.998-.415Zm0-2.458a.695.695 0 0 1 .615.767.699.699 0 0 1-.774.61c-1.51-.176-3.16-.037-4.41.36-.074.01-.149.028-.214.028a.687.687 0 0 1-.662-.48.686.686 0 0 1 .447-.869c1.436-.462 3.291-.61 4.998-.416Z" />
	</svg>
);

export default IconBook;

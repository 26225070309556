import * as React from 'react';

const IconPin = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M11.802 1c-.825.825-.753 2.321.02 3.828L6.03 8.687C3.82 7.2 1.835 6.65 1 7.485l5.04 5.04L1 19l6.475-5.04 5.04 5.04c.835-.835.275-2.82-1.212-5.03l3.869-5.792c1.497.773 2.993.855 3.828.02L11.802 1Z" />
	</svg>
);

export default IconPin;

import * as React from 'react';

const IconTrendingUp = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="m14.042 5.708 1.2 1.2-4.067 4.067-2.742-2.742a.83.83 0 0 0-1.175 0l-5 5.009a.83.83 0 1 0 1.175 1.175L7.842 10l2.741 2.742a.83.83 0 0 0 1.175 0l4.659-4.65 1.2 1.2A.416.416 0 0 0 18.325 9V5.417A.4.4 0 0 0 17.917 5h-3.575a.417.417 0 0 0-.3.708Z" />
	</svg>
);

export default IconTrendingUp;

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, {
	ChangeEvent,
	ForwardedRef,
	forwardRef,
	ReactNode,
	useEffect,
	useRef,
	useState,
} from 'react';
import { mergeRefs } from '~common/helpers/mergeRefs';
import focusOutline from '~common/styles/mixins/focusOutline';
import square from '~common/styles/mixins/square';
import { BaseInputProps } from '~common/types/input';
import Colors from '~tokens/colors/Colors';
import MotionDurations from '~tokens/motion-durations/MotionDurations';
import MotionEasings from '~tokens/motion-easings/MotionEasings';
import Sizes from '~tokens/sizes/Sizes';
import Spacings from '~tokens/spacings/Spacings';

// =================================================================================================
// INTERFACE
// =================================================================================================

export interface InputRangeProps extends Omit<BaseInputProps, 'feSize'> {
	/** If true, shows the minimum and maximum value next to the slider */
	feDisplayMinMax?: boolean;

	/** Sets the minimum possible value of the input */
	max: number | string;

	/** Sets the maximum possible value of the input */
	min: number | string;

	/** If provided, allows you to react to min + max value updates */
	onChange?: (event: ChangeEvent<HTMLInputElement>, value: number) => void;
}

// =================================================================================================
// COMPONENT
// =================================================================================================

const InputRange = forwardRef(
	(
		{
			className,
			defaultValue,
			disabled,
			feDisplayMinMax = false,
			onChange,
			max,
			min,
			step,
			value,
			...rest
		}: InputRangeProps,
		ref: ForwardedRef<HTMLInputElement>
	) => {
		const isControlled = value === undefined ? false : true;
		const defaultFillValue = getFillCalculatedValue(defaultValue || value, max, min, step);
		const [fillValue, setFillValue] = useState<number>(defaultFillValue);
		const inputRangeRef = useRef<HTMLInputElement>(null);
		const mergedRefs = mergeRefs([inputRangeRef, ref]);

		useEffect(() => {
			if (isControlled) {
				setFillValue(getFillCalculatedValue(value, max, min, step));
			}
		}, [value]);

		return (
			<StyledInputRange className={className} data-comp="input-range">
				{feDisplayMinMax && getMinMax({ disabled, stPos: 'left', children: min })}
				<StyledInputRangeInput
					{...rest}
					defaultValue={defaultValue}
					disabled={disabled}
					max={max}
					min={min}
					onChange={(e) => {
						onChange && onChange(e, Number(e.target.value));
						setFillValue(getFillCalculatedValue(e.target.value, max, min, step));
					}}
					ref={mergedRefs}
					step={step}
					stFillValue={fillValue}
					type="range"
					value={value}
				/>
				{feDisplayMinMax && getMinMax({ disabled, stPos: 'right', children: max })}
			</StyledInputRange>
		);
	}
);

InputRange.displayName = 'InputRange';
export default InputRange;

// =================================================================================================
// HELPER
// =================================================================================================

const getMinMax = ({ children, ...rest }: StyledMinMaxProps) => (
	<StyledInputRangeMinMax {...rest}>{children}</StyledInputRangeMinMax>
);

function isEmpty(value: InputRangeProps['value']) {
	return value == null || (typeof value === 'string' && value.length === 0);
}

function getFillCalculatedValue(
	val?: InputRangeProps['value'],
	max?: InputRangeProps['max'],
	min?: InputRangeProps['min'],
	step?: InputRangeProps['step']
) {
	const valNum = Number(val);
	const maxNum = Number(max);
	const minNum = Number(min);
	const stepNum = Number(step) || 1;

	const steppedVal = Math.round((valNum - minNum) / stepNum) * stepNum;
	const calculatedVal = (steppedVal * 100) / (maxNum - minNum);

	return isEmpty(val) ? 50 : calculatedVal;
}

// =================================================================================================
// STYLE
// =================================================================================================

const StyledInputRange = styled.div`
	align-items: center;
	display: flex;
`;

interface StyledInputProps extends Pick<InputRangeProps, 'disabled'> {
	stFillValue: number;
}

const StyledInputRangeInput = styled.input(({ disabled, stFillValue }: StyledInputProps) => {
	const thumb = css`
		${square(Sizes.S12, '50%')}

		appearance: none;
		background: ${disabled ? Colors.Gray500 : Colors.Brand};
		transition: box-shadow ${MotionDurations.Fast}ms ${MotionEasings.EaseIn};

		&:hover {
			box-shadow: ${!disabled && `0 0 0 3px ${Colors.Primary400}`};
		}
	`;

	return css`
		appearance: none;
		background: linear-gradient(
			to right,
			${disabled ? Colors.Gray500 : Colors.Brand} 0%,
			${disabled ? Colors.Gray500 : Colors.Brand} ${stFillValue}%,
			${disabled ? Colors.Gray300 : Colors.Primary400} ${stFillValue}%,
			${disabled ? Colors.Gray300 : Colors.Primary400} 100%
		);
		border-radius: ${Sizes.S02};
		cursor: ${disabled ? 'not-allowed' : 'pointer'};
		flex: auto;
		height: ${Sizes.S02};
		margin: 0;

		&::-moz-range-thumb {
			border: 0;
			${thumb};
		}

		&::-webkit-slider-thumb {
			${thumb}
		}

		&:focus-visible {
			${focusOutline({ offset: Sizes.S08 })}
		}

		@supports not selector(:focus-visible) {
			&:focus {
				${focusOutline({ offset: Sizes.S08 })}
			}
		}
	`;
});

interface StyledMinMaxProps extends Pick<InputRangeProps, 'disabled'> {
	children: ReactNode;
	stPos: 'left' | 'right';
}

const StyledInputRangeMinMax = styled.div(
	({ disabled, stPos }: StyledMinMaxProps) => css`
		color: ${disabled && Colors.Gray500};
		margin-left: ${stPos === 'right' && Spacings.Xs};
		margin-right: ${stPos === 'left' && Spacings.Xs};
	`
);

import * as React from 'react';

const IconPDF = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M18 2c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H2c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h16ZM5.385 11.5H2.5v5h1.73v-1.667h1.155c.957 0 1.73-.558 1.73-1.25v-.833c0-.692-.773-1.25-1.73-1.25Zm5.769 0H8.269v5h2.885c.958 0 1.73-.558 1.73-1.25v-2.5c0-.692-.772-1.25-1.73-1.25Zm6.346 0h-3.462v5h1.731v-1.667H17.5v-1.25h-1.73v-.833h1.73V11.5Zm-6.346 1.25v2.5H10v-2.5h1.154Zm-5.77 0v.833H4.232v-.833h1.154ZM2.882 4.13c-.605-.35-1.381.05-1.381.72 0 .29.16.56.425.72l6.949 4.09c.69.41 1.562.41 2.252 0l6.949-4.09a.845.845 0 0 0 .425-.72c0-.67-.776-1.07-1.381-.72L10 8.32 2.881 4.13Z" />
	</svg>
);

export default IconPDF;

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { format as dateFnsFormat } from 'date-fns';
import React, { ForwardedRef, forwardRef, TimeHTMLAttributes } from 'react';
import FontSizes from '~tokens/font-sizes/FontSizes';
import Locales, { LocaleNames } from '~tokens/locales/Locales';

// =================================================================================================
// INTERFACE
// =================================================================================================

export interface TimeProps extends Omit<TimeHTMLAttributes<HTMLTimeElement>, 'children'> {
	/** A valid dateTime string */
	dateTime: string;

	/** If provided, changes format of the output */
	feFormat?: 'date' | 'dateTime' | 'dateTimeShort' | 'time' | 'timeShort';

	/** If provided, displays an alternative size */
	feSize?: 'md' | 'sm';

	/** If provided, sets locale of the formatter */
	locale?: LocaleNames;
}

// =================================================================================================
// COMPONENT
// =================================================================================================

/**
 * The `<Time>` component renders date and time in various formats.<br>
 * It extends the interface of native html `<time>` element.
 *
 * See [InVision DSM](https://skf.invisionapp.com/dsm/ab-skf/4-web-applications/nav/5fa7caf78c01200018354495/folder/619626ffa09c09a6050d3455) for design principles.<br>
 * See [MDN Web Docs](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/time) for further information about the element and related attributes.
 */
const Time = forwardRef(
	(
		{ dateTime, feFormat = 'time', feSize = 'md', locale, ...rest }: TimeProps,
		ref: ForwardedRef<HTMLTimeElement>
	) => {
		const timeFormats: Map<TimeProps['feFormat'], string> = new Map([
			['date', 'd MMM yyyy'],
			['dateTime', 'd MMM yyyy, HH:mm:ss'],
			['dateTimeShort', 'd MMM yyyy, HH:mm'],
			['time', 'HH:mm:ss'],
			['timeShort', 'HH:mm'],
		]);

		const timeFormat = timeFormats.get(feFormat) ?? 'HH:mm:ss';

		const output = dateFnsFormat(new Date(dateTime), timeFormat, {
			locale: locale && Locales[locale],
		});

		return (
			<StyledTime {...rest} data-comp="time" dateTime={dateTime} feSize={feSize} ref={ref}>
				{output}
			</StyledTime>
		);
	}
);

Time.displayName = 'Time';
export default Time;

// =================================================================================================
// STYLE
// =================================================================================================

const StyledTime = styled.time(
	({ feSize }: Pick<TimeProps, 'feSize'>) => css`
		font-size: ${feSize === 'sm' && FontSizes.Sm};
	`
);

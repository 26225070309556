import { css } from '@emotion/react';
import Colors from '~tokens/colors/Colors';
import IconColors from '~tokens/icon-colors/IconColors';
import Sizes from '~tokens/sizes/Sizes';

interface FocusOutlineProps {
	color?: Colors | IconColors;
	offset?: string;
	radius?: string | null;
}

export const focusOutline = (props?: FocusOutlineProps) => {
	const { color, offset = Sizes.S02, radius } = props || {};

	return css`
		border-radius: ${radius};
		outline: ${Sizes.S02} solid ${color || Colors.Brand};
		outline-offset: ${offset};
	`;
};

export default focusOutline;

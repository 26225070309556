import * as React from 'react';

const IconPlay = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M3 3.296v13.408c0 1.022 1.2 1.644 2.125 1.087l11.233-6.704c.856-.505.856-1.67 0-2.187L5.125 2.209C4.201 1.652 3 2.274 3 3.296Z" />
	</svg>
);

export default IconPlay;

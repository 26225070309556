import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { ForwardedRef, forwardRef, InputHTMLAttributes, useRef } from 'react';
import Hint from '~common/components/hint/Hint';
import InputRange, { InputRangeProps } from '~common/components/input-range/InputRange';
import Label from '~common/components/label/Label';
import { mergeRefs } from '~common/helpers/mergeRefs';
import { baseInputStyles } from '~common/styles/shared';
import { BaseInputFieldProps } from '~common/types/input';
import generateId from '~helpers/generateId/generateId';
import Spacings from '~tokens/spacings/Spacings';

// =================================================================================================
// INTERFACE
// =================================================================================================

export interface SliderProps extends BaseInputFieldProps, Omit<InputRangeProps, 'feSeverity'> {
	value: InputHTMLAttributes<HTMLInputElement>['value'];
}

// =================================================================================================
// COMPONENT
// =================================================================================================

/**
 * <br>
 * <span class="sbdocs-tag sbdocs-blue">controlled</span>
 * <span class="sbdocs-tag sbdocs-blue">uncontrolled</span>
 * <br><br>
 *
 * The `<Slider>` component is for selecting a single number within a range (slider).<br>
 * It extends the interface of native html `<input>` element.
 *
 * See [InVision DSM](https://skf.invisionapp.com/dsm/ab-skf/4-web-applications/nav/5fa7caf78c01200018354495/asset/6229e1ac5b734b05b551217c) for design principles.<br>
 * See [MDN Web Docs](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/range) for further information about the element and related attributes.
 */
const Slider = forwardRef(
	(
		{
			className,
			disabled,
			feHideLabel,
			feHint,
			feLabel,
			feRequiredText,
			id = generateId(),
			max,
			min,
			onChange,
			required,
			step,
			value,
			...rest
		}: SliderProps,
		ref: ForwardedRef<HTMLInputElement>
	) => {
		const inputTextRef = useRef<HTMLInputElement>(null);
		const inputRangeRef = useRef<HTMLInputElement>(null);
		const mergedRefs = mergeRefs([inputRangeRef, ref]);

		return (
			<div className={className} data-comp="slider">
				<Label
					feDisabled={disabled}
					feHideLabel={feHideLabel}
					feRequired={required}
					feRequiredText={feRequiredText}
					htmlFor={id}
				>
					{feLabel}
				</Label>
				<StyledSliderBody>
					<StyledSliderControl
						{...rest}
						disabled={disabled}
						id={id}
						max={max}
						min={min}
						onChange={(e) => {
							onChange && onChange(e, Number(e.target.value));
							if (inputTextRef.current) inputTextRef.current.value = e.target.value;
						}}
						ref={mergedRefs}
						required={required}
						step={step}
						value={value}
					/>
					<StyledSliderInput
						aria-hidden
						disabled={disabled}
						max={max}
						min={min}
						onChange={(e) => {
							onChange && onChange(e, Number(e.target.value));
							if (inputRangeRef.current) {
								inputRangeRef.current.value = e.target.value ? e.target.value : '0';
								// TODO: Check step when entering manual values
							}
						}}
						ref={inputTextRef}
						step={step}
						stWidth={max.toString().length}
						type="number"
						value={value}
					/>
				</StyledSliderBody>
				{feHint && <Hint>{feHint}</Hint>}
			</div>
		);
	}
);

Slider.displayName = 'Slider';
export default Slider;

// =================================================================================================
// STYLE
// =================================================================================================

const StyledSliderBody = styled.div`
	display: flex;
	gap: ${Spacings.Sm};
`;

const StyledSliderControl = styled(InputRange)`
	flex: auto;
`;

const StyledSliderInput = styled.input(
	({ stWidth }: { stWidth: number }) => css`
		${baseInputStyles('sm')};

		box-sizing: content-box;
		width: ${stWidth}ch;
	`
);

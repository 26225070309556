import * as React from 'react';

const IconStructuralVibration = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<g>
			<path d="M10.5 0a1 1 0 0 1 1 1v4.5H13a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-2a1 1 0 0 1 1-1h1.5V1a1 1 0 0 1 1-1h1ZM9.5 20a1 1 0 0 1-1-1v-4.5H7a1 1 0 0 1-1-1v-2a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-1.5V19a1 1 0 0 1-1 1h-1Z" />
			<path
				strokeWidth={1.5}
				strokeLinecap="round"
				d="m3.982 3.927-.066 6.708M1.724 6.141l-.022 2.236M16.08 17.081l-.051-6.708M18.299 14.828l-.017-2.236"
			/>
		</g>
	</svg>
);

export default IconStructuralVibration;

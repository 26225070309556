import * as React from 'react';

const IconWarning = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="m10.243 1.592.194.195 9.15 16.47a.5.5 0 0 1-.437.743H.85a.5.5 0 0 1-.437-.743l9.15-16.47a.5.5 0 0 1 .68-.195ZM9.989 14.77l-.802.331a1.1 1.1 0 0 0-.327.804c0 .36.114.64.342.841.229.2.496.301.802.301.3 0 .564-.101.79-.304.226-.204.339-.483.339-.838 0-.315-.112-.583-.335-.804a1.108 1.108 0 0 0-.81-.331Zm.074-9.23-.872.354c-.22.236-.331.565-.331.986 0 .233.013.578.039 1.036l.273 4.143c.046.472.122.824.229 1.055.106.231.297.347.57.347.27 0 .463-.12.58-.358.117-.239.193-.582.228-1.029l.357-4.098a9.79 9.79 0 0 0 .024-.677c0-.568-.074-1.004-.22-1.306-.148-.302-.44-.453-.877-.453Z" />
	</svg>
);

export default IconWarning;

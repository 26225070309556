import styled from '@emotion/styled';
import React, { ForwardedRef, forwardRef, HTMLAttributes } from 'react';
import ColorSeverity from '~common/constants/colorSeverity';
import IconSeverity from '~common/constants/iconSeverity';
import { BaseSeverity } from '~common/types/severity';
import Icon from '~components/icon/Icon';
import Text from '~components/text/Text';
import Colors from '~tokens/colors/Colors';
import FontSizes from '~tokens/font-sizes/FontSizes';
import Icons from '~tokens/icons/Icons';
import LineHeights from '~tokens/line-heights/LineHeights';
import Spacings from '~tokens/spacings/Spacings';

// =================================================================================================
// INTERFACE
// =================================================================================================

export interface HintProps extends HTMLAttributes<HTMLDivElement> {
	/** The hint/description */
	children: string;

	/** If provided, sets the corresponding visual severity */
	feSeverity?: BaseSeverity;

	/** If provided, displays an alternative size */
	feSize?: 'md' | 'sm';
}

// =================================================================================================
// COMPONENT
// =================================================================================================

const Hint = forwardRef(
	(
		{ children, feSeverity, feSize = 'md', ...rest }: HintProps,
		ref: ForwardedRef<HTMLDivElement>
	) => (
		<StyledHint data-comp="hint">
			{feSeverity && (
				<Icon
					feColor={IconSeverity.get(feSeverity)?.color}
					feIcon={IconSeverity.get(feSeverity)?.icon || Icons.InfoCircleFilled}
				/>
			)}
			<Text
				{...rest}
				as="div"
				ref={ref}
				feColor={feSeverity ? ColorSeverity.get(feSeverity)?.base : Colors.Gray700}
				feFontSize={feSize === 'sm' ? FontSizes.Xs : FontSizes.Sm}
				feLineHeight={LineHeights.Md}
			>
				{children}
			</Text>
		</StyledHint>
	)
);

Hint.displayName = 'Hint';
export default Hint;

// =================================================================================================
// STYLE
// =================================================================================================

const StyledHint = styled.div`
	align-items: center;
	display: flex;
	gap: ${Spacings.Xxs};
`;

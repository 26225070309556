import styled from '@emotion/styled';
import React, { ForwardedRef, forwardRef, HTMLAttributes } from 'react';
import FontWeights from '~tokens/font-weights/FontWeights';
import Spacings from '~tokens/spacings/Spacings';
import PaginationControl, { PaginationControlProps } from './PaginationControl';

// =================================================================================================
// INTERFACE
// =================================================================================================

export interface PaginationControls {
	/** Collection of props for next control */
	next?: Pick<PaginationControlProps, 'aria-label'>;

	/** Collection of props for previous control */
	prev?: Pick<PaginationControlProps, 'aria-label'>;
}

export interface PaginationProps extends Omit<HTMLAttributes<HTMLDivElement>, 'children'> {
	/** Interface for the next & previous controls  */
	feControls?: PaginationControls;

	/** The word to use instead of "of" */
	feDelimiter?: string;

	/** If true, hides both the delimiter and total value */
	feHideTotal?: boolean;

	/** The number of items to display on each 'page' */
	feItemsPerPage?: number;

	/** Event that triggers when user selects previous or next page. Make sure to maintain your own state containing page.  */
	feOnPageChange: (page: number) => void;

	/** Set active page */
	fePage: number;

	/** The total amount of objects that is paginated */
	feTotal: number;
}

// =================================================================================================
// COMPONENT
// =================================================================================================

/**
 * The `<Pagination>` component renders a pagination.
 *
 * See [InVision DSM](https://skf.invisionapp.com/dsm/ab-skf/4-web-applications/nav/5fa7caf78c01200018354495/asset/619e567f72662cccfc48efd6) for design principles.
 */
const Pagination = forwardRef(
	(
		{
			'aria-label': ariaLabel = 'Pagination',
			feControls,
			feDelimiter = 'of',
			feHideTotal = false,
			feItemsPerPage = 50,
			feOnPageChange,
			fePage,
			feTotal,
			...rest
		}: PaginationProps,
		ref: ForwardedRef<HTMLDivElement>
	) => {
		const { next, prev } = feControls || {};

		/* Navigates relative to the current page */
		const totalPages = Math.ceil(feTotal / feItemsPerPage);

		/* Ensure that active page is within 1 and total number of pages */
		fePage = Math.max(1, Math.min(totalPages, fePage));

		const pageStart = (fePage - 1) * feItemsPerPage + 1;
		const pageEnd = Math.min(pageStart + feItemsPerPage - 1, feTotal);
		const disabledNext = pageEnd >= feTotal;
		const disabledPrev = fePage <= 1;

		return (
			<StyledPagination {...rest} aria-label={ariaLabel} data-comp="pagination" ref={ref}>
				<StyledPaginationItems>
					{pageStart} - {pageEnd}
				</StyledPaginationItems>
				{!feHideTotal && (
					<>
						<StyledPaginationDelimiter>{feDelimiter}</StyledPaginationDelimiter>
						{feTotal}
					</>
				)}
				<PaginationControl
					{...prev}
					disabled={disabledPrev}
					onClick={() => feOnPageChange(fePage - 1)}
					type="Previous page"
				/>
				<PaginationControl
					{...next}
					disabled={disabledNext}
					onClick={() => feOnPageChange(fePage + 1)}
					type="Next page"
				/>
			</StyledPagination>
		);
	}
);

Pagination.displayName = 'Pagination';
export default Pagination;

// =================================================================================================
// STYLE
// =================================================================================================

const StyledPagination = styled.div`
	align-items: center;
	display: inline-flex;
`;

const StyledPaginationItems = styled.span`
	font-weight: ${FontWeights.Bold};
`;

const StyledPaginationDelimiter = styled.span`
	margin: 0 ${Spacings.Xxs};
`;

enum Sizes {
	S12 = '0.75rem',
	S14 = '0.875rem',
	S16 = '1rem',
	S18 = '1.125rem',
	S20 = '1.25rem',
	S24 = '1.5rem',
	S28 = '1.75rem',
	S32 = '2rem',
	S36 = '2.25rem',
	S40 = '2.5rem',
	S48 = '3rem',
	S56 = '3.5rem',
	S64 = '4rem',
	S02 = '0.125rem',
	S04 = '0.25rem',
	S06 = '0.375rem',
	S08 = '0.5rem',
}

export default Sizes;

import * as React from 'react';

const IconPieChart = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M1.13 16.33A9.98 9.98 0 0 0 8.873 20c5.522 0 10-4.477 10-10S14.653 0 9.13 0v10" />
	</svg>
);

export default IconPieChart;

import { BaseSeverity } from '~common/types/severity';
import { opacity } from '~helpers/color/color';
import Colors from '~tokens/colors/Colors';

interface ColorSeverityProps {
	base: Colors;
	light: string;
}

const ColorSeverityMap: Map<BaseSeverity, ColorSeverityProps> = new Map([
	['error', { base: Colors.RedBase, light: opacity(Colors.RedBase, 0.1) }],
	['info', { base: Colors.Brand, light: opacity(Colors.Brand, 0.1) }],
	['success', { base: Colors.GreenBase, light: opacity(Colors.GreenBase, 0.1) }],
	['warning', { base: Colors.OrangeDark, light: opacity(Colors.OrangeDark, 0.1) }],
]);

const ColorSeverity = Object.freeze(ColorSeverityMap);

export default ColorSeverity;

import {
	af,
	ar,
	arDZ,
	arEG,
	arMA,
	arSA,
	arTN,
	az,
	be,
	bg,
	bn,
	bs,
	ca,
	cs,
	cy,
	da,
	de,
	deAT,
	el,
	enAU,
	enCA,
	enGB,
	enIE,
	enIN,
	enNZ,
	enUS,
	enZA,
	eo,
	es,
	et,
	eu,
	faIR,
	fi,
	fr,
	frCA,
	frCH,
	fy,
	gd,
	gl,
	gu,
	he,
	hi,
	hr,
	ht,
	hu,
	hy,
	id,
	is,
	it,
	ja,
	jaHira,
	ka,
	kk,
	km,
	kn,
	ko,
	lb,
	lt,
	lv,
	mk,
	mn,
	ms,
	mt,
	nb,
	nl,
	nlBE,
	nn,
	pl,
	pt,
	ptBR,
	ro,
	ru,
	sk,
	sl,
	sq,
	sr,
	srLatn,
	sv,
	ta,
	te,
	th,
	tr,
	ug,
	uk,
	uz,
	uzCyrl,
	vi,
	zhCN,
	zhHK,
	zhTW,
} from 'date-fns/locale';
import { Locale } from 'date-fns';

export type LocaleNames =
	| 'af'
	| 'ar'
	| 'arDZ'
	| 'arEG'
	| 'arMA'
	| 'arSA'
	| 'arTN'
	| 'az'
	| 'be'
	| 'bg'
	| 'bn'
	| 'bs'
	| 'ca'
	| 'cs'
	| 'cy'
	| 'da'
	| 'de'
	| 'deAT'
	| 'el'
	| 'enAU'
	| 'enCA'
	| 'enGB'
	| 'enIE'
	| 'enIN'
	| 'enNZ'
	| 'enUS'
	| 'enZA'
	| 'eo'
	| 'es'
	| 'et'
	| 'eu'
	| 'faIR'
	| 'fi'
	| 'fr'
	| 'frCA'
	| 'frCH'
	| 'fy'
	| 'gd'
	| 'gl'
	| 'gu'
	| 'he'
	| 'hi'
	| 'hr'
	| 'ht'
	| 'hu'
	| 'hy'
	| 'id'
	| 'is'
	| 'it'
	| 'ja'
	| 'jaHira'
	| 'ka'
	| 'kk'
	| 'km'
	| 'kn'
	| 'ko'
	| 'lb'
	| 'lt'
	| 'lv'
	| 'mk'
	| 'mn'
	| 'ms'
	| 'mt'
	| 'nb'
	| 'nl'
	| 'nlBE'
	| 'nn'
	| 'pl'
	| 'pt'
	| 'ptBR'
	| 'ro'
	| 'ru'
	| 'sk'
	| 'sl'
	| 'sq'
	| 'sr'
	| 'srLatn'
	| 'sv'
	| 'ta'
	| 'te'
	| 'th'
	| 'tr'
	| 'ug'
	| 'uk'
	| 'uz'
	| 'uzCyrl'
	| 'vi'
	| 'zhCN'
	| 'zhHK'
	| 'zhTW';

const Locales: Readonly<{ [key: string]: Locale }> = {
	af,
	ar,
	arDZ,
	arEG,
	arMA,
	arSA,
	arTN,
	az,
	be,
	bg,
	bn,
	bs,
	ca,
	cs,
	cy,
	da,
	de,
	deAT,
	el,
	enAU,
	enCA,
	enGB,
	enIE,
	enIN,
	enNZ,
	enUS,
	enZA,
	eo,
	es,
	et,
	eu,
	faIR,
	fi,
	fr,
	frCA,
	frCH,
	fy,
	gd,
	gl,
	gu,
	he,
	hi,
	hr,
	ht,
	hu,
	hy,
	id,
	is,
	it,
	ja,
	jaHira,
	ka,
	kk,
	km,
	kn,
	ko,
	lb,
	lt,
	lv,
	mk,
	mn,
	ms,
	mt,
	nb,
	nl,
	nlBE,
	nn,
	pl,
	pt,
	ptBR,
	ro,
	ru,
	sk,
	sl,
	sq,
	sr,
	srLatn,
	sv,
	ta,
	te,
	th,
	tr,
	ug,
	uk,
	uz,
	uzCyrl,
	vi,
	zhCN,
	zhHK,
	zhTW,
};

export default Locales;

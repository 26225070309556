import styled from '@emotion/styled';
import { format } from 'date-fns';
import React from 'react';
import { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import { DatePickerFieldProps } from '~components/date-picker/DatePickerField';
import Flexbox from '~components/flexbox/Flexbox';
import Icon from '~components/icon/Icon';
import Text from '~components/text/Text';
import VisuallyHidden from '~components/visually-hidden/VisuallyHidden';
import IconSizes from '~tokens/icon-sizes/IconSizes';
import Icons from '~tokens/icons/Icons';
import Spacings from '~tokens/spacings/Spacings';

// =================================================================================================
// INTERFACE
// =================================================================================================

export interface DatePickerHeaderProps extends ReactDatePickerCustomHeaderProps {
	feRange?: boolean;
	locale: DatePickerFieldProps<false>['locale'];
}

// =================================================================================================
// COMPONENT
// =================================================================================================

/**
 * @internal
 */
const DatePickerHeader = ({
	customHeaderCount,
	decreaseMonth,
	decreaseYear,
	increaseMonth,
	increaseYear,
	locale,
	monthDate,
	nextMonthButtonDisabled,
	nextYearButtonDisabled,
	prevMonthButtonDisabled,
	prevYearButtonDisabled,
	feRange = false,
}: DatePickerHeaderProps) => {
	const DatePickerHeaderDecreaseControls =
		customHeaderCount === 1 && feRange ? VisuallyHidden : 'div';
	const DatePickerHeaderIncreaseControls =
		customHeaderCount === 0 && feRange ? VisuallyHidden : 'div';

	return (
		<StyledDatePickerHeader>
			<DatePickerHeaderDecreaseControls>
				<button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
					<Icon feIcon={Icons.ChevronLeft} feSize={IconSizes.Lg} />
				</button>
				<button onClick={decreaseYear} disabled={prevYearButtonDisabled}>
					<Icon feIcon={Icons.DoubleChevronLeft} feSize={IconSizes.Lg} />
				</button>
			</DatePickerHeaderDecreaseControls>

			<StyledDatePickerHeaderText>
				{format(monthDate, 'MMM yyyy', { locale })}
			</StyledDatePickerHeaderText>

			<DatePickerHeaderIncreaseControls>
				<button onClick={increaseYear} disabled={nextYearButtonDisabled}>
					<Icon feIcon={Icons.DoubleChevronRight} feSize={IconSizes.Lg} />
				</button>

				<button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
					<Icon feIcon={Icons.ChevronRight} feSize={IconSizes.Lg} />
				</button>
			</DatePickerHeaderIncreaseControls>
		</StyledDatePickerHeader>
	);
};

DatePickerHeader.displayName = 'DatePickerHeader';
export default DatePickerHeader;

// =================================================================================================
// STYLE
// =================================================================================================

const StyledDatePickerHeader = styled(Flexbox)`
	margin: ${Spacings.Xl} 0 ${Spacings.Lg};
`;

const StyledDatePickerHeaderText = styled(Text)`
	margin: auto;
`;

import * as React from 'react';

const IconSend = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="m2.295 18.415 16.143-7.493c.75-.351.75-1.493 0-1.844L2.295 1.585c-.61-.29-1.286.2-1.286.912L1 7.115c0 .5.342.932.805.992L14.876 10 1.805 11.883c-.463.07-.805.501-.805 1.002l.01 4.618c0 .712.675 1.202 1.285.912Z" />
	</svg>
);

export default IconSend;

import * as React from 'react';

const IconHarmonicCursor = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M5.243 8.9v11.343h-2V8.899l1 1 1-1ZM0 4.242 4.243 0l4.242 4.243-4.242 4.242L0 4.243Zm12.425 8.939 1.939-1.94 1.94 1.94-1.94 1.94-1.94-1.94Zm4 4 1.939-1.94 1.94 1.94-1.94 1.94-1.94-1.94Zm-8 0 1.939-1.94 1.94 1.94-1.94 1.94-1.94-1.94Z" />
	</svg>
);

export default IconHarmonicCursor;

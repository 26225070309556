import * as React from 'react';

const IconNoData = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<g>
			<path d="m7.03 9.499 4.742 4.744-1.182 1.506a.75.75 0 0 1-1.18 0l-3.957-5.036a.75.75 0 0 1 .59-1.213l.986-.001Zm4.94 0 1.987.001a.75.75 0 0 1 .59 1.213l-.6.763-1.977-1.977Zm-1.22-5.334a.75.75 0 0 1 .75.75v4.113l-3-2.999V4.915a.75.75 0 0 1 .343-.63c.102-.04.206-.08.311-.115l.096-.005h1.5Z" />
			<path d="M10 1c-4.968 0-9 4.032-9 9s4.032 9 9 9 9-4.032 9-9-4.032-9-9-9Zm.12 16.305c-4.1 0-7.42-3.32-7.42-7.42A7.33 7.33 0 0 1 4.268 5.34l10.397 10.397a7.33 7.33 0 0 1-4.545 1.568Zm5.617-2.64L5.34 4.268A7.33 7.33 0 0 1 9.885 2.7c4.1 0 7.42 3.32 7.42 7.42a7.33 7.33 0 0 1-1.568 4.545Z" />
		</g>
	</svg>
);

export default IconNoData;

const generateId = (prefix = 'fe-', length = 16) => {
	let string = '';

	for (let i = 1; i < length + 1; i = i + 8) {
		string += Math.random().toString(36).substring(2, 10);
	}

	const id = string.substring(0, length);
	return prefix + id;
};

export default generateId;

import * as React from 'react';

const IconIntegration = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M11.988 1c.21 0 .38.17.38.38v2.14c.59.15 1.25.34 1.75.58l1.519-1.52a.37.37 0 0 1 .53 0l2.12 2.12c.07.07.11.17.11.26 0 .1-.04.2-.11.27l-1.52 1.52c.24.5.43 1.16.58 1.75h2.14c.21 0 .38.17.38.38v2.25c0 .2-.17.37-.38.37h-2.14c-.15.6-.34 1.25-.58 1.75l1.52 1.53c.15.14.15.38 0 .53l-2.12 2.12c-.14.14-.38.14-.53 0l-1.52-1.53c-.5.24-1.16.44-1.75.59v2.14c0 .2-.17.37-.38.37h-2.25c-.2 0-.37-.17-.37-.37v-2.14c-.48-.12-1.01-.28-1.46-.47l2.46-2.46c.16.03.33.04.5.04 1.99 0 3.6-1.61 3.6-3.6 0-1.98-1.61-3.6-3.6-3.6-.08 0-.16 0-.25.01l-2.59-2.48c.42-.17.9-.3 1.34-.41V1.38c0-.21.17-.38.37-.38ZM5 3.54c0-.17.208-.255.332-.137l5.591 6.324a.187.187 0 0 1 .003.268l-5.592 6.598c-.122.122-.334.038-.334-.133v-5.24h-.022l-.115-.002H4.65l-.093-.001H2.11l-.257.001h-.548A1.224 1.224 0 0 1 .08 9.996c0-.666.54-1.205 1.205-1.204h.28l.528.001h.247l1.072.001H4.802l.06-.001h.089L5 8.79Z" />
	</svg>
);

export default IconIntegration;

import * as React from 'react';

const IconUser = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M7.777 9.874c1.475 1.44 2.965 1.448 4.438.003.238.167.453.328.678.475.655.429 1.361.754 2.097 1.017.536.19 1.063.392 1.506.768.554.472.908 1.069 1.144 1.746.28.807.366 1.644.359 2.491a9.979 9.979 0 0 1-.253 2.16.437.437 0 0 1-.302.346c-1.077.41-2.195.657-3.336.785-.953.107-1.912.166-2.868.248-.063.005-.125.018-.188.027H8.918c-.248-.02-.495-.044-.743-.058-1.353-.075-2.7-.206-4.018-.526-.565-.137-1.114-.338-1.67-.508-.127-.039-.185-.122-.215-.248a9.534 9.534 0 0 1-.155-3.743c.108-.676.317-1.319.68-1.903a3.35 3.35 0 0 1 1.79-1.43c.933-.326 1.84-.705 2.663-1.264.174-.119.34-.249.527-.386ZM9.554.023l.284.03c.456.078.477.144.786.25.67.228 1.305.534 1.883.945.378.268.724.577.885 1.032.058.163.096.338.11.51.05.623-.018 1.24-.128 1.853-.02.11-.009.19.088.261a.406.406 0 0 1 .171.357c-.01.503-.138.967-.375 1.413-.124.235-.17.51-.26.763-.347.998-.868 1.872-1.764 2.481-.75.51-1.7.512-2.454.01-.898-.6-1.455-1.45-1.813-2.446-.068-.19-.103-.39-.172-.578-.053-.143-.139-.273-.202-.412-.177-.39-.288-.796-.28-1.23.002-.146.045-.27.168-.359.093-.067.1-.15.089-.262-.054-.495-.107-.99-.135-1.488-.041-.717.221-1.325.714-1.842A4.633 4.633 0 0 1 9.086.081a2.34 2.34 0 0 1 .468-.058Z" />
	</svg>
);

export default IconUser;

import * as React from 'react';

const IconFullScreenExit = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M4 14h2v2c0 .55.45 1 1 1s1-.45 1-1v-3c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1s.45 1 1 1Zm2-8H4c-.55 0-1 .45-1 1s.45 1 1 1h3c.55 0 1-.45 1-1V4c0-.55-.45-1-1-1s-1 .45-1 1v2Zm7 11c.55 0 1-.45 1-1v-2h2c.55 0 1-.45 1-1s-.45-1-1-1h-3c-.55 0-1 .45-1 1v3c0 .55.45 1 1 1Zm1-11V4c0-.55-.45-1-1-1s-1 .45-1 1v3c0 .55.45 1 1 1h3c.55 0 1-.45 1-1s-.45-1-1-1h-2Z" />
	</svg>
);

export default IconFullScreenExit;

import * as React from 'react';

const IconAttachment = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M13.636 5.227v9.618c0 1.9-1.39 3.59-3.281 3.773a3.63 3.63 0 0 1-3.991-3.619V3.763c0-1.191.854-2.273 2.036-2.391a2.273 2.273 0 0 1 2.51 2.264v9.545c0 .5-.41.91-.91.91s-.909-.41-.909-.91V5.227a.687.687 0 0 0-.682-.682.687.687 0 0 0-.682.682v7.827c0 1.19.855 2.273 2.037 2.39a2.273 2.273 0 0 0 2.509-2.263v-9.39c0-1.9-1.391-3.592-3.282-3.774A3.635 3.635 0 0 0 5 3.636V14.79c0 2.61 1.91 4.946 4.51 5.191A5.005 5.005 0 0 0 15 15V5.227a.687.687 0 0 0-.682-.682.687.687 0 0 0-.682.682Z" />
	</svg>
);

export default IconAttachment;

import * as React from 'react';

const IconCheckSmall = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M8.649 15.449a1.196 1.196 0 0 1-1.698 0l-3.6-3.6a1.2 1.2 0 1 1 1.697-1.697l2.752 2.75 7.551-7.55a1.2 1.2 0 1 1 1.697 1.697l-8.4 8.4Z" />
	</svg>
);

export default IconCheckSmall;

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, {
	ForwardedRef,
	forwardRef,
	HTMLAttributes,
	MouseEvent,
	ReactNode,
	useEffect,
	useRef,
	useState,
} from 'react';
import { HeadingLevel } from '~components/heading/Heading';
import Icon from '~components/icon/Icon';
import generateId from '~helpers/generateId/generateId';
import Colors from '~tokens/colors/Colors';
import FontSizes from '~tokens/font-sizes/FontSizes';
import FontWeights from '~tokens/font-weights/FontWeights';
import IconSizes from '~tokens/icon-sizes/IconSizes';
import Icons from '~tokens/icons/Icons';
import MotionDurations from '~tokens/motion-durations/MotionDurations';
import MotionEasings from '~tokens/motion-easings/MotionEasings';
import Sizes from '~tokens/sizes/Sizes';
import Spacings from '~tokens/spacings/Spacings';

// =================================================================================================
// INTERFACE
// =================================================================================================

export interface CollapseProps extends Omit<HTMLAttributes<HTMLDivElement>, 'onClick'> {
	/** Contents of the Collapse body */
	children: ReactNode;

	/** If true, will animate the expand/collapse state */
	feAnimate?: boolean;

	/** If true, will set the collapse to be expanded by default */
	feExpanded?: boolean;

	/** Title for the Collapse */
	feTitle: string;

	/** If provided, encloses the title in selected heading element */
	feTitleAs?: Exclude<HeadingLevel, 'h1'>;

	/** If provided, allows you to listen to click on the title-button */
	onClick?: (event: MouseEvent<HTMLButtonElement>, expanded: boolean) => void;
}

// =================================================================================================
// COMPONENT
// =================================================================================================

/**
 * The `<Collapse>` component is a general purpose container for content that can be collapsed / expanded.
 *
 * See [InVision DSM](https://skf.invisionapp.com/dsm/ab-skf/4-web-applications/nav/5fa7caf78c01200018354495/asset/6229e2eb8a8dacf366dc3243) for design principles.
 */
const Collapse = forwardRef(
	(
		{
			children,
			feAnimate = false,
			feExpanded = false,
			feTitle,
			feTitleAs,
			onClick,
			...rest
		}: CollapseProps,
		ref: ForwardedRef<HTMLDivElement>
	) => {
		const mainRef = useRef<HTMLDivElement>(null);
		const [expanded, setExpanded] = useState(feExpanded);
		const [height, setHeight] = useState(mainRef.current?.offsetHeight || 0);
		const CollapseTitle = feTitleAs ? StyledCollapseTitle : 'div';
		const id = useRef(generateId('fe-collapse-')).current;

		useEffect(() => {
			setHeight(mainRef.current?.offsetHeight || 0);
			setExpanded(feExpanded);
		}, [feExpanded]);

		return (
			<StyledCollapse {...rest} data-comp="collapse" ref={ref}>
				<CollapseTitle as={feTitleAs}>
					<StyledCollapseButton
						aria-controls={id}
						aria-expanded={expanded}
						onClick={(event) => {
							setExpanded(!expanded);
							onClick && onClick(event, !expanded);
						}}
						type="button"
					>
						{feTitle}
						<StyledIcon feIcon={expanded ? Icons.CaretUp : Icons.CaretDown} feSize={IconSizes.Lg} />
					</StyledCollapseButton>
				</CollapseTitle>
				<StyledCollapseBody feAnimate={feAnimate} feExpanded={expanded} id={id} stHeight={height}>
					<StyledCollapseMain ref={mainRef}>{children}</StyledCollapseMain>
				</StyledCollapseBody>
			</StyledCollapse>
		);
	}
);

Collapse.displayName = 'Collapse';
export default Collapse;

// =================================================================================================
// STYLE
// =================================================================================================

const StyledCollapse = styled.div`
	background: ${Colors.White};
	border-bottom: 1px solid ${Colors.Gray300};
`;

const StyledCollapseTitle = styled.h2`
	font-size: ${FontSizes.Md};
	font-weight: ${FontWeights.Bold};
`;

const StyledCollapseButton = styled.button`
	align-items: center;
	display: flex;
	height: ${Sizes.S40};
	width: 100%;
`;

const StyledIcon = styled(Icon)`
	margin-left: auto;
`;

interface StyledBodyProps extends Pick<CollapseProps, 'feAnimate' | 'feExpanded'> {
	stHeight: number;
}

const StyledCollapseBody = styled.div(
	({ feAnimate, feExpanded, stHeight }: StyledBodyProps) => css`
		height: ${feExpanded ? `${stHeight}px` : 0};
		overflow: hidden;
		transition: ${feAnimate && `height ${MotionDurations.Normal}ms ${MotionEasings.EaseIn}`};
	`
);

const StyledCollapseMain = styled.div`
	padding: 0 0 ${Spacings.Md};
`;

import * as React from 'react';

const IconHistory = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M11.384 1.003c-5.016-.14-9.126 3.95-9.126 8.999H.494c-.443 0-.66.54-.345.85L2.9 13.65c.197.2.502.2.7 0l2.749-2.8c.305-.31.089-.85-.355-.85H4.23c0-3.898 3.134-7.048 6.997-6.998 3.666.05 6.75 3.18 6.8 6.899C18.075 13.81 14.97 17 11.127 17a6.733 6.733 0 0 1-4.217-1.48.97.97 0 0 0-1.301.08c-.414.42-.385 1.13.079 1.49a8.65 8.65 0 0 0 5.44 1.91c4.976 0 9.007-4.17 8.869-9.258-.129-4.69-3.992-8.609-8.613-8.739Zm-.503 5a.75.75 0 0 0-.74.75v3.679c0 .35.188.68.484.86l3.074 1.85a.74.74 0 0 0 1.015-.26.764.764 0 0 0-.256-1.03l-2.838-1.71v-3.4c0-.4-.335-.74-.739-.74Z" />
	</svg>
);

export default IconHistory;

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { BaseInputProps } from '~common/types/input';
import Icon from '~components/icon/Icon';
import Colors from '~tokens/colors/Colors';
import Sizes from '~tokens/sizes/Sizes';
import Spacings from '~tokens/spacings/Spacings';

interface StyledComboboxButtonProps {
	feSize?: BaseInputProps['feSize'];
	inputRef?: React.RefObject<HTMLInputElement>;
	isMatching?: boolean;
}

export const StyledComboboxButton = styled.button<StyledComboboxButtonProps>(
	({ isMatching, feSize }: StyledComboboxButtonProps) => css`
		align-items: center;
		background-color: ${isMatching ? Colors.Primary200 : 'none'};
		border: none;
		display: flex;
		flex-shrink: 0;
		height: ${feSize === 'sm' ? Sizes.S32 : Sizes.S40};
		padding-inline: ${Spacings.Xxl} ${Spacings.Xs};
		width: 100%;

		&:has([data-comp='icon']) {
			padding-inline-start: ${Spacings.Xs};
		}

		&:hover {
			background-color: ${Colors.Primary200};
		}
	`
);

export const StyledComboboxIcon = styled(Icon)`
	margin-inline-end: ${Spacings.Xs};
`;

import * as React from 'react';

const IconRoutes = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M8.484.255a.75.75 0 0 1 1.06.03l2.694 2.845a.75.75 0 0 1 0 1.03L9.546 7.01a.75.75 0 0 1-1.09-1.03l1.54-1.63H5A2.25 2.25 0 0 0 2.75 6.6v.7A2.25 2.25 0 0 0 5 9.55h9.67a3.75 3.75 0 0 1 3.75 3.75v.7a3.75 3.75 0 0 1-3.75 3.75l-8.765.001A3.001 3.001 0 0 1 0 17a3 3 0 0 1 5.905-.75h8.765A2.25 2.25 0 0 0 16.92 14v-.7a2.25 2.25 0 0 0-2.25-2.25H5A3.75 3.75 0 0 1 1.25 7.3v-.7A3.75 3.75 0 0 1 5 2.85h4.908L8.455 1.316A.75.75 0 0 1 8.41.337ZM17 .6a3 3 0 1 1 0 6 3 3 0 0 1 0-6Z" />
	</svg>
);

export default IconRoutes;

import * as React from 'react';

const IconConnection1 = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<g>
			<path d="M3.826 14.163a1 1 0 0 1 1 1v2.674a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1v-2.674a1 1 0 0 1 1-1h1Z" />
			<path
				d="M7.493 9.837h1a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1v-7a1 1 0 0 1 1-1Zm4.666-4.334h1a1 1 0 0 1 1 1v11.334a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1V6.503a1 1 0 0 1 1-1Zm5.667-4.333a1 1 0 0 1 1 1v15.667a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1V2.17a1 1 0 0 1 1-1h1Z"
				fillOpacity={0.2}
			/>
		</g>
	</svg>
);

export default IconConnection1;

import * as React from 'react';

const IconArrowUp = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M8.834 6.652 6.822 8.664a1.066 1.066 0 1 1-1.509-1.51l3.842-3.841a1.066 1.066 0 0 1 1.509 0l3.841 3.842a1.066 1.066 0 1 1-1.508 1.509l-2.023-2.012v9.278c0 .588-.482 1.07-1.07 1.07-.589 0-1.07-.482-1.07-1.07V6.652Z" />
	</svg>
);

export default IconArrowUp;

import * as React from 'react';

const IconFunctionalLocation = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<defs>
			<path
				d="m6.685 5.958 5.23 2.678a1 1 0 0 1 .544.89v5.795a1 1 0 0 1-.544.89l-5.23 2.678a1 1 0 0 1-.911 0l-5.23-2.678a1 1 0 0 1-.544-.89V9.526a1 1 0 0 1 .544-.89l5.23-2.678a1 1 0 0 1 .911 0ZM6.23 9.42a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm10.718-1.985 2.779 1.416a.5.5 0 0 1 .273.446v3.063a.5.5 0 0 1-.273.446l-2.779 1.416a.5.5 0 0 1-.454 0l-2.778-1.416a.5.5 0 0 1-.273-.446V9.297a.5.5 0 0 1 .273-.446l2.778-1.416a.5.5 0 0 1 .454 0Zm-.228 1.89a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Zm-3.706-8.27 2.779 1.416a.5.5 0 0 1 .273.446V5.98a.5.5 0 0 1-.273.446l-2.78 1.416a.5.5 0 0 1-.453 0L9.78 6.426a.5.5 0 0 1-.273-.446V2.917a.5.5 0 0 1 .273-.446l2.779-1.416a.5.5 0 0 1 .454 0Zm-.234 1.89a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Z"
				id="a"
			/>
		</defs>
		<use xlinkHref="#a" />
	</svg>
);

export default IconFunctionalLocation;

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, {
	AnchorHTMLAttributes,
	AriaAttributes,
	ForwardedRef,
	forwardRef,
	Fragment,
	HTMLAttributes,
	MouseEvent,
} from 'react';
import focusOutline from '~common/styles/mixins/focusOutline';
import Icon from '~components/icon/Icon';
import Colors from '~tokens/colors/Colors';
import FontSizes from '~tokens/font-sizes/FontSizes';
import IconSizes from '~tokens/icon-sizes/IconSizes';
import Icons from '~tokens/icons/Icons';
import Spacings from '~tokens/spacings/Spacings';

// =================================================================================================
// INTERFACE
// =================================================================================================

interface BaseProps {
	/** If provided, displays an alternative size */
	feSize?: 'md' | 'sm';
}

export interface BreadcrumbsItem extends Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'onClick'> {
	href: string;

	label?: string;

	/** Custom onClick where the second return parameter enables custom routing */
	onClick?: (event: MouseEvent<HTMLAnchorElement>, route: string) => void;
}

export interface BreadcrumbsInteractive extends BaseProps, HTMLAttributes<HTMLElement> {
	/** If provided, renders an alternative `aria-current` label. **Notice!** Only applicable if `feType` is `interactive`. */
	ariaCurrentLabel?: Extract<AriaAttributes['aria-current'], 'location' | 'page' | 'step'>;

	/** Array of Breadcrumb items */
	feItems: BreadcrumbsItem[];

	/** Alters the appearance and what is rendered as breadcrumbs. Links or static. */
	feType: 'interactive';
}

export interface BreadcrumbsStatic extends BaseProps, HTMLAttributes<HTMLDivElement> {
	/** Array of Breadcrumb items */
	feItems: Pick<BreadcrumbsItem, 'label'>[];

	feType: 'static';
}

export type BreadcrumbsProps = BreadcrumbsInteractive | BreadcrumbsStatic;

// =================================================================================================
// COMPONENT
// =================================================================================================

/**
 * The `<Breadcrumbs>` component can render a static or interactive path to help visualize where the user is located
 *
 * See [InVision DSM](https://skf.invisionapp.com/dsm/ab-skf/4-web-applications/nav/5fa7caf78c01200018354495/folder/62aae11e90af6355c42f40e2) for design principles.
 */

const Breadcrumbs = forwardRef(
	(
		{ feSize = 'md', ...rest }: BreadcrumbsProps,
		ref: ForwardedRef<HTMLDivElement | HTMLElement>
	) => {
		const { feType } = rest;
		const isInteractive = feType === 'interactive';
		const ariaCurrentLabel = isInteractive ? rest.ariaCurrentLabel : undefined;
		const BreadcrumbsSeparator = feSize === 'sm' ? StyledBreadcrumbsDivider : Icon;
		const items = isInteractive ? rest.feItems : rest.feItems;

		return (
			<StyledBreadcrumbs
				{...rest}
				aria-label={feType === 'interactive' ? rest['aria-label'] || 'Breadcrumbs' : undefined}
				as={feType === 'static' ? 'div' : undefined}
				data-comp="breadcrumbs"
				feSize={feSize}
				ref={ref}
			>
				<StyledBreadcrumbsList>
					{items.map((item, index) => {
						const BreadcrumbsLink = isInteractive ? StyledBreadcrumbsLink : Fragment;
						const current = !!(index === items.length - 1);
						const rest = item as BreadcrumbsItem;
						const href = isInteractive ? rest.href : undefined;

						const clickHandler = (event: MouseEvent<HTMLAnchorElement>, route: string) => {
							rest.onClick && event.preventDefault();
							rest.onClick?.(event, route);
						};

						return (
							<StyledBreadcrumbsItem stCurrent={current} key={index}>
								<BreadcrumbsLink
									aria-current={current ? ariaCurrentLabel : undefined}
									href={href}
									onClick={href ? (event) => clickHandler(event, href) : undefined}
									stCurrent={current}
								>
									{item.label}
								</BreadcrumbsLink>
								{index !== items.length - 1 && (
									<BreadcrumbsSeparator feIcon={Icons.ChevronRight} feSize={IconSizes.Lg}>
										/
									</BreadcrumbsSeparator>
								)}
							</StyledBreadcrumbsItem>
						);
					})}
				</StyledBreadcrumbsList>
			</StyledBreadcrumbs>
		);
	}
);

Breadcrumbs.displayName = 'Breadcrumbs';
export default Breadcrumbs;

// =================================================================================================
// STYLE
// =================================================================================================

interface StyleProps {
	stCurrent?: boolean;
}

const StyledBreadcrumbs = styled.nav(
	({ feSize }: Pick<BreadcrumbsProps, 'feSize'>) => css`
		font-size: ${feSize === 'sm' && FontSizes.Sm};
	`
);

const StyledBreadcrumbsList = styled.ol`
	display: flex;
	gap: ${Spacings.Xxs};
`;

const StyledBreadcrumbsItem = styled.li(
	({ stCurrent }: StyleProps) => css`
		align-items: center;
		color: ${stCurrent ? Colors.Black : Colors.Gray700};
		display: inherit;
	`
);

const StyledBreadcrumbsLink = styled.a(
	({ stCurrent }: StyleProps) => css`
		color: ${stCurrent ? 'inherit' : Colors.Brand};
		text-decoration: none;

		&:hover,
		&:focus,
		&:active {
			color: inherit;
		}

		&:focus-visible {
			${focusOutline()}
		}

		@supports not selector(:focus-visible) {
			&:focus {
				${focusOutline()}
			}
		}
	`
);

const StyledBreadcrumbsDivider = styled.span`
	margin-left: ${Spacings.Xxs};
`;

import styled from '@emotion/styled';
import React, { ForwardedRef, forwardRef, HTMLAttributes } from 'react';
import ImgEmptyState from '~common/images/ImgEmptyState';
import Button, { ButtonProps } from '~components/button/Button';
import Heading, { HeadingLevel } from '~components/heading/Heading';
import Link, { LinkAnchor } from '~components/link/Link';
import Text from '~components/text/Text';
import Colors from '~tokens/colors/Colors';
import FontWeights from '~tokens/font-weights/FontWeights';
import Spacings from '~tokens/spacings/Spacings';

// =================================================================================================
// INTERFACE
// =================================================================================================

export interface EmptyStateHeading {
	as?: HeadingLevel;
	children: string;
}

export interface EmptyStateProps extends HTMLAttributes<HTMLDivElement> {
	/** If provided, renders a button */
	feButton?: Omit<ButtonProps, 'feType'>;

	/** If provided, renders a heading */
	feHeading?: EmptyStateHeading;

	/** If provided, renders a button */
	feLink?: Omit<LinkAnchor, 'as'>;

	/** If provided, renders a descriptive text */
	feText?: string;
}

// =================================================================================================
// COMPONENT
// =================================================================================================

/**
 * The `<EmptyState>` should be used when content can’t be shown
 *
 * See [InVision DSM](https://skf.invisionapp.com/dsm/ab-skf/4-web-applications/nav/5fa7caf78c01200018354495/folder/5ea6a919c465cf512ac038f2) for design principles.
 */
const EmptyState = forwardRef(
	(
		{ feButton, feHeading, feLink, feText, ...rest }: EmptyStateProps,
		ref: ForwardedRef<HTMLDivElement>
	) => (
		<StyledEmptyState {...rest} data-comp="empty-state" ref={ref}>
			<StyledImg>
				<ImgEmptyState />
			</StyledImg>
			{(feHeading || feText) && (
				<StyledBody>
					{feHeading && (
						<StyledHeading as={feHeading.as || 'h2'} feStyledAs="h2">
							{feHeading.children}
						</StyledHeading>
					)}
					{feText && <StyledText feColor={Colors.Gray700}>{feText}</StyledText>}
				</StyledBody>
			)}
			{(feLink || feButton) && (
				<StyledFoot>
					{feLink && (
						<Link {...feLink} as="a">
							{feLink.children}
						</Link>
					)}
					{feButton && (
						<Button {...feButton} feType="primary">
							{feButton.children}
						</Button>
					)}
				</StyledFoot>
			)}
		</StyledEmptyState>
	)
);

EmptyState.displayName = 'EmptyState';
export default EmptyState;

// =================================================================================================
// STYLE
// =================================================================================================

const StyledEmptyState = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	gap: ${Spacings.Lg};
	text-align: center;
`;

const StyledImg = styled.div`
	width: min(100%, 320px);
`;

const StyledBody = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${Spacings.Xs};
`;

const StyledHeading = styled(Heading)`
	font-weight: ${FontWeights.Normal};
	margin-block-start: ${Spacings.Xs};
`;

const StyledText = styled(Text)`
	max-width: 50ch;
`;

const StyledFoot = styled.div`
	display: flex;
	gap: ${Spacings.Xl};
	justify-content: center;
`;

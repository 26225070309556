import * as React from 'react';

const IconO = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M9.915 16c1.636 0 2.942-.529 3.92-1.587.976-1.058 1.465-2.524 1.465-4.397 0-1.89-.492-3.364-1.477-4.425C12.838 4.531 11.525 4 9.885 4c-.907 0-1.707.153-2.4.46a4.336 4.336 0 0 0-1.44 1.044c-.437.47-.783 1-1.036 1.591-.34.802-.509 1.794-.509 2.976 0 1.847.489 3.297 1.466 4.35C6.943 15.474 8.259 16 9.916 16Zm-.007-2.003c-.897 0-1.636-.339-2.218-1.017-.582-.678-.874-1.674-.874-2.988 0-1.335.284-2.334.851-2.996.567-.662 1.314-.993 2.24-.993.927 0 1.67.327 2.23.981.56.655.839 1.647.839 2.976 0 1.346-.287 2.355-.862 3.028s-1.31 1.01-2.206 1.01Z" />
	</svg>
);

export default IconO;

import React, { RefObject } from 'react';
import { BaseInputProps } from '~common/types/input';
import { useComboboxOnChange } from '~components/combobox/Combobox.hooks';
import {
	StyledComboboxButton as StyledComboboxAddButton,
	StyledComboboxIcon,
} from '~components/combobox/Combobox.styles';
import Icons from '~tokens/icons/Icons';
import { ComboboxItem, ComboboxLocalization, ExtendedOnChange } from './Combobox';
import { equal, srtSelected } from './Combobox.helpers';

// =================================================================================================
// INTERFACE
// =================================================================================================

interface ComboboxAddButtonProps {
	feSize?: BaseInputProps['feSize'];
	inputRef: RefObject<HTMLInputElement>;
	inputValue: string;
	itemsSource: ComboboxItem[];
	localization?: ComboboxLocalization;
	multiselect?: boolean;
	onChange?: ExtendedOnChange;
	setInputValue: (x: string) => void;
	setItemsSource: (x: ComboboxItem[]) => void;
	updatePlaceholderValue: (x: ComboboxItem[]) => ComboboxItem[];
}

// =================================================================================================
// COMPONENT
// =================================================================================================

/**
 * @internal
 */
const ComboboxAddButton = ({
	feSize,
	inputRef,
	inputValue,
	itemsSource,
	localization,
	multiselect,
	onChange,
	setInputValue,
	setItemsSource,
	updatePlaceholderValue,
}: ComboboxAddButtonProps) => {
	const { emitChangeEvent } = useComboboxOnChange(onChange, multiselect);

	const handleAddClick = () => {
		const valueToAdd: ComboboxItem = {
			value: inputValue.trim(),
			selected: multiselect,
		};
		const itemExists = itemsSource.findIndex((item) => equal(item.value, valueToAdd.value)) >= 0;
		if (itemExists) return;
		const itemsUpdated = [...itemsSource, valueToAdd].sort(srtSelected);
		setItemsSource(itemsUpdated);
		let itemsSelected: ComboboxItem[];
		setTimeout(() => inputRef.current?.focus());
		if (multiselect) {
			setInputValue('');
			itemsSelected = updatePlaceholderValue(itemsUpdated);
			emitChangeEvent({
				itemAdded: inputValue,
				itemsSelected,
				itemsUpdated,
				value: inputValue,
			});
			return;
		}

		// Singleselect
		emitChangeEvent({
			itemAdded: inputValue,
			itemSelected: inputValue,
			itemsUpdated,
			value: inputValue,
		});
	};

	return (
		<StyledComboboxAddButton feSize={feSize} onClick={handleAddClick}>
			<StyledComboboxIcon feIcon={Icons.Plus} />
			<div>
				{localization?.addButtonLabel || 'Add '}&quot;{inputValue}&quot;
			</div>
		</StyledComboboxAddButton>
	);
};

ComboboxAddButton.displayName = 'ComboboxAddButton';
export default ComboboxAddButton;

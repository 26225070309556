import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { AriaAttributes, ForwardedRef, forwardRef, HTMLAttributes } from 'react';
import ColorSeverity from '~common/constants/colorSeverity';
import IconSeverity from '~common/constants/iconSeverity';
import { BaseSeverity } from '~common/types/severity';
import IconInteractive, {
	IconInteractiveButton,
} from '~components/icon-interactive/IconInteractive';
import Icon from '~components/icon/Icon';
import Link, { LinkAnchor, LinkButton } from '~components/link/Link';
import Colors from '~tokens/colors/Colors';
import FontSizes from '~tokens/font-sizes/FontSizes';
import FontWeights from '~tokens/font-weights/FontWeights';
import IconColors from '~tokens/icon-colors/IconColors';
import Icons from '~tokens/icons/Icons';
import Shadows from '~tokens/shadows/Shadows';
import Sizes from '~tokens/sizes/Sizes';
import Spacings from '~tokens/spacings/Spacings';

// =================================================================================================
// INTERFACE
// =================================================================================================

type LinkProps = Omit<LinkAnchor, 'feColor' | 'feIcon'> | Omit<LinkButton, 'feColor' | 'feIcon'>;

export interface AlertCloseButton
	extends Omit<IconInteractiveButton, 'aria-label' | 'as' | 'feColor' | 'feIcon' | 'feSize'> {
	'aria-label'?: AriaAttributes['aria-label'];
}

export interface AlertProps extends HTMLAttributes<HTMLDivElement> {
	/** Alert message */
	children: string;

	/** If provided, sets the props on the internal close button */
	feCloseButton?: AlertCloseButton;

	/** Alert icon (before the text) */
	feIcon: Icons;

	/** If provided, renders a link  */
	feLink?: LinkProps;

	/** If provided, gives the supplied appearance */
	feSeverity?: BaseSeverity;
}

// =================================================================================================
// COMPONENT
// =================================================================================================

/**
 * The `<Alert>` is a type of notification that appears in-line.
 *
 * See [InVision DSM](https://skf.invisionapp.com/dsm/ab-skf/4-web-applications/nav/5fa7caf78c01200018354495/asset/626a69726f829413012b7d12) for design principles.<br>
 */
const Alert = forwardRef(
	(
		{ children, feCloseButton, feIcon, feLink, feSeverity, ...rest }: AlertProps,
		ref: ForwardedRef<HTMLDivElement>
	) => (
		<StyledAlert
			{...rest}
			aria-describedby="fe-alert-desc"
			aria-label="polite"
			data-comp="alert"
			feSeverity={feSeverity}
			ref={ref}
			role="alert"
		>
			<Icon
				feColor={feSeverity ? IconSeverity.get(feSeverity)?.color : IconColors.Gray}
				feIcon={feIcon}
			/>
			<StyledAlertMain>
				<div id="fe-alert-desc">{children}</div>
				{feLink && <StyledLink {...feLink}>{feLink.children}</StyledLink>}
			</StyledAlertMain>
			{feCloseButton && (
				<IconInteractive
					{...feCloseButton}
					aria-label={feCloseButton?.['aria-label'] || 'Close'}
					as="button"
					feIcon={Icons.Close}
				/>
			)}
		</StyledAlert>
	)
);

Alert.displayName = 'Alert';
export default Alert;

// =================================================================================================
// STYLE
// =================================================================================================

const StyledAlert = styled.div(
	({ feSeverity }: Pick<AlertProps, 'feSeverity'>) => css`
		border-radius: ${Sizes.S02};
		box-shadow: ${Shadows.Md};
		display: flex;
		font-size: ${FontSizes.Sm};
		gap: ${Spacings.Xs};
		padding: ${Spacings.Sm} ${Spacings.Xs};

		${!feSeverity &&
		css`
			background-color: ${Colors.Gray100};
			border: 1px solid ${Colors.Gray600};
		`}

		${feSeverity &&
		css`
			/* stylelint-disable indentation */
			background: linear-gradient(
					${ColorSeverity.get(feSeverity)?.light},
					${ColorSeverity.get(feSeverity)?.light}
				),
				linear-gradient(${Colors.White}, ${Colors.White});
			/* stylelint-enable indentation */
			border: 1px solid ${ColorSeverity.get(feSeverity)?.base};
		`}
	`
);

const StyledAlertMain = styled.div`
	display: flex;
	flex: auto;
	flex-wrap: wrap;
	gap: ${Spacings.Xxs} ${Spacings.Md};
	justify-content: space-between;
`;

const StyledLink = styled(Link)`
	font-weight: ${FontWeights.Normal};
	margin-inline-end: ${Spacings.Xs};
	text-transform: uppercase;
`;

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { ForwardedRef, forwardRef, HTMLAttributes } from 'react';
import DividerColors from '~tokens/divider-colors/DividerColors';
import Spacings from '~tokens/spacings/Spacings';

// =================================================================================================
// INTERFACE
// =================================================================================================

export interface DividerProps extends Omit<HTMLAttributes<HTMLHRElement>, 'children'> {
	/** Option to render the component as a div. Rendered as a hr by default. */
	as?: 'div' | 'hr';

	/** If provided, alters the Divider color */
	feColor?: DividerColors;

	/** The amount of space the component should be indented. */
	feInset?: Spacings;

	/** The amount of space the component occupies. */
	feSpacing?: Spacings;

	/** The axis the component occupies space in. */
	feVertical?: boolean;
}

// =================================================================================================
// COMPONENT
// =================================================================================================

/**
 * The `<Divider>` component can separate items from each other, to visually emphasize their lack of a relationship between groups of information.<br>
 * It extends the interface of native html `<hr>` element.
 *
 * See [InVision DSM](https://skf.invisionapp.com/dsm/ab-skf/4-web-applications/nav/5fa7caf78c01200018354495/folder/6196220aa09c0948140d335d) for design principles.<br>
 * See [MDN Web Docs](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/hr) for further information about the element and related attributes.
 */
const Divider = forwardRef(
	(
		{
			as = 'hr',
			feColor = DividerColors.Primary,
			feSpacing = Spacings.Md,
			feVertical = false,
			...rest
		}: DividerProps,
		ref: ForwardedRef<HTMLHRElement>
	) => (
		<StyledDivider
			{...rest}
			as={as}
			data-comp="divider"
			feColor={feColor}
			feSpacing={feSpacing}
			feVertical={feVertical}
			ref={ref}
		/>
	)
);

Divider.displayName = 'Divider';
export default Divider;

// =================================================================================================
// STYLE
// =================================================================================================

const StyledDivider = styled.hr(
	({ feColor, feInset, feSpacing, feVertical }: DividerProps) => css`
		background-color: ${feColor};
		border: 0;

		${!feVertical &&
		css`
			height: 1px;
			margin: ${feSpacing} ${feInset ? feInset : 0};
		`}

		${feVertical &&
		css`
			align-self: stretch;
			display: flex;
			flex-shrink: 0; /* Prevents shrink when flex-child */
			margin: ${feInset ? feInset : 0} ${feSpacing};
			width: 1px;
		`}
	`
);

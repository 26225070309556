import * as React from 'react';

const IconZoomIn = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M14.19 12.608h-.833l-.296-.285a6.859 6.859 0 0 0 1.562-5.635c-.496-2.933-2.944-5.276-5.9-5.635C4.26.505.514 4.261 1.053 8.725c.358 2.954 2.701 5.403 5.635 5.898a6.86 6.86 0 0 0 5.635-1.561l.285.295v.834l4.496 4.485c.433.432 1.13.432 1.562 0l.01-.011a1.102 1.102 0 0 0 0-1.562l-4.485-4.495Zm-6.332 0A4.742 4.742 0 0 1 3.11 7.86 4.742 4.742 0 0 1 7.858 3.11a4.742 4.742 0 0 1 4.75 4.749 4.742 4.742 0 0 1-4.75 4.748Zm0-7.386a.523.523 0 0 0-.527.527v1.583H5.748a.523.523 0 0 0-.528.528c0 .295.232.527.528.527H7.33V9.97c0 .295.232.528.527.528a.523.523 0 0 0 .528-.528V8.387H9.97a.523.523 0 0 0 .528-.527.523.523 0 0 0-.528-.528H8.386V5.749a.523.523 0 0 0-.528-.527Z" />
	</svg>
);

export default IconZoomIn;

import styled from '@emotion/styled';
import React from 'react';

// =================================================================================================
// COMPONENT
// =================================================================================================

const ImgEmptyState = () => (
	<StyledImgEmptyState
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 240 138"
		height="100%"
		width="100%"
	>
		<g fill="none" fillRule="evenodd">
			<path d="M0 0h240v138H0z" />
			<path
				fill="#F1F5FC"
				d="M166.65 8c4.195 0 7.595 4.86 7.595 10.855s-3.4 10.855-7.594 10.855h36.887c4.194 0 7.594 4.86 7.594 10.856 0 5.995-3.4 10.855-7.594 10.855h23.868c4.194 0 7.594 4.86 7.594 10.855s-3.4 10.855-7.594 10.855h-15.189c-4.194 0-10.149 4.86-10.149 10.856 0 5.995 1.059 9.4 5.253 10.855 5.786 2.267 8.679 5.886 8.679 10.855 0 7.454-3.4 10.855-7.594 10.855h-25.143c.92.026 5.255.524 5.255 7.6 0 7.791-5.26 7.848-5.421 7.848H61.236s-.055-.009-.152-.03l-.112-.028c-1.087-.279-5.161-1.728-5.161-7.79 0-6.647 4.896-7.523 5.385-7.594l.035-.005h-6.325c-6.81 0-9.667-4.86-9.667-10.856 0-5.995 5.472-10.855 9.667-10.855H12.594C8.4 94.842 5 89.982 5 83.987S8.4 73.13 12.594 73.13h21.698c4.195 0 7.595-4.86 7.595-10.855s-3.4-10.855-7.595-10.855h-5.424c-4.194 0-8.68-4.86-8.68-10.855s4.486-10.856 8.68-10.856h43.396c-4.194 0-7.594-4.86-7.594-10.855S68.07 8 72.264 8h94.387Zm58.85 70a9.5 9.5 0 1 1 0 19 9.5 9.5 0 0 1 0-19Z"
			/>
			<g transform="translate(37 68)">
				<circle cx="141.596" cy="8" r="3" stroke="#0F58D6" strokeWidth="2" />
				<circle cx="172.596" cy="18" r="3" fill="#0F58D6" />
				<path
					stroke="#0F58D6"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2.5"
					d="m157.839 18.757-8.358 8.359m-.127-8.359 8.358 8.359M6 12.596l-6 6m0-6 6 6"
				/>
				<circle cx="13.596" cy="3" r="3" fill="#0F58D6" />
			</g>
			<g transform="translate(75 34)">
				<ellipse cx="36.526" cy="36.337" fill="#FFF" rx="35" ry="34" />
				<path
					fill="#808895"
					fillRule="nonzero"
					d="M59.384 64.395c-6.255 4.95-14.19 7.91-22.825 7.91C16.368 72.306 0 56.12 0 36.154 0 16.186 16.368 0 36.56 0c20.19 0 36.558 16.186 36.558 36.153 0 8.539-2.993 16.386-7.999 22.572l18.637 18.43a3.979 3.979 0 0 1 0 5.67 4.087 4.087 0 0 1-5.735 0l-18.637-18.43ZM36.56 69.723c18.749 0 33.948-15.03 33.948-33.57s-15.2-33.57-33.948-33.57c-18.749 0-33.948 15.03-33.948 33.57s15.2 33.57 33.948 33.57Z"
				/>
				<ellipse
					cx="36.526"
					cy="36.337"
					fill="#FFF"
					stroke="#808895"
					strokeWidth="2"
					rx="28.315"
					ry="27.843"
				/>
				<path
					fill="#808895"
					fillRule="nonzero"
					d="M26.864 29.27c-.022-1.227.35-2.476 1.115-3.748.766-1.272 1.896-2.334 3.392-3.187 1.496-.852 3.25-1.296 5.264-1.331 1.871-.033 3.53.284 4.974.95 1.445.665 2.568 1.584 3.37 2.758a6.91 6.91 0 0 1 1.239 3.852c.019 1.097-.187 2.062-.618 2.896a8.83 8.83 0 0 1-1.55 2.167c-.603.611-1.685 1.64-3.248 3.088-.432.408-.778.765-1.037 1.073-.26.308-.451.59-.576.843-.125.254-.22.508-.287.76a29.4 29.4 0 0 0-.296 1.332c-.222 1.347-.978 2.031-2.269 2.054-.671.012-1.24-.198-1.705-.629-.466-.43-.707-1.078-.722-1.943-.019-1.084.133-2.026.454-2.826.322-.8.755-1.504 1.3-2.114.544-.61 1.28-1.336 2.208-2.179.814-.737 1.401-1.293 1.763-1.667.361-.374.664-.79.907-1.246.244-.456.361-.948.352-1.478-.018-1.032-.417-1.897-1.197-2.593-.78-.697-1.777-1.034-2.99-1.013-1.42.025-2.46.401-3.118 1.13-.66.727-1.209 1.792-1.649 3.194-.413 1.467-1.233 2.21-2.46 2.232a2.456 2.456 0 0 1-1.842-.733c-.506-.501-.764-1.049-.774-1.642Zm9.573 22.742a3.047 3.047 0 0 1-2.075-.729c-.596-.5-.903-1.207-.919-2.124-.014-.813.258-1.502.816-2.067.559-.565 1.25-.854 2.077-.869.813-.014 1.502.252 2.067.797.564.545.854 1.224.868 2.038.016.903-.262 1.618-.834 2.144a2.94 2.94 0 0 1-2 .81Z"
				/>
			</g>
		</g>
	</StyledImgEmptyState>
);

export default ImgEmptyState;

// =================================================================================================
// STYLE
// =================================================================================================

const StyledImgEmptyState = styled.svg`
	display: block;
`;

import * as React from 'react';

const IconUnknownCircle = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M10 1c4.968 0 9 4.032 9 9s-4.032 9-9 9-9-4.032-9-9 4.032-9 9-9Zm-.26 12.4c-.293 0-.539.098-.74.294-.2.196-.3.437-.3.725 0 .323.104.575.311.755.207.18.45.27.728.27.269 0 .506-.091.711-.273.205-.183.308-.433.308-.752a.985.985 0 0 0-.294-.725.985.985 0 0 0-.725-.294ZM10 4.5c-.71 0-1.333.146-1.866.438-.533.291-.939.66-1.217 1.104-.278.444-.417.882-.417 1.315 0 .21.088.405.263.585.176.18.391.27.646.27.433 0 .727-.258.882-.773.164-.492.365-.864.602-1.117s.606-.38 1.107-.38c.428 0 .778.126 1.05.376.27.251.406.559.406.923 0 .187-.044.36-.133.52a1.96 1.96 0 0 1-.328.434c-.13.13-.341.322-.633.577-.332.292-.597.544-.793.756a2.525 2.525 0 0 0-.471.738c-.119.28-.178.612-.178.995 0 .305.08.535.243.69a.832.832 0 0 0 .598.233c.455 0 .727-.237.813-.711.05-.224.088-.38.113-.469.025-.088.06-.177.106-.266.046-.09.115-.187.208-.294.094-.107.218-.231.373-.373.56-.501.95-.858 1.166-1.07.216-.211.403-.463.56-.755.157-.292.236-.631.236-1.018a2.44 2.44 0 0 0-.414-1.368c-.275-.419-.666-.75-1.172-.994-.506-.244-1.09-.366-1.75-.366Z" />
	</svg>
);

export default IconUnknownCircle;

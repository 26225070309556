import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { ChangeEvent, ForwardedRef, forwardRef, TextareaHTMLAttributes } from 'react';
import ColorSeverity from '~common/constants/colorSeverity';
import focusOutline from '~common/styles/mixins/focusOutline';
import { BaseInputProps } from '~common/types/input';
import Colors from '~tokens/colors/Colors';
import FontSizes from '~tokens/font-sizes/FontSizes';
import Sizes from '~tokens/sizes/Sizes';
import Spacings from '~tokens/spacings/Spacings';

// =================================================================================================
// INTERFACE
// =================================================================================================

export interface TextAreaProps
	extends Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, 'children' | 'onChange'>,
		Pick<BaseInputProps, 'feSeverity'> {
	/** If provided, displays an alternative size */
	feSize?: 'md' | 'sm';

	/** Callback that will fire anytime the value of the text area changes. */
	onChange?: (event: ChangeEvent<HTMLTextAreaElement>, value: string) => void;
}

// =================================================================================================
// COMPONENT
// =================================================================================================

const TextArea = forwardRef(
	({ feSize = 'md', onChange, ...rest }: TextAreaProps, ref: ForwardedRef<HTMLTextAreaElement>) => (
		<StyledTextArea
			{...rest}
			data-comp="text-area"
			feSize={feSize}
			onChange={(event) => onChange && onChange(event, event.target.value)}
			ref={ref}
		></StyledTextArea>
	)
);

TextArea.displayName = 'TextArea';
export default TextArea;

// =================================================================================================
// STYLE
// =================================================================================================

const StyledTextArea = styled.textarea(
	({ feSeverity, feSize }: Pick<TextAreaProps, 'feSeverity' | 'feSize'>) => css`
		appearance: none;
		background: ${Colors.White};
		border: 1px solid ${feSeverity ? ColorSeverity.get(feSeverity)?.base : Colors.Gray500};
		border-radius: ${Sizes.S02};
		display: block;
		margin: 0;
		width: 100%;

		&:focus-visible {
			${focusOutline({ offset: `-${Sizes.S02}` })}
		}

		@supports not selector(:focus-visible) {
			&:focus {
				${focusOutline({ offset: `-${Sizes.S02}` })}
			}
		}

		&:disabled {
			background: ${Colors.Gray100};
			color: ${Colors.Gray600};
			cursor: not-allowed;
		}

		${feSize === 'md' &&
		css`
			padding: ${Spacings.Sm};
		`}

		${feSize === 'sm' &&
		css`
			font-size: ${FontSizes.Sm};
			padding: ${Spacings.Xs};
		`}
	`
);

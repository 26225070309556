import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, {
	ButtonHTMLAttributes,
	ForwardedRef,
	forwardRef,
	useEffect,
	useRef,
	useState,
} from 'react';
import ellipsis from '~common/styles/mixins/ellipsis';
import focusOutline from '~common/styles/mixins/focusOutline';
import Icon, { IconProps } from '~components/icon/Icon';
import Loader from '~components/loader/Loader';
import { darken } from '~helpers/color/color';
import Colors from '~tokens/colors/Colors';
import FontSizes from '~tokens/font-sizes/FontSizes';
import FontWeights from '~tokens/font-weights/FontWeights';
import MotionDurations from '~tokens/motion-durations/MotionDurations';
import MotionEasings from '~tokens/motion-easings/MotionEasings';
import Sizes from '~tokens/sizes/Sizes';
import Spacings from '~tokens/spacings/Spacings';

// =================================================================================================
// INTERFACE
// =================================================================================================

export interface ButtonIcon extends Pick<IconProps, 'feIcon'> {
	/** Position of the icon in relation to the text.  */
	position: 'left' | 'right';
}

export interface ButtonPrimary {
	/** If true, hides text & icon and shows loading indicator. **Notice!** Only applicable if `feType` is `primary`. */
	feLoading?: boolean;

	/** If provided, alters the appearance */
	feType?: 'primary';
}

export interface ButtonSecondary {
	/** If provided, alters the appearance */
	feType?: 'secondary';
}

interface BaseProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	/** Contents of the button */
	children: string;

	/** If true, gives destructive appearance */
	feDestructive?: boolean;

	/** If provided, renders an icon before or after the text. */
	feIcon?: ButtonIcon;

	/** If provided, displays an alternative size */
	feSize?: 'md' | 'sm';
}

export type ButtonProps = BaseProps & (ButtonPrimary | ButtonSecondary);

// =================================================================================================
// COMPONENT
// =================================================================================================

const animationDuration = MotionDurations.Normal;

/**
 * Our `<Button>` component, to be used in forms or for interactivity.<br>
 * It extends the interface of native html `<button>` element.
 *
 * See [InVision DSM](https://skf.invisionapp.com/dsm/ab-skf/4-web-applications/nav/5fa7caf78c01200018354495/folder/6167f8740cff7ceb45640de9) for design principles.<br>
 * See [MDN Web Docs](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button) for further information about the element and related attributes.
 */
const Button = forwardRef(
	(
		{ children, disabled, feIcon, feSize = 'md', type = 'button', ...rest }: ButtonProps,
		ref: ForwardedRef<HTMLButtonElement>
	) => {
		const [displayLoader, setDisplayLoader] = useState(false);
		const [renderLoader, setRenderLoader] = useState(false);
		const { feType = 'primary' } = rest;
		const loading = rest.feType === 'primary' && rest.feLoading;
		const timerRef = useRef<NodeJS.Timeout>();

		useEffect(() => {
			if (loading) {
				setRenderLoader(true);
				timerRef.current && clearTimeout(timerRef.current);
				timerRef.current = setTimeout(() => {
					setDisplayLoader(true);
				}, animationDuration);
			} else {
				setDisplayLoader(false);
				timerRef.current && clearTimeout(timerRef.current);
				timerRef.current = setTimeout(() => {
					setRenderLoader(false);
				}, animationDuration);
			}
		}, [loading]);

		return (
			<StyledButton
				{...rest}
				data-comp="button"
				disabled={loading || disabled}
				feLoading={loading}
				feSize={feSize}
				feType={feType}
				ref={ref}
				type={type}
			>
				{renderLoader && <StyledLoader feColorInvert feLoading={displayLoader} feSize="sm" />}
				<StyledButtonBody feLoading={loading}>
					{feIcon?.position === 'left' && <StyledIcon {...feIcon} />}
					<StyledButtonLabel>{children}</StyledButtonLabel>
					{feIcon?.position === 'right' && <StyledIcon {...feIcon} />}
				</StyledButtonBody>
			</StyledButton>
		);
	}
);

Button.displayName = 'Button';
export default Button;

// =================================================================================================
// STYLE
// =================================================================================================

interface StyledButtonProps
	extends Pick<ButtonPrimary, 'feLoading'>,
		Pick<ButtonProps, 'feDestructive' | 'feSize' | 'feType'> {}

const StyledButton = styled.button(
	({ feDestructive, feLoading, feSize, feType }: StyledButtonProps) => {
		const buttonColor = feDestructive ? Colors.RedBase : Colors.Brand;

		return css`
			border-radius: 2px;
			font-weight: ${FontWeights.Bold};
			max-width: min(100%, 23ch);
			padding: 0 ${Spacings.Md};
			position: relative;
			transition-duration: ${MotionDurations.Normal}ms;
			transition-property: background-color, color;

			&:focus-visible {
				${focusOutline()}
			}

			@supports not selector(:focus-visible) {
				&:focus {
					${focusOutline()}
				}
			}

			${!feLoading &&
			css`
				&:disabled,
				&:disabled:hover,
				&:disabled:active {
					background-color: ${Colors.Gray100};
					border: 0;
					color: ${Colors.Gray500};
				}
			`}

			${feSize === 'md' &&
			css`
				font-size: ${FontSizes.Md};
				height: ${Sizes.S40};
				min-width: 100px;
			`}

			${feSize === 'sm' &&
			css`
				font-size: ${FontSizes.Sm};
				height: ${Sizes.S32};
				min-width: 64px;
			`}

			${feType === 'primary' &&
			css`
				background-color: ${buttonColor};
				color: ${Colors.White};

				&:hover {
					background-color: ${darken(buttonColor, 15)};
				}

				&:active {
					background-color: ${darken(buttonColor, 35)};
				}
			`}

			${feType === 'secondary' &&
			css`
				background-color: ${Colors.White};
				border: 1px solid ${buttonColor};
				color: ${buttonColor};

				&:hover {
					background-color: ${Colors.Gray100};
				}

				&:active {
					background-color: ${Colors.Gray200};
				}
			`}
		`;
	}
);

const StyledLoader = styled(Loader)(
	({ feLoading }: Pick<ButtonPrimary, 'feLoading'>) => css`
		left: 50%;
		opacity: ${!feLoading && 0};
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%);
		transition: opacity ${animationDuration}ms ${MotionEasings.EaseIn};
		transition-delay: ${feLoading && `${animationDuration}ms`};
	`
);

const StyledButtonBody = styled.span(
	({ feLoading }: Pick<ButtonPrimary, 'feLoading'>) => css`
		align-items: center;
		display: flex;
		justify-content: center;
		opacity: ${feLoading && 0};
		transition: opacity ${animationDuration}ms ${MotionEasings.EaseIn};
		transition-delay: ${!feLoading && `${animationDuration}ms`};
	`
);

const StyledButtonLabel = styled.span`
	${ellipsis()}
`;

const StyledIcon = styled(Icon)(
	({ position }: Pick<ButtonIcon, 'position'>) => css`
		color: inherit;
		margin-left: ${position === 'right' && Spacings.Xs};
		margin-right: ${position === 'left' && Spacings.Xs};
	`
);

import Colors from '~tokens/colors/Colors';

const feLog = (topic: string, message: string, type: 'error' | 'info' | 'warn' = 'info') => {
	let color: Colors;
	switch (type) {
		case 'error':
			color = Colors.RedBase;
			break;
		case 'info':
			color = Colors.Brand;
			break;
		case 'warn':
			color = Colors.YellowDark;
			break;
		default:
			color = Colors.Black;
			break;
	}

	return console[type](
		`%cFerris | ${topic}:`,
		`color: ${color}; font-weight: bold`,
		`\n${message}`
	);
};

export default feLog;

import React, { OptionHTMLAttributes } from 'react';
import Hint from '~common/components/hint/Hint';
import Label from '~common/components/label/Label';
import { BaseInputFieldProps } from '~common/types/input';
import { IconProps } from '~components/icon/Icon';
import {
	SelectItemSizes,
	SelectListSizes,
	SelectValueTypeConstraint,
} from '~components/select/selectCommonTypes';
import SelectField, { SelectFieldProps } from '~components/select/SelectField';
import Spacer from '~components/spacer/Spacer';
import generateId from '~helpers/generateId/generateId';
import Spacings from '~tokens/spacings/Spacings';

// =================================================================================================
// INTERFACE
// =================================================================================================

export type SelectItem<T extends SelectValueTypeConstraint = string> = Omit<
	OptionHTMLAttributes<HTMLOptionElement>,
	'selected' | 'value'
> & {
	/** If provided, adds an icon to the right of your SelectField item */
	feIcon?: IconProps['feIcon'];

	/** If provided, adds a very short label to the right of your SelectField item  */
	feShortLabel?: string;

	/** If provided, renders an optgroup with corresponding items */
	items?: SelectItem<T>[];

	value?: T;
};

export type SelectProps<T extends SelectValueTypeConstraint> = BaseInputFieldProps &
	SelectFieldProps<T> & {
		/** If provided, sets number of visible rows for the list */
		feRows?: 5 | 6 | 7;
	};

// =================================================================================================
// COMPONENT
// =================================================================================================

/**
 * <br>
 * <span class="sbdocs-tag sbdocs-blue">controlled</span>
 * <span class="sbdocs-tag sbdocs-blue">uncontrolled</span>
 * <br><br>
 *
 * The `<Select>` component is a custom "select" that retains the accessible functionality of its native html `<select>` element.<br>
 * It uses `downshift` behind the scenes.
 *
 * See [Downshift](https://downshift-js.com/) for more information on how the component works.<br>
 * See [InVision DSM](https://skf.invisionapp.com/dsm/ab-skf/4-web-applications/nav/5fa7caf78c01200018354495/folder/6196268b993f76cbf10607b3) for design principles.
 */
const Select = <T extends SelectValueTypeConstraint = string>({
	className,
	defaultValue,
	disabled,
	feHideLabel,
	feHint,
	feLabel,
	feRows,
	feRequiredText,
	feSeverity,
	feSize = 'md',
	id = generateId(),
	multiple,
	onChange,
	required,
	value,
	...rest
}: SelectProps<T>) => {
	// TODO: Use alias token for magic number 16?
	const size = feSize === 'sm' ? 'Sm' : 'Md';
	const listPadding = Number(String(SelectListSizes.Padding).replace(/\D+$/g, '')) * 16 * 2;
	const itemSize = Number(String(SelectItemSizes[size]).replace(/\D+$/g, '')) * 16;
	const listHeight = feRows ? feRows * itemSize + listPadding : undefined;

	return (
		<div className={className} data-comp="select">
			<Label
				feDisabled={disabled}
				feHideLabel={feHideLabel}
				feRequired={required}
				feRequiredText={feRequiredText}
				feSize={feSize}
				htmlFor={id}
				id={id ? `${id}-label` : undefined}
				onClick={() => window.dispatchEvent(new Event('resize'))}
			>
				{feLabel}
			</Label>
			{!feHideLabel && <Spacer feSpacing={Spacings.Xs} />}
			{multiple ? (
				<SelectField
					{...rest}
					multiple
					defaultValue={defaultValue}
					disabled={disabled}
					feListHeight={listHeight}
					feSeverity={feSeverity}
					feSize={feSize}
					id={id}
					onChange={onChange}
					required={required}
					value={value}
				/>
			) : (
				<SelectField
					{...rest}
					multiple={false}
					defaultValue={defaultValue}
					disabled={disabled}
					feListHeight={listHeight}
					feSeverity={feSeverity}
					feSize={feSize}
					id={id}
					onChange={onChange}
					required={required}
					value={value}
				/>
			)}
			{feHint && (
				<>
					<Spacer feSpacing={Spacings.Xxs} />
					<Hint feSeverity={feSeverity}>{feHint}</Hint>
				</>
			)}
		</div>
	);
};

Select.displayName = 'Select';
export default Select;

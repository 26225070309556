import * as React from 'react';

const IconRewalkableRoute = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M10 0c3.29 0 6.18 1.6 8 4.05V2c0-.55.45-1 1-1s1 .45 1 1v4c0 .55-.45 1-1 1h-4c-.55 0-1-.45-1-1s.45-1 1-1h1.24C14.77 3.17 12.53 2 10 2c-4.42 0-8 3.58-8 8s3.58 8 8 8 8-3.58 8-8c0-.55.45-1 1-1s1 .45 1 1c0 5.52-4.48 10-10 10S0 15.52 0 10 4.48 0 10 0Zm-.504 6.109c.672-.265 1.447-.032 1.835.543l.692 1.035c.464.763 1.336 1.345 2.389 1.507.339.052.588.317.588.634 0 .394-.374.705-.79.646-1.204-.18-2.277-.756-3.018-1.558l-.415 1.94 1.025.912c.276.246.429.582.429.938v3.647c0 .356-.312.647-.693.647-.38 0-.692-.291-.692-.647V13.12l-1.454-1.294-1.121 4.67a.687.687 0 0 1-.679.504h-.055c-.436 0-.769-.375-.679-.77l1.773-8.349-1.246.453v1.552c0 .356-.312.647-.693.647-.38 0-.692-.291-.692-.647V8.353c0-.524.332-.99.845-1.196ZM11.5 3c.825 0 1.5.675 1.5 1.5S12.325 6 11.5 6 10 5.325 10 4.5 10.675 3 11.5 3Z" />
	</svg>
);

export default IconRewalkableRoute;

import * as React from 'react';

const IconEyeHidden = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M17.778 2.222a1 1 0 0 1 0 1.414L3.636 17.778a1 1 0 1 1-1.414-1.414L16.364 2.222a1 1 0 0 1 1.414 0ZM16.69 6.139C17.57 7.17 18.34 8.459 19 10c-2 4.667-5 7-9 7-1.258 0-2.418-.231-3.478-.693l1.651-1.651a5 5 0 0 0 6.482-6.482l2.035-2.035ZM10 3c1.258 0 2.418.231 3.478.693l-1.65 1.652a5 5 0 0 0-6.483 6.482L3.31 13.86C2.43 12.83 1.66 11.541 1 10l.148-.337C3.147 5.22 6.098 3 10 3Zm2.996 6.835A3 3 0 0 1 10 13l-.166-.006 3.162-3.16ZM10 7l.166.005-3.161 3.161A3 3 0 0 1 10 7Z" />
	</svg>
);

export default IconEyeHidden;

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, {
	ButtonHTMLAttributes,
	FocusEventHandler,
	MouseEvent,
	RefObject,
	useRef,
} from 'react';
import { InputNumberProps } from '~common/components/input-number/InputNumber';
import ColorSeverity from '~common/constants/colorSeverity';
import Icon from '~components/icon/Icon';
import Colors from '~tokens/colors/Colors';
import IconSizes from '~tokens/icon-sizes/IconSizes';
import Icons from '~tokens/icons/Icons';
import Sizes from '~tokens/sizes/Sizes';

// =================================================================================================
// INTERFACE
// =================================================================================================

export interface InputNumberControlProps
	extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'aria-label' | 'onBlur' | 'type'> {
	inputRef: RefObject<HTMLInputElement>;
	onBlur?: FocusEventHandler<HTMLButtonElement | HTMLInputElement>;
	severity: InputNumberProps['feSeverity'];
	type: 'Decrement' | 'Increment';
}

// =================================================================================================
// COMPONENT
// =================================================================================================

const InputNumberControl = ({ inputRef, onMouseDown, type, ...rest }: InputNumberControlProps) => {
	const repeater = useRef<NodeJS.Timeout>();
	const timer = useRef<NodeJS.Timeout>();

	const updateValue = (type: InputNumberControlProps['type']) => {
		if (inputRef.current) {
			if (type === 'Increment') inputRef.current?.stepUp();
			else inputRef.current?.stepDown();

			inputRef.current.dispatchEvent(new Event('input', { bubbles: true }));
		}
	};

	const mouseDownHandler = (
		event: MouseEvent<HTMLButtonElement>,
		type: InputNumberControlProps['type']
	) => {
		onMouseDown && onMouseDown(event);

		if (event.button === 0) {
			mouseUpHandler();

			updateValue(type);
			timer.current = setTimeout(() => {
				repeater.current = setInterval(() => updateValue(type), 50);
				updateValue(type);
			}, 500);
		}
	};

	const mouseUpHandler = () => {
		if (repeater.current) clearInterval(repeater.current);
		if (timer.current) clearTimeout(timer.current);
		inputRef.current?.focus();
	};

	return (
		<StyledInputNumberControl
			{...rest}
			aria-hidden
			onMouseDown={(event) => mouseDownHandler(event, type)}
			onMouseUp={mouseUpHandler}
			tabIndex={-1}
			type="button"
		>
			<StyledIcon
				feIcon={type === 'Increment' ? Icons.ChevronUp : Icons.ChevronDown}
				feSize={IconSizes.Lg}
			/>
		</StyledInputNumberControl>
	);
};

InputNumberControl.displayName = 'InputNumberControl';
export default InputNumberControl;

// =================================================================================================
// STYLE
// =================================================================================================

const StyledInputNumberControl = styled.button(
	({ severity }: Pick<InputNumberControlProps, 'severity'>) => css`
		background: inherit;
		border-left: 1px solid ${severity ? ColorSeverity.get(severity)?.base : Colors.Gray500};
		flex: auto;
		height: calc(50% - 1px);
		line-height: 0;
		position: absolute;
		right: 1px;
		width: ${Sizes.S48};

		&:first-of-type {
			top: 1px;
		}

		&:last-of-type {
			border-top: 1px solid ${severity ? ColorSeverity.get(severity)?.base : Colors.Gray500};
			bottom: 1px;
		}

		&:hover {
			background: ${Colors.Primary200};
		}

		&:active {
			background: ${Colors.Primary300};
		}

		&:disabled {
			background: inherit;
		}
	`
);

const StyledIcon = styled(Icon)`
	left: 50%;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
`;

import styled from '@emotion/styled';
import React, { RefObject } from 'react';
import { BaseInputProps } from '~common/types/input';
import { StyledComboboxButton, StyledComboboxIcon } from '~components/combobox/Combobox.styles';
import Colors from '~tokens/colors/Colors';
import Icons from '~tokens/icons/Icons';
import { ComboboxItem } from './Combobox';
import { equal } from './Combobox.helpers';

// =================================================================================================
// INTERFACE
// =================================================================================================

interface ComboboxListItemProps {
	feSize?: BaseInputProps['feSize'];
	handleClickedItem: (value: string) => void;
	inputRef: RefObject<HTMLInputElement>;
	inputValue: string;
	item: ComboboxItem;
	multiselect?: boolean;
}

// =================================================================================================
// COMPONENT
// =================================================================================================

/**
 * @internal
 */
const ComboboxListItem = ({
	feSize,
	handleClickedItem,
	inputRef,
	inputValue,
	item,
	multiselect,
}: ComboboxListItemProps) => (
	<StyledComboboxListItem key={item.value.replace(/\s/g, '_')}>
		<StyledComboboxButton
			aria-selected={item.selected}
			feSize={feSize}
			inputRef={inputRef}
			isMatching={equal(item.value, inputValue)}
			onMouseDown={() => handleClickedItem(item.value)}
			type="button"
		>
			{multiselect && item.selected && <StyledComboboxIcon feIcon={Icons.Check} />}
			{item.value}
		</StyledComboboxButton>
	</StyledComboboxListItem>
);

ComboboxListItem.displayName = 'ComboboxListItem';
export default ComboboxListItem;

// =================================================================================================
// STYLE
// =================================================================================================

const StyledComboboxListItem = styled.li`
	background-color: ${Colors.White};
	cursor: pointer;
`;

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { ReactNode } from 'react';
import MediaQueries from '~tokens/media-queries/MediaQueries';

// =================================================================================================
// INTERFACE
// =================================================================================================

type Colspan = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export interface GridItemColspan {
	desktop?: Colspan;
	mobile?: Colspan;
	tablet?: Colspan;
}

export interface GridItemDevice {
	desktop?: boolean;
	mobile?: boolean;
	tablet?: boolean;
}

export interface GridItemProps {
	/** If provided, sets the children */
	children?: ReactNode;

	/** If provided, sets the corresponding device/breakpoint colspan **/
	feColspan?: GridItemColspan;

	/** If false, renders the corresponding device/breakpoint display: none **/
	feDisplay?: GridItemDevice;

	/** If false, renders the corresponding device/breakpoint visibility: hidden **/
	feVisible?: GridItemDevice;
}

// =================================================================================================
// COMPONENT
// =================================================================================================

const GridItem = ({ children, ...rest }: GridItemProps) => (
	<StyledGridItem {...rest} data-comp="grid-item">
		{children}
	</StyledGridItem>
);

GridItem.displayName = 'GridItem';
export default GridItem;

// =================================================================================================
// STYLE
// =================================================================================================

const StyledGridItem = styled.div(
	({ feColspan, feDisplay, feVisible }: GridItemProps) => css`
		@media screen and ${MediaQueries.MobileMax} {
			display: ${feDisplay?.mobile === false && 'none'};
			grid-column: ${feColspan?.mobile && `span ${feColspan?.mobile}`};
			visibility: ${feVisible?.mobile === false && 'hidden'};
		}

		@media screen and ${MediaQueries.Tablet} and ${MediaQueries.TabletMax} {
			display: ${feDisplay?.tablet === false && 'none'};
			grid-column: ${feColspan?.tablet && `span ${feColspan?.tablet}`};
			visibility: ${feVisible?.tablet === false && 'hidden'};
		}

		@media screen and ${MediaQueries.Desktop} {
			display: ${feDisplay?.desktop === false && 'none'};
			grid-column: ${feColspan?.desktop && `span ${feColspan?.desktop}`};
			visibility: ${feVisible?.desktop === false && 'hidden'};
		}
	`
);

enum FontSizes {
	Lg = '1.125rem',
	Md = '1rem',
	Sm = '0.875rem',
	Xl = '1.25rem',
	Xs = '0.75rem',
	Xxl = '1.5rem',
	Xxs = '0.625rem',
	Xxxl = '2rem',
	Xxxxl = '3rem',
}

export default FontSizes;

import * as React from 'react';

const IconBandCursor = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="m15 18.398-10-10v3.585l7.517 7.517h-1.415L5 13.398V19.5H3V.5h14v19h-2v-1.102Zm0-1.415v-3.585l-10-10v3.585l10 10Zm0-5V8.398L9.102 2.5H5.517L15 11.983Zm0-5V2.5h-4.483L15 6.983Z" />
	</svg>
);

export default IconBandCursor;

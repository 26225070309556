import * as React from 'react';

const IconImage = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M16.611 1.5H3.39C2.35 1.5 1.5 2.35 1.5 3.389V16.61c0 1.039.85 1.889 1.889 1.889H16.61c1.039 0 1.889-.85 1.889-1.889V3.39c0-1.039-.85-1.889-1.889-1.889ZM4.9 13.967l1.889-2.522a.469.469 0 0 1 .755 0l1.748 2.333 2.455-3.277a.469.469 0 0 1 .756 0l2.597 3.466a.472.472 0 0 1-.378.755H5.278a.472.472 0 0 1-.378-.755Z" />
	</svg>
);

export default IconImage;

import * as React from 'react';

const IconCaretDown = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="m6.33 9.405 2.886 2.886a1.11 1.11 0 0 0 1.57 0l2.886-2.886c.702-.702.201-1.905-.79-1.905H7.11c-.991 0-1.482 1.203-.78 1.905Z" />
	</svg>
);

export default IconCaretDown;

import * as React from 'react';

const IconHeatmap = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M1.252 5.08a1.193 1.193 0 1 0 0-2.387 1.193 1.193 0 0 0 0 2.387Zm11.455 0a1.193 1.193 0 1 0 0-2.387 1.193 1.193 0 0 0 0 2.387Zm4.773 0a1.193 1.193 0 1 0 0-2.387 1.193 1.193 0 0 0 0 2.387ZM7.218 6.273a2.386 2.386 0 1 0 0-4.773 2.386 2.386 0 0 0 0 4.773Zm10.93 4.534a1.193 1.193 0 1 0 0-2.387 1.193 1.193 0 0 0 0 2.387Zm-16.228 0a1.193 1.193 0 1 0 0-2.387 1.193 1.193 0 0 0 0 2.387ZM12.182 12a2.386 2.386 0 1 0 0-4.773 2.386 2.386 0 0 0 0 4.773Zm.048 4.534a1.193 1.193 0 1 0 0-2.386 1.193 1.193 0 0 0 0 2.386Zm-10.978 0a1.193 1.193 0 1 0 0-2.386 1.193 1.193 0 0 0 0 2.386Zm5.966 1.193a2.386 2.386 0 1 0 0-4.772 2.386 2.386 0 0 0 0 4.772Zm10.338 0a2.386 2.386 0 1 0 0-4.772 2.386 2.386 0 0 0 0 4.772Z" />
	</svg>
);

export default IconHeatmap;

import * as React from 'react';

const IconAsset = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<defs>
			<path
				d="m10.461 1.24 7.25 3.766a1 1 0 0 1 .539.887v8.214a1 1 0 0 1-.539.887l-7.25 3.767a1 1 0 0 1-.922 0l-7.25-3.767a1 1 0 0 1-.539-.887V5.893a1 1 0 0 1 .539-.887l7.25-3.767a1 1 0 0 1 .922 0ZM10 5.5a4.5 4.5 0 1 0 0 9 4.5 4.5 0 0 0 0-9Z"
				id="a"
			/>
		</defs>
		<use xlinkHref="#a" />
	</svg>
);

export default IconAsset;

import * as React from 'react';

const IconViewVertical = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M.5 2h2a.5.5 0 0 1 .5.5v15a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-15A.5.5 0 0 1 .5 2Zm5 0H11a.5.5 0 0 1 .5.5v15a.5.5 0 0 1-.5.5H5.5a.5.5 0 0 1-.5-.5v-15a.5.5 0 0 1 .5-.5ZM14 2h5.5a.5.5 0 0 1 .5.5v15a.5.5 0 0 1-.5.5H14a.5.5 0 0 1-.5-.5v-15A.5.5 0 0 1 14 2Z" />
	</svg>
);

export default IconViewVertical;

import * as React from 'react';

const IconCPM = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M3.562 13.5c.721 0 1.316-.18 1.785-.538.47-.359.805-.907 1.006-1.646l-1.315-.42c-.113.495-.3.859-.56 1.09-.26.23-.57.346-.93.346-.489 0-.886-.182-1.192-.545-.305-.363-.458-.973-.458-1.829 0-.806.155-1.39.465-1.75.31-.36.715-.54 1.213-.54.36 0 .667.102.919.305s.418.48.497.831l1.343-.323c-.153-.542-.382-.957-.688-1.247-.513-.49-1.18-.734-2.003-.734-.94 0-1.7.312-2.278.935C.79 8.058.5 8.933.5 10.06c0 1.065.287 1.905.862 2.519.574.614 1.308.921 2.2.921Zm4.937-.115V10.83h.884c.615 0 1.084-.032 1.408-.097a1.96 1.96 0 0 0 .703-.32c.231-.162.421-.385.57-.668.15-.283.226-.633.226-1.048 0-.539-.13-.978-.39-1.318a1.761 1.761 0 0 0-.967-.663c-.25-.068-.789-.102-1.614-.102H7.142v6.77H8.5ZM9.24 9.68H8.5v-1.92h.655c.49 0 .815.015.977.046.22.04.401.14.545.3.144.16.216.363.216.61 0 .2-.052.375-.154.526a.892.892 0 0 1-.424.332c-.18.071-.538.106-1.073.106Zm5.029 3.704V8.056l1.33 5.329h1.306l1.333-5.329v5.329H19.5v-6.77h-2.035l-1.206 4.618-1.219-4.618h-2.03v6.77h1.26Z" />
	</svg>
);

export default IconCPM;

import * as React from 'react';

const IconTimewave = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M0 8.25V6.5c1.357 0 1.875.815 2.774 3.175.6 1.577.916 2.075 1.226 2.075.31 0 .625-.498 1.226-2.075C6.125 7.315 6.642 6.5 8 6.5c1.357 0 1.874.815 2.773 3.175.601 1.577.917 2.075 1.226 2.075.31 0 .626-.498 1.227-2.075C14.125 7.315 14.642 6.5 16 6.5c1.357 0 1.874.815 2.774 3.175.6 1.577.916 2.075 1.226 2.075v1.75c-1.357 0-1.875-.815-2.774-3.175-.6-1.577-.917-2.075-1.226-2.075-.31 0-.626.498-1.227 2.075-.899 2.36-1.416 3.175-2.774 3.175-1.357 0-1.874-.815-2.773-3.175C8.625 8.748 8.309 8.25 8 8.25c-.31 0-.626.498-1.227 2.075C5.874 12.685 5.357 13.5 4 13.5c-1.357 0-1.875-.815-2.774-3.175C.626 8.748.31 8.25 0 8.25Z" />
	</svg>
);

export default IconTimewave;

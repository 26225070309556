import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { TdHTMLAttributes } from 'react';
import Icon from '~components/icon/Icon';
import { TableProps } from '~components/table/Table';
import { TableSorting } from '~components/table/useTable';
import VisuallyHidden from '~components/visually-hidden/VisuallyHidden';
import Colors from '~tokens/colors/Colors';
import IconColors from '~tokens/icon-colors/IconColors';
import Icons from '~tokens/icons/Icons';
import LineHeights from '~tokens/line-heights/LineHeights';
import Spacings from '~tokens/spacings/Spacings';

// =================================================================================================
// INTERFACE
// =================================================================================================

export interface TableCellSortableLabels {
	ascending?: string;
	descending?: string;
	neutral?: string;
}

export interface TableCellSortable {
	direction?: 'asc' | 'desc';
	labels?: TableCellSortableLabels;
	onClick?: () => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	sortBy?: (props: any) => string;
}

export interface TableCellProps extends TdHTMLAttributes<HTMLTableCellElement> {
	as?: 'td' | 'th';
	compact: TableProps['feCompact'];
	index: number;
	scope?: 'col' | 'colgroup' | 'row' | 'rowgroup';
	sortable?: TableCellSortable;
	sorting?: TableSorting;
}

// =================================================================================================
// COMPONENT
// =================================================================================================

/**
 * Renders a table cell (<td>)
 *
 * @internal
 */
const TableCell = ({ children, compact, index, sortable, sorting, ...rest }: TableCellProps) => {
	const labelDefault = sortable?.labels?.neutral || 'Sortable';
	const iconDefault = Icons.CaretUpDown;

	let sortableLabel = labelDefault;
	let sortableIcon: Icons = iconDefault;

	if (index === sorting?.column) {
		if (sorting.direction === 'asc') {
			sortableLabel = sortable?.labels?.ascending || 'Ascending';
			sortableIcon = Icons.CaretUp;
		} else if (sorting.direction === 'desc') {
			sortableLabel = sortable?.labels?.descending || 'Descending';
			sortableIcon = Icons.CaretDown;
		} else {
			sortableLabel = labelDefault;
			sortableIcon = iconDefault;
		}
	}

	return (
		<StyledTableCell {...rest} feCompact={compact} sortable={sortable}>
			{children}
			{sortable && (
				<StyledTableButton
					onClick={sortable.onClick}
					stSorted={index == sorting?.column}
					direction={sorting?.direction}
				>
					<VisuallyHidden>{sortableLabel}</VisuallyHidden>
					<StyledIcon feIcon={sortableIcon} />
				</StyledTableButton>
			)}
		</StyledTableCell>
	);
};

TableCell.displayName = 'TableCell';
export default TableCell;

// =================================================================================================
// STYLE
// =================================================================================================

interface StyleProps
	extends Pick<TableCellProps, 'sortable'>,
		Pick<TableProps, 'feCompact'>,
		Pick<TableSorting, 'direction'> {
	stSorted: boolean;
}

const StyledTableCell = styled.td(
	({ feCompact, sortable }: Pick<StyleProps, 'feCompact' | 'sortable'>) => css`
		padding: ${feCompact ? Spacings.Xs : Spacings.Md} ${Spacings.Md};
		position: ${sortable && 'relative'};
		text-align: left;
	`
);

const StyledTableButton = styled.button(
	({ direction, stSorted }: Pick<StyleProps, 'direction' | 'stSorted'>) => css`
		color: ${stSorted && direction ? IconColors.Gray : Colors.Gray500};
		line-height: ${LineHeights.Sm};
		margin-left: ${Spacings.Xs};
		vertical-align: middle;

		&::after {
			bottom: 0;
			content: '';
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
		}
	`
);

const StyledIcon = styled(Icon)`
	color: inherit;
`;

import * as React from 'react';

const IconZoomOut = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M14.19 12.608h-.833l-.295-.285a6.86 6.86 0 0 0 1.561-5.635c-.495-2.933-2.944-5.276-5.898-5.635a6.864 6.864 0 0 0-7.672 7.672c.36 2.954 2.702 5.403 5.635 5.898a6.86 6.86 0 0 0 5.635-1.561l.285.295v.834l4.495 4.485c.433.432 1.13.432 1.562 0l.01-.011a1.102 1.102 0 0 0 0-1.562l-4.484-4.495Zm-6.33 0A4.742 4.742 0 0 1 3.11 7.86 4.742 4.742 0 0 1 7.86 3.11a4.742 4.742 0 0 1 4.748 4.749 4.742 4.742 0 0 1-4.748 4.748ZM5.75 7.332h4.22c.295 0 .528.232.528.528a.522.522 0 0 1-.528.527H5.75a.522.522 0 0 1-.528-.527c0-.296.232-.528.527-.528Z" />
	</svg>
);

export default IconZoomOut;

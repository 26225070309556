import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Property } from 'csstype';
import React, { ForwardedRef, forwardRef, SVGAttributes } from 'react';
import LogoColors from '~tokens/logo-colors/LogoColors';
import Sizes from '~tokens/sizes/Sizes';

// =================================================================================================
// INTERFACE
// =================================================================================================

export interface LogoProps extends Omit<SVGAttributes<SVGSVGElement>, 'children'> {
	/** Fill color of the Logo. <br><br>Takes `LogoColors.<Name>` token.<br><br> */
	feColor?: LogoColors;

	/** If provided, sets the CSS height of the Logo */
	feHeight?: Property.Height;

	/** If provided, sets the CSS width of the Logo */
	feWidth?: Property.Width;

	/** If provided, sets a descriptive `<title>` for the `<svg>` element */
	title?: string;
}

// =================================================================================================
// COMPONENT
// =================================================================================================

/**
 * The `<Logo>` component renders the SKF logo in three different colors.
 *
 * See [InVision DSM](https://skf.invisionapp.com/dsm/ab-skf/4-web-applications/nav/5fa7caf78c01200018354495/folder/61962620fa9fb7a532915c27) for design principles.
 */
const Logo = forwardRef(
	(
		{
			feColor = LogoColors.Primary,
			feHeight = Sizes.S32,
			title = 'SKF logotype',
			...rest
		}: LogoProps,
		ref: ForwardedRef<SVGSVGElement>
	) => (
		<StyledLogo
			{...rest}
			data-comp="logo"
			feColor={feColor}
			feHeight={feHeight}
			ref={ref}
			viewBox="0 0 1300 300"
			x="0px"
			xmlns="http://www.w3.org/2000/svg"
			y="0px"
		>
			<title>{title}</title>
			<g>
				<g>
					<path
						d="M363.7,300c6.2,0,11.2-5,11.2-11.3v-165c0-6.2-5-11.3-11.3-11.2H157.5c-4.1,0-7.5-3.4-7.5-7.5V45
			c0-4.1,3.4-7.5,7.5-7.5l60,0c4.1,0,7.5,3.4,7.5,7.5v18.8c0,6.2,5,11.2,11.2,11.2h90c6.2,0,11.3-5,11.3-11.2V11.2
			c0-6.2-5-11.2-11.3-11.2L48.7,0c-6.2,0-11.2,5-11.2,11.3v127.5c0,6.2,5,11.2,11.3,11.2H255c4.1,0,7.5,3.4,7.5,7.5V255
			c0,4.1-3.4,7.5-7.5,7.5H120c-4.1,0-7.5-3.4-7.5-7.5v-56.2c0-6.2-5-11.2-11.3-11.2h-90c-6.2,0-11.3,5-11.3,11.2v90
			C0,295,5,300,11.3,300H363.7z"
					/>
					<path
						d="M562.5,192.3c0-1,0.8-1.9,1.9-1.9c0.5,0,1,0.2,1.3,0.5c0.3,0.3,104.9,105,105.8,105.9
			c0.6,0.6,3.4,3.2,7.9,3.2h134.4c6.2,0,11.2-5,11.2-11.2v-90c0-6.2-5-11.2-11.2-11.2h-97c-4.3,0-7.1-2.4-7.8-3.1
			c-1.4-1.4-70.2-70.3-70.6-70.6c-0.3-0.3-0.6-0.8-0.6-1.3c0-0.5,0.2-1,0.6-1.3c0.3-0.3,32.3-32.4,32.8-32.9
			c1.8-1.8,4.6-3.2,7.9-3.2c2.8,0,97.1,0,97.1,0c6.2,0,11.3-5,11.3-11.2l0-52.5c0-6.2-5-11.2-11.2-11.2h-96.9
			c-4.5,0-7.2,2.6-7.9,3.2C670.9,3.8,566,108.8,565.7,109.1c-0.3,0.3-0.8,0.5-1.3,0.5c-1,0-1.9-0.8-1.9-1.9l0-96.5
			c0-6.2-5-11.2-11.3-11.2H442.5c-6.2,0-11.2,5-11.2,11.2v90c0,6.2,5,11.3,11.2,11.3c4.1,0,7.5,3.4,7.5,7.5v60
			c0,4.1-3.4,7.5-7.5,7.5c-6.2,0-11.2,5-11.2,11.2v90c0,6.2,5,11.3,11.2,11.3h108.7c6.2,0,11.3-5,11.3-11.3L562.5,192.3z"
					/>
					<path
						d="M1001.2,300c6.2,0,11.3-5,11.3-11.3v-90c0-6.2-5-11.2-11.3-11.2c-4.1,0-7.5-3.4-7.5-7.5v-22.5
			c0-4.2,3.4-7.5,7.5-7.5h41.2c4.1,0,7.5,3.4,7.5,7.5c0,6.2,5,11.2,11.3,11.2h90.1c6.2,0,11.2-5,11.2-11.2V105
			c0-6.2-5-11.2-11.2-11.2h-90.1c-6.2,0-11.3,5-11.3,11.2c0,4.1-3.4,7.5-7.5,7.5l-41.2,0c-4.1,0-7.5-3.4-7.5-7.5V45
			c0-4.1,3.4-7.5,7.5-7.5h116.3c4.1,0,7.5,3.4,7.5,7.5v18.8c0,6.2,5,11.2,11.3,11.2h127.5c6.2,0,11.3-5,11.3-11.2V11.2
			c0-6.2-5-11.2-11.3-11.2H892.5c-6.2,0-11.2,5-11.2,11.2v90c0,6.2,5,11.3,11.2,11.3c4.1,0,7.5,3.4,7.5,7.5v60
			c0,4.1-3.4,7.5-7.5,7.5c-6.2,0-11.2,5-11.2,11.2v90c0,6.2,5,11.3,11.2,11.3H1001.2z"
					/>
				</g>
			</g>
			<path
				d="M1207.4,262.9c0-17.5,13.2-30.7,30.1-30.7c16.7,0,30,13.2,30,30.7c0,17.8-13.2,30.9-30,30.9
	C1220.7,293.8,1207.4,280.7,1207.4,262.9z M1237.6,300c20.2,0,37.4-15.7,37.4-37.1c0-21.2-17.2-36.9-37.4-36.9
	c-20.4,0-37.6,15.7-37.6,36.9C1200,284.3,1217.2,300,1237.6,300z M1230.9,265.5h6.5l9.9,16.3h6.4l-10.7-16.5
	c5.5-0.7,9.7-3.6,9.7-10.3c0-7.4-4.4-10.7-13.3-10.7h-14.3v37.6h5.7V265.5z M1230.9,260.7V249h7.7c4,0,8.2,0.9,8.2,5.5
	c0,5.8-4.3,6.1-9.1,6.1H1230.9z"
			/>
		</StyledLogo>
	)
);

Logo.displayName = 'Logo';
export default Logo;

// =================================================================================================
// STYLE
// =================================================================================================

const StyledLogo = styled.svg(
	({ feColor, feHeight, feWidth }: LogoProps) => css`
		fill: ${feColor};
		height: ${feHeight};
		width: ${feWidth};
	`
);

import * as React from 'react';

const IconCheckCircle = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M10 1c4.968 0 9 4.032 9 9s-4.032 9-9 9-9-4.032-9-9 4.032-9 9-9Zm5.463 5.094a.866.866 0 0 0-1.224 0l-5.977 5.977-2.504-2.497a.867.867 0 0 0-1.226 1.225l3.023 3.023a1 1 0 0 0 1.414 0l6.494-6.494c.34-.34.341-.89.003-1.231Z" />
	</svg>
);

export default IconCheckCircle;

import { useEffect, useState } from 'react';
import MediaQueries from '~tokens/media-queries/MediaQueries';

const useMediaQuery = (query: MediaQueries) => {
	const [matches, setMatches] = useState(false);

	useEffect(() => {
		const media = window.matchMedia(query);
		if (media.matches !== matches) {
			setMatches(media.matches);
		}
		const handler = () => setMatches(media.matches);
		window.addEventListener('resize', handler);
		return () => window.removeEventListener('resize', handler);
	}, [matches, query]);

	return matches;
};

export default useMediaQuery;

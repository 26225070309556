import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { ForwardedRef, forwardRef, ProgressHTMLAttributes } from 'react';
import { opacity } from '~helpers/color/color';
import Colors from '~tokens/colors/Colors';
import MotionDurations from '~tokens/motion-durations/MotionDurations';
import Spacings from '~tokens/spacings/Spacings';

// =================================================================================================
// INTERFACE
// =================================================================================================

export interface ProgressProps extends ProgressHTMLAttributes<HTMLProgressElement> {
	/** If true, the bar will be overlayed with an animated effect. */
	feAnimated?: boolean;
}

// =================================================================================================
// COMPONENT
// =================================================================================================

/**
 * The `<Progress>` component renders a progress bar to indicate work being done in the background.<br>
 * It extends the interface of native html `<progress>` element.
 *
 * See [InVision DSM](https://skf.invisionapp.com/dsm/ab-skf/4-web-applications/nav/5fa7caf78c01200018354495/asset/619e420eb3d2145aa7584b14) for design principles.<br>
 * See [MDN Web Docs](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/progress) for further information about the element and related attributes.
 */
const Progress = forwardRef(
	(
		{ 'aria-label': ariaLabel = 'Loading...', children, max = 100, value, ...rest }: ProgressProps,
		ref: ForwardedRef<HTMLProgressElement>
	) => (
		<StyledProgress
			{...rest}
			aria-label={ariaLabel}
			data-comp="progress"
			max={max}
			ref={ref}
			value={value}
		>
			{children || `${value}%`}
		</StyledProgress>
	)
);

Progress.displayName = 'Progress';
export default Progress;

// =================================================================================================
// STYLE
// =================================================================================================

const StyledProgress = styled.progress(
	({ feAnimated, value }: Pick<ProgressProps, 'feAnimated' | 'value'>) => {
		const stValue = 100 - Number(value);

		const progressValueStyle = (tp: string) => css`
			background: ${Colors.Brand};
			border-radius: inherit;
			transition: ${tp} ${MotionDurations.Slow}ms cubic-bezier(0.4, 0.93, 0.71, 0.89);

			${feAnimated &&
			css`
				animation: progress-animation ${MotionDurations.Slow}ms linear infinite reverse;
				background-image: linear-gradient(
					-45deg,
					${opacity(Colors.White, 0.2)} 25%,
					transparent 25%,
					transparent 50%,
					${opacity(Colors.White, 0.2)} 50%,
					${opacity(Colors.White, 0.2)} 75%,
					transparent 75%,
					transparent
				);
				background-size: 50px 50px;
			`}
		`;

		return css`
			background: ${Colors.Gray300};
			border: 0;
			border-radius: ${Spacings.Xxs};
			height: ${Spacings.Xs};
			overflow: hidden;
			position: relative;
			width: 100%;

			/* Webkit */
			@supports selector(::-webkit-progress-bar) {
				&::after {
					${progressValueStyle('right')};

					content: '';
					height: inherit;
					left: 0;
					position: absolute;
					right: ${stValue}%;
					top: 0;
				}

				&::-webkit-progress-inner-element {
					display: none;
				}
			}

			/* Firefox */
			&::-moz-progress-bar {
				${progressValueStyle('width')}
			}

			@keyframes progress-animation {
				to {
					background-position: 50px 50px;
				}
			}
		`;
	}
);

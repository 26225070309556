import * as React from 'react';

const IconBands = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M15.647 14H17v-3h-2.765l1.412 3Zm.47 1L17 16.875V15h-.882ZM6.5 14h7L12 11H8l-1.5 3ZM6 15l-2 4H2V2h1v4h5.118L10 2l1.882 4H17V2h1v17h-2l-2-4H6Zm-1.647-1 1.412-3H3v3h1.353Zm-.47 1H3v1.875L3.882 15Zm9.882-5H17V7h-4.647l1.412 3ZM8.5 10h3L10 7l-1.5 3Zm-2.265 0 1.412-3H3v3h3.235Z" />
	</svg>
);

export default IconBands;

import * as React from 'react';

const IconSync = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M10.5 3.004V1.439c0-.394-.506-.586-.797-.306l-2.625 2.44a.413.413 0 0 0 0 .62l2.616 2.44c.3.271.806.079.806-.315V4.753c3.103 0 5.625 2.352 5.625 5.247 0 .69-.14 1.364-.413 1.968-.14.314-.037.673.216.91.478.445 1.284.288 1.538-.298.346-.796.534-1.67.534-2.58 0-3.865-3.356-6.996-7.5-6.996Zm0 12.243c-3.103 0-5.625-2.352-5.625-5.247 0-.69.14-1.364.412-1.968.141-.314.038-.673-.215-.91-.478-.445-1.284-.288-1.538.298A6.446 6.446 0 0 0 3 10c0 3.865 3.356 6.996 7.5 6.996v1.565c0 .394.506.586.797.306l2.615-2.44a.413.413 0 0 0 0-.62l-2.615-2.44c-.29-.271-.797-.079-.797.315v1.565Z" />
	</svg>
);

export default IconSync;

import styled from '@emotion/styled';
import React, { ForwardedRef, forwardRef } from 'react';
import InputCheckbox, { InputCheckboxProps } from '~common/components/input-checkbox/InputCheckbox';
import Label from '~common/components/label/Label';
import { BaseInputFieldProps } from '~common/types/input';
import generateId from '~helpers/generateId/generateId';
import Spacings from '~tokens/spacings/Spacings';

// =================================================================================================
// INTERFACE
// =================================================================================================

export interface CheckboxProps extends InputCheckboxProps, Omit<BaseInputFieldProps, 'feHint'> {}

// =================================================================================================
// COMPONENT
// =================================================================================================

/**
 * <br>
 * <span class="sbdocs-tag sbdocs-blue">controlled</span>
 * <span class="sbdocs-tag sbdocs-blue">uncontrolled</span>
 * <br><br>
 *
 * A `<Checkbox>` component to be used in forms.<br>
 * It extends the interface of native html `<input>` element.
 *
 * See [InVision DSM](https://skf.invisionapp.com/dsm/ab-skf/4-web-applications/nav/5fa7caf78c01200018354495/asset/61962431a09c0951b70d343a) for design principles.<br>
 * See [MDN Web Docs](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/checkbox) for further information about the element and related attributes.
 */
const Checkbox = forwardRef(
	(
		{
			feLabel,
			className,
			disabled,
			feHideLabel = false,
			feRequiredText,
			feSeverity,
			feSize,
			id = generateId(),
			required,
			...rest
		}: CheckboxProps,
		ref: ForwardedRef<HTMLInputElement>
	) => (
		<StyledCheckbox className={className} data-comp="checkbox">
			<InputCheckbox
				{...rest}
				aria-invalid={feSeverity === 'error' ? true : undefined}
				disabled={disabled}
				feSeverity={feSeverity}
				feSize={feSize}
				id={id}
				required={required}
				ref={ref}
			/>
			<Label
				feDisabled={disabled}
				feHideLabel={feHideLabel}
				feRequired={required}
				feRequiredText={feRequiredText}
				feSize={feSize}
				htmlFor={id}
			>
				{feLabel}
			</Label>
		</StyledCheckbox>
	)
);

Checkbox.displayName = 'Checkbox';
export default Checkbox;

// =================================================================================================
// STYLE
// =================================================================================================

const StyledCheckbox = styled.div`
	align-items: center;
	display: flex;
	gap: ${Spacings.Xs};
`;

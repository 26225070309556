import * as React from 'react';

const IconWarningDiamond = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="m11.433.586 8.019 8.019a2 2 0 0 1 0 2.828l-8.019 8.019a2 2 0 0 1-2.828 0l-8.02-8.019a2 2 0 0 1 0-2.828l8.02-8.02a2 2 0 0 1 2.828 0ZM9.992 13.14c-.284 0-.523.1-.72.298a.988.988 0 0 0-.293.72c0 .324.102.576.307.756.205.18.445.27.72.27.27 0 .506-.091.709-.273.202-.183.304-.433.304-.752 0-.283-.1-.523-.3-.721a.995.995 0 0 0-.727-.298Zm.067-8.305c-.324 0-.585.106-.783.317-.198.212-.297.507-.297.886 0 .278.02.736.061 1.374l.22 3.274c.04.424.108.74.204.947.096.207.267.311.513.311a.537.537 0 0 0 .52-.321c.104-.215.173-.522.204-.923l.294-3.37c.032-.31.048-.616.048-.916 0-.51-.066-.902-.198-1.173-.132-.27-.394-.406-.786-.406Z" />
	</svg>
);

export default IconWarningDiamond;

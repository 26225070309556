import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, {
	AnchorHTMLAttributes,
	ButtonHTMLAttributes,
	ForwardedRef,
	forwardRef,
	MouseEvent,
} from 'react';
import ellipsis from '~common/styles/mixins/ellipsis';
import focusOutline from '~common/styles/mixins/focusOutline';
import Icon, { IconProps } from '~components/icon/Icon';
import Colors from '~tokens/colors/Colors';
import FontWeights from '~tokens/font-weights/FontWeights';
import Spacings from '~tokens/spacings/Spacings';

// =================================================================================================
// INTERFACE
// =================================================================================================

export interface LinkIcon extends Pick<IconProps, 'feIcon'> {
	/** Position of the icon in relation to the text  */
	position: 'left' | 'right';
}

export type LinkColor = 'primary' | 'secondary' | 'tertiary';

interface BaseProps {
	/** Text displayed  */
	children: string;

	/** If provided, alters the text-color */
	feColor?: LinkColor;

	/** If provided, renders an icon before or after the text */
	feIcon?: LinkIcon;

	/** Custom onClick where the second return parameter enables custom routing */
	onClick?: (event: MouseEvent<HTMLElement>, route: string) => void;
}

export interface LinkAnchor
	extends Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'children' | 'onClick'>,
		BaseProps {
	/** Option to render the component as a button. Rendered as a link by default */
	as: 'a';

	href: string;
}

export interface LinkButton
	extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'children' | 'onClick'>,
		BaseProps {
	as: 'button';

	/** Custom route which is used together with onClick */
	route?: string;
}

export type LinkProps = LinkAnchor | LinkButton;

// =================================================================================================
// COMPONENT
// =================================================================================================

/**
 * The `<Link>` component renders a link as an anchor or button element. **Notice!** Be careful to choose the appropriate type.<br>
 * It extends the interface of native `<a>` and `<button>` element.
 *
 * See [InVision DSM](https://skf.invisionapp.com/dsm/ab-skf/4-web-applications/nav/5fa7caf78c01200018354495/folder/619626129069cd5f9510528f) for design principles.<br>
 * See [MDN Web Docs](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a) for further information about the `<a>` element and related attributes.<br>
 * See [MDN Web Docs](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button) for further information about the `<button>` element and related attributes.
 */
const Link = forwardRef(
	(
		{ children, feColor = 'primary', feIcon, onClick, ...rest }: LinkProps,
		ref: ForwardedRef<HTMLAnchorElement | HTMLButtonElement>
	) => {
		const { as } = rest;
		const href = as === 'a' ? rest.href : undefined;
		const route = as === 'button' ? rest.route : undefined;

		const clickHandler = (event: MouseEvent<HTMLElement>, route: string) => {
			if (as === 'a') onClick && event.preventDefault();
			onClick?.(event, route);
		};

		return (
			<StyledLink
				{...rest}
				as={as}
				data-comp="link"
				feColor={feColor}
				onClick={(event) => clickHandler(event, href || route || '')}
				ref={ref}
			>
				{feIcon?.position === 'left' && <StyledIcon {...feIcon} />}
				<StyledLinkLabel>{children}</StyledLinkLabel>
				{feIcon?.position === 'right' && <StyledIcon {...feIcon} />}
			</StyledLink>
		);
	}
);

Link.displayName = 'Link';
export default Link;

// =================================================================================================
// HELPER
// =================================================================================================

interface Color {
	default: Colors;
	disabled: Colors;
	hover: Colors;
}

const colors: ReadonlyMap<LinkProps['feColor'], Color> = new Map([
	['primary', { default: Colors.Brand, disabled: Colors.Gray500, hover: Colors.Primary800 }],
	['secondary', { default: Colors.White, disabled: Colors.Primary500, hover: Colors.Primary200 }],
	['tertiary', { default: Colors.Gray700, disabled: Colors.Gray500, hover: Colors.Black }],
]);

// =================================================================================================
// STYLE
// =================================================================================================

const StyledLink = styled.span(
	({ feColor }: Pick<LinkProps, 'feColor'>) => css`
		align-items: center;
		color: ${colors.get(feColor)?.default};
		display: inline-flex;
		font-weight: ${FontWeights.Bold};
		text-decoration: none;

		&:focus-visible {
			${focusOutline({ color: colors.get(feColor)?.default })}
		}

		@supports not selector(:focus-visible) {
			&:focus {
				${focusOutline({ color: colors.get(feColor)?.default })}
			}
		}

		&:hover {
			color: ${colors.get(feColor)?.hover};
		}

		/* Will only be used for the Button */
		&:disabled {
			color: ${colors.get(feColor)?.disabled};
		}
	`
);

const StyledIcon = styled(Icon)(
	({ position }: Pick<LinkIcon, 'position'>) => css`
		color: inherit;
		margin-left: ${position === 'right' && Spacings.Xs};
		margin-right: ${position === 'left' && Spacings.Xs};
	`
);

const StyledLinkLabel = styled.span`
	${ellipsis()}
`;

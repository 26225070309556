import * as React from 'react';

const IconArrowLeft = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M1.29 10.71A.997.997 0 0 1 1 10c0-.28.11-.53.29-.71l6-6A1.003 1.003 0 0 1 9 4c0 .28-.11.53-.3.71L4.41 9H18c.55 0 1 .45 1 1s-.45 1-1 1H4.41l4.3 4.29a1.003 1.003 0 0 1-1.42 1.42l-6-6Z" />
	</svg>
);

export default IconArrowLeft;

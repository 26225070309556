import * as React from 'react';

const IconHourglassOutlined = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M16 1.5v.032a1 1 0 0 1-.147 1.963C15.393 6.534 13.91 8.99 11.975 10c1.935 1.01 3.419 3.465 3.877 6.504l-.102-.005a1 1 0 0 1 .25 1.969v.031H4v-.032a1 1 0 0 1 .147-1.963C4.607 13.466 6.09 11.01 8.025 10 6.09 8.989 4.606 6.534 4.148 3.495l.102.005A1 1 0 0 1 4 1.531V1.5h12Zm-6 10c-1.626 0-3.255 1.99-3.805 4.885l-.021.114h7.651l-.025-.14c-.54-2.804-2.09-4.742-3.658-4.854L10 11.5Zm3.825-8H6.174l.026.14c.54 2.805 2.09 4.743 3.658 4.855L10 8.5c1.626 0 3.255-1.99 3.805-4.885l.02-.115Z" />
	</svg>
);

export default IconHourglassOutlined;

import styled from '@emotion/styled';
import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import Hint from '~common/components/hint/Hint';
import Label from '~common/components/label/Label';
import { BaseInputFieldProps, BaseInputProps } from '~common/types/input';
import DatePickerField, { DatePickerFieldProps } from '~components/date-picker/DatePickerField';
import Flexbox from '~components/flexbox/Flexbox';
import Icon from '~components/icon/Icon';
import Spacer from '~components/spacer/Spacer';
import generateId from '~helpers/generateId/generateId';
import IconSizes from '~tokens/icon-sizes/IconSizes';
import Icons from '~tokens/icons/Icons';
import Spacings from '~tokens/spacings/Spacings';

// =================================================================================================
// INTERFACE
// =================================================================================================

interface BaseProps extends BaseInputFieldProps, Omit<BaseInputProps, 'value'> {
	/** If true, hides the calendar icon. */
	feHideIcon?: boolean;

	/** Localizes the month and weekday names. */
	locale?: Locale;
}

interface SingleProps extends BaseProps, Omit<DatePickerFieldProps<false>, 'onChange' | 'value'> {
	/** If true, allows for selection of two dates. */
	feRange?: false;

	/** Callback that will fire anytime the value of the field changes. */
	onChange: DatePickerFieldProps<false>['onChange'];

	/** Sets the value of the field. Makes the component controlled. */
	value: DatePickerFieldProps<false>['value'];
}

interface MultiProps extends BaseProps, Omit<DatePickerFieldProps<true>, 'onChange' | 'value'> {
	/** If true, allows for selection of two dates. */
	feRange: true;

	/** Callback that will fire anytime the value of the field changes. */
	onChange: DatePickerFieldProps<true>['onChange'];

	/** Sets the value of the field. Makes the component controlled. */
	value: DatePickerFieldProps<true>['value'];
}

export type DatePickerProps = MultiProps | SingleProps;

// =================================================================================================
// COMPONENT
// =================================================================================================

/**
 * <br>
 * <span class="sbdocs-tag sbdocs-blue">controlled</span>
 * <br><br>
 *
 * The `<DatePicker>` component is a date input with built in datepicker for selecting dates.<br>
 * It uses `react-datepicker` behind the scenes.
 *
 * See [InVision DSM](https://skf.invisionapp.com/dsm/ab-skf/4-web-applications/nav/5fa7caf78c01200018354495/asset/62a34fbab000e98c480b2124) for design principles.<br>
 * See [React Datepicker](https://reactdatepicker.com/) for more information on what props it can accept.
 * */
const DatePicker = ({
	'aria-errormessage': ariaErrorMessage,
	disabled = false,
	feHideIcon = false,
	feHideLabel,
	feHint,
	feLabel,
	feRange = false,
	feReactDatePickerProps,
	feRequiredText,
	feSeverity,
	feSize = 'md',
	id = generateId(),
	locale,
	onChange,
	required,
	value,
}: DatePickerProps) => {
	const commonProps = { disabled, feHideIcon, feSize, locale };
	const hintId = feHint && feSeverity === 'error' ? id + '-hint' : undefined;

	return (
		<div data-comp="date-picker">
			<Label
				feDisabled={disabled}
				feHideLabel={feHideLabel}
				feRequired={required}
				feRequiredText={feRequiredText}
				feSize={feSize}
				htmlFor={id}
			>
				{feLabel}
			</Label>
			{!feHideLabel && <Spacer feSpacing={Spacings.Xs} />}
			{feRange ? (
				<Flexbox feGap={Spacings.Xs}>
					<DatePickerField<true>
						{...commonProps}
						aria-errormessage={
							feSeverity === 'error' ? (feHint ? hintId : ariaErrorMessage) : undefined
						}
						aria-invalid={feSeverity === 'error'}
						feDateDisplay="first"
						feReactDatePickerProps={feReactDatePickerProps as MultiProps['feReactDatePickerProps']}
						feSeverity={feSeverity}
						id={id}
						onChange={onChange as MultiProps['onChange']}
						value={value as MultiProps['value']}
					/>
					<StyledIcon feIcon={Icons.ArrowRight} feSize={IconSizes.Lg} />
					<DatePickerField<true>
						{...commonProps}
						aria-errormessage={
							feSeverity === 'error' ? (feHint ? hintId : ariaErrorMessage) : undefined
						}
						aria-invalid={feSeverity === 'error'}
						feDateDisplay="last"
						feReactDatePickerProps={feReactDatePickerProps as MultiProps['feReactDatePickerProps']}
						feSeverity={feSeverity}
						onChange={onChange as MultiProps['onChange']}
						value={value as MultiProps['value']}
					/>
				</Flexbox>
			) : (
				<DatePickerField
					{...commonProps}
					aria-errormessage={
						feSeverity === 'error' ? (feHint ? hintId : ariaErrorMessage) : undefined
					}
					aria-invalid={feSeverity === 'error'}
					feReactDatePickerProps={feReactDatePickerProps as SingleProps['feReactDatePickerProps']}
					feSeverity={feSeverity}
					id={id}
					onChange={onChange as SingleProps['onChange']}
					value={value as SingleProps['value']}
				/>
			)}
			{feHint && (
				<>
					<Spacer feSpacing={Spacings.Xxs} />
					<Hint feSeverity={feSeverity} id={hintId}>
						{feHint}
					</Hint>
				</>
			)}
		</div>
	);
};

DatePicker.displayName = 'DatePicker';
export default DatePicker;

// =================================================================================================
// STYLE
// =================================================================================================

const StyledIcon = styled(Icon)`
	align-self: center;
	flex-shrink: 0;
`;

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { ForwardedRef, forwardRef, HTMLAttributes } from 'react';
import boxPadding from '~common/styles/mixins/boxPadding';
import Colors from '~tokens/colors/Colors';
import Shadows from '~tokens/shadows/Shadows';
import Sizes from '~tokens/sizes/Sizes';

// =================================================================================================
// INTERFACE
// =================================================================================================

export interface CardProps extends HTMLAttributes<HTMLDivElement> {
	/** If true, removes border */
	feNoBorder?: boolean;

	/** If true, removes padding */
	feNoPadding?: boolean;

	/** If true, the Card fills the parent element height */
	feStretch?: boolean;
}

// =================================================================================================
// COMPONENT
// =================================================================================================

/**
 * The `<Card>` component is a general purpose container for various content.
 *
 * See [InVision DSM](https://skf.invisionapp.com/dsm/ab-skf/4-web-applications/nav/5fa7caf78c01200018354495/asset/6229d63d9fe16020a60657e5) for design principles.
 */
const Card = forwardRef(
	(
		{ feNoBorder = false, feNoPadding = false, feStretch = false, ...rest }: CardProps,
		ref: ForwardedRef<HTMLDivElement>
	) => (
		<StyledCard
			{...rest}
			data-comp="card"
			feNoBorder={feNoBorder}
			feNoPadding={feNoPadding}
			feStretch={feStretch}
			ref={ref}
		/>
	)
);

Card.displayName = 'Card';
export default Card;

// =================================================================================================
// STYLE
// =================================================================================================

const StyledCard = styled.div(
	({ feNoBorder, feNoPadding, feStretch }: CardProps) => css`
		background: ${Colors.White};
		border: ${!feNoBorder && `1px solid ${Colors.Gray300}`};
		border-radius: ${Sizes.S02};
		box-shadow: ${Shadows.Md};
		height: ${feStretch && '100%'};

		${!feNoPadding && boxPadding()};
	`
);

import * as React from 'react';

const IconReorder = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M13.964 5.977c.545 0 .99.448.99.996v8.63l1.86-1.871a.982.982 0 0 1 1.396 0 .995.995 0 0 1 0 1.403l-3.553 3.574a.982.982 0 0 1-1.396 0l-3.553-3.574a.995.995 0 0 1 0-1.403.982.982 0 0 1 1.396 0l1.87 1.871v-8.63c0-.548.446-.996.99-.996ZM6.74 1.291l3.553 3.574a.995.995 0 0 1 0 1.403.982.982 0 0 1-1.396 0l-1.87-1.871v8.63a.996.996 0 0 1-.99.996.996.996 0 0 1-.99-.996v-8.63l-1.86 1.871a.982.982 0 0 1-1.396 0 .995.995 0 0 1 0-1.403L5.343 1.29a.982.982 0 0 1 1.396 0Z" />
	</svg>
);

export default IconReorder;

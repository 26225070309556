import * as React from 'react';

const IconUpload = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M14.59 8.092H13v5c0 .55-.45 1-1 1H8c-.55 0-1-.45-1-1v-5H5.41c-.89 0-1.34-1.08-.71-1.71l4.59-4.59a.996.996 0 0 1 1.41 0l4.59 4.59c.63.63.19 1.71-.7 1.71ZM3 17.5c0-.55.45-1 1-1h12c.55 0 1 .45 1 1s-.45 1-1 1H4c-.55 0-1-.45-1-1Z" />
	</svg>
);

export default IconUpload;

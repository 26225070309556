import styled from '@emotion/styled';
import React, { ForwardedRef, forwardRef } from 'react';
import ComponentSizes from '~common/constants/componentSizes';
import IconSeverity from '~common/constants/iconSeverity';
import { BaseSeverity } from '~common/types/severity';
import Alert, { AlertProps } from '~components/alert/Alert';
import Colors from '~tokens/colors/Colors';
import Icons from '~tokens/icons/Icons';
import Sizes from '~tokens/sizes/Sizes';

// =================================================================================================
// INTERFACE
// =================================================================================================

export interface ToastProps extends Omit<AlertProps, 'feIcon' | 'feSeverity'> {
	/** Gives the supplied appearance */
	feSeverity: BaseSeverity;
}

// =================================================================================================
// COMPONENT
// =================================================================================================

/**
 * The `<Toast>` component renders a notification on top of other content that presents timely information.<br><br>
 * **Notice!** This component is not ment to be used as is but requires a combination of the `ToastContext` and `useToast` hook. See links below:
 *
 * See [InVision DSM](https://skf.invisionapp.com/dsm/ab-skf/4-web-applications/nav/5fa7caf78c01200018354495/folder/6151917114d5a861461dbf03) for design principles.<br>
 * See [ToastContext](./?path=/docs/other-contexts-toast-context) for info.<br>
 * See [useToast](./?path=/docs/other-hooks-usetoast--page) for more info on how to use the hook.
 *
 * @internal
 */
const Toast = forwardRef(
	({ children, feSeverity, ...rest }: ToastProps, ref: ForwardedRef<HTMLDivElement>) => (
		<StyledToast
			{...rest}
			data-comp="toast"
			feIcon={IconSeverity.get(feSeverity)?.icon || Icons.InfoCircleFilled}
			feSeverity={feSeverity}
			ref={ref}
		>
			{children}
		</StyledToast>
	)
);

Toast.displayName = 'Toast';
export default Toast;

// =================================================================================================
// STYLE
// =================================================================================================

const StyledToast = styled(Alert)`
	background: ${Colors.White};
	border-left-width: ${Sizes.S08};
	width: ${ComponentSizes.ToastWidth};
`;

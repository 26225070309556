import * as React from 'react';

const IconLock = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<defs>
			<path
				d="M16 6.667h-1V4.762C15 2.133 12.76 0 10 0S5 2.133 5 4.762v1.905H4c-1.1 0-2 .857-2 1.904v9.524C2 19.143 2.9 20 4 20h12c1.1 0 2-.857 2-1.905V8.571c0-1.047-.9-1.904-2-1.904ZM10 16c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2Zm3.1-9.333H6.9V4.762C6.9 3.133 8.29 1.81 10 1.81c1.71 0 3.1 1.323 3.1 2.952v1.905Z"
				id="a"
			/>
		</defs>
		<use xlinkHref="#a" />
	</svg>
);

export default IconLock;

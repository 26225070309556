import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { ForwardedRef, forwardRef, HTMLAttributes, ReactNode } from 'react';
import Menu, { MenuProps } from '~common/components/menu/Menu';
import Layers from '~common/constants/layers';
import square from '~common/styles/mixins/square';
import Colors from '~tokens/colors/Colors';
import FontSizes from '~tokens/font-sizes/FontSizes';
import Sizes from '~tokens/sizes/Sizes';
import Spacings from '~tokens/spacings/Spacings';

// =================================================================================================
// INTERFACE
// =================================================================================================

interface BaseProps extends HTMLAttributes<HTMLDivElement> {}

export interface PopoverDropdown extends BaseProps {
	content: MenuProps;

	/** Type of Popover */
	feType: 'dropdown';
}

export interface PopoverPopup extends BaseProps {
	content: ReactNode;

	/** If true, removes outer padding */
	feNoPadding?: boolean;

	/** Type of Popover */
	feType: 'popup';
}

export interface PopoverTooltip extends BaseProps {
	content: string;

	/** If true, adds dark theme */
	feDark?: boolean;

	/** Type of Popover */
	feType: 'tooltip';
}

export type PopoverProps = PopoverDropdown | PopoverPopup | PopoverTooltip;

// =================================================================================================
// COMPONENT
// =================================================================================================

/**
 * Popver-family component used to display overlay content
 */
const Popover = forwardRef((props: PopoverProps, ref: ForwardedRef<HTMLDivElement>) => {
	const { content, feType, ...rest } = props;
	const hasArrow = ['popup', 'tooltip'].includes(feType);
	const isDark = feType === 'tooltip' && 'feDark' in props;

	return (
		<StyledPopover {...rest} data-comp={feType} feDark={isDark} feType={feType} ref={ref}>
			{hasArrow && <StyledPopoverArrow data-popper-arrow />}
			<StyledPopoverBody>
				{feType === 'dropdown' ? <Menu {...content} /> : content}
			</StyledPopoverBody>
		</StyledPopover>
	);
});

Popover.displayName = 'Popover';
export default Popover;

// =================================================================================================
// STYLE
// =================================================================================================

interface StyledPopoverProps
	extends Pick<PopoverProps, 'feType'>,
		Pick<PopoverPopup, 'feNoPadding'>,
		Pick<PopoverTooltip, 'feDark'> {}

// TODO: Replace "inline" shadows with tokens
const StyledPopover = styled.div(
	({ feDark, feNoPadding, feType }: StyledPopoverProps) => css`
		background-color: ${feDark ? Colors.Gray700 : Colors.White};
		border-radius: ${Sizes.S02};
		box-shadow: 0 1px 1px 0 rgba(39, 51, 66, 10%), 0 1px 6px 0 rgba(39, 51, 66, 10%),
			0 0 10px 0 rgba(39, 51, 66, 12%);
		color: ${feDark && Colors.White};
		max-width: 288px;
		pointer-events: ${feType === 'tooltip' && 'none'};
		z-index: ${Layers.Popover};

		${feType !== 'dropdown' &&
		css`
			font-size: ${FontSizes.Xs};
			padding: ${!feNoPadding && `${Spacings.Xs} ${Spacings.Sm}`};
		`}

		${feType === 'dropdown' &&
		css`
			padding-block: ${Spacings.Xxs};
		`}

		&[data-popper-placement^='right'] {
			filter: drop-shadow(-1px 0 1px rgba(39, 51, 66, 30%));
		}

		&[data-popper-placement^='left'] {
			filter: drop-shadow(1px 0 1px rgba(39, 51, 66, 30%));
		}

		&[data-popper-placement^='top'] {
			filter: drop-shadow(0 1px 1px rgba(39, 51, 66, 30%));
		}

		&[data-popper-placement^='bottom'] {
			filter: drop-shadow(0 -1px 1px rgba(39, 51, 66, 30%));
		}
	`
);

const StyledPopoverBody = styled.div`
	position: relative;
`;

const StyledPopoverArrow = styled.div`
	visibility: hidden;

	&,
	&::before {
		${square(Sizes.S08)};

		background-color: inherit;
		position: absolute;
	}

	&::before {
		content: '';
		transform: rotate(45deg);
		visibility: visible;
	}

	[data-popper-placement='left'] &,
	[data-popper-placement='right'] & {
		top: 50%;
		transform: translateY(-50%);
	}

	[data-popper-placement='bottom'] &,
	[data-popper-placement='top'] & {
		left: 50%;
		transform: translateX(-50%);
	}

	[data-popper-placement^='top'] & {
		bottom: -${Sizes.S04};
	}

	[data-popper-placement='bottom-end'] &,
	[data-popper-placement='top-end'] & {
		right: ${Spacings.Sm};
	}

	[data-popper-placement='bottom-start'] &,
	[data-popper-placement='top-start'] & {
		left: ${Spacings.Sm};
	}

	[data-popper-placement^='bottom'] & {
		top: -${Sizes.S04};
	}

	[data-popper-placement^='left'] & {
		right: -${Sizes.S04};
	}

	[data-popper-placement^='right'] & {
		left: -${Sizes.S04};
	}

	[data-popper-placement='left-end'] &,
	[data-popper-placement='right-end'] & {
		bottom: ${Spacings.Sm};
	}

	[data-popper-placement='left-start'] &,
	[data-popper-placement='right-start'] & {
		top: ${Spacings.Sm};
	}
`;

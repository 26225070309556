import * as React from 'react';

const IconMicrophone = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M9.999 12.368c1.679 0 3.034-1.27 3.034-2.842V3.842C13.033 2.27 11.678 1 9.999 1 8.32 1 6.965 2.27 6.965 3.842v5.684c0 1.573 1.355 2.842 3.034 2.842Zm5.977-2.842a.99.99 0 0 0-.991.806c-.415 2.226-2.488 3.931-4.986 3.931-2.498 0-4.571-1.705-4.986-3.931a.99.99 0 0 0-.99-.806c-.618 0-1.103.512-1.012 1.08.496 2.842 2.923 5.069 5.977 5.476v1.97c0 .522.455.948 1.01.948.557 0 1.012-.426 1.012-.947v-1.97c3.054-.408 5.481-2.635 5.977-5.477.101-.568-.395-1.08-1.011-1.08Z" />
	</svg>
);

export default IconMicrophone;

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
	GetPropsCommonOptions,
	UseSelectGetItemPropsOptions,
	UseSelectGetMenuPropsOptions,
	UseSelectPropGetters,
} from 'downshift';
import React from 'react';
import Icon from '~components/icon/Icon';
import { SelectItem } from '~components/select/Select';
import {
	SelectItemSizes,
	SelectListSizes,
	SelectValueTypeConstraint,
} from '~components/select/selectCommonTypes';
import Spacer from '~components/spacer/Spacer';
import Colors from '~tokens/colors/Colors';
import FontSizes from '~tokens/font-sizes/FontSizes';
import FontWeights from '~tokens/font-weights/FontWeights';
import Icons from '~tokens/icons/Icons';
import Shadows from '~tokens/shadows/Shadows';
import Spacings from '~tokens/spacings/Spacings';

// =================================================================================================
// INTERFACE
// =================================================================================================

interface SelectListProps<T extends SelectValueTypeConstraint> {
	allItems: SelectItem<T>[];
	getItemProps: (options: UseSelectGetItemPropsOptions<SelectItem<T>>) => unknown;
	getMenuProps: (
		options?: UseSelectGetMenuPropsOptions,
		otherOptions?: GetPropsCommonOptions
	) => any;
	highlightedIndex: number;
	isOpen: boolean;
	items: SelectItem<T>[];
	listHeight?: number;
	listWidth?: number;
	selectedItems?: SelectItem<T>[];
	size?: 'md' | 'sm';
}

// =================================================================================================
// COMPONENT
// =================================================================================================

/**
 * @internal
 */
const SelectList = <T extends SelectValueTypeConstraint>({
	allItems,
	getItemProps,
	getMenuProps,
	highlightedIndex,
	isOpen,
	items,
	listHeight,
	listWidth,
	selectedItems,
	size,
}: SelectListProps<T>) => (
	<StyledSelectList
		{...getMenuProps()}
		isOpen={isOpen}
		listHeight={listHeight}
		listWidth={listWidth}
		stParent
	>
		{isOpen &&
			items.map((item) => {
				const isGroup = item.items != null;
				let isSelected;

				if (isGroup) {
					return (
						<li key={item.label}>
							<StyledSelectGroupLabel size={size}>{item.label}</StyledSelectGroupLabel>
							<StyledSelectList>
								{item.items?.map((groupedItem) => {
									const index = allItems.findIndex((i) => i.value === groupedItem.value);
									isSelected = selectedItems?.some((item) => item.value === groupedItem.value);

									return getItem({
										getItemProps: getItemProps,
										highlightedIndex: highlightedIndex,
										index: index,
										inGroup: true,
										isSelected: isSelected,
										item: groupedItem,
										size: size,
									});
								})}
							</StyledSelectList>
						</li>
					);
				} else {
					const index = allItems.findIndex((i) => i.value === item.value);
					isSelected = selectedItems?.some((i) => i.value === item.value);

					return getItem({
						getItemProps: getItemProps,
						highlightedIndex: highlightedIndex,
						index: index,
						isSelected: isSelected,
						item: item,
						size: size,
					});
				}
			})}
	</StyledSelectList>
);

SelectList.displayName = 'SelectList';
export default SelectList;

// =================================================================================================
// HELPER
// =================================================================================================

interface ItemProps<T extends SelectValueTypeConstraint> {
	getItemProps: UseSelectPropGetters<SelectItem<T>>['getItemProps'];
	highlightedIndex: number;
	inGroup?: boolean;
	index: number;
	isSelected?: boolean;
	item: SelectItem<T>;
	size?: 'md' | 'sm';
}

const getItem = <T extends SelectValueTypeConstraint>({
	getItemProps,
	highlightedIndex,
	inGroup,
	index,
	isSelected,
	item,
	size,
}: ItemProps<T>) => {
	const { value, disabled, feShortLabel, label, feIcon } = item;

	return (
		<StyledSelectItem
			{...getItemProps({ item, index, disabled })}
			key={`${value}${index}`}
			size={size}
			stDisabled={disabled}
			stHighlighted={highlightedIndex === index}
		>
			{isSelected ? (
				<StyledIconCheck feIcon={Icons.Check} />
			) : (
				<StyledSpacer feSpacing={Spacings.Md} feHorizontal />
			)}
			{inGroup && <Spacer feHorizontal feSpacing={Spacings.Sm} />}
			{label}
			{feShortLabel && !feIcon && <StyledSelectShortLabel>{feShortLabel}</StyledSelectShortLabel>}
			{!feShortLabel && feIcon && <StyledIcon feIcon={feIcon} />}
		</StyledSelectItem>
	);
};

// =================================================================================================
// STYLE
// =================================================================================================

interface StyledSelectListProps
	extends Partial<Pick<SelectListProps<string>, 'isOpen' | 'listHeight' | 'listWidth'>> {
	stParent?: boolean;
}

const StyledSelectList = styled.ul(
	({ isOpen, listHeight, listWidth, stParent }: StyledSelectListProps) => css`
		display: flex;
		flex-direction: column;

		${stParent &&
		css`
			background-color: ${Colors.White};
			box-shadow: ${isOpen ? Shadows.Lg : 'none'};
			opacity: ${isOpen ? 1 : 0};
			padding: ${SelectListSizes.Padding};
			width: ${listWidth}px;

			${listHeight &&
			css`
				max-height: ${listHeight}px;
				overflow-y: auto;
			`}
		`}
	`
);

const StyledSelectGroupLabel = styled.div(
	({ size }: Pick<SelectListProps<string>, 'size'>) => css`
		align-items: center;
		display: flex;
		font-weight: ${FontWeights.Bold};
		height: ${SelectItemSizes.Md};
		margin-left: ${Spacings.Xs};

		${size === 'sm' &&
		css`
			font-size: ${FontSizes.Sm};
			height: ${SelectItemSizes.Sm};
		`}
	`
);

interface StyledSelectItemProps extends Pick<SelectListProps<string>, 'size'> {
	stDisabled?: boolean;
	stHighlighted: boolean;
}

const StyledSelectItem = styled.li(
	({ stDisabled, size, stHighlighted }: StyledSelectItemProps) => css`
		align-items: center;
		background-color: ${stHighlighted ? Colors.Primary200 : Colors.White};
		cursor: pointer;
		display: flex;
		flex-shrink: 0;
		height: ${SelectItemSizes.Md};
		padding-inline-end: ${Spacings.Xs};

		${size === 'sm' &&
		css`
			font-size: ${FontSizes.Sm};
			height: ${SelectItemSizes.Sm};
		`}

		${stDisabled &&
		css`
			color: ${Colors.Gray500};
			cursor: default;
		`}
	`
);

const StyledIconCheck = styled(Icon)`
	margin-inline: ${Spacings.Xxs} ${Spacings.Xs};
`;

const StyledSpacer = styled(Spacer)`
	margin-inline: ${Spacings.Xxs} ${Spacings.Xs};
`;

const StyledSelectShortLabel = styled.span`
	color: ${Colors.Gray500};
	font-size: ${FontSizes.Sm};
	margin-left: auto;
`;

const StyledIcon = styled(Icon)`
	color: inherit;
	margin-left: auto;
`;

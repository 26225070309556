import * as React from 'react';

const IconDefectFrequenciesAlternative = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M9 8h2v11H9V8ZM7 1h6l-3 6-3-6Z" />
	</svg>
);

export default IconDefectFrequenciesAlternative;

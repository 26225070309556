import * as React from 'react';

const IconDraftOutlined = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<g>
			<path d="M17 1a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h14Zm-.8 1.8H3.8a1 1 0 0 0-1 1v12.4a1 1 0 0 0 1 1h12.4a1 1 0 0 0 1-1V3.8a1 1 0 0 0-1-1Z" />
			<path d="M4.5 13.034v1.688c0 .156.122.278.278.278h1.688a.26.26 0 0 0 .195-.083l6.066-6.06-2.083-2.084-6.06 6.06a.273.273 0 0 0-.084.2Zm9.838-5.788a.553.553 0 0 0 0-.784l-1.3-1.3a.553.553 0 0 0-.784 0L11.238 6.18l2.083 2.083 1.017-1.016ZM11 13h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5Zm3 0h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5Z" />
		</g>
	</svg>
);

export default IconDraftOutlined;

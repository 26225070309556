import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { ForwardedRef, forwardRef, HTMLAttributes, ReactNode } from 'react';
import Colors from '~tokens/colors/Colors';
import FontSizes from '~tokens/font-sizes/FontSizes';
import FontWeights from '~tokens/font-weights/FontWeights';
import LineHeights from '~tokens/line-heights/LineHeights';

// =================================================================================================
// INTERFACE
// =================================================================================================

export interface TextProps extends Omit<HTMLAttributes<HTMLSpanElement>, 'color'> {
	/** If provided, changes the element rendered */
	as?: 'b' | 'div' | 'em' | 'i' | 'p' | 'small' | 'span' | 'strong';

	/** The text to show */
	children: ReactNode;

	/** If provided, changes the text color */
	feColor?: Colors;

	/** If provided, changes the font-size */
	feFontSize?: FontSizes;

	/** If provided, changes the font-weight */
	feFontWeight?: FontWeights;

	/** If provided, changes the line-height */
	feLineHeight?: LineHeights;
}

// =================================================================================================
// COMPONENT
// =================================================================================================

/**
 * The `<Text>` component is a general purpose component for text rendering.
 *
 * See [InVision DSM](https://skf.invisionapp.com/dsm/ab-skf/4-web-applications/nav/5fa7caf78c01200018354495/folder/619626e9fa9fb73437915c2d) for design principles.
 */
const Text = forwardRef(
	({ as = 'p', children, ...rest }: TextProps, ref: ForwardedRef<HTMLSpanElement>) => (
		<StyledText {...rest} as={as} data-comp="text" ref={ref}>
			{children}
		</StyledText>
	)
);

Text.displayName = 'Text';
export default Text;

// =================================================================================================
// STYLE
// =================================================================================================

const StyledText = styled.span(
	({ as, feColor, feFontSize, feFontWeight, feLineHeight }: TextProps) => css`
		color: ${feColor};
		font-size: ${feFontSize};
		font-weight: ${feFontWeight};
		line-height: ${feLineHeight};
		margin: ${as === 'p' && 0};
	`
);

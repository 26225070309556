import { css } from '@emotion/react';
import { opacity } from '~helpers/color/color';
import Colors from '~tokens/colors/Colors';

const overlay = (visible: boolean, duration: number) => css`
	background: ${visible && opacity(Colors.Black, 0.4)};
	transition: background ${duration}ms ease-in;
	transition-delay: ${!visible && `${duration}ms`}; /* Delays transition onClose */
`;

export default overlay;

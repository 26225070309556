import * as React from 'react';

const IconHierarchy = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M12 0a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H3.6v4.4H9v-.9a1 1 0 0 1 1-1h9a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-9a1 1 0 0 1-1-1v-.9H3.6v6.3H9V16a1 1 0 0 1 1-1h9a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-9a1 1 0 0 1-1-1v-.901L3 18.1a.6.6 0 0 1-.6-.6V5H1a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1h11Z" />
	</svg>
);

export default IconHierarchy;

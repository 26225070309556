import React, { ForwardedRef, forwardRef } from 'react';
import Hint from '~common/components/hint/Hint';
import Label from '~common/components/label/Label';
import TextArea, { TextAreaProps } from '~common/components/text-area/TextArea';
import { BaseInputFieldProps } from '~common/types/input';
import Spacer from '~components/spacer/Spacer';
import generateId from '~helpers/generateId/generateId';
import Spacings from '~tokens/spacings/Spacings';

// =================================================================================================
// INTERFACE
// =================================================================================================

export interface TextAreaFieldProps extends BaseInputFieldProps, TextAreaProps {}

// =================================================================================================
// COMPONENT
// =================================================================================================

/**
 * <br>
 * <span class="sbdocs-tag sbdocs-blue">controlled</span>
 * <span class="sbdocs-tag sbdocs-blue">uncontrolled</span>
 * <br><br>
 *
 * The `<TextAreaField>` component is a text area to be used in a form.<br>
 * It extends the interface of native html `<textarea>` element.
 *
 * See [InVision DSM](https://skf.invisionapp.com/dsm/ab-skf/4-web-applications/nav/5fa7caf78c01200018354495/asset/619e56db4440ff643175e3e9) for design principles.<br>
 * See [MDN Web Docs](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/textarea) for further information about the element and related attributes.
 */
const TextAreaField = forwardRef(
	(
		{
			'aria-errormessage': ariaErrorMessage,
			className,
			disabled,
			feHideLabel,
			feHint,
			feLabel,
			feRequiredText,
			feSeverity,
			feSize,
			id = generateId(),
			required,
			...rest
		}: TextAreaFieldProps,
		ref: ForwardedRef<HTMLTextAreaElement>
	) => {
		const hintId = feHint && feSeverity === 'error' ? id + '-hint' : undefined;

		return (
			<div className={className} data-comp="text-area-field">
				<Label
					feDisabled={disabled}
					feHideLabel={feHideLabel}
					feRequired={required}
					feRequiredText={feRequiredText}
					feSize={feSize}
					htmlFor={id}
				>
					{feLabel}
				</Label>
				{!feHideLabel && <Spacer feSpacing={Spacings.Xs} />}
				<TextArea
					{...rest}
					aria-errormessage={
						feSeverity === 'error' ? (feHint ? hintId : ariaErrorMessage) : undefined
					}
					aria-invalid={feSeverity === 'error' ? true : undefined}
					disabled={disabled}
					feSeverity={feSeverity}
					feSize={feSize}
					id={id}
					ref={ref}
					required={required}
				/>
				{feHint && (
					<>
						<Spacer feSpacing={Spacings.Xxs} />
						<Hint feSeverity={feSeverity} id={hintId}>
							{feHint}
						</Hint>
					</>
				)}
			</div>
		);
	}
);

TextAreaField.displayName = 'TextAreaField';
export default TextAreaField;

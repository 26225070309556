import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { ForwardedRef, forwardRef, HTMLAttributes } from 'react';
import Spacings from '~tokens/spacings/Spacings';

// =================================================================================================
// INTERFACE
// =================================================================================================

export interface SpacerProps extends Omit<HTMLAttributes<HTMLDivElement>, 'children'> {
	/** If true, component will occupy space on the horizontal axis */
	feHorizontal?: boolean;

	/** The amount of space the component occupies */
	feSpacing?: Spacings;
}

// =================================================================================================
// COMPONENT
// =================================================================================================

/**
 * The `<Spacer>` is an empty element that provides spacing. Can be replaced with correct usage of margins and padding.
 */
const Spacer = forwardRef(
	(
		{ feHorizontal = false, feSpacing = Spacings.Md, ...rest }: SpacerProps,
		ref: ForwardedRef<HTMLDivElement>
	) => (
		<StyledSpacer
			{...rest}
			data-comp="spacer"
			feHorizontal={feHorizontal}
			feSpacing={feSpacing}
			ref={ref}
		/>
	)
);

Spacer.displayName = 'Spacer';
export default Spacer;

// =================================================================================================
// STYLE
// =================================================================================================

const StyledSpacer = styled.div(
	({ feHorizontal, feSpacing }: SpacerProps) => css`
		height: ${!feHorizontal && feSpacing};
		width: ${feHorizontal && feSpacing};
	`
);

import * as React from 'react';

const IconPaper = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="m13.594 0 3.956 4H14a.5.5 0 0 1-.5-.5V0h.094Zm1.931 16H4.475a.475.475 0 0 0-.475.475v1.05c0 .262.213.475.475.475h11.05a.475.475 0 0 0 .475-.475v-1.05a.475.475 0 0 0-.475-.475Zm0-5H4.475a.475.475 0 0 0-.475.475v1.05c0 .262.213.475.475.475h11.05a.475.475 0 0 0 .475-.475v-1.05a.475.475 0 0 0-.475-.475Zm-2.881-5H4.356C4.16 6 4 6.213 4 6.475v1.05c0 .262.16.475.356.475h8.288c.197 0 .356-.213.356-.475v-1.05c0-.262-.16-.475-.356-.475ZM12.5 3.5A1.5 1.5 0 0 0 14 5h4v13c0 1.105-.85 2-1.9 2H3.9C2.85 20 2 19.105 2 18V2c0-1.105.85-2 1.9-2h8.6v3.5Z" />
	</svg>
);

export default IconPaper;

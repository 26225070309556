import * as React from 'react';

const IconArrowDown = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="m10.985 13.348 2.012-2.012a1.066 1.066 0 1 1 1.508 1.51l-3.841 3.841a1.066 1.066 0 0 1-1.51 0l-3.841-3.842a1.066 1.066 0 1 1 1.509-1.509l2.022 2.012V4.07c0-.588.482-1.07 1.07-1.07.59 0 1.07.482 1.07 1.07v9.278Z" />
	</svg>
);

export default IconArrowDown;

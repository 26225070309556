import * as React from 'react';

const IconCaretUp = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M13.67 10.711c.702.702.211 1.906-.78 1.906H7.119c-.992 0-1.493-1.204-.791-1.906l2.885-2.885a1.11 1.11 0 0 1 1.571 0l2.886 2.885Z" />
	</svg>
);

export default IconCaretUp;

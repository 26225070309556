import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { ChangeEvent, ForwardedRef, forwardRef } from 'react';
import ColorSeverity from '~common/constants/colorSeverity';
import focusOutline from '~common/styles/mixins/focusOutline';
import square from '~common/styles/mixins/square';
import { BaseInputProps } from '~common/types/input';
import Colors from '~tokens/colors/Colors';
import MotionDurations from '~tokens/motion-durations/MotionDurations';
import Sizes from '~tokens/sizes/Sizes';

// =================================================================================================
// INTERFACE
// =================================================================================================

export interface InputRadioProps extends BaseInputProps {
	/** Callback that will fire anytime the state of the radio changes. */
	onChange?: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

// =================================================================================================
// COMPONENT
// =================================================================================================

const InputRadio = forwardRef(
	(
		{ className, feSeverity, feSize = 'md', onChange, ...rest }: InputRadioProps,
		ref: ForwardedRef<HTMLInputElement>
	) => (
		<StyledInputRadio className={className} data-comp="input-radio" feSize={feSize}>
			<StyledInputRadioInput
				{...rest}
				onChange={(event) => onChange && onChange(event, event.target.checked)}
				ref={ref}
				type="radio"
			/>
			<StyledInputRadioMarker feSeverity={feSeverity} />
		</StyledInputRadio>
	)
);

InputRadio.displayName = 'InputRadio';
export default InputRadio;

// =================================================================================================
// STYLE
// =================================================================================================

const StyledInputRadio = styled.div(
	({ feSize }: Pick<InputRadioProps, 'feSize'>) => css`
		display: inline-flex;
		flex-shrink: 0; /* Prevents shrink when flex-child */
		line-height: 0;
		position: relative;

		${feSize === 'md' && square(Sizes.S24)};
		${feSize === 'sm' && square(Sizes.S18)};
	`
);

const StyledInputRadioInput = styled.input`
	cursor: pointer;
	height: inherit;
	margin: 0;
	opacity: 0;
	width: inherit;

	&:disabled {
		cursor: not-allowed;
	}
`;

const StyledInputRadioMarker = styled.div(
	({ feSeverity }: Pick<InputRadioProps, 'feSeverity'>) => css`
		align-items: center;
		background-color: ${Colors.White};
		border: solid 1px ${feSeverity ? ColorSeverity.get(feSeverity)?.base : Colors.Gray500};
		border-radius: 50%;
		display: flex;
		height: inherit;
		justify-content: center;
		left: 0;
		pointer-events: none;
		position: absolute;
		top: 0;
		width: inherit;

		&::after {
			background-color: ${Colors.White};
			border-radius: 50%;
			content: '';
			height: 44%;
			opacity: 0;
			transition: opacity ${MotionDurations.Fast}ms;
			width: 44%;
		}

		input:checked ~ & {
			background-color: ${Colors.Brand};

			&::after {
				opacity: 1;
			}
		}

		input:checked:not(:disabled) ~ & {
			border: 0;
		}

		input:disabled ~ & {
			background-color: ${Colors.Gray100};
		}

		input:focus-visible ~ & {
			${focusOutline()}
		}

		@supports not selector(:focus-visible) {
			input:focus ~ & {
				${focusOutline()}
			}
		}
	`
);

import * as React from 'react';

const IconLocationPin = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M10 0C6.13 0 3 3.215 3 7.19c0 4.284 4.42 10.19 6.24 12.44a.976.976 0 0 0 1.53 0C12.58 17.38 17 11.474 17 7.19 17 3.216 13.87 0 10 0Zm0 9.622a2.5 2.5 0 0 1 0-5 2.5 2.5 0 0 1 0 5Z" />
	</svg>
);

export default IconLocationPin;

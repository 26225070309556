import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Property } from 'csstype';
import React, { ForwardedRef, forwardRef, ImgHTMLAttributes } from 'react';

// =================================================================================================
// INTERFACE
// =================================================================================================

export interface ImageProps extends Omit<ImgHTMLAttributes<HTMLImageElement>, 'children'> {
	/** If provided, sets the CSS height of the image */
	feHeight?: Property.Height;

	/** If provided, sets how the content of a replaced element should be resized to fit its container */
	feObjectFit?: Property.ObjectFit;

	/** If provided, specifies the alignment of the element's contents within the element's box */
	feObjectPosition?: Property.ObjectPosition;

	/** If true, the image fills the parent element */
	feStretch?: boolean;

	/** If provided, sets the CSS width of the image */
	feWidth?: Property.Width;

	/** The image source URL */
	src: string;
}

// =================================================================================================
// COMPONENT
// =================================================================================================

/**
 * The `<Image>` component embeds an image into the document.<br>
 * It extends the interface of native html `<img>` element.
 *
 * See [MDN Web Docs](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/img) for further information about the element and related attributes.<br>
 */
const Image = forwardRef(
	({ alt = '', ...rest }: ImageProps, ref: ForwardedRef<HTMLImageElement>) => (
		<StyledImage {...rest} alt={alt} data-comp="image" ref={ref} />
	)
);

Image.displayName = 'Image';
export default Image;

// =================================================================================================
// STYLE
// =================================================================================================

const StyledImage = styled.img(
	({ feStretch, feHeight, feWidth, feObjectFit, feObjectPosition }: ImageProps) => css`
		height: ${feHeight};
		object-fit: ${feObjectFit};
		object-position: ${feObjectPosition};
		width: ${feWidth};

		${feStretch &&
		css`
			height: 100%;
			width: 100%;
		`}
	`
);

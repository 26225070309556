import * as React from 'react';

const IconMinus = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<defs>
			<path
				d="M16.857 11.286H3.143a1.143 1.143 0 0 1 0-2.286h13.714a1.143 1.143 0 0 1 0 2.286Z"
				id="a"
			/>
		</defs>
		<use xlinkHref="#a" />
	</svg>
);

export default IconMinus;

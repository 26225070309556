import * as React from 'react';

const IconChevronUpDown = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<g>
			<path d="m14.161 11.31-4.166 4.125-4.167-4.124a1.077 1.077 0 0 0-1.514 0 1.051 1.051 0 0 0 0 1.499l4.929 4.88a1.077 1.077 0 0 0 1.514 0l4.929-4.88a1.051 1.051 0 0 0 0-1.5 1.1 1.1 0 0 0-1.525 0ZM5.839 8.69l4.166-4.125 4.167 4.124a1.077 1.077 0 0 0 1.514 0 1.051 1.051 0 0 0 0-1.499l-4.929-4.88a1.077 1.077 0 0 0-1.514 0L4.314 7.19a1.051 1.051 0 0 0 0 1.5 1.1 1.1 0 0 0 1.525 0Z" />
		</g>
	</svg>
);

export default IconChevronUpDown;

import * as React from 'react';

const IconUnscheduledAction = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="m9.812 3.5.32.011a6.257 6.257 0 0 1 5.872 6.62c-.03.582.19 1.15.605 1.558l.904.905 2.34 2.308-4.934 4.934-3.228-3.228a2.028 2.028 0 0 0-1.559-.606 6.258 6.258 0 0 1-6.51-7.464l3.672 3.673c.155.156.374.231.591.202l3.444-.494a.696.696 0 0 0 .591-.591l.494-3.444a.701.701 0 0 0-.202-.591L8.54 3.62a6.254 6.254 0 0 1 1.594-.109ZM5.2.2a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1v-4a1 1 0 0 1 1-1h4Z" />
	</svg>
);

export default IconUnscheduledAction;

import * as React from 'react';

const IconDrop = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M9.597 20A7.097 7.097 0 0 1 2.5 12.903C2.5 10.29 4.866 5.99 9.597 0c4.731 5.99 7.097 10.29 7.097 12.903A7.097 7.097 0 0 1 9.597 20Zm3.806-9.435a.88.88 0 0 0-.695 1.03 4.365 4.365 0 0 1-3.448 5.114.88.88 0 0 0 .336 1.727 6.128 6.128 0 0 0 4.84-7.177.88.88 0 0 0-1.033-.694Z" />
	</svg>
);

export default IconDrop;

import * as React from 'react';

const IconReceived = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M17 11v5c0 .55-.45 1-1 1H4c-.55 0-1-.45-1-1v-5c0-.55-.45-1-1-1s-1 .45-1 1v6c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-6c0-.55-.45-1-1-1s-1 .45-1 1Zm-6-.33 1.88-1.88a.996.996 0 1 1 1.41 1.41l-3.59 3.59a.996.996 0 0 1-1.41 0L5.7 10.2a.996.996 0 1 1 1.41-1.41L9 10.67V2c0-.55.45-1 1-1s1 .45 1 1v8.67Z" />
	</svg>
);

export default IconReceived;

import * as React from 'react';

const IconSettings = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M10 14c-2.206 0-4-1.795-4-4s1.794-4 4-4 4 1.795 4 4-1.794 4-4 4m9.583-5.667h-2.379c-.166-.658-.378-1.389-.648-1.943l1.693-1.693a.416.416 0 0 0 0-.59l-2.357-2.355a.414.414 0 0 0-.59 0L13.61 3.444c-.555-.27-1.285-.482-1.942-.647V.417A.417.417 0 0 0 11.25 0h-2.5a.417.417 0 0 0-.417.417v2.38c-.658.165-1.388.377-1.942.647L4.697 1.752a.414.414 0 0 0-.59 0L1.75 4.108a.417.417 0 0 0 0 .589L3.444 6.39c-.268.551-.482 1.283-.648 1.942H.416A.417.417 0 0 0 0 8.75v2.5c0 .23.187.417.417.417h2.379c.167.659.38 1.39.648 1.942L1.75 15.304a.414.414 0 0 0 0 .588l2.357 2.357a.428.428 0 0 0 .59 0l1.694-1.693c.551.269 1.283.48 1.942.648v2.38c0 .23.187.416.417.416h2.5c.23 0 .417-.187.417-.417v-2.379c.658-.167 1.39-.379 1.942-.648l1.694 1.693a.415.415 0 0 0 .59 0l2.356-2.356a.414.414 0 0 0 0-.589l-1.693-1.695c.269-.552.482-1.283.648-1.942h2.38c.23 0 .416-.187.416-.417v-2.5a.417.417 0 0 0-.417-.417" />
	</svg>
);

export default IconSettings;

import * as React from 'react';

const IconExclamation = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M9.972 14.153c.413 0 .764.138 1.056.414.29.276.436.61.436 1.005 0 .444-.147.793-.441 1.047A1.53 1.53 0 0 1 9.992 17c-.4 0-.748-.125-1.045-.376-.298-.25-.447-.602-.447-1.052 0-.394.142-.729.427-1.005a1.444 1.444 0 0 1 1.045-.414ZM10.07 3c.57 0 .95.189 1.142.567.192.377.288.922.288 1.633 0 .28-.01.561-.03.846l-.467 5.125c-.046.558-.145.987-.298 1.285-.152.299-.404.448-.755.448-.357 0-.606-.144-.745-.433-.139-.29-.238-.729-.298-1.32l-.357-5.18a24.147 24.147 0 0 1-.05-1.295c0-.527.144-.938.432-1.233C9.22 3.148 9.6 3 10.07 3Z" />
	</svg>
);

export default IconExclamation;

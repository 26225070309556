import styled from '@emotion/styled';
import React, { ChangeEvent, ForwardedRef, forwardRef, HTMLInputTypeAttribute } from 'react';
import { baseInputStyles } from '~common/styles/shared';
import { BaseInputProps } from '~common/types/input';

// =================================================================================================
// INTERFACE
// =================================================================================================

type InputTextTypes = Extract<
	HTMLInputTypeAttribute,
	'email' | 'hidden' | 'tel' | 'text' | 'url' | 'week'
>;

export interface InputTextProps extends BaseInputProps {
	/** Callback that will fire anytime the value of the input changes. */
	onChange?: (event: ChangeEvent<HTMLInputElement>, value: string) => void;

	/** The intended type of text. Affects autocomplete and native interface controls. */
	type?: InputTextTypes;
}

// =================================================================================================
// COMPONENT
// =================================================================================================

const InputText = forwardRef(
	(
		{ feSize = 'md', onChange, type = 'text', ...rest }: InputTextProps,
		ref: ForwardedRef<HTMLInputElement>
	) => (
		<StyledInputText
			{...rest}
			data-comp="input-text"
			feSize={feSize}
			onChange={(event) => onChange && onChange(event, event.target.value)}
			ref={ref}
			type={type}
		/>
	)
);

InputText.displayName = 'InputText';
export default InputText;

// =================================================================================================
// STYLE
// =================================================================================================

const StyledInputText = styled.input(
	({ feSeverity, feSize }: Pick<InputTextProps, 'feSeverity' | 'feSize'>) =>
		baseInputStyles(feSize, feSeverity)
);

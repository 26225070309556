import { css } from '@emotion/react';
import ColorSeverity from '~common/constants/colorSeverity';
import focusOutline from '~common/styles/mixins/focusOutline';
import { BaseInputProps } from '~common/types/input';
import Colors from '~tokens/colors/Colors';
import FontSizes from '~tokens/font-sizes/FontSizes';
import Sizes from '~tokens/sizes/Sizes';
import Spacings from '~tokens/spacings/Spacings';

export const baseInputWrapperStyles = css`
	height: fit-content;
	position: relative;
`;

export const baseInputStyles = (
	size: BaseInputProps['feSize'],
	severity?: BaseInputProps['feSeverity']
) => css`
	appearance: none;
	background: ${Colors.White};
	border: 1px solid ${severity ? ColorSeverity.get(severity)?.base : Colors.Gray500};
	border-radius: ${Sizes.S02};
	font-size: ${size === 'sm' && FontSizes.Sm};
	height: ${size === 'sm' ? Sizes.S32 : Sizes.S40};
	margin: 0;
	padding: 0 ${Spacings.Sm};
	width: 100%;

	/* Hides spin buttons in Firefox */
	&[type='number'] {
		appearance: textfield;
	}

	&[type='number']::-webkit-inner-spin-button,
	&[type='search']::-webkit-search-cancel-button {
		display: none;
	}

	&:focus-visible {
		${focusOutline({ offset: '-1px' })}
	}

	@supports not selector(:focus-visible) {
		&:focus {
			${focusOutline({ offset: '-1px' })}
		}
	}

	&:disabled {
		background: ${Colors.Gray100};
		color: ${Colors.Gray600};
		cursor: not-allowed;
	}
`;

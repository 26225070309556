import styled from '@emotion/styled';
import React, { ForwardedRef, forwardRef, HTMLAttributes, ReactNode } from 'react';
import Button, { ButtonProps } from '~components/button/Button';
import Flexbox from '~components/flexbox/Flexbox';
import Spacer from '~components/spacer/Spacer';
import Spacings from '~tokens/spacings/Spacings';

// =================================================================================================
// INTERFACE
// =================================================================================================

export interface ConfirmButton {
	cancel: ButtonProps;
	confirm: ButtonProps;
}

export interface ConfirmProps extends HTMLAttributes<HTMLDivElement> {
	/** Content of the Confirm template */
	children: ReactNode;

	/** Interface for the cancel & confirm buttons  */
	feButtons: ConfirmButton;
}

// =================================================================================================
// COMPONENT
// =================================================================================================

const Confirm = forwardRef(
	({ children, feButtons, ...rest }: ConfirmProps, ref: ForwardedRef<HTMLDivElement>) => {
		const { cancel, confirm } = feButtons;

		return (
			<StyledRoot {...rest} data-comp="confirm" ref={ref}>
				{children}
				<Spacer />
				<Flexbox
					feAlignItems="flex-end"
					feFlexWrap="wrap"
					feGap={Spacings.Md}
					feJustifyContent="flex-end"
				>
					<Button {...cancel} onClick={cancel.onClick} feType="secondary" type="button">
						{cancel.children}
					</Button>
					<Button {...confirm} onClick={confirm.onClick} type="button">
						{confirm.children}
					</Button>
				</Flexbox>
			</StyledRoot>
		);
	}
);

Confirm.displayName = 'Confirm';
export default Confirm;

// =================================================================================================
// STYLE
// =================================================================================================

const StyledRoot = styled.div();

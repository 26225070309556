import * as React from 'react';

const IconDraft = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M2 14.247v2.364c0 .218.168.389.382.389h2.322c.1 0 .198-.039.267-.117l8.34-8.484-2.864-2.916-8.332 8.484a.385.385 0 0 0-.115.28Zm13.527-8.103a.784.784 0 0 0 0-1.097l-1.788-1.82a.751.751 0 0 0-1.077 0l-1.397 1.424 2.864 2.916 1.398-1.423Zm-4.277 8.31h1.5a.5.5 0 0 1 .5.5V16.5a.5.5 0 0 1-.5.5h-1.5a.5.5 0 0 1-.5-.5v-1.545a.5.5 0 0 1 .5-.5Zm3.75 0h1.5a.5.5 0 0 1 .5.5V16.5a.5.5 0 0 1-.5.5H15a.5.5 0 0 1-.5-.5v-1.545a.5.5 0 0 1 .5-.5Z" />
	</svg>
);

export default IconDraft;

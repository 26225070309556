import styled from '@emotion/styled';
import React, { AriaAttributes } from 'react';
import focusOutline from '~common/styles/mixins/focusOutline';
import Icon from '~components/icon/Icon';
import Colors from '~tokens/colors/Colors';
import IconColors from '~tokens/icon-colors/IconColors';
import IconSizes from '~tokens/icon-sizes/IconSizes';
import Icons from '~tokens/icons/Icons';
import LineHeights from '~tokens/line-heights/LineHeights';
import Spacings from '~tokens/spacings/Spacings';

// =================================================================================================
// INTERFACE
// =================================================================================================

export interface PaginationControlProps {
	'aria-label'?: AriaAttributes['aria-label'];
	disabled?: boolean;
	onClick: () => void;
	type: 'Next page' | 'Previous page';
}

// =================================================================================================
// COMPONENT
// =================================================================================================

/**
 * Renders a pagination control
 *
 * @internal
 */
const PaginationControl = ({
	'aria-label': ariaLabel,
	disabled,
	onClick,
	type,
	...rest
}: PaginationControlProps) => (
	<StyledPaginationControl
		{...rest}
		aria-label={ariaLabel || type}
		disabled={disabled}
		type="button"
		onClick={onClick}
	>
		<StyledIcon
			feIcon={type === 'Next page' ? Icons.ChevronRight : Icons.ChevronLeft}
			feSize={IconSizes.Lg}
		/>
	</StyledPaginationControl>
);

PaginationControl.displayName = 'PaginationControl';
export default PaginationControl;

// =================================================================================================
// STYLE
// =================================================================================================

const StyledPaginationControl = styled.button`
	align-items: center;
	border-radius: 2px;
	color: ${IconColors.Gray};
	display: inline-flex;
	justify-content: center;
	line-height: ${LineHeights.Sm};
	margin-left: ${Spacings.Xs};
	padding: 2px;

	&:disabled {
		color: ${Colors.Gray500};
		cursor: not-allowed;
	}

	&:not(:disabled):hover {
		background: ${Colors.Primary200};
	}

	&:focus-visible {
		${focusOutline()}
	}

	@supports not selector(:focus-visible) {
		&:focus {
			${focusOutline()}
		}
	}
`;

const StyledIcon = styled(Icon)`
	color: inherit;
`;

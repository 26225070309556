import * as React from 'react';

const IconStarFilled = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="m10 15.737 4.365 2.609c.8.478 1.777-.229 1.567-1.123l-1.157-4.906 3.86-3.305c.705-.603.326-1.746-.6-1.819l-5.08-.426-1.987-4.636c-.358-.841-1.578-.841-1.936 0L7.045 6.757l-5.08.426c-.926.073-1.305 1.216-.6 1.819l3.86 3.305-1.157 4.906c-.21.894.768 1.6 1.567 1.122L10 15.737Z" />
	</svg>
);

export default IconStarFilled;

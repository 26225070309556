import Sizes from '~tokens/sizes/Sizes';
import Spacings from '~tokens/spacings/Spacings';

/**
 * @internal
 */
export type SelectValueTypeConstraint = number | string;

export enum SelectListSizes {
	Padding = Spacings.Xxs,
}

export enum SelectItemSizes {
	Md = Sizes.S40,
	Sm = Sizes.S32,
}

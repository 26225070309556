import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { HTMLAttributes } from 'react';
import ColorSeverity from '~common/constants/colorSeverity';
import Colors from '~tokens/colors/Colors';
import MotionEasings from '~tokens/motion-easings/MotionEasings';

// =================================================================================================
// INTERFACE
// =================================================================================================

export interface TableRowProps extends HTMLAttributes<HTMLTableRowElement> {
	feHighlight?: boolean;
	interactive?: boolean;
	type?: 'body' | 'foot' | 'head';
}

// =================================================================================================
// COMPONENT
// =================================================================================================

/**
 * Renders a table row
 *
 * @internal
 */
const TableRow = ({ children, ...rest }: TableRowProps) => (
	<StyledTableRow {...rest}>{children}</StyledTableRow>
);

TableRow.displayName = 'TableRow';
export default TableRow;

// =================================================================================================
// STYLE
// =================================================================================================

const StyledTableRow = styled.tr(
	({
		feHighlight,
		interactive,
		type,
	}: Pick<TableRowProps, 'feHighlight' | 'interactive' | 'type'>) => {
		const blink = (odd: boolean) => css`
			animation: blink 2s ${MotionEasings.EaseIn};

			@keyframes blink {
				0% {
					background-color: ${odd ? Colors.White : Colors.Gray025};
					box-shadow: none;
				}

				40%,
				60% {
					background-color: ${ColorSeverity.get('success')?.light};
					box-shadow: inset 0 0 0 1px ${ColorSeverity.get('success')?.base};
				}

				100% {
					background-color: ${odd ? Colors.White : Colors.Gray025};
					box-shadow: none;
				}
			}
		`;

		return css`
			${type === 'body' &&
			css`
				${feHighlight && blink(false)};

				background: ${Colors.Gray025};

				&:nth-of-type(odd) {
					${feHighlight && blink(false)};

					background: ${Colors.White};
				}

				${interactive &&
				css`
					&:hover {
						background: rgb(231, 238, 250);
						box-shadow: inset 0 0 0 1px ${Colors.Brand};
						cursor: pointer;
					}
				`}
			`}
		`;
	}
);

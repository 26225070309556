import * as React from 'react';

const IconEmailOutlined = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M18 2a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h16Zm-1 2H3a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1Zm-1.266 2.427a1 1 0 0 1-.245 1.392l-4.915 3.442a.996.996 0 0 1-.616.18.996.996 0 0 1-.617-.18L4.427 7.819a1 1 0 0 1 1.147-1.638L9.957 9.25l4.384-3.07a1 1 0 0 1 1.393.247Z" />
	</svg>
);

export default IconEmailOutlined;

import * as React from 'react';

const IconTrend = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M12.818 8.182c.334.5.766.927 1.27 1.253l-6 6.535-2.472-2.692L1.28 18 0 16.758l5.616-6.116 2.471 2.692 4.731-5.152ZM16.48 2A3.52 3.52 0 0 1 20 5.52v.197a3.52 3.52 0 1 1-7.04 0V5.52A3.52 3.52 0 0 1 16.48 2Zm0 1.81a1.76 1.76 0 0 0-1.76 1.76v.098a1.76 1.76 0 0 0 3.52 0v-.099a1.76 1.76 0 0 0-1.76-1.76Z" />
	</svg>
);

export default IconTrend;

import * as React from 'react';

const IconBatteryLow = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M15.813 4.8c.854 0 1.537.66 1.537 1.484v1.76h1.328c.17 0 .322.128.322.31v3.28a.314.314 0 0 1-.322.312H17.35v1.76c0 .824-.683 1.484-1.537 1.484H2.536C1.683 15.19 1 14.53 1 13.705v-7.42C1 5.477 1.683 4.8 2.536 4.8Zm0 1.374H2.536c-.057 0-.094.037-.094.092v7.44c0 .054.037.091.094.091v-.018h13.277c.057 0 .095-.037.095-.092V6.266c0-.055-.038-.092-.095-.092ZM7 6.73v6.5H3v-6.5h4Z" />
	</svg>
);

export default IconBatteryLow;

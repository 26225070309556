import * as React from 'react';

const IconSpectrum = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="m7.851 19 2.43-6.352 1.935 2.72 2.709-6.7 1.794 4.91L20 8.514l-1.719-.948-1.052 1.625-2.208-6.045-3.3 8.161-1.984-2.79-1.733 4.532L4.08 1 0 12.221l1.898.587 2.077-5.711L7.85 19Z" />
	</svg>
);

export default IconSpectrum;

import * as React from 'react';

const IconColumnGraph = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M1.1 10.667h1a1 1 0 0 1 1 1V20h-3v-8.333a1 1 0 0 1 1-1Zm5.633-8h1a1 1 0 0 1 1 1V20h-3V3.667a1 1 0 0 1 1-1ZM12.367 7h1a1 1 0 0 1 1 1v12h-3V8a1 1 0 0 1 1-1ZM18 0h1a1 1 0 0 1 1 1v19h-3V1a1 1 0 0 1 1-1Z" />
	</svg>
);

export default IconColumnGraph;

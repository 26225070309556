import { ComboboxItem } from './Combobox';

/**
 * Case insensitive string compare.
 * @param s1 - string
 * @param s2 - string
 * @returns boolean
 */
export const equal = (s1: string, s2: string) =>
	s1.localeCompare(s2, undefined, { sensitivity: 'accent' }) === 0;

// =================================================================================================

/**
 * Sorts items in the itemslist aplfabetically on its value string.
 */
export const srtAlpha = (a: ComboboxItem, b: ComboboxItem) => (b.value < a.value ? -1 : 1);

// =================================================================================================

/**
 * Sorts items in the itemslist on selected status and secondly alphanumeric.
 */
interface Srt {
	a: ComboboxItem;
	b: ComboboxItem;
}
const _alpha = ({ a, b }: Srt) => (a.value < b.value ? -1 : 1);
const _selected = ({ a, b }: Srt) =>
	!!a.selected < !!b.selected ? 1 : a.selected === b.selected ? undefined : -1;
export const srtSelected = (a: ComboboxItem, b: ComboboxItem) =>
	_selected({ a, b }) || _alpha({ a, b });

// =================================================================================================

/**
 * Get the computed width of a ref element.
 */
export const getWidth = (ref: Element | null) =>
	ref ? window.getComputedStyle(ref).width : 'auto';

// =================================================================================================

/**
 * Toggles selected item and returns updated list.
 */
export const toggleSelected = (source: ComboboxItem[], index: number): ComboboxItem[] =>
	source.map((item, i) => {
		if (i === index) item.selected = !item.selected;
		return item;
	});

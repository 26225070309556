import styled from '@emotion/styled';
import React, { ForwardedRef, forwardRef, HTMLAttributes } from 'react';
import Link, { LinkProps } from '~components/link/Link';
import Colors from '~tokens/colors/Colors';
import Sizes from '~tokens/sizes/Sizes';
import Spacings from '~tokens/spacings/Spacings';

// =================================================================================================
// INTERFACE
// =================================================================================================

export interface MenuProps extends HTMLAttributes<HTMLUListElement> {
	/** Menu items */
	feItems: LinkProps[];
}

// =================================================================================================
// COMPONENT
// =================================================================================================

const Menu = forwardRef(({ feItems }: MenuProps, ref: ForwardedRef<HTMLUListElement>) => (
	<ul ref={ref}>
		{feItems.map((item, index) => (
			<li key={index}>
				<StyledLink {...item} />
			</li>
		))}
	</ul>
));

Menu.displayName = 'Menu';
export default Menu;

// =================================================================================================
// STYLE
// =================================================================================================

const StyledLink = styled(Link)`
	height: ${Sizes.S40};
	padding-inline: ${Spacings.Sm};

	&:not(:disabled):hover {
		background-color: ${Colors.Primary200};
	}
`;

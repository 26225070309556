import * as React from 'react';

const IconUnknownDiamond = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="m11.433.586 8.019 8.019a2 2 0 0 1 0 2.828l-8.019 8.019a2 2 0 0 1-2.828 0l-8.02-8.019a2 2 0 0 1 0-2.828l8.02-8.02a2 2 0 0 1 2.828 0ZM10.019 13.14c-.292 0-.538.098-.738.294-.2.196-.301.437-.301.725 0 .323.104.575.311.755.207.18.45.27.728.27.269 0 .506-.091.711-.273.205-.183.308-.433.308-.752a.985.985 0 0 0-.294-.725.985.985 0 0 0-.725-.294Zm0-8.621c-.711 0-1.333.146-1.866.437-.534.292-.94.66-1.217 1.104-.278.445-.417.883-.417 1.316 0 .21.088.405.263.585.175.18.39.27.646.27.433 0 .727-.258.882-.773l.072-.203c.15-.393.326-.698.53-.914.236-.253.605-.38 1.107-.38.428 0 .778.126 1.05.376.27.25.406.558.406.923 0 .187-.045.36-.133.52a1.96 1.96 0 0 1-.329.434c-.086.086-.209.2-.367.343l-.456.404c-.245.221-.445.416-.602.586a2.525 2.525 0 0 0-.472.738c-.118.28-.177.612-.177.995 0 .305.08.535.242.69a.832.832 0 0 0 .599.232c.455 0 .726-.237.813-.71l.077-.33.036-.139c.025-.089.06-.178.106-.266.045-.09.115-.187.208-.294.094-.107.218-.232.373-.373.374-.334.67-.604.892-.81l.273-.26c.217-.212.404-.463.56-.755.158-.292.237-.631.237-1.019a2.44 2.44 0 0 0-.414-1.367c-.276-.42-.666-.75-1.172-.994-.506-.244-1.09-.366-1.75-.366Z" />
	</svg>
);

export default IconUnknownDiamond;

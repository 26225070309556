import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { ForwardedRef, forwardRef, Fragment, LabelHTMLAttributes } from 'react';
import { BaseInputFieldProps, BaseInputProps } from '~common/types/input';
import VisuallyHidden from '~components/visually-hidden/VisuallyHidden';
import Colors from '~tokens/colors/Colors';
import FontSizes from '~tokens/font-sizes/FontSizes';
import LineHeights from '~tokens/line-heights/LineHeights';

// =================================================================================================
// INTERFACE
// =================================================================================================

export interface LabelProps
	extends LabelHTMLAttributes<HTMLLabelElement>,
		Pick<BaseInputFieldProps, 'feHideLabel' | 'feRequiredText'>,
		Pick<BaseInputProps, 'feSize'> {
	/** If true, the label will appear non-interactable */
	feDisabled?: boolean;

	/** If true, will indicate that the corresponding input is required */
	feRequired?: boolean;
}

// =================================================================================================
// COMPONENT
// =================================================================================================

const Label = forwardRef(
	(
		{
			children,
			feHideLabel = false,
			feRequired = false,
			feRequiredText = 'Required',
			feSize = 'md',
			...rest
		}: LabelProps,
		ref: ForwardedRef<HTMLLabelElement>
	) => {
		const LabelBody = feHideLabel ? VisuallyHidden : Fragment;

		return (
			<StyledLabel {...rest} data-comp="label" feSize={feSize} ref={ref}>
				<LabelBody>
					{children}
					{feRequired && (
						<>
							<VisuallyHidden>{feRequiredText}</VisuallyHidden>
							<StyledLabelAsterisk aria-hidden>{' *'}</StyledLabelAsterisk>
						</>
					)}
				</LabelBody>
			</StyledLabel>
		);
	}
);

Label.displayName = 'Label';
export default Label;

// =================================================================================================
// STYLE
// =================================================================================================

const StyledLabel = styled.label(
	({ feDisabled, feSize }: Pick<LabelProps, 'feDisabled' | 'feSize'>) => css`
		color: ${feDisabled ? Colors.Gray600 : Colors.Black};
		cursor: ${feDisabled ? 'not-allowed' : 'pointer'};
		display: block;
		font-size: ${feSize === 'sm' && FontSizes.Sm};
		line-height: ${LineHeights.Md};
		margin: 0;
	`
);

const StyledLabelAsterisk = styled.span`
	color: ${Colors.RedBase};
`;

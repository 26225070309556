import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { ForwardedRef, forwardRef, HTMLAttributes } from 'react';
import square from '~common/styles/mixins/square';
import Colors from '~tokens/colors/Colors';
import Sizes from '~tokens/sizes/Sizes';

// =================================================================================================
// INTERFACE
// =================================================================================================

export interface LoaderProps extends Omit<HTMLAttributes<HTMLDivElement>, 'children'> {
	/** If true, invert color (to be used on colored backgrounds) */
	feColorInvert?: boolean;

	/** If provided, displays an alternative size */
	feSize?: 'md' | 'sm';
}

// =================================================================================================
// COMPONENT
// =================================================================================================

/**
 * The `<Loader>` component is a progress indicator that uses circular indicators for short, indeterminate activities.
 *
 * See [InVision DSM](https://skf.invisionapp.com/dsm/ab-skf/4-web-applications/nav/5fa7caf78c01200018354495/asset/619e4125b3d2147c0c584b11) for design principles.
 */
const Loader = forwardRef(
	(
		{
			'aria-label': ariaLabel = 'Loading...',
			feColorInvert = false,
			feSize = 'md',
			...rest
		}: LoaderProps,
		ref: ForwardedRef<HTMLDivElement>
	) => (
		<StyledLoader
			{...rest}
			aria-label={ariaLabel}
			aria-live="polite"
			data-comp="loader"
			feSize={feSize}
			ref={ref}
			role="progressbar"
		>
			<svg
				aria-hidden={true}
				enableBackground="new 0 0 0 0"
				height="100%"
				version="1.1"
				viewBox="0 0 52 52"
				width="100%"
				x="0px"
				xmlns="http://www.w3.org/2000/svg"
				xmlnsXlink="http://www.w3.org/1999/xlink"
				xmlSpace="preserve"
				y="0px"
			>
				{[
					getCircle({ cx: 6, begin: 0.1, feColorInvert }),
					getCircle({ cx: 26, begin: 0.2, feColorInvert }),
					getCircle({ cx: 46, begin: 0.3, feColorInvert }),
				]}
			</svg>
		</StyledLoader>
	)
);

Loader.displayName = 'Loader';
export default Loader;

// =================================================================================================
// HELPER
// =================================================================================================

interface CircleOptions extends Pick<LoaderProps, 'feColorInvert'> {
	begin: number;
	cx: number;
}

const getCircle = ({ begin, cx, feColorInvert }: CircleOptions) => (
	<circle
		fill={feColorInvert ? Colors.White : Colors.Brand}
		stroke="none"
		cx={cx}
		cy="26"
		r="6"
		key={cx}
	>
		<animate
			attributeName="opacity"
			dur="1s"
			values="0;1;0"
			repeatCount="indefinite"
			begin={begin}
		/>
	</circle>
);

// =================================================================================================
// STYLE
// =================================================================================================

const StyledLoader = styled.div(
	({ feSize }: Pick<LoaderProps, 'feSize'>) => css`
		${feSize === 'md' && square(Sizes.S48)}
		${feSize === 'sm' && square(Sizes.S24)}
	`
);

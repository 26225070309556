import * as React from 'react';

const IconStarOutlined = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="m18.043 7.19-5.09-.437-1.987-4.622c-.358-.841-1.577-.841-1.935 0L7.043 6.764l-5.079.426c-.925.072-1.304 1.215-.6 1.818l3.86 3.303-1.157 4.903c-.21.893.768 1.6 1.567 1.122l4.364-2.597 4.365 2.607c.799.478 1.777-.229 1.566-1.122l-1.156-4.913 3.86-3.303c.704-.603.336-1.746-.59-1.818Zm-8.045 6.606-3.954 2.358 1.052-4.446-3.491-2.991 4.606-.395 1.787-4.186 1.799 4.196 4.606.395-3.492 2.992 1.052 4.446-3.965-2.369Z" />
	</svg>
);

export default IconStarOutlined;

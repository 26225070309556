import { css } from '@emotion/react';

const ellipsis = (dir?: 'x' | 'y') => {
	const property = dir ? `overflow-${dir}` : 'overflow';

	return css`
		${property}: hidden;

		text-overflow: ellipsis;
		white-space: nowrap;
	`;
};

export default ellipsis;

import { useMemo, useState } from 'react';
import { TableCell, TableRow } from '~components/table/Table';
import { TableCellSortable } from '~components/table/TableCell';

export type TableSorting = {
	column?: number;
	direction?: TableCellSortable['direction'];
};

type UseTableReturnValues = {
	sortedBody: TableRow[];
	sortedHead: TableRow[];
	sorting: TableSorting;
};

const directionToggler = (direction?: TableCellSortable['direction']) => {
	switch (direction) {
		case 'asc':
			return 'desc';
		case 'desc':
			return undefined;
		default:
		case undefined:
			return 'asc';
	}
};

/**
 * @internal
 */
const useTable = (body: TableRow[], head?: TableRow[]) => {
	let defaultColumn;
	let defaultDirection;

	head?.forEach((row) => {
		defaultDirection = row.cells.find((cell) => cell.sortable?.direction !== undefined)?.sortable
			?.direction;

		defaultColumn = row.cells.findIndex((cell) => cell.sortable?.direction !== undefined);
		defaultColumn = defaultColumn === -1 ? undefined : defaultColumn;
	});

	const [sorting, setSorting] = useState<{
		column?: number;
		direction?: TableCellSortable['direction'];
	}>({ column: defaultColumn, direction: defaultDirection });

	const onSortableHeadingClick = (cell: TableCell, cellIndex: number) => {
		cell.sortable?.onClick && cell.sortable.onClick();
		const prevCellDirection = sorting.column === cellIndex ? sorting.direction : undefined;
		setSorting({ column: cellIndex, direction: directionToggler(prevCellDirection) });
	};

	const sortedHead = useMemo(
		() =>
			head?.map((row) => ({
				...row,
				cells: row.cells.map((cell, cellIndex) => {
					if (cell.sortable === undefined) return cell;
					return {
						...cell,
						sortable: {
							...cell.sortable,
							onClick: () => onSortableHeadingClick(cell, cellIndex),
						},
					};
				}),
			})),
		[head, sorting]
	);

	const sortedBody = useMemo(() => {
		if (sorting.direction === undefined) {
			return body;
		}

		const outp = [...body];
		const sortingColumn = sorting.column;

		if (sortingColumn || sortingColumn === 0) {
			outp.sort((a, b) => {
				const aJSXProps: Record<string, string> = (
					a.cells[sortingColumn].children?.valueOf() as JSX.Element
				).props;
				const bJSXProps: Record<string, string> = (
					b.cells[sortingColumn].children?.valueOf() as JSX.Element
				).props;

				const getSortValue = head && head[0].cells[sortingColumn].sortable?.sortBy;
				const getChildrenAsString = (row: TableRow) =>
					row.cells[sortingColumn].children?.toString();

				const aSortValue =
					(getSortValue && getSortValue(aJSXProps)) || getChildrenAsString(a) || '';
				const bSortValue =
					(getSortValue && getSortValue(bJSXProps)) || getChildrenAsString(b) || '';

				let text1 = aSortValue;
				let text2 = bSortValue;

				if (sorting.direction === 'desc') {
					const temp: string = text2;
					text2 = text1;
					text1 = temp;
				}

				return text1.localeCompare(text2, undefined, { numeric: true, sensitivity: 'base' });
			});
		}
		return outp;
	}, [sorting, body]);

	return {
		sortedHead,
		sortedBody,
		sorting,
	} as UseTableReturnValues;
};

export default useTable;

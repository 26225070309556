import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { ButtonHTMLAttributes, ForwardedRef, forwardRef, HTMLAttributes } from 'react';
import ellipsis from '~common/styles/mixins/ellipsis';
import IconInteractive from '~components/icon-interactive/IconInteractive';
import Icon from '~components/icon/Icon';
import Spacer from '~components/spacer/Spacer';
import { opacity } from '~helpers/color/color';
import Colors from '~tokens/colors/Colors';
import FontSizes from '~tokens/font-sizes/FontSizes';
import IconColors from '~tokens/icon-colors/IconColors';
import IconSizes from '~tokens/icon-sizes/IconSizes';
import Icons from '~tokens/icons/Icons';
import Sizes from '~tokens/sizes/Sizes';
import Spacings from '~tokens/spacings/Spacings';

// =================================================================================================
// INTERFACE
// =================================================================================================

export interface TagProps extends HTMLAttributes<HTMLDivElement> {
	/** The text shown in the tag. **Notice!** A maximum limit of 48 characters. */
	children: string;

	/** If provided, gives the supplied appearance */
	feColor?: 'blue' | 'green' | 'orange' | 'purple' | 'red' | 'yellow';

	/** If true, adds disabled styling */
	feDisabled?: boolean;

	/** If provided, displays an icon before the text */
	feIcon?: Icons;

	/** If provided, alters the icon color. **Notice!** If `feColor` is provided, this prop will be unset.   */
	feIconColor?: Exclude<IconColors, IconColors.Gray | IconColors.White>;

	/** If provided adds a 'remove-button' to the tag with an optional hidden label */
	feRemoveButton?: ButtonHTMLAttributes<HTMLButtonElement>;

	/** If provided, displays an alternative size */
	feSize?: 'md' | 'sm';

	/** If provided, alters the appearance */
	feType?: 'filled' | 'outlined';
}

// =================================================================================================
// COMPONENT
// =================================================================================================

/**
 * The `<Tag>` component can be used to show results of a selection.
 *
 * See [InVision DSM](https://skf.invisionapp.com/dsm/ab-skf/4-web-applications/nav/5fa7caf78c01200018354495/folder/619626b19069cda505105292) for design principles.
 */
const Tag = forwardRef(
	(
		{
			children,
			feDisabled = false,
			feColor,
			feIcon,
			feIconColor,
			feRemoveButton,
			feSize = 'md',
			feType = 'filled',
			...rest
		}: TagProps,
		ref: ForwardedRef<HTMLDivElement>
	) => (
		<StyledTag
			{...rest}
			data-comp="tag"
			feColor={feColor}
			feDisabled={feDisabled}
			feSize={feSize}
			feType={feType}
			ref={ref}
		>
			{feIcon && (
				<>
					<StyledIcon
						feColor={feColor ? colors.get(feColor)?.icon : feIconColor}
						feDisabled={feDisabled}
						feIcon={feIcon}
						feSize={IconSizes.Sm}
					/>
					<Spacer feHorizontal feSpacing={Spacings.Xxs} />
				</>
			)}
			<StyledTagLabel>{children}</StyledTagLabel>
			{feSize === 'md' && feRemoveButton && (
				<>
					<Spacer feHorizontal feSpacing={Spacings.Xs} />
					<IconInteractive
						{...feRemoveButton}
						aria-label={feRemoveButton?.['aria-label'] || `Remove tag: ${children}`}
						as="button"
						disabled={feDisabled}
						feIcon={Icons.Close}
						feSize={IconSizes.Sm}
						type="button"
					/>
				</>
			)}
		</StyledTag>
	)
);

Tag.displayName = 'Tag';
export default Tag;

// =================================================================================================
// HELPER
// =================================================================================================

interface TagColors {
	bg: string;
	border: Colors;
	icon: IconColors;
}

const colors: ReadonlyMap<TagProps['feColor'], TagColors> = new Map([
	['blue', { bg: opacity(Colors.Brand, 0.1), border: Colors.Brand, icon: IconColors.Brand }],
	[
		'green',
		{ bg: opacity(Colors.GreenBase, 0.1), border: Colors.GreenBase, icon: IconColors.Green },
	],
	[
		'orange',
		{ bg: opacity(Colors.OrangeDark, 0.1), border: Colors.OrangeDark, icon: IconColors.Orange },
	],
	[
		'purple',
		{ bg: opacity(Colors.PurpleBase, 0.1), border: Colors.PurpleBase, icon: IconColors.Purple },
	],
	['red', { bg: opacity(Colors.RedBase, 0.1), border: Colors.RedBase, icon: IconColors.Red }],
	[
		'yellow',
		{ bg: opacity(Colors.YellowDarker, 0.1), border: Colors.YellowDarker, icon: IconColors.Yellow },
	],
]);

// =================================================================================================
// STYLE
// =================================================================================================

const StyledTag = styled.div(
	({
		feColor,
		feDisabled,
		feSize,
		feType,
	}: Pick<TagProps, 'feColor' | 'feDisabled' | 'feSize' | 'feType'>) => css`
		align-items: center;
		color: ${feDisabled && Colors.Gray500};
		display: inline-flex;
		max-width: 48ch;
		padding: 0 ${Spacings.Xs};

		${feSize === 'md' &&
		css`
			border-radius: ${Sizes.S08};
			font-size: ${FontSizes.Xs};
			height: ${Sizes.S28};
		`}

		${feSize === 'sm' &&
		css`
			border-radius: ${Sizes.S06};
			font-size: ${FontSizes.Xxs};
			height: ${Sizes.S20};
		`}

		${feType === 'filled' &&
		css`
			background-color: ${feColor && !feDisabled
				? colors.get(feColor)?.bg
				: feDisabled
				? Colors.Gray100
				: Colors.Gray200};
		`}

		${feType === 'outlined' &&
		css`
			/* stylelint-disable indentation */
			background-color: ${feColor && !feDisabled
				? colors.get(feColor)?.bg
				: feDisabled && Colors.Gray050};
			border: 1px solid
				${feColor && !feDisabled
					? colors.get(feColor)?.border
					: feDisabled
					? Colors.Gray500
					: Colors.Gray600};
			/* stylelint-enable indentation */
		`}
	`
);

const StyledTagLabel = styled.span`
	${ellipsis()}
`;

const StyledIcon = styled(Icon)(
	({ feDisabled }: Pick<TagProps, 'feDisabled'>) => css`
		color: ${feDisabled && Colors.Gray500};
	`
);

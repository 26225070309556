import React, { ForwardedRef, forwardRef, HTMLAttributes, ReactNode, useEffect } from 'react';
import ReactDOM from 'react-dom';

// =================================================================================================
// INTERFACE
// =================================================================================================

export interface PortalProps extends HTMLAttributes<HTMLDivElement> {
	/** Portal content */
	children: ReactNode;

	/** If provided, id of your application root  */
	feAppId?: string;

	/** If true, makes the browser "ignore" user input/focus events on the app-root */
	feAppInert?: boolean;

	/** If true, renders the component */
	feRender?: boolean;

	/** If provided, DOM element/selector where the Portal should render  */
	feTarget?: string;
}

// =================================================================================================
// COMPONENT
// =================================================================================================

/**
 * The `<Portal>` component is a layout container that renders outside the app-root.
 */
const Portal = forwardRef(
	(
		{
			children,
			feAppId = 'root',
			feAppInert = false,
			feRender = true,
			feTarget = 'body',
			...rest
		}: PortalProps,
		ref: ForwardedRef<HTMLDivElement>
	) => {
		const appRoot = document.getElementById(feAppId);
		const target = document.querySelector(feTarget);

		const removeAttributes = () => {
			appRoot?.removeAttribute('aria-hidden');
			appRoot?.removeAttribute('inert');
		};

		if (!target && target !== '') {
			console.error('The portal component requires a target element in the document.');
			return null;
		}

		if (!feRender) return null;

		if (feAppInert) {
			appRoot?.setAttribute('aria-hidden', 'true');
			appRoot?.setAttribute('inert', 'true');
		} else {
			removeAttributes();
		}

		useEffect(() => {
			return () => removeAttributes();
		}, []);

		return ReactDOM.createPortal(
			<div {...rest} data-comp="portal" ref={ref}>
				{children}
			</div>,
			target
		);
	}
);

Portal.displayName = 'Portal';
export default Portal;

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, {
	ChangeEvent,
	ForwardedRef,
	forwardRef,
	HTMLInputTypeAttribute,
	KeyboardEventHandler,
	Reducer,
	SyntheticEvent,
	useReducer,
	useRef,
	useState,
} from 'react';
import { usePopper } from 'react-popper';
import Hint from '~common/components/hint/Hint';
import InputText, { InputTextProps } from '~common/components/input-text/InputText';
import Label from '~common/components/label/Label';
import Layers from '~common/constants/layers';
import { mergeRefs } from '~common/helpers/mergeRefs';
import { BaseInputFieldProps } from '~common/types/input';
import Portal from '~components/portal/Portal';
import Spacer from '~components/spacer/Spacer';
import generateId from '~helpers/generateId/generateId';
import Colors from '~tokens/colors/Colors';
import MotionDurations from '~tokens/motion-durations/MotionDurations';
import MotionEasings from '~tokens/motion-easings/MotionEasings';
import Shadows from '~tokens/shadows/Shadows';
import Spacings from '~tokens/spacings/Spacings';
import { equal, getWidth, srtAlpha, toggleSelected } from './Combobox.helpers';
import {
	useComboboxClickOutside,
	useComboboxFilterList,
	useComboboxOnChange,
	useComboboxScroll,
	useSetPlaceholderValueOnMount,
} from './Combobox.hooks';
import ComboboxAddButton from './ComboboxAddButton';
import ComboboxListItem from './ComboboxListItem';

// =================================================================================================
// INTERFACE
// =================================================================================================

type ComboboxTypes = Extract<
	HTMLInputTypeAttribute,
	'email' | 'hidden' | 'tel' | 'text' | 'url' | 'week'
>;

export interface ComboboxItem {
	selected?: boolean;
	value: string;
}

export interface ComboboxLocalization {
	addButtonLabel: string;
	selectedItemsLabel: string;
}

export interface ComboboxValue {
	itemAdded?: string;
	itemsSelected?: ComboboxItem[];
	itemsUpdated?: ComboboxItem[];
	value?: string;
}

export type OnChangeEvent = ChangeEvent<HTMLInputElement> | undefined;

export type ExtendedOnChange = (event: OnChangeEvent, value: ComboboxValue) => void;

export interface ComboboxProps extends BaseInputFieldProps, Omit<InputTextProps, 'onChange'> {
	/**
	 * Limit the max height of the list and let it scroll.
	 */
	feMaxHeight?: number;

	/** Item array that populate the suggestion list.
	 * Array contains objects with value and optional selected keys. */
	items?: ComboboxItem[];

	/** The intended type of text. Affects autocomplete and native interface controls. */
	localization?: ComboboxLocalization;

	/** if true, Multiselect */
	multiselect?: boolean;

	/** Callback that will fire anytime the value of the input changes as well as when items are added and selected. */
	onChange?: ExtendedOnChange;

	/** The intended type of text. Affects autocomplete and native interface controls. */
	type?: ComboboxTypes;
}

// =================================================================================================
// COMPONENT
// =================================================================================================

/**
 * <br>
 * <span class="sbdocs-tag sbdocs-blue">controlled</span>
 * <span class="sbdocs-tag sbdocs-blue">uncontrolled</span>
 * <br><br>
 *
 * The `<Combobox>` component is a custom "combobox" that retains the accessible functionality of its native html `<input>` and '<datalist>' element.<br>
 * It makes use of the Ferris Input component under the hood and as such it accepts the same attributes plus those needed for the datalist to be populated.
 * Most notably the item attribute. The onChange emits an object with a component state snapshot after each user interction.
 *
 * See [InVision DSM about the Input component in general](https://skf.invisionapp.com/dsm/ab-skf/4-web-applications/nav/5fa7caf78c01200018354495/folder/5ea69e1060bb932b88de53b9?version=63452bff323a732f447a11e6&mode=preview) for design principles.
 */
const Combobox = forwardRef(
	(
		{
			'aria-errormessage': ariaErrorMessage,
			className,
			disabled,
			feHideLabel,
			feHint,
			feLabel,
			feMaxHeight,
			feRequiredText,
			feSeverity,
			feSize,
			id = generateId(),
			items,
			localization,
			multiselect,
			onChange,
			required,
			...rest
		}: ComboboxProps,
		ref: ForwardedRef<HTMLInputElement>
	) => {
		const [itemsSource, setItemsSource] = useReducer<Reducer<ComboboxItem[], ComboboxItem[]>>(
			(_, value) => value.sort(srtAlpha),
			items?.sort(srtAlpha) || []
		);
		const [inputValue, setInputValue] = useState('');
		const [comboboxPopperRef, setComboboxPopperRef] = useState<HTMLDivElement | null>(null);
		const [placeholderValue, setPlaceholderValue] = useState('');
		const [inputRefWrapper, setInputRefWrapper] = useState<HTMLDivElement | null>(null);
		const comboboxList = useRef<HTMLUListElement>(null);
		const inputRef = useRef<HTMLInputElement>(null);
		const mergedRefs = mergeRefs([ref, inputRef]);
		const hintId = feHint && feSeverity === 'error' ? id + '-hint' : undefined;
		const [showDatalist, setShowDatalist] = useComboboxClickOutside(
			inputRefWrapper,
			comboboxPopperRef
		);
		const filteredItems = useComboboxFilterList({ items: itemsSource, inputValue });
		const { emitChangeEvent } = useComboboxOnChange(onChange, multiselect);
		const itemMatchFound =
			(itemsSource?.findIndex((item) => equal(item.value, inputValue)) || 0) >= 0;
		const { styles, attributes } = usePopper(inputRefWrapper, comboboxPopperRef, {
			strategy: 'fixed',
			placement: 'bottom-start',
			modifiers: [
				{
					name: 'offset',
					options: {
						offset: [0, 4],
					},
				},
			],
		});

		const updatePlaceholderValue = (itemsSourceUpdated?: ComboboxItem[]) => {
			const itemsSelected = (itemsSourceUpdated || itemsSource)
				.filter((item) => item.selected === true)
				.sort(srtAlpha);
			setPlaceholderValue(
				`(${itemsSelected.length} ${localization?.selectedItemsLabel || 'items selected'})`
			);
			return itemsSelected;
		};

		useSetPlaceholderValueOnMount(!!multiselect, updatePlaceholderValue);

		const onInputChangeHandler = (event: ChangeEvent<HTMLInputElement>, value: string) => {
			setInputValue(event.target.value.trimStart());
			emitChangeEvent({ e: event, value });
			handleFocus();
		};

		const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
			if (inputValue.trim() === '') return;
			let index: number;
			switch (event.key) {
				case 'Enter':
					handleClickedItem(inputValue);
					break;
				case 'ArrowDown':
					index = filteredItems.findIndex((item) => item.value === inputValue);
					setInputValue(filteredItems[Math.min(index + 1, filteredItems.length - 1)].value);
					break;
				case 'ArrowUp':
					index = filteredItems.findIndex((item) => item.value === inputValue);
					setInputValue(filteredItems[Math.max(index - 1, 0)].value);
					break;
				default:
					return;
			}
		};

		const handleClickedItem = (value: string) => {
			if (!filteredItems || !itemsSource) return;
			const index = itemsSource.findIndex((item) => item.value === value);
			if (multiselect) {
				if (index < 0) return;
				const updatedItemSource = toggleSelected(itemsSource, index);
				setItemsSource(updatedItemSource);
				const itemsSelected = updatePlaceholderValue();
				setInputValue('');
				setTimeout(() => inputRef.current?.focus());
				emitChangeEvent({
					value: inputValue,
					itemsUpdated: itemsSource,
					itemsSelected,
				});
				return;
			}
			// Single select
			const newValue = itemsSource[index].value.trim();
			setInputValue(newValue);
			inputRef.current?.focus();
			setShowDatalist(false);
			emitChangeEvent({ value: newValue, itemSelected: newValue });
		};

		const handleFocus = () => setShowDatalist(true);

		useComboboxScroll({ inputRef, setShowDatalist, showDatalist });

		const handleDatalistScroll = (e: SyntheticEvent) => e.stopPropagation;

		return (
			<div className={className} data-comp="combobox">
				<Label
					feDisabled={disabled}
					feHideLabel={feHideLabel}
					feRequired={required}
					feRequiredText={feRequiredText}
					feSize={feSize}
					htmlFor={id}
				>
					{feLabel}
				</Label>
				{!feHideLabel && <Spacer feSpacing={Spacings.Xs} />}
				<div ref={setInputRefWrapper}>
					<InputText
						{...rest}
						aria-controls="combobox-popper"
						aria-errormessage={
							feSeverity === 'error' ? (feHint ? hintId : ariaErrorMessage) : undefined
						}
						aria-expanded={showDatalist}
						aria-invalid={feSeverity === 'error' ? true : undefined}
						disabled={disabled}
						feSeverity={feSeverity}
						feSize={feSize}
						id={id}
						onChange={onInputChangeHandler}
						onFocus={handleFocus}
						onKeyDown={handleKeyDown}
						placeholder={placeholderValue}
						ref={mergedRefs}
						required={required}
						value={inputValue}
					/>
				</div>
				{feHint && (
					<>
						<Spacer feSpacing={Spacings.Xxs} />
						<Hint feSeverity={feSeverity} id={hintId}>
							{feHint}
						</Hint>
					</>
				)}
				<Portal data-portals="combobox">
					<StyledPopper
						{...attributes.popper}
						feMaxHeight={feMaxHeight}
						id="combobox-popper"
						onScroll={handleDatalistScroll}
						ref={setComboboxPopperRef}
						style={{
							...styles.popper,
						}}
						visible={showDatalist}
						width={getWidth(inputRefWrapper)}
					>
						{inputValue.length > 0 && !itemMatchFound && (
							<ComboboxAddButton
								feSize={feSize}
								inputRef={inputRef}
								inputValue={inputValue}
								itemsSource={itemsSource}
								localization={localization}
								multiselect={multiselect}
								onChange={onChange}
								setInputValue={setInputValue}
								setItemsSource={setItemsSource}
								updatePlaceholderValue={updatePlaceholderValue}
							/>
						)}
						<StyledComboboxList ref={comboboxList}>
							{filteredItems.map((item) => (
								<ComboboxListItem
									feSize={feSize}
									handleClickedItem={handleClickedItem}
									inputRef={inputRef}
									inputValue={inputValue}
									item={item}
									key={item.value.replace(/\s/g, '_')}
									multiselect={multiselect}
								/>
							))}
						</StyledComboboxList>
					</StyledPopper>
				</Portal>
			</div>
		);
	}
);

Combobox.displayName = 'Combobox';
export default Combobox;

// =================================================================================================
// STYLE
// =================================================================================================

interface StyledPopperProps {
	feMaxHeight?: number;
	visible: boolean;
	width: string;
}

const StyledPopper = styled.div(
	({ feMaxHeight, width, visible }: StyledPopperProps) => css`
		background-color: ${Colors.White};
		box-shadow: ${Shadows.Lg};
		max-height: ${feMaxHeight}px;
		opacity: ${visible ? 1 : 0};
		overflow-y: ${feMaxHeight && 'auto'};
		pointer-events: ${!visible && 'none'};
		transition: ${visible ? `opacity ${MotionDurations.Fast} ${MotionEasings.EaseIn}` : 'none'};
		width: ${width};
		z-index: ${Layers.Combobox};
	`
);

const StyledComboboxList = styled.ul`
	padding: ${Spacings.Xxs};
`;

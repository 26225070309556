import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { ChangeEvent, ForwardedRef, forwardRef, useEffect, useRef } from 'react';
import ColorSeverity from '~common/constants/colorSeverity';
import { mergeRefs } from '~common/helpers/mergeRefs';
import focusOutline from '~common/styles/mixins/focusOutline';
import square from '~common/styles/mixins/square';
import { BaseInputProps } from '~common/types/input';
import Icon from '~components/icon/Icon';
import Colors from '~tokens/colors/Colors';
import IconColors from '~tokens/icon-colors/IconColors';
import IconSizes from '~tokens/icon-sizes/IconSizes';
import Icons from '~tokens/icons/Icons';
import MotionDurations from '~tokens/motion-durations/MotionDurations';
import Sizes from '~tokens/sizes/Sizes';

// =================================================================================================
// INTERFACE
// =================================================================================================

export interface InputCheckboxProps extends BaseInputProps {
	/** If true and the checkbox is unchecked, the checkbox will appear indeterminate */
	indeterminate?: HTMLInputElement['indeterminate'];

	/** Callback that will fire anytime the state of the checkbox changes */
	onChange?: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

// =================================================================================================
// COMPONENT
// =================================================================================================

const InputCheckbox = forwardRef(
	(
		{
			className,
			feSeverity,
			feSize = 'md',
			indeterminate = false,
			onChange,
			...rest
		}: InputCheckboxProps,
		ref: ForwardedRef<HTMLInputElement>
	) => {
		const inputRef = useRef<HTMLInputElement>(null);

		useEffect(() => {
			if (!inputRef.current) return;
			inputRef.current.indeterminate = indeterminate;
		}, [indeterminate]);

		const mergedRefs = mergeRefs([ref, inputRef]);

		return (
			<StyledInputCheckbox className={className} data-comp="input-checkbox" feSize={feSize}>
				<StyledInputCheckboxInput
					{...rest}
					onChange={(event) => onChange && onChange(event, event.target.checked)}
					ref={mergedRefs}
					type="checkbox"
				/>
				<StyledInputCheckboxBox feSeverity={feSeverity}>
					<StyledIconIndet
						feColor={IconColors.White}
						feIcon={Icons.Minus}
						feSize={feSize === 'sm' ? IconSizes.Sm : IconSizes.Lg}
					/>
					<StyledIconCheck
						feColor={IconColors.White}
						feIcon={Icons.Check}
						feSize={feSize === 'sm' ? IconSizes.Sm : IconSizes.Lg}
					/>
				</StyledInputCheckboxBox>
			</StyledInputCheckbox>
		);
	}
);

InputCheckbox.displayName = 'InputCheckbox';
export default InputCheckbox;

// =================================================================================================
// STYLE
// =================================================================================================

const StyledInputCheckbox = styled.div(
	({ feSize }: Pick<InputCheckboxProps, 'feSize'>) => css`
		flex-shrink: 0; /* Prevents shrink when flex-child */
		position: relative;

		${feSize === 'md' && square(Sizes.S24)};
		${feSize === 'sm' && square(Sizes.S18)};
	`
);

const StyledInputCheckboxInput = styled.input`
	cursor: pointer;
	height: inherit;
	margin: 0;
	opacity: 0;
	position: absolute;
	width: inherit;

	&:disabled {
		cursor: not-allowed;
	}
`;

const StyledInputCheckboxBox = styled.div(
	({ feSeverity }: Pick<InputCheckboxProps, 'feSeverity'>) => css`
		background-color: ${Colors.White};
		border: solid 1px ${feSeverity ? ColorSeverity.get(feSeverity)?.base : Colors.Gray500};
		border-radius: ${Sizes.S02};
		height: inherit;
		pointer-events: none;
		position: absolute;
		transition: background-color ${MotionDurations.Fast}ms;
		width: inherit;

		input:checked ~ &,
		input:indeterminate ~ & {
			background-color: ${Colors.Brand};
			border: 0;
		}

		input:checked ~ & span:last-of-type {
			opacity: 1;
		}

		input:indeterminate:not(:checked) ~ & span:first-of-type {
			opacity: 1;
		}

		input:disabled ~ & {
			background-color: ${Colors.Gray500};
			border: 0;
		}

		input:focus-visible ~ & {
			${focusOutline()}
		}

		@supports not selector(:focus-visible) {
			input:focus ~ & {
				${focusOutline()}
			}
		}
	`
);

const IconStyles = css`
	left: 50%;
	opacity: 0;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
`;

const StyledIconCheck = styled(Icon)`
	${IconStyles}
`;

const StyledIconIndet = styled(Icon)`
	${IconStyles}
`;

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, {
	AriaAttributes,
	ChangeEvent,
	ForwardedRef,
	forwardRef,
	useRef,
	useState,
} from 'react';
import { mergeRefs } from '~common/helpers/mergeRefs';
import { baseInputStyles, baseInputWrapperStyles } from '~common/styles/shared';
import { BaseInputProps } from '~common/types/input';
import Icon from '~components/icon/Icon';
import IconSizes from '~tokens/icon-sizes/IconSizes';
import Icons from '~tokens/icons/Icons';
import Spacings from '~tokens/spacings/Spacings';

// =================================================================================================
// INTERFACE
// =================================================================================================

export interface InputPasswordButton {
	ariaLabelHide?: AriaAttributes['aria-label'];
	ariaLabelShow?: AriaAttributes['aria-label'];
}

export interface InputPasswordProps extends BaseInputProps {
	/** Optional props for the button */
	feButton?: InputPasswordButton;

	/** If true, hides the button that shows the password in plain-text */
	feHideButton?: boolean;

	/** Callback that will fire anytime the value of the input changes. */
	onChange?: (event: ChangeEvent<HTMLInputElement>, value: string) => void;
}

// =================================================================================================
// COMPONENT
// =================================================================================================

const InputPassword = forwardRef(
	(
		{
			className,
			disabled,
			feButton,
			feHideButton = false,
			feSize = 'md',
			onChange,
			required,
			...rest
		}: InputPasswordProps,
		ref: ForwardedRef<HTMLInputElement>
	) => {
		const inputRef = useRef<HTMLInputElement>(null);
		const { ariaLabelHide = 'Hide password', ariaLabelShow = 'Show password' } = feButton || {};
		const [showPassword, setShowPassword] = useState(false);
		const [buttonDown, setButtonDown] = useState(false);
		const mergedRefs = mergeRefs([ref, inputRef]);

		return (
			<StyledInputPassword className={className} data-comp="input-password">
				<StyledInputPasswordInput
					{...rest}
					disabled={disabled}
					feSize={feSize}
					onBlur={() => {
						if (buttonDown) return;
						setShowPassword(false);
					}}
					onChange={(event) => onChange?.(event, event.target.value)}
					ref={mergedRefs}
					required={required}
					type={showPassword ? 'text' : 'password'}
				/>
				{!feHideButton && (
					<StyledInputPasswordButton
						aria-label={showPassword ? ariaLabelHide : ariaLabelShow}
						onClick={() => {
							setShowPassword(!showPassword);
							setButtonDown(false);
						}}
						onMouseDown={() => setButtonDown(true)}
						type="button"
					>
						<Icon
							feIcon={showPassword ? Icons.EyeHidden : Icons.EyeVisible}
							feSize={IconSizes.Lg}
						/>
					</StyledInputPasswordButton>
				)}
			</StyledInputPassword>
		);
	}
);

InputPassword.displayName = 'InputPassword';
export default InputPassword;

// =================================================================================================
// STYLE
// =================================================================================================

const StyledInputPassword = styled.div`
	${baseInputWrapperStyles};
`;

const StyledInputPasswordInput = styled.input(
	({ feSeverity, feSize }: Pick<InputPasswordProps, 'feSeverity' | 'feSize'>) => css`
		${baseInputStyles(feSize, feSeverity)};

		padding-right: calc(${IconSizes.Lg} + ${Spacings.Sm} * 2);
	`
);

const StyledInputPasswordButton = styled.button`
	line-height: 0;
	position: absolute;
	right: ${Spacings.Sm};
	top: 50%;
	transform: translateY(-50%);
`;

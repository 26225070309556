import * as React from 'react';

const IconDuplicate = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M.91 3.636c.5 0 .908.41.908.91v12.727c0 .5.41.909.91.909h12.727c.5 0 .909.409.909.909s-.41.909-.91.909H1.819C.818 20 0 19.182 0 18.182V4.545c0-.5.41-.909.91-.909ZM18.181 0c1 0 1.818.818 1.818 1.818v12.727c0 1-.818 1.819-1.818 1.819H5.455c-1 0-1.819-.819-1.819-1.819V1.818C3.636.818 4.455 0 5.455 0h12.727Z" />
	</svg>
);

export default IconDuplicate;

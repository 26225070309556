import * as React from 'react';

const IconPlus = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M16.857 11.143h-5.714v5.714a1.143 1.143 0 1 1-2.286 0v-5.714H3.143a1.143 1.143 0 1 1 0-2.286h5.714V3.143a1.143 1.143 0 1 1 2.286 0v5.714h5.714a1.143 1.143 0 0 1 0 2.286Z" />
	</svg>
);

export default IconPlus;

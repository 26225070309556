import * as React from 'react';

const IconRefresh = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M15.648 4.353a7.963 7.963 0 0 0-6.487-2.31c-3.674.37-6.697 3.35-7.107 7.02a7.987 7.987 0 0 0 7.938 8.94 7.989 7.989 0 0 0 7.217-4.56c.32-.67-.16-1.44-.9-1.44-.371 0-.721.2-.882.53-1.13 2.43-3.844 3.97-6.807 3.31-2.222-.49-4.014-2.3-4.484-4.52a6.004 6.004 0 0 1 5.856-7.32c1.662 0 3.143.69 4.224 1.78l-1.511 1.51c-.631.63-.19 1.71.7 1.71H17c.55 0 1.001-.45 1.001-1v-3.59c0-.89-1.081-1.34-1.712-.71l-.64.65Z" />
	</svg>
);

export default IconRefresh;

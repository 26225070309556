import { Property } from 'csstype';
import Colors from '~tokens/colors/Colors';

export const lighten = (color: Colors | Property.Color, percentage: number): string => {
	const RgbNumbers = color
		.split('(')[1]
		.split(')')[0]
		.split(',')
		.map((n) => Number.parseInt(n, 10));

	let R = RgbNumbers[0];
	let G = RgbNumbers[1];
	let B = RgbNumbers[2];

	R = (R * (100 + percentage)) / 100;
	G = (G * (100 + percentage)) / 100;
	B = (B * (100 + percentage)) / 100;

	R = R < 255 ? R : 255;
	G = G < 255 ? G : 255;
	B = B < 255 ? B : 255;

	R = R > 0 ? R : 0;
	G = G > 0 ? G : 0;
	B = B > 0 ? B : 0;

	return `rgb(${Math.round(R)}, ${Math.round(G)}, ${Math.round(B)})`;
};

export const darken = (color: Colors | Property.Color, percentage: number): string => {
	return lighten(color, -percentage);
};

export const opacity = (color: Colors | Property.Color, alpha: number): string => {
	if (color.substring(0, 3) === 'rgb') {
		const values: number[] = color
			.split('(')[1]
			.split(',')
			.map((part) => parseInt(part));

		return `rgba(${values.join(',')},${alpha})`;
	}
	return color;
};

import { useCallback, useEffect, useState } from 'react';
import ModalDurations from '~common/constants/modalDurations';

type UseModalReturnValues = {
	closeModal: () => void;

	isOpen: boolean;
	targetElement: HTMLBodyElement;
	willRender: boolean;
};

interface UseModalProps {
	closeDelay?: number;
	interruptive?: boolean;
	onClose?: () => void;
	open: boolean;
	pageScroll?: boolean;
}

// TODO: Add possibility to close modals one-by-one using escape key (need to handle listeners in a different manner)
const useModal = ({
	closeDelay = ModalDurations.Out,
	interruptive = false,
	onClose,
	open,
	pageScroll = false,
}: UseModalProps) => {
	const [willRender, setWillRender] = useState(open);
	const [isOpen, setIsOpen] = useState(false);
	const targetElement = document.querySelector('body');

	function openModal() {
		if (!interruptive) document.onkeyup = handleKeyUp;
		if (!pageScroll) targetElement && (targetElement.style.overflow = 'hidden');
		setWillRender(true);
	}

	function closeModal() {
		onClose?.();
		modalWasClosed();
	}

	function modalWasClosed() {
		if (!interruptive && document.onkeyup) document.onkeyup = null;
		if (!pageScroll && targetElement && targetElement?.style.overflow !== '')
			targetElement.style.overflow = '';
	}

	const handleKeyUp = useCallback((key: KeyboardEvent) => {
		if (key.code === 'Escape') closeModal();
	}, []);

	useEffect(() => {
		if (open) {
			openModal();
			setTimeout(() => {
				setIsOpen(true);
			});
		} else if (willRender == true) {
			setIsOpen(false);
			setTimeout(() => {
				setWillRender(false);
				modalWasClosed();
			}, closeDelay);
		}
		return () => {
			if (document?.onkeyup) document.onkeyup = null;
		};
	}, [open]);

	return {
		willRender,
		isOpen,
		closeModal,
		targetElement,
	} as UseModalReturnValues;
};

export default useModal;

import * as React from 'react';

const IconPrinter = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M17 6H3C1.34 6 0 7.34 0 9v4c0 1.1.9 2 2 2h2v2c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2v-2h2c1.1 0 2-.9 2-2V9c0-1.66-1.34-3-3-3Zm-4 11H7c-.55 0-1-.45-1-1v-4h8v4c0 .55-.45 1-1 1Zm4-7c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1Zm-2-9H5c-.55 0-1 .45-1 1v2c0 .55.45 1 1 1h10c.55 0 1-.45 1-1V2c0-.55-.45-1-1-1Z" />
	</svg>
);

export default IconPrinter;

import { css } from '@emotion/react';
import focusOutline from '~common/styles/mixins/focusOutline';
import Colors from '~tokens/colors/Colors';
import FontSizes from '~tokens/font-sizes/FontSizes';
import FontWeights from '~tokens/font-weights/FontWeights';
import Shadows from '~tokens/shadows/Shadows';
import Sizes from '~tokens/sizes/Sizes';
import Spacings from '~tokens/spacings/Spacings';

/**
 * @internal
 */
export const datePickerCalendarStyles = css`
	border: none;
	border-radius: ${Sizes.S02};
	box-shadow: ${Shadows.Xl};

	/* stylelint-disable selector-class-pattern */
	& .react-datepicker__header {
		background-color: transparent;
		border: none;
		font-size: ${FontSizes.Lg};
		font-weight: ${FontWeights.Bold};
		padding: 0 ${Spacings.Md};
	}

	& .react-datepicker__month {
		display: flex;
		flex-direction: column;
		gap: 1px;
		margin: ${Spacings.Md};
		margin-top: ${Spacings.Sm};
		padding: 1px;
	}

	& .react-datepicker__day-names,
	.react-datepicker__week {
		display: flex;
		gap: 1px;
	}

	& .react-datepicker__day-name {
		color: ${Colors.Gray700};
		font-size: ${FontSizes.Sm};
		font-weight: ${FontWeights.Normal};
		margin: 0;
		width: 40px;
	}

	& .react-datepicker__day {
		background-color: ${Colors.White};
		border-radius: 0;
		font-size: ${FontSizes.Md};
		height: 40px;
		margin: 0;
		width: 40px;
	}

	& .react-datepicker__day:hover {
		background-color: ${Colors.Primary300};
	}

	& .react-datepicker__day:focus-visible {
		z-index: 1;
		${focusOutline()}
	}

	& .react-datepicker__day:not(.react-datepicker__day--outside-month) {
		box-shadow: 0 0 0 1px ${Colors.Gray100};
	}

	& .react-datepicker__day--in-range {
		background-color: ${Colors.Primary300};
		color: ${Colors.Black};
	}

	& .react-datepicker__day--in-selecting-range {
		background-color: ${Colors.Primary300};
		color: ${Colors.Black};
	}

	& .react-datepicker__day--keyboard-selected:not(.react-datepicker__day--disabled) {
		color: ${Colors.Black};
	}

	& .react-datepicker__day--selected,
	.react-datepicker__day--range-start,
	.react-datepicker__day--range-end,
	.react-datepicker__day--selecting-range-start {
		background-color: ${Colors.Primary600};
		color: ${Colors.White};
	}

	& .react-datepicker__day--outside-month {
		background-color: white;
		color: white;
		pointer-events: none;
		user-select: none;
	}
`;

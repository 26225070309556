import { MutableRefObject } from 'react';
import { PopoverProps } from '~common/components/popover/Popover';

interface AssertPopperArrowAlignmentProps {
	popperName: PopoverProps['feType'];
	triggerRef: MutableRefObject<HTMLElement | null>;
}

const wait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const popperAlignment = async ({
	popperName,
	triggerRef,
}: AssertPopperArrowAlignmentProps) => {
	/**
	 * If placement contains '...-start'/'...-end' and
	 * trigger size/2 is smaller than popper arrow
	 * center to popper edge, then recalculate position.
	 */
	// TODO: return early if there is no arrow to align.
	// TODO: consider asserting popperEl is child of triggerEl in case multiple popper el are present.
	const arrowCenter = 4;
	if (!triggerRef.current) {
		warn();
		return;
	}
	const { width, height } = triggerRef.current.getBoundingClientRect();
	await wait(0); // Allows for popper to render out.
	const popperEl: HTMLElement | null = document.querySelector(`[data-comp='${popperName}']`);
	if (!popperEl) {
		warn();
		return;
	}
	const placement = popperEl.getAttribute('data-popper-placement') || '';
	if (!/start|end/.test(placement)) return;
	const popperComputedStyle = window.getComputedStyle(popperEl);
	const arrowOffset = parseInt(popperComputedStyle.paddingLeft);
	const nudgeAxis = /left|right/.test(placement) ? 'vertically' : 'horisontally';
	const nudgeDirection = placement.includes('start') ? -1 : 1;
	const triggerSize = nudgeAxis === 'vertically' ? height : width;
	const needToNudgePopper = arrowOffset + arrowCenter > triggerSize / 2;
	if (!needToNudgePopper) return;
	const nudgeBy = Math.round(arrowOffset + arrowCenter - triggerSize / 2) * nudgeDirection;
	// console.log('Need to nudge by ', nudgeBy + 'px');
	// TODO: Might be better to nudge via the transform prop but it's messier to parse.
	const previousVal =
		parseInt(popperComputedStyle[nudgeAxis === 'vertically' ? 'top' : 'left']) || 0;
	popperEl.style[nudgeAxis === 'vertically' ? 'top' : 'left'] = previousVal + nudgeBy + 'px';

	function warn() {
		console.warn('Could not determinate if popper arrow realignment was needed.');
	}
};

import * as React from 'react';

const IconBearingFault = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<g>
			<path d="M9.5 1a8.5 8.5 0 0 1 8.486 8h-4.013A4.5 4.5 0 1 0 9 13.973v4.013A8.5 8.5 0 0 1 9.5 1ZM6 14a1 1 0 1 0 0 2 1 1 0 0 0 0-2ZM3 9.5a1 1 0 1 0 0 2 1 1 0 0 0 0-2ZM15 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2ZM5 4a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm5-2a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z" />
			<path d="m17.676 11.02-2.677 2.675-2.675-2.675a.922.922 0 0 0-1.304 0l-.082.095a.922.922 0 0 0 .082 1.21l2.675 2.674-2.675 2.677a.922.922 0 0 0 0 1.304l.095.082a.922.922 0 0 0 1.21-.082l2.674-2.676 2.677 2.676c.36.36.944.36 1.304 0l.082-.095a.922.922 0 0 0-.082-1.21L16.304 15l2.676-2.675a.922.922 0 1 0-1.304-1.304Z" />
		</g>
	</svg>
);

export default IconBearingFault;

import * as React from 'react';

const IconFilter = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M3.308 2h13.384a1 1 0 0 1 .73 1.684L11.5 10h-3L2.579 3.684A1 1 0 0 1 3.309 2ZM8.5 10h3v7.066a.5.5 0 0 1-.777.416l-2-1.334a.5.5 0 0 1-.223-.416V10Z" />
	</svg>
);

export default IconFilter;

import * as React from 'react';

const IconBulb = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="m13.362 12.913-6.631.03s-.12-1.59-2.11-4.38c0 0-2.11-3.89 1.45-7.06 0 0 3.72-3.44 8.13.09 0 0 3.19 2.66 1.27 7-.48 1.1-1.77 2.38-2.11 4.32ZM12.039 18.6s.193 1.295-2.015 1.3c-.677.001-2.14-.128-2.017-1.3.003-.384.003-.58 0-.584h4.032c.019 0 .019.194 0 .584Zm-5.288-4.38h6.6v2.08s.02 1.06-1.02 1.02c0 0-1.73.011-4.93 0-.3 0-.65-.369-.65-1.02v-2.08Z" />
	</svg>
);

export default IconBulb;

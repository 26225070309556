import * as React from 'react';

const IconForbidden = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M10 1.5A8.503 8.503 0 0 0 1.5 10c0 4.692 3.808 8.5 8.5 8.5s8.5-3.808 8.5-8.5-3.808-8.5-8.5-8.5Zm0 15.3A6.798 6.798 0 0 1 3.2 10c0-1.572.535-3.018 1.436-4.165l9.529 9.529A6.717 6.717 0 0 1 10 16.8Zm5.364-2.635L5.835 4.637A6.717 6.717 0 0 1 10 3.2c3.757 0 6.8 3.043 6.8 6.8a6.717 6.717 0 0 1-1.437 4.165Z" />
	</svg>
);

export default IconForbidden;

import * as React from 'react';

const IconProCollect = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M8.519 0a8.488 8.488 0 0 1 6.033 2.502c3.014 3.013 3.34 7.83.76 11.204l-.16.21L20 18.764 18.764 20l-4.849-4.848-.21.161c-3.375 2.58-8.192 2.253-11.206-.76-3.328-3.328-3.333-8.738-.01-12.06A8.461 8.461 0 0 1 8.513 0Zm4.797 3.739C10.67 1.094 6.37 1.09 3.728 3.732a6.723 6.723 0 0 0-1.982 4.791 6.743 6.743 0 0 0 1.99 4.794 6.769 6.769 0 0 0 4.8 1.986c1.734 0 3.467-.66 4.787-1.979 2.64-2.64 2.638-6.94-.007-9.585Zm-5.57.608a.44.44 0 0 1 .406.35l1.302 6.204.9-1.909a.44.44 0 0 1 .4-.252h3.082a5.128 5.128 0 0 1-.09.884h-2.714l-1.34 2.842a.442.442 0 0 1-.83-.098L7.62 6.461 6.763 9a.44.44 0 0 1-.418.301h-.068l-.795-.001h-.748L4.55 9.3h-.484l-.128-.001h-.255a5.126 5.126 0 0 1-.043-.63c0-.082.008-.161.012-.242.016-.002.03-.01.047-.01H6.03l1.274-3.77a.447.447 0 0 1 .444-.3Z" />
	</svg>
);

export default IconProCollect;

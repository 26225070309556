import styled from '@emotion/styled';
import React, { ForwardedRef, forwardRef, HTMLAttributes } from 'react';

// =================================================================================================
// INTERFACE
// =================================================================================================

export interface VisuallyHiddenProps extends HTMLAttributes<HTMLSpanElement> {
	children: React.ReactNode;
}

// =================================================================================================
// COMPONENT
// =================================================================================================

/**
 * The `<VisuallyHidden>` component renders content invisible and removes it from document flow, while keeping it accessible to screen readers, document parsing, and similar technology.
 */
const VisuallyHidden = forwardRef(
	({ children, ...rest }: VisuallyHiddenProps, ref: ForwardedRef<HTMLSpanElement>) => (
		<StyledVisuallyHidden {...rest} data-comp="visually-hidden" ref={ref}>
			{children}
		</StyledVisuallyHidden>
	)
);

VisuallyHidden.displayName = 'VisuallyHidden';
export default VisuallyHidden;

// =================================================================================================
// STYLE
// =================================================================================================

const StyledVisuallyHidden = styled.span`
	border: 0 !important;
	clip: rect(1px, 1px, 1px, 1px) !important;
	height: 1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	width: 1px !important;
`;

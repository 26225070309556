import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Property } from 'csstype';
import React, { ForwardedRef, forwardRef } from 'react';
import Layers from '~common/constants/layers';
import ModalDurations from '~common/constants/modalDurations';
import useModal from '~common/hooks/useModal';
import boxPadding from '~common/styles/mixins/boxPadding';
import overlay from '~common/styles/mixins/overlay';
import { BaseModalProps } from '~common/types/modal';
import Heading from '~components/heading/Heading';
import IconInteractive from '~components/icon-interactive/IconInteractive';
import Portal from '~components/portal/Portal';
import Colors from '~tokens/colors/Colors';
import IconColors from '~tokens/icon-colors/IconColors';
import IconSizes from '~tokens/icon-sizes/IconSizes';
import Icons from '~tokens/icons/Icons';
import MediaQueries from '~tokens/media-queries/MediaQueries';
import MotionEasings from '~tokens/motion-easings/MotionEasings';
import Sizes from '~tokens/sizes/Sizes';
import Spacings from '~tokens/spacings/Spacings';

// =================================================================================================
// INTERFACE
// =================================================================================================

export interface DialogProps extends BaseModalProps {
	/** If provided, sets value of css `height` property */
	cssHeight?: Property.Height;

	/** If provided, sets value of css `width` property */
	cssWidth?: Property.Width;

	/** If true, the Dialog fills the entire viewport */
	feFullscreen?: boolean;

	/** If true, the Dialog requires user interaction to close */
	feInterruptive?: boolean;
}

// =================================================================================================
// COMPONENT
// =================================================================================================

/**
 * The `<Dialog>` component is a free floating box that can interrupt the application until it is closed.
 *
 * See [InVision DSM](https://skf.invisionapp.com/dsm/ab-skf/4-web-applications/nav/5fa7caf78c01200018354495/asset/619e5667323f84cc6df0bbb1) for design principles.
 */
const Dialog = forwardRef(
	(
		{
			children,
			cssHeight,
			cssWidth,
			feCloseButton,
			feFullscreen,
			feInterruptive,
			feNoPadding = false,
			feOnClose,
			feTitle,
			open,
			...rest
		}: DialogProps,
		ref: ForwardedRef<HTMLDivElement>
	) => {
		const {
			closeModal: closeDialog,
			isOpen,
			willRender,
		} = useModal({
			interruptive: feInterruptive,
			onClose: feOnClose,
			open,
		});

		return (
			<Portal data-portals="dialog" feAppInert={isOpen} feRender={willRender}>
				<StyledDialog
					{...rest}
					aria-labelledby="dialog-heading"
					data-comp="dialog"
					feFullscreen={feFullscreen}
					onClick={!feInterruptive ? closeDialog : undefined}
					open={isOpen}
					ref={ref}
					role="dialog"
				>
					<StyledDialogInner
						cssHeight={cssHeight}
						cssWidth={cssWidth}
						feFullscreen={feFullscreen}
						onClick={(e) => e.stopPropagation()}
						open={isOpen}
					>
						<StyledDialogHead>
							<StyledHeading as="h2" feStyledAs="h3" id="dialog-heading">
								{feTitle}
							</StyledHeading>
							{!feInterruptive && (
								<StyledDialogCloseButton
									{...feCloseButton}
									aria-label={feCloseButton?.['aria-label'] || 'Close'}
									as="button"
									feColor={IconColors.White}
									feIcon={Icons.Close}
									feSize={IconSizes.Lg}
									onClick={closeDialog}
								/>
							)}
						</StyledDialogHead>
						<StyledDialogBody
							cssHeight={cssHeight}
							feFullscreen={feFullscreen}
							feNoPadding={feNoPadding}
						>
							{children}
						</StyledDialogBody>
					</StyledDialogInner>
				</StyledDialog>
			</Portal>
		);
	}
);

Dialog.displayName = 'Dialog';
export default Dialog;

// =================================================================================================
// STYLES
// =================================================================================================

const StyledDialog = styled.div(
	({ feFullscreen, feLayer, open }: Pick<DialogProps, 'feFullscreen' | 'feLayer' | 'open'>) => css`
		${overlay(!!open, ModalDurations.In)};

		inset: 0;
		padding: ${Spacings.Md};
		position: fixed;
		z-index: ${feLayer || Layers.Dialog};

		${!feFullscreen &&
		css`
			align-items: center;
			display: flex;
			justify-content: center;
		`}
	`
);

const StyledDialogInner = styled.div(
	({
		cssHeight,
		cssWidth,
		feFullscreen,
		open,
	}: Pick<DialogProps, 'cssHeight' | 'cssWidth' | 'feFullscreen' | 'open'>) => css`
		background-color: ${Colors.White};
		display: flex;
		flex-direction: column;
		opacity: ${!open && 0};
		overflow: hidden;
		transition: opacity ${ModalDurations.In}ms ${MotionEasings.EaseIn};
		transition-delay: ${open && `${ModalDurations.In}ms`}; /* Delays transition onOpen */

		/* Default behaviour */
		${!feFullscreen &&
		css`
			flex: ${!cssWidth && 'auto'};
			height: ${cssHeight};
			max-height: ${cssHeight ? '100%' : '50vh'};
			max-width: ${cssWidth && '100%'};
			width: ${cssWidth};

			@media screen and ${MediaQueries.Mobile} {
				max-width: ${!cssWidth && '400px'};
			}

			@media screen and ${MediaQueries.MobileMax} and ${MediaQueries.Landscape} {
				max-height: ${!cssHeight && '100%'};
			}
		`}

		${feFullscreen &&
		css`
			height: 100%;
		`}
	`
);

const StyledDialogHead = styled.div`
	${boxPadding('inline')};

	align-items: center;
	background-color: ${Colors.Brand};
	display: flex;
	flex-shrink: 0;
	height: ${Sizes.S48};
`;

const StyledHeading = styled(Heading)`
	color: ${Colors.White};
`;

const StyledDialogCloseButton = styled(IconInteractive)`
	margin-left: auto;
`;

const StyledDialogBody = styled.div(
	({
		cssHeight,
		feFullscreen,
		feNoPadding,
	}: Pick<DialogProps, 'cssHeight' | 'feFullscreen' | 'feNoPadding'>) => css`
		${!feNoPadding && boxPadding()};

		height: ${feFullscreen ? 'inherit' : cssHeight && '100%'};
		overflow-y: auto;
	`
);

enum Spacings {
	Lg = '1.25rem',
	Md = '1rem',
	Sm = '0.75rem',
	Xl = '1.5rem',
	Xs = '0.5rem',
	Xxl = '2rem',
	Xxs = '0.25rem',
}

export default Spacings;

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { ForwardedRef, forwardRef, HTMLAttributes } from 'react';
import square from '~common/styles/mixins/square';
import IconColors from '~tokens/icon-colors/IconColors';
import IconSizes from '~tokens/icon-sizes/IconSizes';
import Icons, { IconData } from '~tokens/icons/Icons';

// =================================================================================================
// INTERFACE
// =================================================================================================

export interface IconProps extends Omit<HTMLAttributes<HTMLSpanElement>, 'children' | 'color'> {
	/** Fill color of the icon. <br><br>Takes `IconColors.<Name>` token.<br><br> */
	feColor?: IconColors;

	/** The name of the icon that will be rendered. <br><br>Takes `Icons.<Name>` token.<br><br> */
	feIcon: Icons;

	/** Size of the icon component. <br><br>Takes `IconSizes.<Size>` token.<br><br> */
	feSize?: IconSizes;
}

// =================================================================================================
// COMPONENT
// =================================================================================================

/**
 * The `<Icon>` component is used to clarify interface elements. When used, should always be paired with (possibly invisible) text.
 *
 * See [InVision DSM](https://skf.invisionapp.com/dsm/ab-skf/4-web-applications/nav/5fa7caf78c01200018354494/folder/60d0563faa295035103b96af) for design principles.
 */
const Icon = forwardRef(
	(
		{ feColor = IconColors.Gray, feIcon, feSize = IconSizes.Md, ...rest }: IconProps,
		ref: ForwardedRef<HTMLSpanElement>
	) => {
		const Icon = IconData[feIcon];

		return (
			<StyledIcon
				{...rest}
				aria-hidden
				data-comp="icon"
				feColor={feColor}
				feSize={feSize}
				ref={ref}
			>
				<Icon />
			</StyledIcon>
		);
	}
);

Icon.displayName = 'Icon';
export default Icon;

// =================================================================================================
// STYLE
// =================================================================================================

const StyledIcon = styled.span(
	({ feColor, feSize }: Pick<IconProps, 'feColor' | 'feSize'>) => css`
		${square(feSize)};

		color: ${feColor};
		display: inline-flex;
		flex-shrink: 0; /* Prevents shrink when flex-child */
		pointer-events: none;
		user-select: none;
	`
);

import * as React from 'react';

const IconPowerOff = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M1.723 1.819a.896.896 0 0 1 1.27 0l15.281 15.282a.896.896 0 1 1-1.269 1.269l-3.816-3.816-.306.306v1.8c0 .495-.405.9-.9.9h-2.7a.903.903 0 0 1-.9-.9v-1.8l-2.628-2.646a1.798 1.798 0 0 1-.522-1.269V6.751c0-.045.01-.09.01-.144l-3.52-3.519a.896.896 0 0 1 0-1.269Zm11.61-.459c.495 0 .9.405.9.9l.01 2.7c.99 0 1.79.801 1.79 1.791v4.203c0 .369-.117.729-.324 1.035L7.033 3.313V2.26c0-.495.405-.9.9-.9s.9.405.9.9v2.7h3.6v-2.7c0-.495.405-.9.9-.9Z" />
	</svg>
);

export default IconPowerOff;

import * as React from 'react';

const IconHourglassFramedFilled = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M17 1a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h14Zm-2.679 2H5.75v.031a1 1 0 0 0 .133 1.962l.02.001.047.279c.394 2.171 1.338 3.914 2.545 4.726-1.258.848-2.23 2.706-2.592 5.006a1 1 0 0 0-.153 1.964V17h8.571v-.053a1 1 0 0 0-.151-1.932c-.36-2.305-1.334-4.167-2.595-5.014 1.26-.85 2.234-2.71 2.594-5.015a1 1 0 0 0 .152-1.933V3Z" />
	</svg>
);

export default IconHourglassFramedFilled;

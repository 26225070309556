import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Property } from 'csstype';
import React, {
	createContext,
	HTMLAttributes,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import ComponentSizes from '~common/constants/componentSizes';
import Layers from '~common/constants/layers';
import useModal from '~common/hooks/useModal';
import boxPadding from '~common/styles/mixins/boxPadding';
import Portal from '~components/portal/Portal';
import Toast, { ToastProps } from '~components/toast/Toast';
import generateId from '~helpers/generateId/generateId';
import MotionDurations from '~tokens/motion-durations/MotionDurations';
import Sizes from '~tokens/sizes/Sizes';
import Spacings from '~tokens/spacings/Spacings';

// =================================================================================================
// INTERFACE
// =================================================================================================

export interface ToastProviderProps extends HTMLAttributes<HTMLDivElement> {
	/** If true, the 'error' type uses default timer set by `feTimeout` instead of being persistent */
	feErrorTimeout?: boolean;

	/** If provided, alters the z-index of the toast-wrapper */
	feLayer?: Property.ZIndex;

	/** Sets the time (in seconds) for how long a `<Toast>` lives */
	feTimeout?: 3 | 7;
}

interface ExtendedToastProps extends ToastProps {
	open: boolean;
}

export interface ToastContextProps {
	addToast: (toast: ToastProps) => void;
	removeToast: (id: string) => void;
	toasts: ExtendedToastProps[];
}

// =================================================================================================
// COMPONENT
// =================================================================================================

const animationDuration = MotionDurations.Fast;

export const ToastContext = createContext<ToastContextProps>({
	addToast: () => ({}),
	removeToast: () => ({}),
	toasts: [],
});

export const useToast = () => useContext(ToastContext);

/**
 * The `<ToastContext>` and helpers exists for proper usage of the `<Toast>` component.
 *
 * See [Toast](./?path=/docs/components-toast--story-toast) for more info on the visual component.<br>
 * See [useToast](./?path=/docs/other-hooks-usetoast--page) for more info on how to use the hook.
 */
const ToastProvider = ({
	children,
	feErrorTimeout = false,
	feTimeout = 3,
	...rest
}: ToastProviderProps) => {
	const [toasts, setToasts] = useState<ExtendedToastProps[]>([]);
	const { willRender } = useModal({
		closeDelay: animationDuration,
		interruptive: true,
		open: toasts.length > 0,
		pageScroll: true,
	});

	const addToast = (toast: ToastProps) => {
		const id = generateId();
		const newItem: ExtendedToastProps = {
			...toast,
			id,
			open: true,
		};
		setToasts([newItem, ...toasts]);
		return newItem.id;
	};

	const removeToast = (id: string) => {
		const theToast = toasts.find((toast) => toast.id === id);
		if (theToast) theToast.open = false;
		setToasts(toasts.filter((toast: ExtendedToastProps) => toast.id !== id));
	};

	return (
		<ToastContext.Provider value={{ toasts, addToast, removeToast }}>
			{children}
			<Portal feRender={willRender} data-portals="toast">
				<StyledToastWrapper {...rest} data-comp="toast-wrapper">
					{toasts.map((item) => (
						<ToastContextItem
							{...item}
							errorShouldTimeout={feErrorTimeout}
							key={item.id}
							timer={feTimeout}
						/>
					))}
				</StyledToastWrapper>
			</Portal>
		</ToastContext.Provider>
	);
};

ToastProvider.displayName = 'ToastProvider';
export default ToastProvider;

// =================================================================================================
// SUB-COMPONENT
// =================================================================================================

interface ToastContextItemProps extends ExtendedToastProps {
	errorShouldTimeout: ToastProviderProps['feErrorTimeout'];
	timer: ToastProviderProps['feTimeout'] | null;
}

const ToastContextItem = ({
	errorShouldTimeout,
	feSeverity,
	id,
	timer,
	open,
	...rest
}: ToastContextItemProps) => {
	const isMounted = useRef(false);
	const ref = useRef<HTMLDivElement>(null);
	const timeout = useRef<NodeJS.Timeout>();
	const [height, setHeight] = useState<number>(ref.current?.offsetHeight || 0);
	const [visible, setVisible] = useState(open);
	const { isOpen } = useModal({
		closeDelay: animationDuration,
		interruptive: true,
		onClose: () => id && removeToast(id),
		open: visible,
		pageScroll: true,
	});
	const { removeToast } = useToast();

	const mouseOverHandler = () => timeout.current && clearTimeout(timeout.current);

	useEffect(() => {
		if (isMounted.current) return;
		isMounted.current = true;

		setHeight(ref.current?.offsetHeight || 0);

		if (feSeverity === 'error' && !errorShouldTimeout) timer = null;

		if (timer) {
			timeout.current = setTimeout(() => {
				setVisible(false);
			}, timer * 1000);
		}
	}, []);

	return (
		<StyledToastItem id={id} stHeight={height} stVisible={isOpen}>
			<Toast
				{...rest}
				feCloseButton={{
					onClick: () => {
						setVisible(false);
					},
				}}
				onMouseOver={mouseOverHandler}
				feSeverity={feSeverity}
				ref={ref}
			/>
		</StyledToastItem>
	);
};

// =================================================================================================
// STYLE
// =================================================================================================

const StyledToastWrapper = styled.div(
	({ feLayer }: Pick<ToastProviderProps, 'feLayer'>) => css`
		${boxPadding('inline')};

		display: flex;
		flex-direction: column;
		left: 50%;
		position: fixed;
		top: calc(${ComponentSizes.HeaderHeight} + ${Sizes.S16});
		transform: translateX(-50%);
		width: ${ComponentSizes.ToastWidth};
		z-index: ${feLayer || Layers.Toast};
	`
);

interface StyledToastItemProps {
	stHeight: number;
	stVisible?: boolean;
}

const StyledToastItem = styled.div(
	({ stHeight, stVisible }: StyledToastItemProps) => css`
		height: ${stVisible ? `${stHeight}px` : '0'};
		margin-bottom: ${stVisible ? Spacings.Md : 0};
		opacity: ${!stVisible && 0};
		overflow: hidden;
		transform: ${!stVisible && 'translateY(-' + Spacings.Md + ')'};
		transition: opacity ${animationDuration}ms ${stVisible && `${animationDuration}ms`},
			transform ${animationDuration}ms ${stVisible && `${animationDuration}ms`},
			height ${animationDuration}ms ${!stVisible && `${animationDuration}ms`},
			margin-bottom ${animationDuration}ms ${!stVisible && `${animationDuration}ms`};
	`
);

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Property } from 'csstype';
import React, { ForwardedRef, forwardRef, HTMLAttributes } from 'react';
import Spacings from '~tokens/spacings/Spacings';

// =================================================================================================
// INTERFACE
// =================================================================================================

export interface FlexboxProps extends HTMLAttributes<HTMLDivElement> {
	/** Controls how the children will be placed along the vertical axis */
	feAlignItems?: Property.AlignItems;

	/** Controls how the children will be placed, by setting the direction of the main axis */
	feFlexDirection?: Property.FlexDirection;

	/** Controls whether children are forced onto one line or can wrap onto multiple lines */
	feFlexWrap?: Property.FlexWrap;

	/** Controls the gutters between children */
	feGap?: Spacings;

	/** Controls how the children will be spaced along the horizontal axis */
	feJustifyContent?: Property.JustifyContent;

	/** If true, the Flexbox fills the parent element height */
	feStretch?: boolean;
}

// =================================================================================================
// COMPONENT
// =================================================================================================

/**
 * The `<Flexbox>` component is a one-dimensional layout container for arranging items in rows or columns.
 * It makes heavy use of the native css `flexbox` properties.
 *
 * See [MDN Web Docs](https://developer.mozilla.org/en-US/docs/Learn/CSS/CSS_layout/Flexbox) for further information about the CSS module and related properties.
 */
const Flexbox = forwardRef(
	({ children, ...rest }: FlexboxProps, ref: ForwardedRef<HTMLDivElement>) => (
		<StyledFlexbox {...rest} data-comp="flexbox" ref={ref}>
			{children}
		</StyledFlexbox>
	)
);

Flexbox.displayName = 'Flexbox';
export default Flexbox;

// =================================================================================================
// STYLE
// =================================================================================================

const StyledFlexbox = styled.div(
	({
		feAlignItems,
		feFlexDirection,
		feFlexWrap,
		feGap,
		feJustifyContent,
		feStretch,
	}: Omit<FlexboxProps, 'children'>) => css`
		align-items: ${feAlignItems};
		display: flex;
		flex-flow: ${feFlexDirection} ${feFlexWrap};
		gap: ${feGap};
		height: ${feStretch && '100%'};
		justify-content: ${feJustifyContent};
	`
);

import * as React from 'react';

const IconCalendarBooked = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<g>
			<path
				d="m11.75 16.33-2.226-2.403a.688.688 0 0 0-1.015 0 .78.78 0 0 0 0 1.07l2.727 2.93a.696.696 0 0 0 1.022 0l6.204-6.484a.78.78 0 0 0 0-1.07.688.688 0 0 0-1.014 0L11.75 16.33Z"
				strokeWidth={0.5}
			/>
			<path d="M19 6.73H2.8v9.64a1 1 0 0 0 1 1h3.7a.5.5 0 0 1 .5.5v.63a.5.5 0 0 1-.5.5l-.5-.001L2.8 19c-.99 0-1.8-.736-1.8-1.636V4.273c0-.9.81-1.637 1.8-1.637h.9v-.818c0-.45.405-.818.9-.818s.9.368.9.818v.818h9v-.818c0-.45.405-.818.9-.818s.9.368.9.818v.818h.9c.99 0 1.8.737 1.8 1.637V6.73Z" />
		</g>
	</svg>
);

export default IconCalendarBooked;

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { ForwardedRef, forwardRef, Fragment, HtmlHTMLAttributes } from 'react';
import FontWeights from '~tokens/font-weights/FontWeights';
import Spacings from '~tokens/spacings/Spacings';

// =================================================================================================
// INTERFACE
// =================================================================================================

export interface DescriptionListGroup {
	detail: string;
	term: string;
}

export interface DescriptionListProps extends HtmlHTMLAttributes<HTMLDListElement> {
	/** List groups */
	feGroups: DescriptionListGroup[];

	/** If provided, changes the layout of the `groups` or `key` and `value` fields */
	feLayout?: 'block' | 'grid' | 'inline-block';
}

// =================================================================================================
// COMPONENT
// =================================================================================================

/**
 * The `<DescriptionList>` is a list of groups of terms and descriptions.
 * It extends the interface of native html `<dl>` element.
 *
 * See [InVision DSM](https://skf.invisionapp.com/dsm/ab-skf/4-web-applications/nav/5fa7caf78c01200018354495/folder/62a31132b1d53006c554a52e) for design principles.<br>
 * See [MDN Web Docs](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/dl) for further information about the element and related attributes.
 */
const DescriptionList = forwardRef(
	(
		{ feGroups, feLayout = 'block', ...rest }: DescriptionListProps,
		ref: ForwardedRef<HTMLDListElement>
	) => {
		const DListGroup = feLayout === 'grid' ? Fragment : 'div';

		return (
			<StyledDList {...rest} data-comp="description-list" feLayout={feLayout} ref={ref}>
				{feGroups.map(({ detail, term }, index) => (
					<DListGroup key={index}>
						<StyledDListTerm>{term}</StyledDListTerm>
						<StyledDListDetail>{detail}</StyledDListDetail>
					</DListGroup>
				))}
			</StyledDList>
		);
	}
);

DescriptionList.displayName = 'DescriptionList';
export default DescriptionList;

// =================================================================================================
// STYLE
// =================================================================================================

const StyledDList = styled.dl(
	({ feLayout }: Pick<DescriptionListProps, 'feLayout'>) => css`
		margin: 0;

		${(feLayout === 'block' || feLayout === 'inline-block') &&
		css`
			display: flex;
		`}

		${feLayout === 'block' &&
		css`
			flex-direction: column;
			gap: ${Spacings.Xs};
		`}

		${feLayout === 'grid' &&
		css`
			display: grid;
			gap: ${Spacings.Xs} ${Spacings.Md};
			grid: auto / auto 1fr;
		`}

		${feLayout === 'inline-block' &&
		css`
			flex-wrap: wrap;
			gap: ${Spacings.Lg};
		`}
	`
);

const StyledDListTerm = styled.dt`
	font-weight: ${FontWeights.Bold};

	&::after {
		content: ': ';
	}
`;

const StyledDListDetail = styled.dd`
	margin: 0;
`;

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { ForwardedRef, forwardRef } from 'react';
import Layers from '~common/constants/layers';
import ModalDurations from '~common/constants/modalDurations';
import useModal from '~common/hooks/useModal';
import boxPadding from '~common/styles/mixins/boxPadding';
import overlay from '~common/styles/mixins/overlay';
import { BaseModalProps } from '~common/types/modal';
import Heading from '~components/heading/Heading';
import IconInteractive from '~components/icon-interactive/IconInteractive';
import Portal from '~components/portal/Portal';
import Colors from '~tokens/colors/Colors';
import DrawerSizes from '~tokens/drawer-sizes/DrawerSizes';
import IconColors from '~tokens/icon-colors/IconColors';
import IconSizes from '~tokens/icon-sizes/IconSizes';
import Icons from '~tokens/icons/Icons';
import MediaQueries from '~tokens/media-queries/MediaQueries';
import MotionEasings from '~tokens/motion-easings/MotionEasings';
import Sizes from '~tokens/sizes/Sizes';

// =================================================================================================
// INTERFACE
// =================================================================================================

export interface DrawerProps extends BaseModalProps {
	/** If provided, sets the Drawer width (only affects breakpoint greater than mobile) */
	feSize?: DrawerSizes;
}

// =================================================================================================
// COMPONENT
// =================================================================================================

/**
 * The `<Drawer>` component is a sidebar layout container that can interrupt the application until it is closed.
 *
 * See [InVision DSM](https://skf.invisionapp.com/dsm/ab-skf/4-web-applications/nav/5fa7caf78c01200018354495/asset/619e566c4440ff1c5075e3e5) for design principles.
 */
const Drawer = forwardRef(
	(
		{
			children,
			feCloseButton,
			feNoPadding = false,
			feOnClose,
			feSize = DrawerSizes.Md,
			feTitle,
			open,
			...rest
		}: DrawerProps,
		ref: ForwardedRef<HTMLDivElement>
	) => {
		const { willRender, isOpen, closeModal: closeDrawer } = useModal({ open, onClose: feOnClose });

		return (
			<Portal feRender={willRender} feAppInert={isOpen} data-portals="drawer">
				<StyledDrawer
					{...rest}
					aria-labelledby="drawer-heading"
					data-comp="drawer"
					onClick={closeDrawer}
					open={isOpen}
					ref={ref}
					role="dialog"
				>
					<StyledDrawerInner feSize={feSize} onClick={(e) => e.stopPropagation()} open={isOpen}>
						<StyledDrawerHead>
							<StyledHeading as="h2" feStyledAs="h3" id="drawer-heading">
								{feTitle}
							</StyledHeading>
							<StyledDrawerCloseButton
								{...feCloseButton}
								aria-label={feCloseButton?.['aria-label'] || 'Close'}
								as="button"
								feColor={IconColors.White}
								feIcon={Icons.Close}
								feSize={IconSizes.Lg}
								onClick={closeDrawer}
							/>
						</StyledDrawerHead>
						<StyledDrawerBody feNoPadding={feNoPadding}>{children}</StyledDrawerBody>
					</StyledDrawerInner>
				</StyledDrawer>
			</Portal>
		);
	}
);

Drawer.displayName = 'Drawer';
export default Drawer;

// =================================================================================================
// STYLES
// =================================================================================================

const StyledDrawer = styled.div(
	({ feLayer, open }: Pick<DrawerProps, 'feLayer' | 'open'>) => css`
		${overlay(open, ModalDurations.In)};

		display: flex;
		inset: 0;
		justify-content: flex-end;
		position: fixed;
		z-index: ${feLayer || Layers.Drawer};
	`
);

const StyledDrawerInner = styled.div(
	({ feSize, open }: Pick<DrawerProps, 'feSize' | 'open'>) => css`
		background: ${Colors.White};
		display: flex;
		flex: auto;
		flex-direction: column;
		transform: ${!open && 'translateX(100%)'};
		transition: transform ${ModalDurations.In}ms ${MotionEasings.EaseIn};
		transition-delay: ${open && `${ModalDurations.In}ms`}; /* Delays transition onOpen */

		@media screen and ${MediaQueries.Mobile} {
			max-width: ${feSize};
		}
	`
);

const StyledDrawerHead = styled.div`
	${boxPadding('inline')};

	align-items: center;
	background-color: ${Colors.Brand};
	color: ${Colors.White};
	display: flex;
	flex-shrink: 0;
	height: ${Sizes.S48};
`;

const StyledHeading = styled(Heading)`
	color: ${Colors.White};
`;

const StyledDrawerCloseButton = styled(IconInteractive)`
	margin-left: auto;
`;

const StyledDrawerBody = styled.div(
	({ feNoPadding }: Pick<DrawerProps, 'feNoPadding'>) => css`
		${!feNoPadding && boxPadding()};

		flex: auto;
		overflow-y: auto;
	`
);

import * as React from 'react';

const IconUndo = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M11.245 2.003c-4.514-.124-8.213 3.511-8.213 8H1.445a.44.44 0 0 0-.31.756l2.474 2.48a.438.438 0 0 0 .63 0l2.474-2.48a.445.445 0 0 0-.32-.756H4.807a6.213 6.213 0 0 1 6.297-6.222c3.3.044 6.076 2.827 6.12 6.133.044 3.476-2.75 6.312-6.208 6.312a6.227 6.227 0 0 1-3.051-.81.861.861 0 0 0-1.047.16c-.408.41-.328 1.112.177 1.396a8.006 8.006 0 0 0 3.92 1.031c4.48 0 8.107-3.706 7.983-8.23-.115-4.17-3.592-7.654-7.752-7.77Z" />
	</svg>
);

export default IconUndo;

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, {
	ButtonHTMLAttributes,
	ChangeEvent,
	ForwardedRef,
	forwardRef,
	MouseEvent,
	useRef,
	useState,
} from 'react';
import { mergeRefs } from '~common/helpers/mergeRefs';
import { baseInputStyles, baseInputWrapperStyles } from '~common/styles/shared';
import { BaseInputProps } from '~common/types/input';
import Icon from '~components/icon/Icon';
import IconSizes from '~tokens/icon-sizes/IconSizes';
import Icons from '~tokens/icons/Icons';
import Spacings from '~tokens/spacings/Spacings';

// =================================================================================================
// INTERFACE
// =================================================================================================

export interface InputSearchResetButton
	extends Pick<ButtonHTMLAttributes<HTMLButtonElement>, 'aria-label' | 'onClick'> {}

export interface InputSearchProps extends BaseInputProps {
	/** Optional props for the reset button */
	feResetButton?: InputSearchResetButton;

	/** Callback that will fire anytime the value of the input changes. */
	onChange?: (event: ChangeEvent<HTMLInputElement>, value: string) => void;
}

// =================================================================================================
// COMPONENT
// =================================================================================================

const InputSearch = forwardRef(
	(
		{
			className,
			defaultValue,
			disabled,
			feResetButton,
			feSize = 'md',
			onChange,
			value,
			...rest
		}: InputSearchProps,
		ref: ForwardedRef<HTMLInputElement>
	) => {
		const [displayResetButton, setDisplayResetButton] = useState(false);
		const inputRef = useRef<HTMLInputElement>(null);
		const { 'aria-label': resetButtonAriaLabel = 'Reset', onClick } = feResetButton || {};

		const toggleResetButton = (value: string) => {
			if (value !== '') {
				setDisplayResetButton(true);
			} else {
				setDisplayResetButton(false);
			}
		};

		const inputChangeHandler = (event: ChangeEvent<HTMLInputElement>, value: string) => {
			onChange?.(event, value);
			toggleResetButton(event.currentTarget.value);
		};

		const resetButtonClickHandler = (event: MouseEvent<HTMLButtonElement>): void => {
			if (inputRef.current) {
				inputRef.current.value = '';
				inputRef.current.focus();
			}
			onClick?.(event);
			setDisplayResetButton(false);
		};

		const mergedRefs = mergeRefs([ref, inputRef]);

		return (
			<StyledInputSearch className={className} data-comp="input-search">
				<StyledIcon feIcon={Icons.Search} feSize={IconSizes.Lg} />
				<StyledInputSearchInput
					{...rest}
					defaultValue={defaultValue}
					disabled={disabled}
					feSize={feSize}
					onChange={(event) => inputChangeHandler(event, event.target.value)}
					onFocus={(event) => toggleResetButton(event.target.value)}
					ref={mergedRefs}
					type="search"
					value={value}
				/>
				{displayResetButton && (
					<StyledInputSearchButton
						aria-label={resetButtonAriaLabel}
						disabled={disabled}
						onClick={(event) => resetButtonClickHandler(event)}
						type="reset"
					>
						<Icon feIcon={Icons.Close} feSize={IconSizes.Lg} />
					</StyledInputSearchButton>
				)}
			</StyledInputSearch>
		);
	}
);

InputSearch.displayName = 'InputSearch';
export default InputSearch;

// =================================================================================================
// STYLE
// =================================================================================================

const StyledInputSearch = styled.div`
	${baseInputWrapperStyles};
`;

const StyledIcon = styled(Icon)`
	left: ${Spacings.Xs};
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
`;

const StyledInputSearchInput = styled.input(
	({ feSeverity, feSize }: Pick<InputSearchProps, 'feSeverity' | 'feSize'>) => css`
		${baseInputStyles(feSize, feSeverity)};

		padding-left: ${Spacings.Xxl};
		padding-right: ${Spacings.Xxl};
		width: 100%;
	`
);

const StyledInputSearchButton = styled.button`
	line-height: 0;
	position: absolute;
	right: ${Spacings.Xs};
	top: 50%;
	transform: translateY(-50%);

	&:disabled {
		pointer-events: none;
	}
`;

import * as React from 'react';

const IconMinusSmall = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M14.8 9H5.2C4.54 9 4 9.54 4 10.2c0 .66.54 1.2 1.2 1.2h9.6c.66 0 1.2-.54 1.2-1.2 0-.66-.54-1.2-1.2-1.2Z" />
	</svg>
);

export default IconMinusSmall;

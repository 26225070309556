/* eslint-disable no-mixed-spaces-and-tabs */
import { RefObject, useEffect, useRef, useState } from 'react';
import { ComboboxItem, ExtendedOnChange, OnChangeEvent } from './Combobox';
import { srtAlpha, srtSelected } from './Combobox.helpers';

export function useSetPlaceholderValueOnMount(
	multiselect: boolean,
	updatePlaceholderValue: () => void
) {
	useEffect(() => {
		if (multiselect) updatePlaceholderValue();
	}, []);
}

// =================================================================================================

type OutsideClickReturns = [showDatalist: boolean, setShowDatalist: (open?: boolean) => void];

/**
 * internal
 */
export function useComboboxClickOutside(
	refInput: HTMLDivElement | null,
	refList: HTMLDivElement | null
): OutsideClickReturns {
	const [showDatalist, setShowDatalist] = useState(false);
	const _showDatalistInsync = useRef(false);
	const eventEl = window;

	const setShowDatalistProxy = (open = true) => {
		if (open === _showDatalistInsync.current) return;
		setShowDatalist(open);
		_showDatalistInsync.current = open;
		open
			? eventEl?.addEventListener('click', closeListIfOutside)
			: eventEl?.removeEventListener('click', closeListIfOutside);
	};

	const closeListIfOutside = (e: MouseEvent) => {
		const clikIsInsideDatalist = !!refList?.contains(e.target as HTMLElement);
		const clickIsOnInput = !!refInput?.contains(e.target as HTMLElement);
		if (!clikIsInsideDatalist && !clickIsOnInput) {
			setShowDatalist(false);
			_showDatalistInsync.current = false;
			eventEl?.removeEventListener('click', closeListIfOutside);
		}
	};

	useEffect(function cleanupListenerOnUmount() {
		return () => {
			_showDatalistInsync.current = false;
			eventEl?.removeEventListener('click', closeListIfOutside);
		};
	}, []);

	return [showDatalist, setShowDatalistProxy];
}

// =================================================================================================

interface useFilterListProps {
	inputValue: string;
	items?: ComboboxItem[];
}

export function useComboboxFilterList({ items, inputValue }: useFilterListProps) {
	const [filteredItems, setFilteredItems] = useState<ComboboxItem[]>([]);

	useEffect(() => {
		const updatedItems =
			items?.filter((item) =>
				item.value.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase())
			) || [];
		setFilteredItems(updatedItems);
	}, [items, inputValue]);

	return filteredItems.sort(srtSelected);
}

// =================================================================================================

interface EmitChangeEventProps {
	e?: OnChangeEvent;
	itemAdded?: string;
	itemSelected?: string;
	itemsSelected?: ComboboxItem[];
	itemsUpdated?: ComboboxItem[];
	value?: string;
}

/**
 * Factory function submits a curated onChange event and payload
 * on input change, add item, select item.
 */
export function useComboboxOnChange(onChange?: ExtendedOnChange, multiselect?: boolean) {
	const emitChangeEvent = ({
		e,
		itemAdded,
		itemsUpdated,
		value,
		itemSelected,
	}: EmitChangeEventProps) => {
		if (!onChange) return;
		const upd = itemsUpdated
			? [...itemsUpdated]?.map(({ ...item }) => {
					delete item.selected;
					return item;
			  })
			: undefined;
		const payload = multiselect
			? {
					value,
					itemAdded,
					itemsUpdated: upd,
					itemsSelected: itemsUpdated?.filter((item) => !!item.selected).sort(srtAlpha),
			  }
			: {
					value,
					itemAdded,
					itemsUpdated: upd,
					itemSelected,
			  };
		onChange(e, payload);
	};

	return { emitChangeEvent };
}

// =================================================================================================

interface CloseListOnScrollProps {
	inputRef: RefObject<HTMLInputElement>;
	setShowDatalist: (x: boolean) => void;
	showDatalist: boolean;
}

/**
 * Closes list and unfocus input on scroll.
 */
export function useComboboxScroll({
	inputRef,
	showDatalist,
	setShowDatalist,
}: CloseListOnScrollProps) {
	const setOnlyOnce = useRef(false);
	if (!showDatalist) setOnlyOnce.current = false;

	useEffect(() => {
		if (setOnlyOnce.current) return;
		setOnlyOnce.current = showDatalist;

		const hideList = () => {
			setShowDatalist(false);
			inputRef.current?.blur();
			setOnlyOnce.current = false;
		};

		showDatalist
			? window.addEventListener('scroll', hideList, { once: true })
			: window.removeEventListener('scroll', hideList);

		return () => window.removeEventListener('scroll', hideList);
	}, [showDatalist]);
}

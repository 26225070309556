import * as React from 'react';

const IconHourglassFramedOutlined = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<g>
			<path d="M17 1a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h14Zm-.8 1.8H3.8a1 1 0 0 0-1 1v12.4a1 1 0 0 0 1 1h12.4a1 1 0 0 0 1-1V3.8a1 1 0 0 0-1-1Z" />
			<path d="M13.75 4a.75.75 0 0 1 .158 1.483c-.302 2.082-1.325 3.76-2.657 4.418 1.397.688 2.455 2.501 2.697 4.725a.75.75 0 0 1-.198 1.474h-7.5a.75.75 0 0 1-.196-1.474c.24-2.224 1.298-4.037 2.695-4.727-1.332-.656-2.355-2.334-2.657-4.416A.75.75 0 0 1 6.25 4h7.5ZM10 11c-.977 0-1.957 1.433-2.287 3.518L7.7 14.6h4.6l-.015-.1c-.325-2.02-1.257-3.416-2.199-3.496L10 11ZM7.715 5.499C8.04 7.52 8.972 8.916 9.914 8.996L10 9c.974 0 1.95-1.424 2.284-3.499l-4.569-.002Z" />
		</g>
	</svg>
);

export default IconHourglassFramedOutlined;

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { ForwardedRef, forwardRef, HTMLAttributes } from 'react';
import ComponentSizes from '~common/constants/componentSizes';
import Link, { LinkAnchor } from '~components/link/Link';
import Colors from '~tokens/colors/Colors';
import Icons from '~tokens/icons/Icons';
import MotionDurations from '~tokens/motion-durations/MotionDurations';
import MotionEasings from '~tokens/motion-easings/MotionEasings';
import Sizes from '~tokens/sizes/Sizes';
import Spacings from '~tokens/spacings/Spacings';

// =================================================================================================
// INTERFACE
// =================================================================================================

export interface HeaderNavItem extends Omit<LinkAnchor, 'as' | 'feIcon'> {
	icon?: Icons;

	selected?: boolean;
}

export interface HeaderNavProps extends HTMLAttributes<HTMLElement> {
	/** Menu items */
	feItems: HeaderNavItem[];

	/** If true, displays the items inline on the horizontal axis */
	feVertical?: boolean;
}

// =================================================================================================
// COMPONENT
// =================================================================================================

/**
 * @internal
 */
const HeaderNav = forwardRef(
	(
		{
			'aria-label': ariaLabel = 'Main navigation',
			feItems,
			feVertical = false,
			...rest
		}: HeaderNavProps,
		ref: ForwardedRef<HTMLElement>
	) => (
		<StyledHeaderNav {...rest} aria-label={ariaLabel} ref={ref}>
			<StyledHeaderNavList feVertical={feVertical} role="menubar">
				{feItems?.map(({ children, icon, ...item }, index) => (
					<StyledHeaderNavItem feVertical={feVertical} key={index} role="menuitem">
						<StyledLink
							{...item}
							as="a"
							feColor="secondary"
							feIcon={
								icon && {
									feIcon: icon,
									position: 'left',
								}
							}
							feVertical={feVertical}
						>
							{children}
						</StyledLink>
					</StyledHeaderNavItem>
				))}
			</StyledHeaderNavList>
		</StyledHeaderNav>
	)
);

HeaderNav.displayName = 'HeaderNav';
export default HeaderNav;

// =================================================================================================
// STYLES
// =================================================================================================

const StyledHeaderNav = styled.nav`
	background-color: ${Colors.Brand};
`;

const StyledHeaderNavList = styled.ul(
	({ feVertical }: Pick<HeaderNavProps, 'feVertical'>) => css`
		display: ${!feVertical && 'flex'};
	`
);

const StyledHeaderNavItem = styled.li(
	({ feVertical }: Pick<HeaderNavProps, 'feVertical'>) => css`
		min-width: ${!feVertical && 0};
	`
);

const StyledLink = styled(Link)(
	({
		feVertical,
		selected,
	}: Pick<HeaderNavItem, 'selected'> & Pick<HeaderNavProps, 'feVertical'>) => css`
		height: ${ComponentSizes.HeaderHeight};
		padding-inline: ${Spacings.Lg};
		position: relative;

		&::after {
			bottom: 0;
			content: '';
			left: 0;
			position: absolute;
			transition: transform ${MotionDurations.Fast}ms ${MotionEasings.EaseIn};
		}

		${selected &&
		css`
			background-color: ${Colors.White};
			color: ${Colors.Gray700};

			&:hover {
				color: ${Colors.Gray700};
			}
		`}

		${!feVertical &&
		css`
			max-width: 100%;

			&::after {
				border-bottom: ${Sizes.S04} solid ${Colors.White};
				right: 0;
				transform: translateY(100%);
			}

			&:hover {
				&::after {
					transform: translateY(0);
				}
			}
		`}

		${feVertical &&
		css`
			width: 100%;

			&::after {
				border-left: ${Sizes.S04} solid ${Colors.White};
				top: 0;
				transform: translateX(-100%);
			}

			&:hover {
				&::after {
					transform: translateX(0);
				}
			}
		`}
	`
);

import React, { ForwardedRef, forwardRef, HTMLAttributes, ReactNode, useState } from 'react';
import Collapse from '~components/collapse/Collapse';
import { HeadingLevel } from '~components/heading/Heading';

// =================================================================================================
// INTERFACE
// =================================================================================================

export interface AccordionItem {
	children: ReactNode;
	title: string;
}

export interface AccordionProps extends HTMLAttributes<HTMLDivElement> {
	/** If provided, sets the corresponding section as expanded */
	feDefaultExpanded?: number;

	/** Menu items */
	feItems: AccordionItem[];

	/** If true, multiple items can be shown at a time */
	feMultiple?: boolean;

	/** If provided, encloses the titles in selected heading element */
	feTitlesAs?: Exclude<HeadingLevel, 'h1'>;
}

// =================================================================================================
// COMPONENT
// =================================================================================================

/**
 * The `<Accordion>` component consists of multiple `<Collapse>`, working together
 *
 * See [InVision DSM](https://skf.invisionapp.com/dsm/ab-skf/4-web-applications/nav/5fa7caf78c01200018354495/asset/6256cb1b44eba592d319d92e) for design principles.
 */
const Accordion = forwardRef(
	(
		{ feDefaultExpanded, feItems, feTitlesAs = 'h2', feMultiple = false, ...rest }: AccordionProps,
		ref: ForwardedRef<HTMLDivElement>
	) => {
		const [expanded, setExpanded] = useState(feDefaultExpanded);

		const clickHandler = (index: number) => {
			if (!feMultiple) setExpanded(index === expanded ? undefined : index);
		};

		return (
			<div {...rest} data-comp="accordion" ref={ref}>
				{feItems?.map(({ children, title }, index) => (
					<Collapse
						feAnimate
						feExpanded={index === expanded}
						feTitle={title}
						feTitleAs={feTitlesAs}
						onClick={() => clickHandler(index)}
						key={index}
					>
						{children}
					</Collapse>
				))}
			</div>
		);
	}
);

Accordion.displayName = 'Accordion';
export default Accordion;

import * as React from 'react';

const IconSensorA = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M15.625 16c.207 0 .375.224.375.5v2c0 .276-.168.5-.375.5H4.375C4.168 19 4 18.776 4 18.5v-2c0-.276.168-.5.375-.5h11.25ZM11.5 0c.276 0 .5.209.5.467V2H7.5c-.245 0-.45.153-.492.355L7 2.433 6.999 14H5.5c-.276 0-.5-.209-.5-.467V.467C5 .209 5.224 0 5.5 0h6Zm3.071 4c.237 0 .429.15.429.333v9.334c0 .184-.192.333-.429.333H9.43C9.192 14 9 13.85 9 13.667V4.333C9 4.15 9.192 4 9.429 4h5.142Z" />
	</svg>
);

export default IconSensorA;

import { css } from '@emotion/react';
import MediaQueries from '~tokens/media-queries/MediaQueries';
import Spacings from '~tokens/spacings/Spacings';

type LogicStart =
	| 'block-end'
	| 'block-start'
	| 'block'
	| 'inline-end'
	| 'inline-start'
	| 'inline'
	| false;

const boxPadding = (logicStart?: LogicStart) => {
	const property = logicStart ? `padding-${logicStart}` : 'padding';

	return css`
		${property}: ${Spacings.Md};

		@media screen and ${MediaQueries.Tablet} {
			${property}: ${Spacings.Xl};
		}

		@media screen and ${MediaQueries.Widescreen} {
			${property}: ${Spacings.Xxl};
		}
	`;
};

export default boxPadding;

import * as React from 'react';

const IconCalendarEmpty = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M17.2 2.636h-.9v-.818c0-.45-.405-.818-.9-.818s-.9.368-.9.818v.818h-9v-.818c0-.45-.405-.818-.9-.818s-.9.368-.9.818v.818h-.9c-.99 0-1.8.737-1.8 1.637v13.09c0 .9.81 1.637 1.8 1.637h14.4c.99 0 1.8-.736 1.8-1.636V4.273c0-.9-.81-1.637-1.8-1.637Zm-.9 14.728H3.7c-.495 0-.9-.369-.9-.819V6.727h14.4v9.818c0 .45-.405.819-.9.819Z" />
	</svg>
);

export default IconCalendarEmpty;

import * as React from 'react';

const IconIMX = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M18.33 0C19.251 0 20 .748 20 1.67v16.66A1.67 1.67 0 0 1 18.33 20H1.67A1.67 1.67 0 0 1 0 18.33V1.67C0 .749.748 0 1.67 0h16.66ZM4.493 1.667H1.67l-.001.538v.311L9.734 9.45a.701.701 0 0 1 0 1.1l-8.067 7.453v.326l.538.001h.157l2.128.001h.302l3.865.001h.382l6.165.001h3.125l.001-.538v-.157l.001-2.128v-.302l.001-3.865v-.382l.001-6.165V1.671l-.538-.001h-.157l-2.128-.001h-.302l-3.865-.001h-.382l-6.165-.001h-.302Z" />
	</svg>
);

export default IconIMX;

import { Global } from '@emotion/react';
import React, { createContext, ReactNode } from 'react';
import globalStyles from '~common/styles/global';

// =================================================================================================
// INTERFACE
// =================================================================================================

interface FerrisProviderProps {
	children: ReactNode;
}

// =================================================================================================
// COMPONENT
// =================================================================================================

const FerrisContext = createContext(null);

/**
 * The `<FerrisContext>` is used to apply ferris global styles to your application.
 *
 * The `<FerrisProvider>` component shall be applied as the outmost container of your application.
 *
 */
const FerrisProvider = ({ children }: FerrisProviderProps) => {
	return (
		<FerrisContext.Provider value={null}>
			<Global styles={globalStyles} />
			{children}
		</FerrisContext.Provider>
	);
};

FerrisProvider.displayName = 'FerrisProvider';
export default FerrisProvider;

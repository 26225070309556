import * as React from 'react';

const IconCalendarNotBooked = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<g>
			<path d="m10.291 10.121 3.208 3.208 3.21-3.208a.756.756 0 0 1 1.07 1.07l-3.21 3.208 3.21 3.21a.756.756 0 1 1-1.07 1.069l-3.21-3.209-3.208 3.209a.756.756 0 0 1-1.07-1.07l3.209-3.21-3.208-3.207a.756.756 0 0 1 1.07-1.07Z" />
			<path d="M19 6.73H2.8v9.64a1 1 0 0 0 1 1h3.7a.5.5 0 0 1 .5.5v.63a.5.5 0 0 1-.5.5l-.5-.001L2.8 19c-.99 0-1.8-.736-1.8-1.636V4.273c0-.9.81-1.637 1.8-1.637h.9v-.818c0-.45.405-.818.9-.818s.9.368.9.818v.818h9v-.818c0-.45.405-.818.9-.818s.9.368.9.818v.818h.9c.99 0 1.8.737 1.8 1.637V6.73Z" />
		</g>
	</svg>
);

export default IconCalendarNotBooked;

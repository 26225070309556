import { css } from '@emotion/react';
import { Property } from 'csstype';

const square = (size: number | string | undefined, radius?: Property.BorderRadius) => css`
	border-radius: ${radius};
	height: ${size};
	width: ${size};
`;

export default square;

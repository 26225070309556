enum Headings {
	H1Size = '2rem',
	H1Weight = '400',
	H2Size = '1.5rem',
	H2Weight = '700',
	H3Size = '1.25rem',
	H3Weight = '700',
	H4Size = '1.125rem',
	H4Weight = '700',
}

export default Headings;

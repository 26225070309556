import * as React from 'react';

const IconChevronUp = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M14.161 13.19 9.995 9.064l-4.167 4.124a1.077 1.077 0 0 1-1.514 0 1.051 1.051 0 0 1 0-1.499l4.929-4.88a1.077 1.077 0 0 1 1.514 0l4.929 4.88a1.051 1.051 0 0 1 0 1.5 1.1 1.1 0 0 1-1.525 0Z" />
	</svg>
);

export default IconChevronUp;

import * as React from 'react';

const IconCalendarRecurring = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<g>
			<path
				d="M14.254 9.021V7.99c0-.26-.32-.386-.504-.202l-1.661 1.609a.28.28 0 0 0 0 .41l1.655 1.608c.19.179.51.052.51-.208v-1.032c1.964 0 3.56 1.551 3.56 3.46 0 .455-.09.9-.261 1.297a.543.543 0 0 0 .136.6c.303.294.813.19.973-.196.22-.525.338-1.102.338-1.701 0-2.549-2.124-4.613-4.746-4.613Zm-.504 8.205c-1.965 0-3.563-1.551-3.563-3.46 0-.455.09-.9.262-1.297a.543.543 0 0 0-.137-.6.603.603 0 0 0-.974.196A4.4 4.4 0 0 0 9 13.766c0 2.549 2.126 4.613 4.75 4.613v1.032c0 .26.32.386.504.202l1.657-1.609a.28.28 0 0 0 0-.41l-1.657-1.608c-.184-.179-.504-.052-.504.208v1.032Z"
				strokeWidth={0.5}
			/>
			<path d="M19 6.73H2.8v10.64H8v1.13a.5.5 0 0 1-.5.5l-.5-.001L2.8 19c-.99 0-1.8-.736-1.8-1.636V4.273c0-.9.81-1.637 1.8-1.637h.9v-.818c0-.45.405-.818.9-.818s.9.368.9.818v.818h9v-.818c0-.45.405-.818.9-.818s.9.368.9.818v.818h.9c.99 0 1.8.737 1.8 1.637V6.73Z" />
		</g>
	</svg>
);

export default IconCalendarRecurring;

import * as React from 'react';

const IconArticle = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M16.417 1.75H3.583A1.839 1.839 0 0 0 1.75 3.583v12.834c0 1.008.825 1.833 1.833 1.833h12.834a1.839 1.839 0 0 0 1.833-1.833V3.583a1.839 1.839 0 0 0-1.833-1.833Zm-5.5 12.833H6.333a.92.92 0 0 1-.916-.916.92.92 0 0 1 .916-.917h4.584a.92.92 0 0 1 .916.917.92.92 0 0 1-.916.916Zm2.75-3.666H6.333A.92.92 0 0 1 5.417 10a.92.92 0 0 1 .916-.917h7.334a.92.92 0 0 1 .916.917.92.92 0 0 1-.916.917Zm0-3.667H6.333a.92.92 0 0 1-.916-.917.92.92 0 0 1 .916-.916h7.334a.92.92 0 0 1 .916.916.92.92 0 0 1-.916.917Z" />
	</svg>
);

export default IconArticle;

enum Layers {
	Toast = 1040,
	Popover = 1030,
	Select = 1020,
	Combobox = 1020,
	DatePicker = 1020,
	Dialog = 1010,
	Drawer = 1000,
}

export default Layers;

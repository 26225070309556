import * as React from 'react';

const IconEyeVisible = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M10 3c4 0 7 2.333 9 7-2 4.667-5 7-9 7s-7-2.333-9-7l.148-.337C3.147 5.22 6.098 3 10 3Zm0 2a5 5 0 1 0 0 10 5 5 0 0 0 0-10Zm0 2a3 3 0 1 1 0 6 3 3 0 0 1 0-6Z" />
	</svg>
);

export default IconEyeVisible;

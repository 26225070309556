import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, {
	AriaAttributes,
	ForwardedRef,
	forwardRef,
	Fragment,
	HTMLAttributes,
	useState,
} from 'react';
import ComponentSizes from '~common/constants/componentSizes';
import Divider from '~components/divider/Divider';
import Drawer from '~components/drawer/Drawer';
import HeaderNav, { HeaderNavProps } from '~components/header/HeaderNav';
import Heading from '~components/heading/Heading';
import IconInteractive from '~components/icon-interactive/IconInteractive';
import Logo from '~components/logo/Logo';
import VisuallyHidden from '~components/visually-hidden/VisuallyHidden';
import useMediaQuery from '~hooks/useMediaQuery';
import Colors from '~tokens/colors/Colors';
import DividerColors from '~tokens/divider-colors/DividerColors';
import FontWeights from '~tokens/font-weights/FontWeights';
import IconColors from '~tokens/icon-colors/IconColors';
import IconSizes from '~tokens/icon-sizes/IconSizes';
import Icons from '~tokens/icons/Icons';
import LogoColors from '~tokens/logo-colors/LogoColors';
import MediaQueries from '~tokens/media-queries/MediaQueries';
import Spacings from '~tokens/spacings/Spacings';

// =================================================================================================
// INTERFACE
// =================================================================================================

interface HeaderControl {
	'aria-label'?: AriaAttributes['aria-label'];
}

interface HeaderControls {
	menuButton?: Pick<HeaderControl, 'aria-label'>;
}

export interface HeaderIntro {
	baseUrl?: string;
	displayName?: boolean;
	name: string;
}

export interface HeaderProps extends HTMLAttributes<HTMLElement> {
	feControls?: HeaderControls;

	/** Intro related props (logo, application name) */
	feIntro: HeaderIntro;

	/** If provided, navigation related props */
	feNavigation?: HeaderNavProps;
}

// =================================================================================================
// COMPONENT
// =================================================================================================

/**
 * The `<Header>` component is aimed to be used as the header section of each page in an app.<br>
 * It extends the interface of native html `<header>` element.
 *
 * See [InVision DSM](https://skf.invisionapp.com/dsm/ab-skf/4-web-applications/nav/5fa7caf78c01200018354495/folder/62b406ff98ebaed3065fff4b) for design principles.<br>
 * See [MDN Web Docs](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/header) for further information about the element and related attributes.
 */
const Header = forwardRef(
	({ feControls, feIntro, feNavigation, ...rest }: HeaderProps, ref: ForwardedRef<HTMLElement>) => {
		const [renderDrawer, setRenderDrawer] = useState(false);
		const { menuButton } = feControls || {};
		const isDesktop = useMediaQuery(MediaQueries.Desktop);

		return (
			<StyledHeader {...rest} data-comp="header" ref={ref} stDesktop={isDesktop}>
				<HeaderIntro {...feIntro} />
				{feNavigation && (
					<>
						{isDesktop && (
							<>
								<Divider
									as="div"
									feColor={DividerColors.Secondary}
									feInset={Spacings.Md}
									feVertical
								/>
								<StyledNavigation {...feNavigation} />
							</>
						)}
						{!isDesktop && (
							<>
								<IconInteractive
									{...menuButton}
									aria-label={menuButton?.['aria-label'] || 'Open Menu'}
									as="button"
									feColor={IconColors.White}
									feIcon={Icons.Burger}
									feSize={IconSizes.Lg}
									onClick={() => setRenderDrawer(true)}
								/>
								<StyledDrawer
									feNoPadding
									feOnClose={() => setRenderDrawer(false)}
									feTitle={feIntro.name}
									open={renderDrawer}
								>
									<HeaderNav {...feNavigation} feVertical />
								</StyledDrawer>
							</>
						)}
					</>
				)}
			</StyledHeader>
		);
	}
);

Header.displayName = 'Header';
export default Header;

// =================================================================================================
// SUBCOMPONENT
// =================================================================================================

const HeaderIntro = ({ baseUrl, displayName = true, name }: HeaderIntro) => {
	const HeaderLink = baseUrl ? StyledHeaderLink : Fragment;
	const HeaderName = !displayName ? VisuallyHidden : StyledHeaderName;

	return (
		<StyledHeading as="h1" feStyledAs="h3">
			<HeaderLink href={baseUrl && baseUrl}>
				<Logo feColor={LogoColors.Secondary} feHeight="auto" feWidth="100px" title="SKF" />
				<HeaderName>{name}</HeaderName>
			</HeaderLink>
		</StyledHeading>
	);
};

// =================================================================================================
// STYLE
// =================================================================================================

const StyledHeader = styled.header(
	({ stDesktop }: { stDesktop: boolean }) => css`
		align-items: center;
		background-color: ${Colors.Brand};
		display: flex;
		gap: ${Spacings.Lg};
		height: ${ComponentSizes.HeaderHeight};
		justify-content: ${!stDesktop && 'space-between'};
		overflow: hidden;
		padding-inline: ${Spacings.Md};
	`
);

const headingStyles = css`
	display: flex;
	gap: ${Spacings.Md};

	@media screen and ${MediaQueries.TabletMax} {
		flex: auto;
	}
`;

const StyledHeading = styled(Heading)`
	${headingStyles};

	color: ${Colors.White};
	font-weight: ${FontWeights.Normal};
	white-space: nowrap;
`;

const StyledHeaderLink = styled.a`
	${headingStyles};

	color: inherit;
	text-decoration: none;
`;

const StyledHeaderName = styled.span`
	@media screen and ${MediaQueries.TabletMax} {
		margin-inline: auto;
	}
`;

const StyledNavigation = styled(HeaderNav)`
	margin-left: auto;
	min-width: 0;
`;

const StyledDrawer = styled(Drawer)`
	& > div {
		background: ${Colors.Brand};
	}
`;

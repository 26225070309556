import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, {
	AnchorHTMLAttributes,
	AriaAttributes,
	ButtonHTMLAttributes,
	ForwardedRef,
	forwardRef,
} from 'react';
import focusOutline from '~common/styles/mixins/focusOutline';
import square from '~common/styles/mixins/square';
import Icon, { IconProps } from '~components/icon/Icon';
import Colors from '~tokens/colors/Colors';
import IconColors from '~tokens/icon-colors/IconColors';
import IconSizes from '~tokens/icon-sizes/IconSizes';

// =================================================================================================
// INTERFACE
// =================================================================================================

interface BaseProps extends Pick<IconProps, 'feIcon' | 'feSize'> {
	/** Label  */
	'aria-label': AriaAttributes['aria-label'];

	/** If provided, alters the appearance */
	feColor?: Extract<IconColors, IconColors.Brand | IconColors.Gray | IconColors.White>;
}

export interface IconInteractiveAnchor
	extends BaseProps,
		Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'aria-label' | 'children'> {
	/** Option to render the component as a anchor or button */
	as: 'a';
}

export interface IconInteractiveButton
	extends BaseProps,
		Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'aria-label' | 'children'> {
	as: 'button';
}

export type IconInteractiveProps = IconInteractiveAnchor | IconInteractiveButton;

// =================================================================================================
// COMPONENT
// =================================================================================================

/**
 * The `<IconInteractive>` component renders as an anchor or button element. **Notice!** Be careful to choose the appropriate type.
 *
 * See [InVision DSM](https://skf.invisionapp.com/dsm/ab-skf/4-web-applications/nav/5fa7caf78c01200018354494/folder/60d0563faa295035103b96af) for design principles.
 */
const IconInteractive = forwardRef(
	(
		{ as, feColor = IconColors.Gray, feIcon, feSize = IconSizes.Md, ...rest }: IconInteractiveProps,
		ref: ForwardedRef<HTMLAnchorElement | HTMLButtonElement>
	) => {
		return (
			<StyledIconInteractive
				{...rest}
				as={as}
				data-comp="icon-interactive"
				feColor={feColor}
				ref={ref}
			>
				<StyledIcon feIcon={feIcon} feSize={feSize} />
			</StyledIconInteractive>
		);
	}
);

IconInteractive.displayName = 'IconInteractive';
export default IconInteractive;

// =================================================================================================
// HELPER
// =================================================================================================

interface Color {
	default: IconColors;
	disabled: Colors;
	hover: Colors;
}

const colors: ReadonlyMap<IconInteractiveProps['feColor'], Color> = new Map([
	[
		IconColors.Brand,
		{
			default: IconColors.Brand,
			disabled: Colors.Gray500,
			hover: Colors.Primary800,
		},
	],
	[
		IconColors.Gray,
		{
			default: IconColors.Gray,
			disabled: Colors.Gray500,
			hover: Colors.Black,
		},
	],
	[
		IconColors.White,
		{
			default: IconColors.White,
			disabled: Colors.Primary500,
			hover: Colors.Primary200,
		},
	],
]);

// =================================================================================================
// STYLE
// =================================================================================================

const StyledIconInteractive = styled.span(
	({ feColor, feSize }: Pick<IconInteractiveProps, 'feColor' | 'feSize'>) => css`
		${square(feSize)};

		color: ${colors.get(feColor)?.default};
		display: inline-flex;

		&:focus-visible {
			${focusOutline({ color: colors.get(feColor)?.default })}
		}

		@supports not selector(:focus-visible) {
			&:focus {
				${focusOutline({ color: colors.get(feColor)?.default })}
			}
		}

		&:hover {
			color: ${colors.get(feColor)?.hover};
		}

		&:disabled {
			color: ${colors.get(feColor)?.disabled};
		}
	`
);

const StyledIcon = styled(Icon)`
	color: inherit;
`;

import styled from '@emotion/styled';
import React, { ChangeEvent, Ref } from 'react';
import Hint from '~common/components/hint/Hint';
import Fieldset, { FieldsetProps } from '~components/fieldset/Fieldset';
import Flexbox from '~components/flexbox/Flexbox';
import Radio, { RadioProps } from '~components/radio/Radio';
import Spacer from '~components/spacer/Spacer';
import generateId from '~helpers/generateId/generateId';
import Spacings from '~tokens/spacings/Spacings';

// =================================================================================================
// INTERFACE
// =================================================================================================

export interface RadioGroupItem extends Omit<RadioProps, 'name' | 'onChange'> {}

export interface RadioGroupProps<T> extends Omit<FieldsetProps, 'children' | 'onChange'> {
	/** If provided, will display under the label for extra information about the input field */
	feHint?: string;

	/** If true, displays the fields inline on the horizontal axis */
	feHorizontal?: boolean;

	/** One or more RadioField components that will be laid out in a column */
	feItems: RadioGroupItem[];

	/** Ref for the wrapping element. This is not a regular forwarded ref in order to allow for generic typing. */
	feWrapperRef?: Ref<HTMLFieldSetElement>;

	/** Callback that fires whenever one of the radio fields are clicked. Includes the value of the newly selected field. */
	onChange?: (event: ChangeEvent<HTMLInputElement>, value: T) => void;

	/** The value of the radio field that should be selected. Makes the fields controlled. */
	value?: T | null;
}

// =================================================================================================
// COMPONENT
// =================================================================================================

/**
 * The `<RadioGroup>` component renders a wrapper for multiple `<Radio>` components. Use this when you need to show errors for `<Radio>`.<br>
 * It extends the interface of native html `<fieldset>` element.
 *
 * See [InVision DSM](https://skf.invisionapp.com/dsm/ab-skf/4-web-applications/nav/5fa7caf78c01200018354495/asset/6229e324f1413fe88521d3de) for design principles.<br>
 * See [MDN Web Docs](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/fieldset) for further information about the element and related attributes.
 */
const RadioGroup = <T extends number | string>({
	feHint,
	feItems,
	feHorizontal,
	feWrapperRef,
	name,
	onChange,
	value,
	...rest
}: RadioGroupProps<T>) => {
	const changeHandler = (
		event: ChangeEvent<HTMLInputElement>,
		checked: boolean,
		value: RadioGroupItem['value']
	) => {
		checked && onChange?.(event, value as number | string as T);
	};

	const state = feItems.find((item) => item.feSeverity)?.feSeverity || undefined;

	const hintId = feHint && state === 'error' ? generateId() : undefined;

	return (
		<StyledRadioGroup {...rest} data-comp="radio-group" ref={feWrapperRef} role="radiogroup">
			<Flexbox feGap={Spacings.Md} feFlexDirection={!feHorizontal ? 'column' : undefined}>
				{feItems.map((item, index) => (
					<Radio
						{...item}
						aria-errormessage={
							item.feSeverity === 'error'
								? feHint
									? hintId
									: item['aria-errormessage']
								: undefined
						}
						key={index}
						name={name}
						onChange={(event, checked) => changeHandler(event, checked, item.value)}
						checked={value !== undefined ? value === item.value : undefined}
						value={item.value}
					/>
				))}
			</Flexbox>
			{feHint && (
				<>
					<Spacer feSpacing={Spacings.Sm} />
					<Hint feSeverity={state} id={hintId}>
						{feHint}
					</Hint>
				</>
			)}
		</StyledRadioGroup>
	);
};

RadioGroup.displayName = 'RadioGroup';
export default RadioGroup;

// =================================================================================================
// STYLE
// =================================================================================================

const StyledRadioGroup = styled(Fieldset)`
	legend {
		font-weight: normal;
	}
`;

import * as React from 'react';

const IconViewFull = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M.5 2h2a.5.5 0 0 1 .5.5v15a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-15A.5.5 0 0 1 .5 2Zm19.5.5v15a.5.5 0 0 1-.5.5h-14a.5.5 0 0 1-.5-.5v-15a.5.5 0 0 1 .5-.5h14a.5.5 0 0 1 .5.5Z" />
	</svg>
);

export default IconViewFull;

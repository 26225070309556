import styled from '@emotion/styled';
import React, { ForwardedRef, forwardRef } from 'react';
import Label from '~common/components/label/Label';
import { BaseInputFieldProps } from '~common/types/input';
import SwitchControl, { SwitchControlProps } from '~components/switch/SwitchControl';
import generateId from '~helpers/generateId/generateId';
import Spacings from '~tokens/spacings/Spacings';

// =================================================================================================
// INTERFACE
// =================================================================================================

export interface SwitchProps extends Omit<BaseInputFieldProps, 'feHint'>, SwitchControlProps {}

// =================================================================================================
// COMPONENT
// =================================================================================================

/**
 * <br>
 * <span class="sbdocs-tag sbdocs-blue">controlled</span>
 * <span class="sbdocs-tag sbdocs-blue">uncontrolled</span>
 * <br><br>
 *
 * The `<Switch>` component renders a toggle button that toggles a single setting on or off.<br>
 * It extends the interface of native html `<input>` element.
 *
 * See [InVision DSM](https://skf.invisionapp.com/dsm/ab-skf/4-web-applications/nav/5fa7caf78c01200018354495/folder/619626a76b610a6e74788d92) for design principles.<br>
 * See [MDN Web Docs](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/checkbox) for further information about the element and related attributes.
 */
const Switch = forwardRef(
	(
		{
			className,
			disabled,
			feHideLabel,
			feLabel,
			feRequiredText,
			feSize,
			id = generateId(),
			required,
			...rest
		}: SwitchProps,
		ref: ForwardedRef<HTMLInputElement>
	) => (
		<StyledSwitch className={className} data-comp="switch">
			<SwitchControl
				{...rest}
				disabled={disabled}
				feSize={feSize}
				id={id}
				ref={ref}
				required={required}
			/>
			<Label
				feDisabled={disabled}
				feHideLabel={feHideLabel}
				feRequired={required}
				feRequiredText={feRequiredText}
				feSize={feSize}
				htmlFor={id}
			>
				{feLabel}
			</Label>
		</StyledSwitch>
	)
);

Switch.displayName = 'Switch';
export default Switch;

// =================================================================================================
// STYLE
// =================================================================================================

const StyledSwitch = styled.div`
	align-items: center;
	display: flex;
	gap: ${Spacings.Xs};
`;

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { ChangeEvent, ForwardedRef, forwardRef } from 'react';
import focusOutline from '~common/styles/mixins/focusOutline';
import { BaseInputProps } from '~common/types/input';
import { opacity } from '~helpers/color/color';
import Colors from '~tokens/colors/Colors';
import MotionDurations from '~tokens/motion-durations/MotionDurations';
import MotionEasings from '~tokens/motion-easings/MotionEasings';
import Sizes from '~tokens/sizes/Sizes';

// =================================================================================================
// INTERFACE
// =================================================================================================

export interface SwitchControlProps extends Omit<BaseInputProps, 'feSeverity'> {
	/** Callback that will fire anytime the state of the checkbox changes. */
	onChange?: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

// =================================================================================================
// COMPONENT
// =================================================================================================

/**
 * @internal
 */
const SwitchControl = forwardRef(
	(
		{ className, feSize = 'md', onChange, ...rest }: SwitchControlProps,
		ref: ForwardedRef<HTMLInputElement>
	) => (
		<StyledSwitchControl className={className} feSize={feSize}>
			<StyledSwitchInput
				{...rest}
				onChange={(event) => onChange && onChange(event, event.target.checked)}
				role="switch"
				type="checkbox"
				ref={ref}
			/>
			<StyledSwitchSlider />
		</StyledSwitchControl>
	)
);

SwitchControl.displayName = 'SwitchControl';
export default SwitchControl;

// =================================================================================================
// STYLE
// =================================================================================================

const StyledSwitchControl = styled.div(
	({ feSize }: Pick<SwitchControlProps, 'feSize'>) => css`
		display: inline-block;
		position: relative;

		${feSize === 'md' &&
		css`
			height: ${Sizes.S24};
			width: ${Sizes.S40};
		`}

		${feSize === 'sm' &&
		css`
			height: ${Sizes.S20};
			width: ${Sizes.S32};
		`}
	`
);

const StyledSwitchInput = styled.input`
	cursor: pointer;
	height: inherit;
	inset: 0;
	margin: 0;
	opacity: 0;
	position: absolute;
	width: inherit;
	z-index: 1;

	&:disabled {
		cursor: not-allowed;
	}
`;

const StyledSwitchSlider = styled.div`
	background-color: ${Colors.Gray400};
	border: 1px solid ${opacity(Colors.Black, 0.1)};
	border-radius: 9999px;
	height: inherit;
	transition: background ${MotionDurations.Fast}ms ${MotionEasings.EaseIn};
	width: inherit;

	/* Handle */
	&::after {
		aspect-ratio: 1;
		background: ${Colors.White} content-box;
		border: 1px solid ${opacity(Colors.Black, 0.1)};
		border-radius: 50%;
		content: '';
		display: block;
		height: 100%;
		transition: transform ${MotionDurations.Fast}ms ${MotionEasings.EaseIn};
	}

	input:checked ~ & {
		background-color: ${Colors.Brand};
		border-color: transparent;

		&::after {
			transform: translateX(calc(100% - 6px));
		}
	}

	input:disabled:not(:checked) ~ & {
		background-color: ${Colors.Gray100};
		border-color: ${opacity(Colors.Black, 0.05)};

		&::after {
			border-color: ${opacity(Colors.Black, 0.05)};
		}
	}

	input:checked:disabled ~ & {
		background-color: ${Colors.BlueBase};
	}

	input:focus-visible ~ & {
		${focusOutline()}
	}

	@supports not selector(:focus-visible) {
		input:focus ~ & {
			${focusOutline()}
		}
	}
`;

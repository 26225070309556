import * as React from 'react';

const IconUnlock = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M16 6.667c1.1 0 2 .857 2 1.904v9.524C18 19.143 17.1 20 16 20H4c-1.1 0-2-.857-2-1.905V8.571c0-1.047.9-1.904 2-1.904h9V4.762c0-1.572-1.35-2.857-3-2.857-1.37 0-2.56.885-2.9 2.143-.14.514-.69.819-1.22.685-.54-.133-.86-.647-.72-1.162C5.73 1.467 7.72 0 10 0c2.76 0 5 2.133 5 4.762v1.905h1ZM10 11.2c1.375 0 2.5 1.125 2.5 2.5s-1.125 2.5-2.5 2.5a2.507 2.507 0 0 1-2.5-2.5c0-1.375 1.125-2.5 2.5-2.5Zm6 5.9V9.9c0-.495-.45-.9-1-.9H5c-.55 0-1 .405-1 .9v7.2c0 .495.45.9 1 .9h10c.55 0 1-.405 1-.9Z" />
	</svg>
);

export default IconUnlock;

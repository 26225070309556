import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, {
	createRef,
	ForwardedRef,
	forwardRef,
	HTMLAttributes,
	KeyboardEvent,
	MouseEvent,
	ReactNode,
	useRef,
	useState,
} from 'react';
import feLog from '~common/helpers/feLog';
import { mergeRefs } from '~common/helpers/mergeRefs';
import boxPadding from '~common/styles/mixins/boxPadding';
import ellipsis from '~common/styles/mixins/ellipsis';
import focusOutline from '~common/styles/mixins/focusOutline';
import Card from '~components/card/Card';
import generateId from '~helpers/generateId/generateId';
import Colors from '~tokens/colors/Colors';
import FontWeights from '~tokens/font-weights/FontWeights';
import MotionDurations from '~tokens/motion-durations/MotionDurations';
import MotionEasings from '~tokens/motion-easings/MotionEasings';
import Sizes from '~tokens/sizes/Sizes';
import Spacings from '~tokens/spacings/Spacings';

// =================================================================================================
// INTERFACE
// =================================================================================================

export interface TabsItem {
	children: ReactNode;
	id?: string;
	label: string;
}

interface Compressed {
	/** If provided, alters the appearance of the tabs */
	feType: 'compressed';
}

interface Expanded {
	/** If true, removes the border */
	feNoBorder?: boolean;

	/** If true, removes the (right, bottom & left) padding */
	feNoPadding?: boolean;

	/** If provided, alters the appearance of the tabs */
	feType: 'expanded';
}

interface BaseProps extends Omit<HTMLAttributes<HTMLDivElement>, 'onClick'> {
	/**  If provided, set the default selected tab  */
	feDefaultSelected?: number;

	/** Array of tab-items */
	feItems: TabsItem[];

	/** If true, disables scroll. **Notice!** Only applicable together with the `feStretch` property. */
	feNoScroll?: boolean;

	/** If provided, this tab will be selected when the component mounts. Also sets the component as 'controlled'. **Notice!** Don't use feDefaultSelected when setting this. */
	feSelected?: number;

	/** If true, the Tabs component fills the parent element height */
	feStretch?: boolean;

	/** Called whenever a tab is clicked */
	onClick?: (event: MouseEvent<HTMLAnchorElement>, index: number) => void;
}

export type TabsProps = BaseProps & (Compressed | Expanded);

// =================================================================================================
// COMPONENT
// =================================================================================================

/**
 * The `<Tabs>` component can be used to divide content into meaningful sections to occupy less screen space.
 *
 * See [InVision DSM](https://skf.invisionapp.com/dsm/ab-skf/4-web-applications/nav/5fa7caf78c01200018354495/asset/619e56ce4440ff2ed575e3e7) for design principles.
 */
const Tabs = forwardRef(
	(
		{ feDefaultSelected, feItems, feNoScroll, feSelected, feStretch, onClick, ...rest }: TabsProps,
		ref: ForwardedRef<HTMLDivElement>
	) => {
		if (feDefaultSelected && feSelected) {
			feLog(
				'Tabs',
				'Component has both feSelected and feDefaultSelected props set. Tabs must be either controlled or uncontrolled (specify either the feSelected prop, or the feDefaultSelected prop, but not both). Decide between using a controlled or uncontrolled Tabs component and remove one of these props.',
				'error'
			);
		}

		const [selected, setSelected] = useState((feSelected || feDefaultSelected) ?? 0);
		const { feType } = rest;
		const feNoBorder = rest.feType === 'expanded' && rest.feNoBorder;
		const feNoPadding = rest.feType === 'expanded' && rest.feNoPadding;
		const rootRef = useRef<HTMLDivElement>(null);
		const mergedRefs = mergeRefs([rootRef, ref]);
		const refs = useRef(feItems.map(() => createRef<HTMLAnchorElement>()));
		const Tabs = feType === 'expanded' ? StyledTabsExpanded : StyledTabsCompressed;

		function handleOnClick(event: MouseEvent<HTMLAnchorElement>, item: TabsItem, index: number) {
			event.preventDefault();
			onClick && onClick(event, index);
			setSelected(index);
		}

		function handleKeyPress(event: KeyboardEvent<HTMLAnchorElement>): void {
			let newSelected;
			if (event.key === 'ArrowRight') {
				newSelected = selected === feItems.length - 1 ? 0 : selected + 1;
			} else if (event.key === 'ArrowLeft') {
				newSelected = selected === 0 ? feItems.length - 1 : selected - 1;
			}

			if (newSelected != null) {
				setSelected(newSelected);
				refs.current[newSelected].current?.focus();
			}
		}

		feItems.map((item) => {
			if (item.id === undefined) item.id = generateId('');
		});

		return (
			<Tabs
				{...rest}
				data-comp="tabs"
				feNoBorder={feNoBorder}
				feNoPadding={true}
				feStretch={feStretch}
				ref={mergedRefs}
			>
				<StyledTabsHead
					aria-orientation="horizontal"
					feStretch={feStretch}
					feType={feType}
					role="tablist"
				>
					{feItems.map((item, index) => {
						const { id, label } = item;
						const isSelected = feSelected != null ? feSelected === index : selected === index;

						return (
							<StyledTabsTab
								aria-selected={isSelected}
								data-label={label}
								feType={feType}
								href={`#fe-tabpanel-${id}`}
								id={`fe-tab-${id}`}
								key={`fe-tab-${id}`}
								onClick={(event) => handleOnClick(event, item, index)}
								onKeyDown={handleKeyPress}
								ref={refs.current[index]}
								role="tab"
								stSelected={isSelected}
								tabIndex={isSelected ? 0 : -1}
							>
								<StyledTabsLabel>{label}</StyledTabsLabel>
							</StyledTabsTab>
						);
					})}
				</StyledTabsHead>
				<StyledTabsBody
					feNoScroll={feNoScroll}
					feNoPadding={feNoPadding}
					feStretch={feStretch}
					feType={feType}
				>
					{feItems.map(({ children, id }, index) => (
						<StyledTabsPanel
							aria-labelledby={`fe-tab-${id}`}
							feNoScroll={feNoScroll}
							feStretch={feStretch}
							id={`fe-tabpanel-${id}`}
							key={id}
							role="tabpanel"
							stSelected={feSelected != null ? feSelected === index : selected === index}
						>
							{children}
						</StyledTabsPanel>
					))}
				</StyledTabsBody>
			</Tabs>
		);
	}
);

Tabs.displayName = 'Tabs';
export default Tabs;

// =================================================================================================
// STYLE
// =================================================================================================

interface StyleProps {
	stSelected: boolean;
}

const StyledTabsCompressed = styled.div(
	({ feStretch }: Pick<TabsProps, 'feStretch'>) => css`
		background: ${Colors.White};

		${feStretch &&
		css`
			display: flex;
			flex-direction: column;
			height: 100%;
		`}
	`
);

const StyledTabsExpanded = styled(Card)(
	({ feStretch }: Pick<TabsProps, 'feStretch'>) => css`
		border-top-left-radius: 0;
		border-top-right-radius: 0;

		${feStretch &&
		css`
			display: flex;
			flex-direction: column;
		`}
	`
);

const StyledTabsHead = styled.div(
	({ feStretch, feType }: Pick<TabsProps, 'feStretch' | 'feType'>) => css`
		display: flex;

		${feType === 'compressed' &&
		css`
			gap: ${Spacings.Md};
		`}

		${feType === 'expanded' &&
		css`
			background: ${Colors.Gray050};
			height: ${Sizes.S40};
		`}

		${feStretch &&
		css`
			flex: none;
		`}
	`
);

const StyledTabsTab = styled.a(
	({ feType, stSelected }: Pick<TabsProps, 'feType'> & StyleProps) => css`
		color: ${Colors.Black};
		min-width: 0; /* Needed for truncation */
		position: relative;
		text-decoration: none;

		/* Blue border used in both variants */
		&::after {
			background: ${Colors.Brand};
			content: '';
			height: 3px;
			left: 50%;
			opacity: 0;
			position: absolute;
			right: 50%;
			transition: all ${MotionDurations.Fast}ms ease-in;
		}

		&:focus-visible {
			${focusOutline()}
		}

		@supports not selector(:focus-visible) {
			&:focus {
				${focusOutline()}
			}
		}

		${stSelected &&
		css`
			color: ${Colors.Brand};
			font-weight: ${FontWeights.Bold};

			&::after {
				left: 0;
				opacity: 1;
				right: 0;
			}
		`}

		${feType === 'expanded' &&
		css`
			align-items: center;
			border-bottom: 1px solid ${Colors.Gray300};
			display: inline-flex;
			flex: 1;
			justify-content: center;
			padding: 0 ${Spacings.Xs};
			transition: background ${MotionDurations.Fast}ms ${MotionEasings.EaseIn};

			&::after {
				top: 0;
			}

			&:hover,
			&:active {
				background: ${Colors.Primary200};
			}

			&:focus-visible {
				outline-offset: -2px;
			}

			${stSelected &&
			css`
				background: ${Colors.White};
				border-bottom-color: transparent;
				border-left: 1px solid ${Colors.Gray300};
				border-right: 1px solid ${Colors.Gray300};

				&:first-of-type {
					border-left: 0;
				}

				&:last-of-type {
					border-right: 0;
				}

				&:hover,
				&:active {
					background: ${Colors.White};
				}
			`}
		`}

		${feType === 'compressed' &&
		css`
			padding: 0 0 ${Spacings.Xxs};

			/* Invisible bold label copy, to avoid layout shifts when selected */
			&::before {
				content: attr(data-label);
				display: block;
				font-weight: ${FontWeights.Bold};
				height: 0;
				visibility: hidden;
			}

			&::after {
				bottom: 0;
			}

			&:hover,
			&:active {
				&::after {
					left: 0;
					opacity: 1;
					right: 0;
				}
			}
		`}
	`
);

const StyledTabsLabel = styled.span`
	${ellipsis('x')};
`;

const StyledTabsBody = styled.div(
	({
		feNoScroll,
		feNoPadding = false,
		feStretch,
		feType,
	}: Pick<Expanded, 'feNoPadding'> & Pick<TabsProps, 'feNoScroll' | 'feStretch' | 'feType'>) => {
		const noPadding = feType === 'expanded' ? feNoPadding : true;

		return css`
			${boxPadding(noPadding && 'block-start')};

			${feStretch &&
			css`
				flex: auto;
				overflow-y: ${feNoScroll ? 'hidden' : 'auto'};
			`}
		`;
	}
);

const StyledTabsPanel = styled.div(
	({
		feNoScroll,
		feStretch,
		stSelected,
	}: Pick<StyleProps, 'stSelected'> & Pick<TabsProps, 'feNoScroll' | 'feStretch'>) => css`
		display: ${stSelected ? 'block' : 'none'};
		height: ${feStretch && feNoScroll && '100%'};
	`
);

import styled from '@emotion/styled';
import React, { ForwardedRef, forwardRef, HTMLAttributes, ReactElement } from 'react';
import { GridItemProps } from '~components/grid/GridItem';
import MediaQueries from '~tokens/media-queries/MediaQueries';
import Spacings from '~tokens/spacings/Spacings';

// =================================================================================================
// INTERFACE
// =================================================================================================

export interface GridProps extends HTMLAttributes<HTMLDivElement> {
	/** Takes single or multiple `<GridItem>` elements as children */
	children: ReactElement<GridItemProps> | ReactElement<GridItemProps>[];
}

// =================================================================================================
// COMPONENT
// =================================================================================================

/**
 * The `<Grid>` component is a container for a two-dimensional layout.
 * It makes heavy use of the native css `grid` properties.
 *
 * See [MDN Web Docs](https://developer.mozilla.org/en-US/docs/Learn/CSS/CSS_layout/Grids) for further information about the CSS module and related properties.
 */
const Grid = forwardRef(({ children, ...rest }: GridProps, ref: ForwardedRef<HTMLDivElement>) => (
	<StyledGrid {...rest} data-comp="grid" ref={ref}>
		{children}
	</StyledGrid>
));

Grid.displayName = 'Grid';
export default Grid;

// =================================================================================================
// STYLE
// =================================================================================================

const StyledGrid = styled.div`
	display: grid;
	gap: ${Spacings.Md};
	grid-template-columns: repeat(12, minmax(0, 1fr));

	@media screen and ${MediaQueries.Tablet} {
		gap: ${Spacings.Xl};
	}

	@media screen and ${MediaQueries.Widescreen} {
		gap: ${Spacings.Xxl};
	}
`;

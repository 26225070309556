enum MediaQueries {
	Landscape = '(min-aspect-ratio: 1/1)',
	Portrait = '(max-aspect-ratio: 1/1)',
	Widescreen = '(min-width: 1600px)',
	Desktop = '(min-width: 1200px)',
	DesktopMax = '(max-width: 1599px)',
	Tablet = '(min-width: 768px)',
	TabletMax = '(max-width: 1199px)',
	Mobile = '(min-width: 540px)',
	MobileMax = '(max-width: 767px)',
}
export default MediaQueries;

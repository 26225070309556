import * as React from 'react';

const IconDefectFrequencies = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M9 7h2v11H9V7ZM8 2h4v4H8V2Z" />
	</svg>
);

export default IconDefectFrequencies;

import * as React from 'react';

const IconArtificialIntelligence = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M12.692 1.25c.341 0 .623.253.667.582l.006.091v1.795h1.122c.991 0 1.795.804 1.795 1.795v1.122h1.795a.673.673 0 0 1 .091 1.34l-.091.006h-1.795v1.346h1.795a.673.673 0 0 1 .091 1.34l-.091.006h-1.795v1.346h1.795a.673.673 0 0 1 .091 1.34l-.091.006h-1.795v1.122c0 .991-.804 1.795-1.795 1.795h-1.122v1.795a.673.673 0 0 1-1.34.091l-.006-.091v-1.795h-1.346v1.795a.673.673 0 0 1-1.34.091l-.006-.091v-1.795H7.98v1.795a.673.673 0 0 1-1.34.091l-.006-.091v-1.795H5.513a1.795 1.795 0 0 1-1.795-1.795v-1.122H1.923a.673.673 0 0 1-.091-1.34l.091-.006h1.795v-1.346H1.923a.673.673 0 0 1-.091-1.34l.091-.006h1.795V7.98H1.923a.673.673 0 0 1-.091-1.34l.091-.006h1.795V5.513c0-.991.804-1.795 1.795-1.795h1.122V1.923a.673.673 0 0 1 1.34-.091l.006.091v1.795h1.346V1.923a.673.673 0 0 1 1.34-.091l.006.091v1.795h1.346V1.923c0-.372.302-.673.673-.673Zm1.795 4.263H5.513v8.974h8.974V5.513Z" />
	</svg>
);

export default IconArtificialIntelligence;

import * as React from 'react';

const IconBatteryEmpty = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M17.35 13.705v-1.759h1.328c.19 0 .322-.146.322-.311v-3.28a.315.315 0 0 0-.322-.312H17.35V6.284c0-.824-.683-1.484-1.537-1.484H2.536C1.683 4.8 1 5.478 1 6.284v7.421c0 .825.683 1.485 1.536 1.485h13.277c.854 0 1.537-.66 1.537-1.485Zm-14.814.092c-.057 0-.094-.037-.094-.092v-7.44c0-.054.037-.09.094-.09h13.277c.057 0 .095.036.095.09v7.422c0 .055-.038.092-.095.092H2.536v.018Z" />
	</svg>
);

export default IconBatteryEmpty;

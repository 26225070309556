import * as React from 'react';

const IconCaretUpDown = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<g>
			<path d="m6.33 12.905 2.886 2.886a1.11 1.11 0 0 0 1.57 0l2.886-2.886c.702-.702.201-1.905-.79-1.905H7.11c-.991 0-1.482 1.203-.78 1.905ZM13.67 7.211l-2.886-2.885a1.11 1.11 0 0 0-1.57 0L6.327 7.21c-.702.702-.201 1.906.79 1.906h5.772c.991 0 1.482-1.204.78-1.906Z" />
		</g>
	</svg>
);

export default IconCaretUpDown;

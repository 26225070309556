import * as React from 'react';

const IconReplace = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		<path d="M8.5 3.5c1.38 0 2.63.56 3.54 1.46l-1.69 1.69a.5.5 0 0 0 .36.85H15c.28 0 .5-.22.5-.5V2.71c0-.45-.54-.67-.85-.35l-1.2 1.2A6.943 6.943 0 0 0 8.5 1.5c-3.04 0-5.62 1.94-6.58 4.64-.24.66.23 1.36.93 1.36.42 0 .79-.26.93-.66A5.007 5.007 0 0 1 8.5 3.5Zm5.64 9.14c.4-.54.72-1.15.95-1.8.23-.65-.25-1.34-.94-1.34a.98.98 0 0 0-.93.66A5.007 5.007 0 0 1 8.5 13.5c-1.38 0-2.63-.56-3.54-1.46l1.69-1.69a.5.5 0 0 0-.36-.85H2c-.28 0-.5.22-.5.5v4.29c0 .45.54.67.85.35l1.2-1.2a6.984 6.984 0 0 0 9.09.7l4.11 4.11c.41.41 1.08.41 1.49 0 .41-.41.41-1.08 0-1.49l-4.1-4.12Z" />
	</svg>
);

export default IconReplace;

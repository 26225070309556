enum Colors {
	Black = 'rgb(39, 51, 66)',
	Brand = 'rgb(15, 88, 214)',
	White = 'rgb(255, 255, 255)',
	BlueBase = 'rgb(132, 169, 233)',
	BlueDark = 'rgb(79, 132, 224)',
	BlueDarker = 'rgb(51, 85, 145)',
	GreenBase = 'rgb(4, 137, 66)',
	GreenDark = 'rgb(3, 116, 56)',
	GreenDarker = 'rgb(2, 89, 42)',
	OrangeBase = 'rgb(255, 153, 54)',
	OrangeDark = 'rgb(255, 128, 4)',
	OrangeDarker = 'rgb(255, 102, 3)',
	PurpleBase = 'rgb(120, 30, 147)',
	PurpleLight = 'rgb(241, 232, 244)',
	RedBase = 'rgb(204, 0, 0)',
	RedDark = 'rgb(173, 0, 0)',
	RedDarker = 'rgb(132, 0, 0)',
	YellowBase = 'rgb(255, 228, 138)',
	YellowDark = 'rgb(255, 215, 79)',
	YellowDarker = 'rgb(255, 202, 21)',
	Gray100 = 'rgb(235, 236, 238)',
	Gray200 = 'rgb(226, 228, 231)',
	Gray300 = 'rgb(213, 216, 220)',
	Gray400 = 'rgb(199, 202, 208)',
	Gray500 = 'rgb(170, 175, 184)',
	Gray600 = 'rgb(128, 136, 149)',
	Gray700 = 'rgb(103, 111, 124)',
	Gray025 = 'rgb(248, 248, 248)',
	Gray050 = 'rgb(246, 246, 246)',
	Primary200 = 'rgb(231, 238, 250)',
	Primary300 = 'rgb(207, 222, 247)',
	Primary400 = 'rgb(159, 188, 238)',
	Primary500 = 'rgb(98, 146, 228)',
	Primary600 = 'rgb(15, 88, 214)',
	Primary700 = 'rgb(12, 74, 182)',
	Primary800 = 'rgb(9, 57, 139)',
}

export default Colors;
